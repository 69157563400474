export const PlayerSettings = {
    // Enables an option to view the video in fullscreen.
    ALLOW_FULLSCREEN: 1,
    // Controls if the logo of YouTube must be shown on the player or not.
    NO_YT_LOGO: 1,
    // Controls if at the end of the video must be shown videos related (1)
    // or just videos of the same channel (0).
    SHOW_RELATED_CONTENT: 0,
};

export const VIDEO_URL = "https://www.youtube-nocookie.com/embed/PENaZRI7U98";
