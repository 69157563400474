import style from "./FirstSectionLeft.module.scss";
import personHeader from "../../../assets/img-header.png";

const FirstSectionLeft = () => (
    <div className={`${style.section} position-relative`}>
        <img src={personHeader} alt="A person" className="position-absolute" />
    </div>
);

export default FirstSectionLeft;
