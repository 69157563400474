import { useEffect } from "react";

// Hook to trigger a scroll to the top of the page based on the change of a state.
const useScrollToTop = (target) => {
    if (!target) {
        throw new Error("'target' can't be null");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [target]);
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default useScrollToTop;
