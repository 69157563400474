import { request, API_BASE } from "../utils/httpRequest";

export const addBusinessSectorAsync = async (data) => {
    return await request(API_BASE + "/api/businesssector", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getBusinessSectorsAsync = async () => {
    return await request(API_BASE + "/api/businesssector");
};
