export const parseTimezones = (zones) => {
    let processedData = zones.map((entry) => {
        const gmtOffset = entry.gmtOffset / 60 / 60;
        const country = entry.countryName;

        const splittedData = entry.zoneName.split("/");

        let label;

        if (entry.isAlias) {
            label = `(GMT ${gmtOffset}:00) ${country} (${entry.zoneName.replace("_", " ")})`;
        } else {
            // Otherwise, we're with a real timezone
            label = `(GMT ${gmtOffset}:00) ${splittedData[splittedData.length - 1].replace("_", " ")}, ${country}`;
        }

        return {
            value: `gmt${gmtOffset}-${entry.zoneName}${
                /* If it's an alias, differentiate it by adding this */
                entry.isAlias ? `-${entry.countryName.replace(", ", "__").replaceAll(" ", "_")}` : ""
            }`,
            rawHours: gmtOffset,
            label,
        };
    });

    processedData.sort((a, b) => a.rawHours - b.rawHours);

    return processedData;
};
