import { Accordion } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import CustomToggle from "./CustomToggle";
import styles from "./QuestionList.module.scss";

const QuestionList = ({ category }) => {
    const { t } = useTranslation();

    const questionsListHTML = category?.questions?.map((item, index) => {
        return (
            <div key={index} className={styles.questionContainer}>
                <div className={styles.questionHeader}>
                    <CustomToggle eventKey={index}>{t(item.question)}</CustomToggle>
                </div>
                <Accordion.Collapse eventKey={index}>
                    <div className={styles.questionBody}>
                        <Trans components={{ li: <li />, ul: <ul /> }}>{item.answer}</Trans>
                    </div>
                </Accordion.Collapse>
            </div>
        );
    });

    return (
        <div className={`${styles.questionListContainer} ${category.name ? "" : "d-none"}`}>
            <span className={styles.categoryName}>{category?.name}</span>
            <Accordion>{questionsListHTML}</Accordion>
        </div>
    );
};

export default QuestionList;
