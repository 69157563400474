import { API_BASE, request } from "../utils/httpRequest";

export const addPaymentAsync = async (projectId) => {
    return await request(`${API_BASE}/api/payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ projectId }),
    });
};

export const addPaymentProofAsync = async (data) => {
    return await request(`${API_BASE}/api/payment/add-payment-proof`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getPaymentsAsync = async (
    page = 1,
    pageSize = 5,
    projectId = null,
    organizationId = null,
    startDate = null,
    endDate = null,
    stripeStatus = null
) => {
    return await request(
        `${API_BASE}/api/payment?page=${page}&pageSize=${pageSize}${projectId ? "&projectId=" + projectId : ""}${
            organizationId ? "&organizationId=" + organizationId : ""
        }${stripeStatus ? "&stripeStatus=" + stripeStatus : ""}${startDate ? "&startDate=" + startDate : ""}${
            endDate ? "&endDate=" + endDate : ""
        }`
    );
};

export const verifyPaymentAsync = async (projectId) => {
    return await request(`${API_BASE}/api/payment/${projectId}/validate-payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
};

export const rejectPaymentAsync = async (projectId) => {
    return await request(`${API_BASE}/api/payment/${projectId}/reject-payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
};
