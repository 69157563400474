import style from "./SecondSection.module.scss";
import SecondSectionLeft from "./SecondSectionLeft";
import SecondSectionRight from "./SecondSectionRight";

const SecondSection = () => (
    <div className={`${style.section} d-flex flex-column flex-lg-row`}>
        <SecondSectionLeft />
        <SecondSectionRight />
    </div>
);

export default SecondSection;
