import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faXmark, faArrowLeftLong, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import style from "./ProfileInformation.module.scss";
import Modal from "../common/modal/Modal";
import ModalButton from "../common/modal/buttons/ModalButton";
import { ModalButtonVariant } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import Button, { variants as btnVariants } from "../common/Button";
import { getStaffAsync } from "../../services/StaffService";
import {
    createLinkDownloadStaffCvFile,
    createLinkDownloadStaffInformationFile,
    createLinkDownloadStaffShortSheetFile,
} from "../../services/FileService";
import { useForceLogout } from "../../utils/useForceLogout";

const ProfileInformation = () => {
    const [profile, setProfile] = useState();
    const [showNotSatisfiedModal, setShowNotSatisfiedModal] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const forceLogout = useForceLogout();

    useEffect(() => {
        (async () => {
            const id = params.id;

            try {
                const request = await getStaffAsync(id, true);
                if (!request.ok) {
                    if (request.status === 401) {
                        await forceLogout();
                    } else if (request.status === 404) {
                        toast.error(t("profileDoesNotExist"));
                    } else {
                        toast.error(t("unableToFetchProfile") + ": " + (await request.text()));
                    }

                    return;
                }

                const profileData = await request.json();

                setProfile({
                    ...profileData,
                    cvUrl: createLinkDownloadStaffCvFile(id),
                    shortSheetUrl: profileData?.shortSheetFileBlobName
                        ? createLinkDownloadStaffShortSheetFile(id)
                        : null,
                    basicInformationUrl: profileData?.informationFileBlobName
                        ? createLinkDownloadStaffInformationFile(id)
                        : null,
                });
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    const onNotSatisfiedClick = (event) => {
        setShowNotSatisfiedModal(true);
    };

    const onModalConfirmBtnClick = (event) => {
        // TODO Redirect to page
        setShowNotSatisfiedModal(false);
    };

    const onModalCancelBtnClick = (event) => {
        setShowNotSatisfiedModal(false);
    };

    const onBtnBackClick = () => {
        const projectId = searchParams.get("from");
        if (!projectId) {
            toast.error("Unable to determinate where to go back");
            return;
        }

        navigate(Routes.buildTeamInfoResumePath(projectId));
    };

    const profileName = useMemo(() => {
        return profile ? `${profile.name} ${profile.lastName[0]}.` : null;
    }, [profile]);

    return (
        <main className={`${style.container} d-flex justify-content-center`}>
            <div className={`${style.innerContainer} d-flex flex-column position-relative w-100`}>
                <div className={`${style.backgroundRectangle} position-absolute d-none d-lg-block`}></div>

                <Link className={`${style.backBtn} mb-3 mb-lg-0 d-md-none`} to={Routes.TEAM_INFO_RESUME}>
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                    <span className="ms-2">{t("goBack")}</span>
                </Link>

                <div className="d-flex flex-column-reverse flex-md-row  justify-content-between">
                    <div className="d-flex flex-column justify-content-end">
                        <div className="d-flex flex-column">
                            <h1 className={style.name}>{profileName}</h1>
                            <span className={style.positionAndSeniority}>
                                {profile?.developmentRols?.length >= 1 && profile?.developmentRols[0].name}{" "}
                                {profile?.seniority?.name}
                            </span>
                        </div>

                        <div className="d-flex flex-column flex-md-row mt-4 mt-md-2">
                            <span className={style.mail}>{profile?.email}</span>
                        </div>

                        <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 mt-2 mt-md-4">
                            <a
                                href={profile?.cvUrl}
                                className={`${style.downloadCv} d-flex justify-content-center align-items-center px-3 gap-3`}
                                download
                            >
                                <span>{t("downloadCV")}</span>
                                <FontAwesomeIcon icon={faDownload} className={style.downloadIcon} />
                            </a>

                            {profile?.basicInformationUrl && (
                                <a
                                    href={profile?.basicInformationUrl}
                                    className={`${style.downloadCv} d-flex justify-content-center align-items-center px-3 gap-3`}
                                    download
                                >
                                    <span>{t("downloadBasicInformation")}</span>
                                    <FontAwesomeIcon icon={faDownload} className={style.downloadIcon} />
                                </a>
                            )}

                            {profile?.shortSheetUrl && (
                                <a
                                    href={profile?.shortSheetUrl}
                                    className={`${style.downloadCv} d-flex justify-content-center align-items-center px-3 gap-3`}
                                    download
                                >
                                    <span>{t("downloadShortSheet")}</span>
                                    <FontAwesomeIcon icon={faDownload} className={style.downloadIcon} />
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="d-none d-lg-block">
                        <button
                            className={`${style.markAsNotSatisfiedBtn} d-flex align-items-center p-2 mb-lg-0`}
                            onClick={onNotSatisfiedClick}
                        >
                            <FontAwesomeIcon icon={faXmark} className={style.xIcon} />
                            <span className="ms-3">{t("rejectCandidate")}</span>
                        </button>
                    </div>
                </div>

                <div className="d-flex flex-column gap-3 mt-5">
                    <div className="d-flex flex-column">
                        <h2 className={style.about}>{t("summary")}</h2>
                        <p className={`${style.aboutText} w-100`}>{profile?.summary}</p>
                    </div>

                    {profile?.languageEvaluation && (
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-3 align-items-center">
                                <h2 className={style.about}>{t("languageEvaluation")}</h2>

                                {profile?.englishInterviewUrl && (
                                    <a
                                        href={profile.englishInterviewUrl}
                                        target="_blank"
                                        className={style.interviewVideos}
                                    >
                                        {t("interviewVideos")}
                                        <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                            className={style.interviewVideoLinkIcon}
                                        />
                                    </a>
                                )}
                            </div>

                            <p className={`${style.aboutText} w-100`}>{profile?.languageEvaluation}</p>
                        </div>
                    )}
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-4 gap-md-5 mt-5">
                    <Button
                        variant={btnVariants.PRIMARY_INVERSE}
                        cssClasses={[style.backButton, "d-none", "d-md-inline-block"]}
                        onClick={onBtnBackClick}
                    >
                        {t("back")}
                    </Button>

                    <Button className={`${style.requestInterviewBtn} px-4 py-3`}>{t("requestInterview")}</Button>

                    <button
                        className={`${style.markAsNotSatisfiedBtn} d-flex d-md-none align-items-center p-2 mb-lg-0`}
                        onClick={onNotSatisfiedClick}
                    >
                        <FontAwesomeIcon icon={faXmark} className={style.xIcon} />
                        <span className="ms-3">{t("rejectCandidate")}</span>
                    </button>
                </div>
            </div>

            <Modal show={showNotSatisfiedModal}>
                <span className={`${style.modalTitle} d-block mb-2`}>{t("candidateRejection")}</span>

                <p className={style.modalContent}>{t("areYouSureRejectCandidate")}</p>

                <div className="d-flex gap-5 justify-content-center mt-4">
                    <ModalButton onClick={onModalCancelBtnClick} variant={ModalButtonVariant.WHITE}>
                        {t("cancel")}
                    </ModalButton>

                    <ModalButton onClick={onModalConfirmBtnClick} variant={ModalButtonVariant.BLUE}>
                        {t("ok")}
                    </ModalButton>
                </div>
            </Modal>
        </main>
    );
};

export default ProfileInformation;
