import { API_BASE, request } from "../utils/httpRequest";

export const getLanguageAsync = async (id) => {
    return await request(API_BASE + "/api/language/" + id);
};

export const getLanguageListAsync = async () => {
    return await request(API_BASE + "/api/language", {
        method: "GET",
    });
};
