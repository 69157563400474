import { faRotateLeft, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generateLinkForVisualizePhoto } from "../../../../../services/FileService";

import styles from "./ResourceCard.module.scss";

const ResourceCard = ({
    resource,
    onCardClick = null,
    deleteOption = false,
    onDeleteClick = null,
    cursorPointer = true,
}) => {
    const { t } = useTranslation();
    const [showTimeIndicator, setShowTimeIndicator] = useState(false);
    const sendDeleteRef = useRef(true);
    const [sendDelete, setSendDelete] = useState(true);
    const [timeOutId, setTimeOutId] = useState(null);

    useEffect(() => {
        sendDeleteRef.current = sendDelete;
    }, [sendDelete]);

    const handleCardClick = (e) => {
        e.preventDefault();
        if (onCardClick) {
            onCardClick(resource.id);
        }
    };

    const handleUndoClick = (e) => {
        e.preventDefault();
        setSendDelete(false);
        setShowTimeIndicator(false);
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
    };

    const sendDeleteFunction = () => {
        if (onDeleteClick && sendDeleteRef.current) {
            onDeleteClick(resource.id);
        }
    };

    const handleDeleteClick = (e) => {
        e.preventDefault();
        setSendDelete(true);
        setShowTimeIndicator(true);
        const timeoutId = setTimeout(sendDeleteFunction, 5500, sendDelete);
        setTimeOutId(timeoutId);
    };

    return (
        <div
            className={`${styles.mainContent} ${
                cursorPointer ? styles.cursorPointer : ""
            } position-relative d-flex align-items-center`}
            onClick={handleCardClick}
        >
            {showTimeIndicator && (
                <>
                    <div className={`${styles.timeIndicator} ${styles.scaleOutHorRight}`}></div>
                    <div className={`${styles.btnUndo} ${styles.cursorPointer}`} onClick={handleUndoClick}>
                        <FontAwesomeIcon icon={faRotateLeft} className={`${styles.icon} ${styles.iconLg}`} />
                    </div>
                </>
            )}

            {deleteOption && (
                <div className={`${styles.btnDelete} ${styles.cursorPointer}`} onClick={handleDeleteClick}>
                    <FontAwesomeIcon icon={faX} className={styles.icon} />
                </div>
            )}
            <div className={styles.photoSection}>
                {resource.pictureBlobName && (
                    <img
                        className={styles.photoSection}
                        src={generateLinkForVisualizePhoto(resource.pictureBlobName)}
                        alt={resource.pictureBlobName}
                    />
                )}
            </div>
            <div className={styles.contentSection}>
                <span className={styles.item}>
                    {t("name")}: <span className={styles.itemText}>{resource.name}</span>
                </span>
                <span className={styles.item}>
                    {t("roles")}:{" "}
                    <span className={styles.itemText}>
                        {resource.developmentRols?.map((role) => role.name).join(", ")}
                    </span>
                </span>
                <span className={styles.item}>
                    {t("seniority")}: <span className={styles.itemText}>{resource.seniority.name}</span>
                </span>
                <span className={styles.item}>
                    {t("technologies")}:{" "}
                    <span className={styles.itemText}>
                        {resource?.technologies?.map((technology) => technology.name).join(", ")}
                        {resource?.staffCustomValues.length > 0 ? ", " : ""}
                        {resource?.staffCustomValues
                            ?.map((staffCustomValue) => staffCustomValue.customValue.value)
                            .join(", ")}
                    </span>
                </span>
            </div>
        </div>
    );
};

export default ResourceCard;
