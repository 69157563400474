import { useTranslation } from "react-i18next";
import { SEX } from "../../../base/js/constants";
import styles from "./SexSelector.module.scss";

const SexSelector = ({ itemActive, setItemActive }) => {
    const { t } = useTranslation();
    const handleSelectMale = () => {
        setItemActive(SEX.MALE);
    };

    const handleSelectFemale = () => {
        setItemActive(SEX.FEMALE);
    };

    const handleSelectOther = () => {
        setItemActive(SEX.OTHER);
    };

    return (
        <>
            <div className={styles.mainContainer}>
                <div
                    className={`${styles.itemContainer} ${itemActive === SEX.MALE ? styles.itemActive : null}`}
                    onClick={handleSelectMale}
                >
                    <span>{t("male")}</span>
                </div>
                <div
                    className={`${styles.itemContainer} ${itemActive === SEX.FEMALE ? styles.itemActive : null}`}
                    onClick={handleSelectFemale}
                >
                    <span>{t("female")}</span>
                </div>
                <div
                    className={`${styles.itemContainer} ${itemActive === SEX.OTHER ? styles.itemActive : null}`}
                    onClick={handleSelectOther}
                >
                    <span>{t("other")}</span>
                </div>
            </div>
        </>
    );
};

export default SexSelector;
