import React, { useState, useEffect, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { parseISO } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import style from "./DevelopmentTimeline.module.scss";
import { ProjectHistoryEntryTypes, ProjectHistoryParameterTypes } from "../../base/js/constants";
import RoutesPath from "../../base/js/routes";
import { useForceLogout } from "../../utils/useForceLogout";
import { getProjectAsync, getProjectHistory } from "../../services/ProjectService";
import { createLinkDownloadProofOfPaymentFile } from "../../services/FileService";
import { replaceMark } from "../../utils/strings";
const MilestoneCard = React.lazy(() => import("./milestone-card/MilestoneCard"));
const TimelinePoint = React.lazy(() => import("./timeline-point/TimelinePoint"));

const CURRENT_DATE = new Date();

const DevelopmentTimeline = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const forceLogout = useForceLogout();
    const [project, setProject] = useState({});
    const [data, setData] = useState([]);

    useEffect(() => {
        const projectId = params.projectId;
        if (!projectId) {
            toast.error(t("projectIdNotFound"));
            navigate(RoutesPath.LETS_START);

            return;
        }

        (async () => {
            try {
                const response = await getProjectHistory(projectId);

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else if (response.status === 404) {
                        toast.error(t("projectNotFound"));
                        navigate(RoutesPath.LETS_START);
                    } else {
                        const error = await response.json();
                        toast.error(t("unexpectedError") + ": " + error);
                    }

                    return;
                }

                const responseData = await response.json();

                // Parse dates
                const projectHistory = responseData.map((entry) => ({
                    ...entry,
                    createdAt: parseISO(entry.createdAt),
                }));

                // Generate the download link of each payment entry
                for (let i = 0; i < projectHistory.length; i++) {
                    if (projectHistory[i].type !== ProjectHistoryEntryTypes.PAYMENT) {
                        continue;
                    }

                    // We're expecting to have one parameter with the ID of the payment.
                    const paymentId = projectHistory[i].parameters.find(
                        (p) => p.type === ProjectHistoryParameterTypes.PAYMENT_ID
                    )?.content;
                    const paymentProofName = projectHistory[i].parameters.find(
                        (p) => p.type === ProjectHistoryParameterTypes.PAYMENT_PROOF_NAME
                    )?.content;

                    projectHistory[i].downloadLink = {
                        fileName: paymentProofName,
                        url: createLinkDownloadProofOfPaymentFile(paymentId),
                    };
                }

                setData(projectHistory);
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            }
        })();

        (async () => {
            try {
                const response = await getProjectAsync(projectId, true);

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else if (response.status === 404) {
                        toast.error(t("projectNotFound"));
                        navigate(RoutesPath.LETS_START);
                    } else {
                        const error = await response.json();
                        toast.error(t("unexpectedError") + ": " + error);
                    }

                    return;
                }

                const responseData = await response.json();

                // Parse dates
                const projectMapped = {
                    ...responseData,
                    createdAt: parseISO(responseData.createdAt),
                    dateStart: parseISO(responseData.dateStart),
                    dateEnd: parseISO(responseData.dateEnd),
                };

                setProject(projectMapped);
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            }
        })();
    }, []);

    const onCloseBtnClick = (e) => {
        e.preventDefault();
        const url = RoutesPath.buildInDevelopmentPath(params.projectId);
        navigate(url);
    };

    return (
        <main className={style.mainContainer}>
            <div className={`${style.innerContainer} d-flex gap-4 justify-content-center mx-auto`}>
                <div className={style.milestoneCardContainer}>
                    <Suspense
                        fallback={
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t("loading")}...</span>
                            </Spinner>
                        }
                    >
                        <MilestoneCard title={t("projectCreation")} date={project.createdAt} />
                    </Suspense>
                </div>

                <div className={style.milestoneCardContainer}>
                    <Suspense
                        fallback={
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t("loading")}...</span>
                            </Spinner>
                        }
                    >
                        <MilestoneCard title={t("projectStart")} date={project.dateStart} replaceDateWithPlaceholder />
                    </Suspense>
                </div>

                <div className={style.milestoneCardContainer}>
                    <Suspense
                        fallback={
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t("loading")}...</span>
                            </Spinner>
                        }
                    >
                        <MilestoneCard
                            title={t("projectEnd")}
                            date={CURRENT_DATE}
                            disabled
                            replaceDateWithPlaceholder
                        />
                    </Suspense>
                </div>
            </div>

            <div className={`${style.innerContainer} d-flex flex-column gap-3 mt-5 mx-auto`}>
                <Suspense
                    fallback={
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">{t("loading")}...</span>
                        </Spinner>
                    }
                >
                    {data.map((entry) => (
                        <TimelinePoint key={entry.id} date={entry.createdAt} title={t(entry.title)}>
                            {entry.type === ProjectHistoryEntryTypes.PAYMENT
                                ? replaceMark(
                                      t(entry.description),
                                      "{fileDownload}",
                                      <a key={`${entry.id}_downloadLink`} href={entry.downloadLink.url} download>
                                          {entry.downloadLink.fileName}
                                      </a>
                                  )
                                : null}
                        </TimelinePoint>
                    ))}
                </Suspense>
            </div>

            <div className="d-flex justify-content-center mt-5">
                <button
                    type="button"
                    className={`${style.closeBtn} py-3 px-4 text-uppercase`}
                    onClick={onCloseBtnClick}
                >
                    {t("close")}
                </button>
            </div>
        </main>
    );
};

export default DevelopmentTimeline;
