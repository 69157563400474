import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotificationAsync, getNotifications, markNotificationAsRead } from "../../../services/UserService";
import { setNotifications } from "../../../store/slices/userSlice";
import { useForceLogout } from "../../../utils/useForceLogout";
import NotificationContainer from "./notification-container/NotificationContainer";
import style from "./NotificationIndicator.module.scss";

// const dummyNotificationData = [
//     {
//         title: "All resources allocated",
//         content: "All resources allocated to the project",
//         projectName: project.teamName,
//         priority: NotificationPriority.HIGH,
//         timestamp: new Date(),
//         id: uuidv4(),
//     },
// ];

const NotificationIndicator = () => {
    const forceLogout = useForceLogout();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notificationLastUpdate = useSelector((state) => state.user.notifications.lastUpdate);
    const notifications = useSelector((state) => state.user.notifications.items);
    const [pendingNotifications, setPendingNotifications] = useState(false);
    const [isContainerOpen, setIsContainerOpen] = useState(false);

    useEffect(() => {
        setPendingNotifications(notifications.filter((notification) => !notification.readedAt).length > 0);
    }, [notifications]);
    const handleClick = async () => {
        onClick();
        await handleMarkNotificationAsRead();
    };

    const onClick = () => {
        setIsContainerOpen((prevState) => !prevState);
    };

    const handleMarkNotificationAsRead = async () => {
        if (notifications.length > 0) {
            const response = await markNotificationAsRead();
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }
                return;
            }

            const userNotificationsResponse = await getNotifications();
            const parsedBodyNotification = await userNotificationsResponse.json();
            if (!userNotificationsResponse.ok) {
                toast.error(
                    parsedBodyNotification.message
                        ? parsedBodyNotification.message
                        : `Unexpected error (${response.status})`
                );
            } else {
                dispatch(setNotifications({ lastUpdate: new Date(), items: parsedBodyNotification }));
            }
        }
    };

    const onNotificationRemove = async (id) => {
        // setNotifications((prevState) => prevState.filter((notification) => notification.id !== id));
        const response = await deleteNotificationAsync(id);
        if (!response.ok) {
            toast.error(response.message ? response.message : `Unexpected error (${response.status})`);
        } else {
            const userNotificationsResponse = await getNotifications();
            const parsedBodyNotification = await userNotificationsResponse.json();
            if (!userNotificationsResponse.ok) {
                toast.error(
                    parsedBodyNotification.message
                        ? parsedBodyNotification.message
                        : `Unexpected error (${parsedBodyNotification.status})`
                );
            } else {
                dispatch(setNotifications({ lastUpdate: new Date(), items: parsedBodyNotification }));
            }
        }
    };

    return (
        <div>
            <button type="button" className={`${style.container} position-relative`} onClick={handleClick}>
                <FontAwesomeIcon icon={faBell} className={style.icon} />
                {pendingNotifications && <div className={`${style.indicator} position-absolute rounded-circle`} />}
            </button>
            {isContainerOpen && (
                <NotificationContainer
                    notifications={notifications.map((notification) => {
                        return {
                            id: notification.id,
                            title: notification.title,
                            content: notification.description,
                            priority: notification.priority,
                            timestamp: notification.createdAt,
                            projectName: notification.projectName,
                            link: notification.link,
                            area: notification.area,
                            type: notification.type,
                        };
                    })}
                    onNotificationRemove={onNotificationRemove}
                    onClose={onClick}
                    onClickOutside={onClick}
                />
            )}
        </div>
    );
};

export default NotificationIndicator;
