import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO } from "date-fns";
import i18n from "i18next";
import React, { forwardRef, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getProjectTraces } from "../../../services/ProjectTraceService";
import { useForceLogout } from "../../../utils/useForceLogout";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import style from "./AdminProjectTracing.module.scss";
import useProcessTraceDescription from "./UseProcessTraceDescription";

const TimelinePoint = React.lazy(() => import("./timeline-point/TimelinePoint"));

const FromInputFilter = forwardRef(({ value, onClick, id, disabled = false }, ref) => (
    <input
        ref={ref}
        type="text"
        id={id}
        value={value}
        onClick={onClick}
        disabled={disabled}
        className={`${sharedStyle.inputDate} ${disabled ? sharedStyle.disabledInput : ""} px-3 py-2`}
        readOnly
    />
));

const ToInputFilter = forwardRef(({ value, onClick, id, disabled = false }, ref) => (
    <input
        ref={ref}
        type="text"
        id={id}
        value={value}
        onClick={onClick}
        disabled={disabled}
        className={`${sharedStyle.inputDate} ${disabled ? sharedStyle.disabledInput : ""} px-3 py-2`}
        readOnly
    />
));

const AdminProjectTracing = () => {
    const params = useParams();
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const [traces, setTraces] = useState({ data: [], loading: true });
    const [processedTraces, setProcessedTraces] = useState({ data: [], filteredData: [] });
    const [filter, setFilter] = useState({ from: null, to: null });
    const { processTracesDescription } = useProcessTraceDescription();
    const memoizedProcessTracesDescription = useCallback(
        (tracesToProcess) => processTracesDescription(tracesToProcess),
        [traces]
    );

    // Load project traces from the API
    useEffect(() => {
        (async () => {
            try {
                const response = await getProjectTraces(params.id);
                const body = await response.json();

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        toast.error(body.title);
                    }

                    return;
                }

                // Parse dates
                const projectTraces = body.map((entry) => ({
                    ...entry,
                    createdAt: parseISO(entry.createdAt),
                }));

                setTraces((prevState) => ({ ...prevState, data: projectTraces }));
            } catch (e) {
                toast.error(`${t("unexpectedError")}: ${e.message}`);
                console.log(e);
            } finally {
                setTraces((prevState) => ({ ...prevState, loading: false }));
            }
        })();
    }, []);

    const applyFilters = useCallback(
        (traces) => {
            let filteredTraces = [...traces];

            if (filter.from) {
                filteredTraces = filteredTraces.filter((t) => t.createdAt.getTime() >= filter.from.getTime());
            }

            if (filter.to) {
                filteredTraces = filteredTraces.filter((t) => t.createdAt.getTime() <= filter.to.getTime());
            }

            return filteredTraces;
        },
        [traces]
    );

    // Process traces
    useEffect(() => {
        setProcessedTraces((prevState) => {
            if (traces.data.length === 0) {
                return { data: [], filteredData: [] };
            }

            const processedData = memoizedProcessTracesDescription(traces.data);

            return { data: processedData, filteredData: applyFilters(processedData) };
        });
    }, [traces, i18n.resolvedLanguage]);

    // Filter traces by date filters
    useEffect(() => {
        setProcessedTraces((prevState) => {
            return { ...prevState, filteredData: applyFilters(prevState.data) };
        });
    }, [filter]);

    const handleFromFilterChange = (newValue) => {
        setFilter((prevState) => ({ ...prevState, from: newValue }));
    };

    const handleToFilterChange = (newValue) => {
        setFilter((prevState) => ({ ...prevState, to: newValue }));
    };

    const handleClearFilters = () => {
        setFilter({ from: null, to: null });
    };

    return (
        <section className="py-5 d-flex flex-column align-items-center gap-2">
            <h1 className="h1 text-center">{t("projectTracing")}</h1>

            <div className={`${style.filtersContainer} d-flex gap-3 m-3 px-4 py-2 align-items-center`}>
                <div className="d-flex flex-column gap-1">
                    <label htmlFor="fromFilter" className="text-capitalize">
                        {t("from")}
                    </label>
                    <DatePicker
                        id="fromFilter"
                        onChange={handleFromFilterChange}
                        selected={filter.from}
                        customInput={<FromInputFilter />}
                        showPopperArrow={false}
                    />
                </div>

                <div className="d-flex flex-column gap-1">
                    <label htmlFor="toFilter" className="text-capitalize">
                        {t("to3")}
                    </label>
                    <DatePicker
                        id="toFilter"
                        onChange={handleToFilterChange}
                        selected={filter.to}
                        minDate={filter.from}
                        customInput={<ToInputFilter />}
                        showPopperArrow={false}
                    />
                </div>

                <button
                    onClick={handleClearFilters}
                    className={style.clearFilters}
                    disabled={!filter.from && !filter.to}
                >
                    <FontAwesomeIcon icon={faX} />
                    {t("clearFilters")}
                </button>
            </div>

            <div className={`${style.innerContainer} d-flex flex-column gap-3 mt-5 mx-auto`}>
                <Suspense
                    fallback={
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">{t("loading")}...</span>
                        </Spinner>
                    }
                >
                    {processedTraces.filteredData?.map((entry) => (
                        <TimelinePoint
                            key={entry.id}
                            date={entry.createdAt}
                            title={t(entry.title)}
                            author={entry.author}
                        >
                            {entry.processedDescription}
                        </TimelinePoint>
                    ))}
                </Suspense>
            </div>

            {traces.loading && <Loading />}
        </section>
    );
};

export default AdminProjectTracing;
