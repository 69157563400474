import { format, formatISO, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_ALTERNATIVE, PROJECT_STATUS_IDS } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getProjectsByUserAsync, removeProject } from "../../services/ProjectService";
import { useForceLogout } from "../../utils/useForceLogout";
import Button, { variants as btnVariants } from "../common/Button";
import ConfirmProjectDeleteModal from "../common/confirm-project-delete-modal/ConfirmProjectDeleteModal";
import styles from "./ManageTeams.module.scss";
import Table from "./table/Table";

const TABLE_HEADERS = ["", "name", "createdAt", "status", ""];
const ROWS_PER_PAGE = 5;

const ManageTeams = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const forceLogout = useForceLogout();

    const username = useSelector((state) => state.user.username);
    const [activeProject, setActiveProject] = useState(null);
    const [reloadData, setReloadData] = useState(false);
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [tableFilters, setTableFilters] = useState({ searchText: null, startDate: null, endDate: null });
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // Fetch projects from API
    useEffect(() => {
        (async () => {
            try {
                const response = await getProjectsByUserAsync(
                    currentPage,
                    rowsPerPage,
                    tableFilters.startDate ? formatISO(tableFilters.startDate) : null,
                    tableFilters.endDate ? formatISO(tableFilters.endDate) : null,
                    tableFilters.searchText
                );
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();

                const data = responseData.projects?.map((project) => {
                    const handleEditProjectAction = (e) => {
                        e.preventDefault();
                        navigate(Routes.BUILD_YOUR_TEAM + `?from=${project.id}`);
                    };

                    const handleManageProjectAction = (e) => {
                        e.preventDefault();

                        if (
                            project.projectStatus[project.projectStatus.length - 1].status.id ===
                            PROJECT_STATUS_IDS.IN_DEVELOPMENT
                        ) {
                            navigate(Routes.buildInDevelopmentPath(project.id));
                            return;
                        }

                        navigate(Routes.buildTeamInfoResumePath(project.id));
                    };

                    const handleDeleteProjectAction = (e) => {
                        e.preventDefault();
                        setActiveProject(project.id);
                        setShowConfirmationModal(true);
                    };

                    const handleDetailsProjectAction = (e) => {
                        e.preventDefault();
                    };

                    const handleValidateHoursAction = (e) => {
                        e.preventDefault();
                        navigate(Routes.buildValidateHoursPath(project.id));
                    };

                    const generateDefaultActions = () => {
                        let actions = [];

                        if (
                            project.projectStatus[0].status.id == PROJECT_STATUS_IDS.CREATING_NEW_PROJECT ||
                            project.projectStatus[0].status.id == PROJECT_STATUS_IDS.STARTING_SELECTION
                        ) {
                            actions.push({
                                label: "edit",
                                action: handleEditProjectAction,
                                color: "green",
                            });
                        }

                        if (
                            project.projectStatus[0].status.id != PROJECT_STATUS_IDS.CREATING_NEW_PROJECT &&
                            project.projectStatus[0].status.id != PROJECT_STATUS_IDS.STARTING_SELECTION &&
                            project.projectStatus[0].status.id != PROJECT_STATUS_IDS.CANCELLED
                        ) {
                            actions.push({
                                label: "manage",
                                action: handleManageProjectAction,
                                color: "green",
                            });
                        }

                        if (project.projectStatus[0].status.id === PROJECT_STATUS_IDS.CANCELLED) {
                            actions.push({
                                label: "details",
                                action: handleDetailsProjectAction,
                                color: "green",
                            });
                        }

                        if (
                            project.projectStatus[0].status.id === PROJECT_STATUS_IDS.FINISHED ||
                            project.projectStatus[0].status.id === PROJECT_STATUS_IDS.CANCELLED
                        ) {
                            actions.push({
                                label: "delete",
                                action: handleDeleteProjectAction,
                                color: "red",
                            });
                        }

                        if (
                            project.projectStatus[project.projectStatus.length - 1].status.id ===
                                PROJECT_STATUS_IDS.IN_DEVELOPMENT &&
                            project.hasValidationHoursPending
                        ) {
                            actions.push({
                                label: "validateHours",
                                action: handleValidateHoursAction,
                                color: "green",
                            });
                        }

                        return actions;
                    };

                    return {
                        pendingConfirmation: project.hasValidationHoursPending,
                        name: project.name,
                        date: format(parseISO(project.createdAt), DATE_FORMAT_ALTERNATIVE),
                        status: project.projectStatus[0].status.value,
                        actions: generateDefaultActions(),
                    };
                });

                setProjects(data);
                setTotalRows(responseData.total);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [setProjects, currentPage, tableFilters, rowsPerPage, reloadData]);

    const handleBack = () => {
        navigate(Routes.HOME);
    };

    const handleOnPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleOnFilterChange = (newFilters) => {
        setTableFilters(newFilters);
    };

    const handleOnRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleAcceptDeleteProject = async () => {
        try {
            const response = await removeProject(activeProject);
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                    return;
                }

                toast.error(t("unexpectedError") + ": " + (await response.text()));
                return;
            }

            toast.success(t("removeProjectSuccess"));
            setShowConfirmationModal(false);
            setReloadData(!reloadData);
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        }
    };

    const handleCancelDeleteProject = () => {
        setShowConfirmationModal(false);
    };

    return (
        <main className={styles.mainContainer}>
            <section className={styles.container}>
                <h3 className={styles.title}>{t("manageTeams")}</h3>

                <Table
                    headers={TABLE_HEADERS}
                    rows={projects}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleOnPageChange}
                    onRowsPerPageChange={handleOnRowsPerPageChange}
                    onFilterChange={handleOnFilterChange}
                />
                <ConfirmProjectDeleteModal
                    show={showConfirmationModal}
                    onAccept={handleAcceptDeleteProject}
                    onCancel={handleCancelDeleteProject}
                />
            </section>

            <Button cssClasses={[styles.btnBack]} variant={btnVariants.PRIMARY_INVERSE} onClick={handleBack}>
                {t("back")}
            </Button>
        </main>
    );
};

export default ManageTeams;
