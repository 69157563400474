import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidV4 } from "uuid";
import style from "./PasswordStrengthIndicator.module.scss";

const PasswordStrengthIndicator = ({ password, rules, className = "", translateLabels = false }) => {
    const { t } = useTranslation();
    const [passwordRules, setPasswordRules] = useState(() => {
        return rules.map((rule) => {
            return { ...rule, id: uuidV4(), approved: false };
        });
    });

    // Check the rules when the password changes
    useEffect(() => {
        setPasswordRules((prevState) => {
            return prevState.map((rule) => {
                return {
                    ...rule,
                    approved: rule.rule.test(password),
                };
            });
        });
    }, [password, setPasswordRules]);

    return (
        <div className={className}>
            {passwordRules.map((rule) => (
                <div key={rule.id} className="d-flex align-items-center gap-2">
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        className={`${style.check} ${
                            rule.approved ? style.completeCheck : style.pendingCheck
                        } flex-shrink-0`}
                    />

                    <span className={`${style.text} ${rule.approved ? style.completeText : style.pendingText}`}>
                        {translateLabels ? t(rule.label) : rule.label}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default PasswordStrengthIndicator;
