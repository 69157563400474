import Joi from "joi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useJoiValidation = () => {
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState({});

    const formatErrorMessage = (name, errorKey) => {
        // For the error's message we have to do two things:
        // 1. Translate the error message (which is a internationalization key)
        // 2. Translate the name of the field (which is a internationalization key, too)
        // With these two strings we can make a fully translated error message.
        return t(errorKey).replace("{{#label}}", t(name));
    };

    // Update error messages on language change
    useEffect(() => {
        setErrors((prevState) => {
            const newState = {};

            Object.keys(prevState)?.forEach((errorKey) => {
                const name = prevState[errorKey].metadata.name;
                const message = prevState[errorKey].metadata.errorKey;

                newState[errorKey] = {
                    message: formatErrorMessage(name, message),
                    metadata: { ...prevState[errorKey].metadata },
                };
            });

            return newState;
        });
    }, [i18n.resolvedLanguage]);

    const validateSchema = (schema, errorMessages, data) => {
        const joiSchema = Joi.object(schema).messages(errorMessages);

        const result = joiSchema.validate(data, { abortEarly: false });

        const { error } = result;

        if (!error) {
            return 0;
        }

        const errorData = {};

        error.details?.forEach((item) => {
            const name = item.path[0];

            errorData[name] = {
                message: formatErrorMessage(name, item.message),
                metadata: { name, errorKey: item.message },
            };
        });

        setErrors(errorData);

        // Return the number of errors detected
        return Object.keys(errorData).length;
    };

    const validateSubSchemaFromEvent = (schema, errorMessages, event) => {
        const { name, value } = event.target;

        setErrors((prevState) => {
            const errorData = { ...prevState };

            const obj = { [name]: value };
            const s = { [name]: schema[name] };

            const subSchema = Joi.object(s).messages(errorMessages);

            const result = subSchema.validate(obj);
            const { error } = result;

            if (error) {
                errorData[name] = {
                    message: formatErrorMessage(name, error.details[0].message),
                    metadata: { name, errorKey: error.details[0].message },
                };
            } else {
                delete errorData[name];
            }

            return errorData;
        });

        // Return the number of errors detected
        // Note: this may cause an error with the synchronization of the state. Keep an eye on it.
        return Object.keys(errors).length;
    };

    const resetErrors = () => {
        setErrors({});
    };

    return { validateSchema, validateSubSchemaFromEvent, resetErrors, errors };
};

export default useJoiValidation;
