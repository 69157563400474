import style from "./FirstSection.module.scss";
import FirstSectionRight from "./FirstSectionRight";
import FirstSectionLeft from "./FirstSectionLeft";

const FirstSection = () => {
    return (
        <div className={`${style.section} d-flex`}>
            <FirstSectionLeft />
            <FirstSectionRight />
        </div>
    );
};

export default FirstSection;
