import { string, func } from "prop-types";

const Image = ({ src, alt, cssClass, onError }) => {
    const handleError = (event) => {
        if (onError) {
            return onError(event);
        }

        // TODO default implementation of error handling
        console.error("Error trying to load the image", src);
    };

    return <img src={src} alt={alt} className={cssClass} onError={handleError} />;
};

Image.propTypes = {
    src: string.isRequired,
    alt: string.isRequired,
    cssClass: string,
    onError: func,
};

export default Image;
