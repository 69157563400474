import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import RoutesPath from "./../../../base/js/routes";
import styles from "./AdminMenu.module.scss";
import CustomMenu from "./DropdownCustom/CustomMenu";
import CustomToggle from "./DropdownCustom/CustomToggle";

const LINKS = [
    {
        to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_INTERVIEWS}`,
        label: "interviews",
    },
    {
        to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_PROJECTS}`,
        label: "projects",
    },
];

const DROPDOWN_SETTINGS = {
    label: "settings",
    to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}`,
    dropdownLinks: [
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_USERS}`,
            label: "users",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_RESOURCES}`,
            label: "resources",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_TECHNOLOGIES}`,
            label: "technologies",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_ORGANIZATION}`,
            label: "organization",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_TARIFFS}`,
            label: "tariffs",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_REVIEWS}`,
            label: "reviews",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_SURVEYS}`,
            label: "surveys",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_PAYMENTS}`,
            label: "payments",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}/${RoutesPath.ADMIN_SETTINGS_HOURS_VALIDATION}`,
            label: "hoursValidation",
        },
    ],
};

const AdminMenu = () => {
    const { t } = useTranslation();
    const [activeLink, setActiveLink] = useState("");
    const [settingsOpen, setSettingOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const activeLink = DROPDOWN_SETTINGS.dropdownLinks.filter((link) => {
            return link.to === location.pathname;
        });

        if (!!activeLink.length) {
            setActiveLink(activeLink[0]?.label);
        } else {
            setActiveLink("");
        }
    }, [location]);

    const handleLinkStatus = ({ isActive }) => {
        return `${styles.item} ${isActive ? styles.itemActive : undefined}`;
    };

    const handleDropdownItemStatus = ({ isActive }) => {
        return `${styles.dropdownItem} ${isActive ? styles.itemActive : undefined}`;
    };

    const handleDropdownToggle = (show) => {
        setSettingOpen(show);
    };

    return (
        <>
            <div className={styles.mainContainer}>
                {LINKS.length &&
                    LINKS.map((link) => {
                        return (
                            <div className={styles.container} key={uuidv4()}>
                                <NavLink className={handleLinkStatus} to={link.to}>
                                    {t(link.label)}
                                </NavLink>
                            </div>
                        );
                    })}
                <Dropdown className={styles.container} onToggle={handleDropdownToggle}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {t(DROPDOWN_SETTINGS.label)} {activeLink ? ` / ${t(activeLink)}` : undefined}
                        {
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`${styles.icon} ${settingsOpen ? styles.iconOpen : undefined}`}
                            />
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {DROPDOWN_SETTINGS.dropdownLinks.length &&
                            DROPDOWN_SETTINGS.dropdownLinks.map((link) => {
                                return (
                                    <Dropdown.Item key={uuidv4()} as={"li"}>
                                        <NavLink className={handleDropdownItemStatus} to={link.to}>
                                            {t(link.label)}
                                        </NavLink>
                                    </Dropdown.Item>
                                );
                            })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
};

export default AdminMenu;
