import { Trans, useTranslation } from "react-i18next";
import Button, { textVariants as btnTextVariants, variants as btnVariants } from "../../common/Button";
import style from "./FirstSectionRight.module.scss";

const FirstSectionRight = () => {
    const { t } = useTranslation();

    return (
        <div className={`${style.section} d-flex flex-column align-items-stretch align-items-lg-end`}>
            <h1 className="align-self-start align-self-lg-auto">
                <Trans
                    components={{
                        span1: <span className={`${style.hasTitleMargin} tdb-text-brown d-block`} />,
                        span2: <span className={`${style.changedTitleMargin} text-italic`} />,
                    }}
                >
                    hiringHasChanged
                </Trans>
            </h1>
            <div className={style.line} />
            <p className="text-start text-lg-end">{t("acquireQualifiedIT")}</p>
            <p className="fw-bold mt-4 mb-4">{t("tdbComesIn")}</p>
            <Button textVariant={btnTextVariants.DARK} variant={btnVariants.LIGHT_BROWN}>
                {t("getAQuote")}
            </Button>
        </div>
    );
};

export default FirstSectionRight;
