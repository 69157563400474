import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import toast from "react-hot-toast";
import AdminTable from "../admin-tables/AdminTable";
import Paginator from "../../common/paginator/Paginator";
import Label from "../../common/custom-label/Label";
import { TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import useJoiValidation from "../../hooks/UseJoiValidation";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import Loading from "../../loading/Loading";
import {
    getRoleListAsync,
    getUserListAsync,
    getUserAsync,
    adminUpdateUserAsync,
    toggleUserStatus,
} from "../../../services/AdminService";
import { useForceLogout } from "../../../utils/useForceLogout";
import Selector from "react-select";
import { getAllOrganizationListAsync } from "../../../services/OrganizationService";
import { adminUserRegisterAsync } from "../../../services/AuthService";
import styles from "./AdminUsers.module.scss";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import ConfirmUserStatusModal from "./confirm-user-status-modal/ConfirmUserStatusModal";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import { Form } from "react-bootstrap";

const BASE_SCHEMA = {
    name: Joi.string().max(50).required(),
    email: Joi.string()
        .max(255)
        .email({ tlds: { allow: false } })
        .required(),
    role: Joi.object({
        value: Joi.string().empty("").required(),
        label: Joi.string().empty("").required(),
    }),
};

const SCHEMA_ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "****",
    "string.email": "invalidEmailFormat",
};

const USER_ACTIONS = {
    ADD: "adding",
    EDIT: "editing",
};

const AdminUsers = () => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [addBtnLabel, setAddBtnLabel] = useState(USER_ACTIONS.ADD);
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [usersData, setUsersData] = useState([]);
    const [usersTotal, setUsersTotal] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [roleList, setRoleList] = useState({ roles: [], isLoading: false });
    const [organizationList, setOrganizationList] = useState({ organizations: [], isLoading: false });
    const [filterRole, setFilterRole] = useState(null);
    const [filterOrganization, setFilterOrganization] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [userActive, setUserActive] = useState(null);

    const [role, setRole] = useState({ value: "", label: "" });
    const [organization, setOrganization] = useState({ value: "", label: "" });
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        (async () => {
            const response = await getUserListAsync(
                activePage,
                totalPerPage,
                searchInput,
                filterRole?.value,
                filterOrganization?.value,
                filterInactive
            );

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else if (response.status === 403) {
                    toast.error(t("insufficientPermissions"));
                } else {
                    toast.error(await response.text());
                }

                return;
            }

            const responseData = await response.json();

            const data = responseData.users.map((user) => {
                const handleUserEdit = async (e) => {
                    e.preventDefault();

                    setUserActive(user.id);

                    const response = await getUserAsync(user.id);
                    if (!response.ok) {
                        if (response.status === 401) {
                            await forceLogout();
                            return;
                        }

                        toast.error(t("unexpectedError") + ": " + (await response.text()));
                        return;
                    }

                    let userData = await response.json();

                    setName(userData.name);
                    setEmail(userData.email);
                    setPhone(userData.phoneNumber);
                    setRole({
                        value: userData.role.id,
                        label: userData.role.name,
                    });
                    setOrganization({
                        value: userData.organization ? userData.organization.id : "",
                        label: userData.organization ? userData.organization.name : "",
                    });

                    setAddBtnLabel(USER_ACTIONS.EDIT);
                    setShowForm(true);
                };

                const handleActiveChange = async (e) => {
                    e.preventDefault();
                    setUserActive(user.id);
                    setShowConfirmationModal(true);
                };

                return {
                    name: user.name,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    organization: user.organization?.name,
                    active: { isActive: user.isActive, action: handleActiveChange },
                    actions: [
                        {
                            label: "edit",
                            action: handleUserEdit,
                            color: "",
                        },
                    ],
                };
            });

            setUsersTotal(responseData.total);
            setUsersData(data);
        })();
    }, [activePage, totalPerPage, searchInput, reloadData, filterRole, filterOrganization, filterInactive]);

    useEffect(() => {
        (async () => {
            setRoleList((prevState) => {
                return {
                    ...prevState,
                    isLoading: true,
                };
            });

            const response = await getRoleListAsync();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else if (response.status === 403) {
                    toast.error(t("insufficientPermissions"));
                } else {
                    toast.error(await response.text());
                }

                return;
            }

            const responseData = await response.json();

            setRoleList({
                isLoading: false,
                roles: responseData.map((role) => {
                    return {
                        value: role.id,
                        label: role.name,
                    };
                }),
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setOrganizationList((prevState) => {
                return {
                    ...prevState,
                    isLoading: true,
                };
            });

            const response = await getAllOrganizationListAsync();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else if (response.status === 403) {
                    toast.error(t("insufficientPermissions"));
                } else {
                    toast.error(await response.text());
                }

                return;
            }

            const responseData = await response.json();

            setOrganizationList({
                isLoading: false,
                organizations: responseData.map((organization) => {
                    return {
                        value: organization.id,
                        label: organization.name,
                    };
                }),
            });
        })();
    }, []);

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const handleInputChange = (e) => {
        e.preventDefault();

        switch (e.target.id) {
            case "search":
                setSearchInput(e.target.value);
                break;
            case "name":
                setName(e.target.value);
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
                break;
            case "email":
                setEmail(e.target.value);
                // We won't be validating the format of the email here to avoid showing an error when the user
                // just started to write it.
                break;
            case "phone":
                setPhone(e.target.value);
                break;
            case "password":
                setPassword(e.target.value);
                break;
        }
    };

    const validateEmail = (e) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleRoleChange = (selectedRole) => {
        setRole(selectedRole);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "role", value: selectedRole, name: "role" },
        });
    };

    const handleOrganizationChange = (selectedOrganization) => {
        setOrganization(selectedOrganization);
        console.log("on change: ", organization);
    };

    const handleFilterRoleChange = (selectedRole) => {
        setFilterRole(selectedRole);
    };

    const handleFilterOrganizationChange = (selectedOrganization) => {
        setFilterOrganization(selectedOrganization);
    };

    const handleFilterInactiveChange = (e) => {
        setFilterInactive(!filterInactive);
    };

    const clearForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setRole({ value: "", label: "" });
        setOrganization({ value: "", label: "" });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: name,
            email: email,
            role: role,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!!cantErrors) {
            return;
        }

        switch (addBtnLabel) {
            case USER_ACTIONS.ADD:
                await handleFormSubmitAdd();
                break;
            case USER_ACTIONS.EDIT:
                await handleFormSubmitEdit(userActive);
                break;
        }
    };

    const handleFormSubmitAdd = async () => {
        try {
            const dataToSend = {
                name: name,
                email: email,
                phoneNumber: phone,
                roleId: role.value,
                organizationId: organization.value,
            };

            setLoading(true);

            const response = await adminUserRegisterAsync(dataToSend);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success("User successfully created");

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmitEdit = async (userId) => {
        try {
            const dataToSend = {
                name: name,
                email: email,
                phoneNumber: phone,
                password: password,
                roleId: role.value,
                organizationId: organization.value,
            };

            setLoading(true);

            const response = await adminUpdateUserAsync(userId, dataToSend);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success("User successfully updated");

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormCancel = () => {
        setShowForm(false);
        setUserActive(null);
        setAddBtnLabel(USER_ACTIONS.ADD);
        clearForm();
    };

    const handleClickAdd = () => {
        setShowForm(true);
    };

    const handleAcceptChangeUserStatus = async () => {
        try {
            setLoading(true);

            const response = await toggleUserStatus(userActive);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success("User successfully updated");
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setShowConfirmationModal(false);
            setLoading(false);
        }
    };

    const handleCancelChangeUserStatus = () => {
        setShowConfirmationModal(false);
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        {/*<Label htmlFor="search">{t("search")}</Label>*/}
                        <input
                            id="search"
                            name="search"
                            className={`${sharedStyle.inputText} px-2`}
                            value={searchInput}
                            onChange={handleInputChange}
                            placeholder={`${t("search")}...`}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterRole"
                            isLoading={roleList.isLoading}
                            options={roleList.roles}
                            value={filterRole}
                            onChange={handleFilterRoleChange}
                            styles={getSelectorStyle(false)}
                            isClearable
                            placeholder={`${t("select")} ${t("role").toLowerCase()}...`}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterOrganization"
                            options={organizationList.organizations}
                            value={filterOrganization}
                            onChange={handleFilterOrganizationChange}
                            styles={getSelectorStyle(false)}
                            placeholder={`${t("select")} ${t("organization").toLowerCase()}...`}
                            isClearable
                        />
                    </div>
                    <div className={`${styles.inactiveFilterContainer} d-flex align-items-center gap-2`}>
                        <Form.Check
                            id={`inactiveFilter`}
                            type="switch"
                            checked={filterInactive}
                            className={`${sharedStyles.customSwitch}`}
                            onChange={handleFilterInactiveChange}
                        />
                        <Label htmlFor="inactiveFilter">{t("inactive")}</Label>
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    <button className={`${styles.btnAdd} btn btn-success`} disabled={showForm} onClick={handleClickAdd}>
                        {showForm ? t(addBtnLabel).toUpperCase() : t("add").toUpperCase()}
                    </button>
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={styles.formContainer}>
                        <form onSubmit={handleFormSubmit} className="py-3">
                            <div className="d-flex m-3 flex-column gap-5">
                                <div className={styles.formRow}>
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="name" requiredIndicator>
                                            {t("fullName")}
                                        </Label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className={`${sharedStyle.inputText} ${
                                                !errors.name ? "" : sharedStyle.invalidField
                                            } px-3 py-2`}
                                            value={name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.name.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="email" requiredIndicator>
                                            {t("email")}
                                        </Label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className={`${sharedStyle.inputText} ${
                                                !errors.email ? "" : sharedStyle.invalidField
                                            } px-3 py-2`}
                                            value={email}
                                            onChange={handleInputChange}
                                            onBlur={validateEmail}
                                        />
                                        {errors.email && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.email.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="phone">{t("phoneNumber")}</Label>
                                        <input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            className={`${sharedStyle.inputText} px-3 py-2`}
                                            value={phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className={styles.formRow}>
                                    {addBtnLabel === USER_ACTIONS.EDIT && (
                                        <div className={styles.fieldContainer}>
                                            <Label htmlFor="password">{t("password")}</Label>
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                className={`${sharedStyle.inputText} px-3 py-2`}
                                                value={password}
                                                onChange={handleInputChange}
                                            />
                                            {errors.role && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.role.message}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="role" requiredIndicator>
                                            {t("role")}
                                        </Label>
                                        <Selector
                                            name="role"
                                            isDisabled={!roleList.roles.length}
                                            isLoading={roleList.isLoading}
                                            options={roleList.roles}
                                            value={role}
                                            onChange={handleRoleChange}
                                            styles={getSelectorStyle(!!errors.role)}
                                        />
                                        {errors.role && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.role.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="organization">{t("organization")}</Label>
                                        <Selector
                                            name="organization"
                                            isDisabled={!organizationList.organizations.length}
                                            isLoading={organizationList.isLoading}
                                            options={organizationList.organizations}
                                            value={organization}
                                            onChange={handleOrganizationChange}
                                            styles={getSelectorStyle(!!errors.organization)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-end gap-3">
                                <button
                                    type="reset"
                                    className="btn btn-danger text-uppercase"
                                    onClick={handleFormCancel}
                                >
                                    {t("cancel")}
                                </button>
                                <button type="submit" className="btn btn-success text-uppercase">
                                    {t("save")}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <AdminTable
                    headers={["name", "email", "phoneNumber", "organization", "active", ""]}
                    data={usersData ? usersData : []}
                />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(usersTotal ? usersTotal / totalPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}
            <ConfirmUserStatusModal
                show={showConfirmationModal}
                onAccept={handleAcceptChangeUserStatus}
                onCancel={handleCancelChangeUserStatus}
            />
        </>
    );
};

export default AdminUsers;
