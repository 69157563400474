import { API_BASE, request } from "../utils/httpRequest";

export const getUserContextualInfo = () => {
    return request(`${API_BASE}/api/user/contextual-info`);
};

export const getUserInformation = () => {
    return request(`${API_BASE}/api/user/information`);
};

export const getNotifications = (lastUpdate = null) => {
    return request(`${API_BASE}/api/user/notifications${lastUpdate ? "?lastUpdate=" + lastUpdate : ""}`);
};

export const markNotificationAsRead = () => {
    return request(`${API_BASE}/api/user/mark-notifications-as-read`, {
        method: "POST",
    });
};

export const deleteNotificationAsync = async (notificationId) => {
    return await request(`${API_BASE}/api/user/notification/${notificationId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
    });
};

export const updateNewsletterPrefs = async (wantsNewsletter) => {
    return await request(`${API_BASE}/api/user/newsletter-prefs`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ wantsNewsletter }),
    });
};
