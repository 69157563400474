import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const useHasRole = (roles = []) => {
    const userRoles = useSelector((state) => state.user.roles);
    let hasRole = false;

    roles.forEach((role) => {
        if (userRoles.includes(role)) {
            hasRole = true;
        }
    });

    return hasRole;
};

useHasRole.propTypes = {
    roles: PropTypes.array.isRequired,
};

export default useHasRole;
