import { Form } from "react-bootstrap";
import style from "./Checkbox.module.scss";

const Checkbox = ({ children, checked, onChange, id = null, name, isValid, className = null, disabled = false }) => {
    const checkboxId = `${id ? id : "checkbox-" + name}`;

    return (
        <div className={`d-flex align-items-center${className ? " " + className : ""}`}>
            <Form.Check
                type="checkbox"
                id={checkboxId}
                name={name}
                className={`me-3 ${disabled ? style.disabled : ""}`}
                checked={checked}
                onChange={onChange}
                isInvalid={!isValid}
                disabled={disabled}
            />
            <label htmlFor={checkboxId}>{children}</label>
        </div>
    );
};

export default Checkbox;
