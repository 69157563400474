import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username: "",
    email: "",
    roles: [],
    pendingSurvey: false,
    notifications: {
        lastUpdate: new Date(),
        items: [],
    },
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUsername(state, { payload }) {
            state.username = payload;
        },
        setEmail(state, { payload }) {
            state.email = payload;
        },
        setRoles(state, { payload }) {
            state.roles = payload;
        },
        setPendingSurvey(state, { payload }) {
            state.pendingSurvey = payload;
        },
        setNotifications(state, { payload }) {
            state.notifications = payload;
        },
    },
});

export const { setUsername, setEmail, setRoles, setPendingSurvey, setNotifications } = userSlice.actions;

export default userSlice.reducer;
