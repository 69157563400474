import { useDrag } from "react-dnd";
import style from "./Pill.module.scss";
import { PillVariants } from "../../constants";

const DraggablePill = ({ skill, onClick, selected = false, variant = PillVariants.NEUTRAL }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "pill",
        item: skill,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    let variantCss;

    switch (variant) {
        case PillVariants.NEUTRAL:
            variantCss = "";
            break;
        case PillVariants.GREEN:
            variantCss = style.greenButton;
            break;
        case PillVariants.ORANGE:
            variantCss = style.orangeButton;
            break;
        default:
            throw new Error(`Unrecognized Pill variant "${variant}"`);
    }

    return (
        <div
            ref={drag}
            onClick={(_) => onClick(skill)}
            className={`${style.container} ${variantCss}`}
            style={{ opacity: isDragging ? 0.2 : 1, transform: "translate(0, 0)" }}
            title={skill.name}
        >
            {!selected && <span className={style.indicator}>+</span>}
            <span className={style.text}>{skill.name}</span>
        </div>
    );
};

export default DraggablePill;
