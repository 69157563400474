import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Col, DropdownButton, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4, v4 as uuidV4 } from "uuid";
import routes from "../../../base/js/routes";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import style from "./TableResume.module.scss";

const TableResume = ({
    header = [],
    data = [],
    total = 0,
    viewMoreLink = routes.HOME,
    showFootContent = true,
    showHoursPendingHelp = false,
    handleClickNotes = null,
}) => {
    const { t } = useTranslation();

    return (
        <table className={`${style.table} w-100`}>
            <thead>
                <tr>
                    {header.map((head) => {
                        return <th key={uuidV4()}>{t(head)}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ? (
                    data?.map((columns, index) => {
                        const rowContent = [];
                        for (const key in columns) {
                            const handleClickReadMore = (e) => {
                                e.preventDefault();
                                if (typeof handleClickNotes === "function") {
                                    handleClickNotes(columns.id, columns[key]);
                                }
                            };

                            if (key === "actions") {
                                rowContent.push(
                                    <td key={uuidV4()}>
                                        <DropdownButton
                                            id="dropdown-basic-button"
                                            title={t("actions")}
                                            size="sm"
                                            variant="secondary"
                                            disabled={columns[key].length === 0}
                                            className={style.dropdownColor}
                                        >
                                            {columns[key].map((action) => {
                                                const CustomDropdownItem = forwardRef(({ children, onClick }, ref) => {
                                                    if (typeof action.action === "function") {
                                                        return (
                                                            <a
                                                                className={`dropdown-item-${action.color}`}
                                                                ref={ref}
                                                                href={"#"}
                                                                onClick={action.action}
                                                            >
                                                                {t(action.label)}
                                                            </a>
                                                        );
                                                    } else {
                                                        return (
                                                            <a
                                                                className={`dropdown-item-${action.color}`}
                                                                target="_blank"
                                                                href={action.action}
                                                            >
                                                                {t(action.label)}
                                                            </a>
                                                        );
                                                    }
                                                });

                                                return (
                                                    <Dropdown.Item
                                                        as={CustomDropdownItem}
                                                        key={uuidV4()}
                                                        href="#"
                                                    ></Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </td>
                                );
                            } else if (key === "active") {
                                rowContent.push(
                                    <td key={uuidV4()}>
                                        <Form.Check
                                            id={`active-${uuidV4()}`}
                                            type="switch"
                                            checked={columns[key].isActive}
                                            className={`${sharedStyles.customSwitch}`}
                                            onChange={columns[key].action}
                                        />
                                    </td>
                                );
                            } else if (key === "pendingConfirmation") {
                                rowContent.push(
                                    columns[key] ? (
                                        <td key={uuidV4()} style={{ width: "5px" }}>
                                            <div className="d-flex align-items-center gap-1">
                                                <span className={`${style.pendingConfirmation} me-2`}></span>
                                            </div>
                                        </td>
                                    ) : (
                                        <td key={uuidv4()}></td>
                                    )
                                );
                            } else if (key === "notes") {
                                if (columns[key].length > 0) {
                                    rowContent.push(
                                        <td key={uuidV4()}>
                                            <div className={style.noteContainer}>
                                                <span>{`${columns[key][columns[key].length - 1]?.userName}: `}</span>
                                                <br />
                                                <span>{`${
                                                    columns[key][columns[key].length - 1]?.note.length > 50
                                                        ? columns[key][columns[key].length - 1]?.note.slice(0, 50) +
                                                          "..."
                                                        : columns[key][columns[key].length - 1]?.note
                                                }`}</span>
                                                <button
                                                    disabled={columns["status"] === "validated" ? true : false}
                                                    className={`${style.readMoreLink} ${
                                                        columns["status"] === "validated" ? style.btnDisabled : ""
                                                    }`}
                                                    onClick={handleClickReadMore}
                                                >
                                                    {t("readMore")}
                                                </button>
                                            </div>
                                        </td>
                                    );
                                } else {
                                    rowContent.push(
                                        <td key={uuidV4()}>
                                            <div className={style.noteContainer}>
                                                <button
                                                    disabled={columns["status"] === "validated" ? true : false}
                                                    className={`${style.readMoreLink} ${
                                                        columns["status"] === "validated" ? style.btnDisabled : ""
                                                    }`}
                                                    onClick={handleClickReadMore}
                                                >
                                                    {t("addNote")}
                                                </button>
                                            </div>
                                        </td>
                                    );
                                }
                            } else if (
                                key === "status" &&
                                (columns[key] === "creating" ||
                                    columns[key] === "pending" ||
                                    columns[key] === "validated")
                            ) {
                                let extraClass = "";
                                switch (columns[key]) {
                                    case "creating":
                                        extraClass = style.statusCreating;
                                        break;
                                    case "pending":
                                        extraClass = style.statusPending;
                                        break;
                                    case "validated":
                                        extraClass = style.statusValidated;
                                        break;
                                }

                                rowContent.push(
                                    <td key={uuidV4()}>
                                        <span className={`${style.status} ${extraClass}`}>{t(columns[key])}</span>
                                    </td>
                                );
                            } else if (key === "id") {
                                // Ignore when the column is ID
                            } else {
                                rowContent.push(<td key={uuidV4()}>{columns[key]}</td>);
                            }
                        }

                        return <tr key={index}>{rowContent}</tr>;
                    })
                ) : (
                    <tr>
                        <td colSpan={header.length}>
                            <span className={`${style.noData} d-flex justify-content-center`}>{t("noDataToShow")}</span>
                        </td>
                    </tr>
                )}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={header.length} className="px-3">
                        {showFootContent && (
                            <div className="d-flex justify-content-between align-items-center">
                                <span>{`${t("showing")} ${data.length} ${t("to2")} ${total}`}</span>
                                {showHoursPendingHelp && (
                                    <div className="d-flex">
                                        <span className={`${style.pendingConfirmation} me-2`}></span>
                                        <span>{t("hoursPendingConfirmation")}</span>
                                    </div>
                                )}
                                <span className={style.viewMore}>
                                    <a href={viewMoreLink}>{t("viewMore")}...</a>
                                </span>
                            </div>
                        )}
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

TableResume.propTypes = {
    header: PropTypes.array,
    data: PropTypes.array,
    total: PropTypes.number,
    viewMoreLink: PropTypes.string,
    showFootContent: PropTypes.bool,
    showHoursPendingHelp: PropTypes.bool,
    handleClickNotes: PropTypes.func,
};

export default TableResume;
