import { useEffect, useMemo, useState } from "react";
import Joi from "joi";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useJoiValidation from "../hooks/UseJoiValidation";
import { LettersRegex, NumbersRegex } from "../../utils/formValidation";
import { getUserInformation } from "../../services/UserService";
import { postContactRequest } from "../../services/ContactUsService";

export const FormFieldsName = {
    NAME: "name",
    PHONE: "phone",
    COMPANY: "company",
    EMAIL: "email",
    MESSAGE: "message",
    NEWSLETTER: "newsletter",
};

const DEFAULT_FORM_STATE = {
    [FormFieldsName.NAME]: "",
    [FormFieldsName.PHONE]: "",
    [FormFieldsName.COMPANY]: "",
    [FormFieldsName.EMAIL]: "",
    [FormFieldsName.MESSAGE]: "",
    [FormFieldsName.NEWSLETTER]: false,
};

const VALIDATION_SCHEMA = {
    [FormFieldsName.NAME]: Joi.string().max(255).required(),
    [FormFieldsName.PHONE]: Joi.string().max(20).required(),
    [FormFieldsName.COMPANY]: Joi.string().max(255),
    [FormFieldsName.EMAIL]: Joi.string()
        .max(255)
        .email({ tlds: { allow: false } })
        .required(),
    [FormFieldsName.MESSAGE]: Joi.string().max(3000).required(),
    [FormFieldsName.NEWSLETTER]: Joi.boolean().required(),
};

const ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.with": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.email": "invalidEmailFormat",
};

const useFormLogic = () => {
    const { t } = useTranslation();
    const { validateSubSchemaFromEvent, validateSchema, errors } = useJoiValidation();
    const [data, setData] = useState(DEFAULT_FORM_STATE);
    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);

    const isUserLogged = useMemo(() => !!user.username && !!user.email, [user]);

    useEffect(() => {
        if (!isUserLogged) return;

        (async () => {
            try {
                setIsLoading(true);
                const response = await getUserInformation();

                // If we get a failed response, return
                if (!response.ok) {
                    if (response.code === 500) {
                        toast.error(t("unableToAutocomplete"));
                    }

                    return;
                }

                const parsedData = await response.json();

                setData((prevState) => {
                    return {
                        ...prevState,
                        [FormFieldsName.NAME]: parsedData.name,
                        [FormFieldsName.EMAIL]: parsedData.email,
                        [FormFieldsName.PHONE]: parsedData.phoneNumber,
                        [FormFieldsName.COMPANY]: parsedData.organization ? parsedData.organization.name : "",
                        [FormFieldsName.NEWSLETTER]: parsedData.newsletter,
                    };
                });
            } catch (e) {
                if (e instanceof TypeError) {
                    toast.error(t("userOffline"));
                } else {
                    toast.error(t("unexpectedError") + ": " + e.message);
                }
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const processInput = (value, previousValue, fieldName) => {
        if (value === "") {
            return value;
        }

        switch (fieldName) {
            case FormFieldsName.NAME:
            case FormFieldsName.COMPANY: {
                if (value.length > 255) {
                    value = value.slice(0, 255);
                }

                return LettersRegex.test(value) ? value : previousValue;
            }
            case FormFieldsName.MESSAGE: {
                if (value.length > 3000) {
                    value = value.slice(0, 3000);
                }

                return value;
            }
            case FormFieldsName.EMAIL: {
                if (value.length > 255) {
                    value = value.slice(0, 255);
                }

                return value;
            }
            case FormFieldsName.PHONE: {
                if (value.length > 20) {
                    value = value.slice(0, 20);
                }

                return NumbersRegex.test(value) ? value : previousValue;
            }
            default:
                throw new Error(`Unable to process input of field "${fieldName}"`);
        }
    };

    const onChange = (event) => {
        // The checkbox need to be handled differently since we get the value from event.target.checked, not event.target.value
        if (event.target.name === FormFieldsName.NEWSLETTER) {
            setData({
                ...data,
                [event.target.name]: event.target.checked,
            });

            return;
        }

        setData((prevState) => {
            event.target.value = processInput(event.target.value, prevState[event.target.name], event.target.name);

            // Check everything minus email, which will be validated onBlur
            if (event.target.name !== FormFieldsName.EMAIL) {
                validateSubSchemaFromEvent(VALIDATION_SCHEMA, ERROR_MESSAGES, event);
            }

            return {
                ...data,
                [event.target.name]: event.target.value,
            };
        });
    };

    const onEmailBlur = (event) => {
        validateSubSchemaFromEvent(VALIDATION_SCHEMA, ERROR_MESSAGES, event);
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        const numberOfErrors = validateSchema(VALIDATION_SCHEMA, ERROR_MESSAGES, data);

        if (numberOfErrors > 0) {
            return;
        }

        try {
            const body = {
                name: data[FormFieldsName.NAME],
                phoneNumber: data[FormFieldsName.PHONE],
                companyName: data[FormFieldsName.COMPANY],
                email: data[FormFieldsName.EMAIL],
                newsletter: data[FormFieldsName.NEWSLETTER],
                message: data[FormFieldsName.MESSAGE],
            };

            setIsLoading(true);
            const response = await postContactRequest(body);
            setIsLoading(false);

            if (!response.ok) {
                if (response.status === 500) {
                    toast.error(t("serverError"));
                }

                return;
            }

            setData(DEFAULT_FORM_STATE);
            toast.success(t("weWillGetInTouch"));
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error(t("userOffline"));
            } else {
                toast.error(t("unexpectedError") + ": " + error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return { onChange, onSubmit, data, errors, onEmailBlur, isLoading };
};

export default useFormLogic;
