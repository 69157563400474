import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addMonths, format, parse } from "date-fns";
import { FormElementsIds as ssElementsIds } from "../second-section/SecondSection";
import {
    FormElementsIds as FirstSectionElementsIds,
    RoleCheckboxNames as FirstSectionCheckboxNames,
} from "../first-section/FirstSection";
import {
    ALLOWED_MIMETYPES,
    CharsLimit,
    ContainerType,
    DATE_FORMAT,
    MAX_ATTACHMENT_FILESIZE,
    NativeEventTypes,
} from "../constants";
import {
    setAdditionalComments,
    setAttachment,
    setConfirmationDate,
    setCustomSkillDesired,
    setCustomSkillMandatory,
    setDecisionFlow,
    setEndDate,
    setInvestmentExpectation,
    setProjectType,
    setRoleInteractingCustom,
    setRolesInteracting,
    setSkills,
    setStartDate,
    setTeamArea,
    setTeamName,
    setTimezone,
} from "../../../../store/slices/assembleYourTeamSlice";
import { uploadFileAsync } from "../../../../services/FileService";

export const useFormOnChange = (validationSchema, schemaErrorMessages, validateSubSchemaFromEvent) => {
    const schema = useRef(validationSchema);
    const errorMessages = useRef(schemaErrorMessages);
    const [checkboxesIds] = useState(() =>
        Object.keys(FirstSectionElementsIds.checkboxes).map((key) => FirstSectionElementsIds.checkboxes[key])
    );

    const selectedCheckboxes = useSelector((state) => state.assembleYourTeam.rolesInteracting);
    const roleInteractingCustom = useSelector((state) => state.assembleYourTeam.roleInteractingCustom);
    const endDate = useSelector((state) => state.assembleYourTeam.endDate);

    const dispatch = useDispatch();

    const processInputValueLength = (event, charsLimit) => {
        return event.nativeEvent.inputType === NativeEventTypes.ON_PASTE && event.target.value.length > charsLimit
            ? event.target.value.slice(0, charsLimit)
            : event.target.value;
    };

    const onCheckboxChange = (event) => {
        const newValue = {
            ...selectedCheckboxes,
            [event.target.name]: event.target.checked,
        };

        dispatch(setRolesInteracting(newValue));
    };

    const onRolesOthersChange = (event) => {
        if (!selectedCheckboxes[FirstSectionCheckboxNames.OTHERS]) {
            throw new Error(
                `If the checkbox "${FirstSectionCheckboxNames.OTHERS}" is not checked,` +
                    ` the contents of the input with ID "${event.target.id}" are not supposed to change`
            );
        }

        if (event.nativeEvent.inputType === NativeEventTypes.ON_INPUT && event.target.value > CharsLimit.ROLES_OTHER)
            return;

        dispatch(setRoleInteractingCustom(processInputValueLength(event, CharsLimit.ROLES_OTHER)));
    };

    const onInputTextChange = (event) => {
        switch (event.target.id) {
            case FirstSectionElementsIds.TEAM_NAME: {
                if (
                    event.nativeEvent.inputType === NativeEventTypes.ON_INPUT &&
                    event.target.value.length > CharsLimit.TEAM_NAME
                ) {
                    return;
                }

                dispatch(setTeamName(processInputValueLength(event, CharsLimit.TEAM_NAME)));

                break;
            }
            case FirstSectionElementsIds.DECISION_FLOW: {
                if (
                    event.nativeEvent.inputType === NativeEventTypes.ON_INPUT &&
                    event.target.value.length > CharsLimit.DECISION_FLOW
                ) {
                    return;
                }

                dispatch(setDecisionFlow(processInputValueLength(event, CharsLimit.DECISION_FLOW)));

                break;
            }
            case ssElementsIds.ADDITIONAL_COMMENTS: {
                if (
                    event.nativeEvent.inputType === NativeEventTypes.ON_INPUT &&
                    event.target.value.length > CharsLimit.ADDITIONAL_COMMENTS
                ) {
                    return;
                }

                dispatch(setAdditionalComments(processInputValueLength(event, CharsLimit.ADDITIONAL_COMMENTS)));

                break;
            }
            default:
                throw new Error(`Unrecognized id ${event.target.id}`);
        }
    };

    const onInputNumberChange = (event) => {
        if (event.target.id !== FirstSectionElementsIds.INVESTMENT_EXPECTATION) {
            throw new Error(`The element with ID "${event.target.id}" should not be handled here!`);
        }

        if (
            event.nativeEvent.inputType === NativeEventTypes.ON_INPUT &&
            event.target.value.length > CharsLimit.INVESTMENT_EXPECTATION
        ) {
            return;
        }

        dispatch(setInvestmentExpectation(processInputValueLength(event, CharsLimit.INVESTMENT_EXPECTATION)));
    };

    const dateToString = (date) => format(date, DATE_FORMAT);

    const stringToDate = (str) => parse(str, DATE_FORMAT, new Date());

    const onDatePickerChange = (event) => {
        switch (event.target.id) {
            case FirstSectionElementsIds.START_DATE: {
                dispatch(setStartDate(dateToString(event.target.value)));

                // If the endDate is not set, return.
                if (!endDate) return;

                const oneMonthLater = addMonths(event.target.value, 1);

                // If the difference in months is at least the minimum required between
                // start and end date, return.
                if (oneMonthLater <= stringToDate(endDate)) return;

                // If the time between the start and end date is less than the minimum, we need
                // to update it to the minimum allowed, which is 1 month.
                dispatch(setEndDate(dateToString(oneMonthLater)));

                break;
            }
            case FirstSectionElementsIds.END_DATE: {
                dispatch(setEndDate(dateToString(event.target.value)));
                break;
            }
            case FirstSectionElementsIds.MIN_CONFIRMATION_DATE: {
                dispatch(setConfirmationDate(dateToString(event.target.value)));
                break;
            }
            default:
                throw new Error(`Unrecognized id ${event.target.id}`);
        }
    };

    const onSelectorChange = (event) => {
        switch (event.target.id) {
            case FirstSectionElementsIds.TIMEZONE: {
                dispatch(setTimezone(event.target.value));
                break;
            }
            case ssElementsIds.TEAM_AREA: {
                dispatch(setTeamArea(event.target.value));
                break;
            }
            case ssElementsIds.PROJECT_TYPE: {
                dispatch(setProjectType(event.target.value));
                break;
            }
            default:
                throw new Error(`Unrecognized ID "${event.target.id}"`);
        }
    };

    const onAttachmentChange = async (event) => {
        /*
         * Disclosure: this is the only handler that will keep handling errors
         * by itself. The reason is that is too complex to put that logic inside
         * the hook useJoiValidation (mainly because we need to do checks with
         * the file directly), at least at this moment.
         *
         * Recommendations are welcomed :)
         * */

        if (event.target.id !== ssElementsIds.FILE_ATTACH) {
            throw new Error(`Unrecongized element (ID "${event.target.id}") to handle attachment`);
        }

        const fileSize = event.target.files[0].size;
        const fileType = event.target.files[0].type;
        let blobName = null;

        const errors = [];

        if (fileSize > MAX_ATTACHMENT_FILESIZE) {
            errors.push("fileSizeLimit");
            return;
        } else if (!ALLOWED_MIMETYPES.includes(fileType)) {
            errors.push("allowedFileTypes");
            return;
        }

        if (event.target.files.length > 0) {
            const formData = new FormData();
            formData.append("file", event.target.files[0]);

            dispatch(
                setAttachment({
                    isLoading: true,
                })
            );
            const response = await uploadFileAsync(formData);

            if (response.ok) {
                blobName = await response.json();
            }
        }

        let finalObj;

        if (errors.length) {
            finalObj = {
                fileName: "",
                blobName: "",
                isValid: false,
                isLoading: false,
                errors,
            };
        } else {
            finalObj = {
                fileName: event.target.files[0]?.name,
                blobName: blobName?.name,
                isValid: true,
                isLoading: false,
            };
        }

        dispatch(setAttachment(finalObj));
    };

    // Handles the change from any input
    return async (event) => {
        switch (event.target.id) {
            case FirstSectionElementsIds.TEAM_NAME:
            case FirstSectionElementsIds.DECISION_FLOW:
            case ssElementsIds.ADDITIONAL_COMMENTS:
            case ssElementsIds.INPUT_CUSTOM_MANDATORY_SKILL:
            case ssElementsIds.INPUT_CUSTOM_DESIRED_SKILL:
                onInputTextChange(event);
                break;
            case FirstSectionElementsIds.INVESTMENT_EXPECTATION:
                onInputNumberChange(event);
                break;
            case FirstSectionElementsIds.checkboxes.DECISION_MAKER:
            case FirstSectionElementsIds.checkboxes.RECOMMENDER:
            case FirstSectionElementsIds.checkboxes.USER:
            case FirstSectionElementsIds.checkboxes.OTHERS:
                onCheckboxChange(event);
                break;
            case FirstSectionElementsIds.checkboxes.ROLES_OTHERS:
                onRolesOthersChange(event);
                break;
            case FirstSectionElementsIds.START_DATE:
            case FirstSectionElementsIds.END_DATE:
            case FirstSectionElementsIds.MIN_CONFIRMATION_DATE:
                onDatePickerChange(event);
                break;
            case FirstSectionElementsIds.TIMEZONE:
            case ssElementsIds.TEAM_AREA:
            case ssElementsIds.PROJECT_TYPE:
                onSelectorChange(event);
                break;
            case ssElementsIds.FILE_ATTACH:
                await onAttachmentChange(event);
                break;
            default:
                throw new Error(`Unrecognized id ${event.target.id}`);
        }

        // Skip the validation of the attachment since it has its own validation. Also, omit the validation
        // of the investment expectation and additional comments since their inputs are already limited.
        if (
            event.target.id === ssElementsIds.FILE_ATTACH ||
            event.target.id === FirstSectionElementsIds.INVESTMENT_EXPECTATION ||
            event.target.id === ssElementsIds.ADDITIONAL_COMMENTS
        ) {
            return;
        }

        // Differentiate if the coming event must be validated against the schema baseSchema.rolesInYourCompany
        if (checkboxesIds.includes(event.target.id) || event.target.name === "otherRolesInput") {
            const recreatedEvent = {
                ...event,
                target: {
                    ...event.target,
                    name: "rolesInYourCompany",
                    value: {
                        ...selectedCheckboxes,
                        otherRolesInput: roleInteractingCustom,
                        // If the input that produces the event it's a checkbox, we've to use the field "checked",
                        // otherwise we use the field "value" as always
                        [event.target.name]:
                            event.target.type === "checkbox" ? event.target.checked : event.target.value,
                    },
                },
            };

            validateSubSchemaFromEvent(schema.current, errorMessages.current, recreatedEvent);
        } else {
            validateSubSchemaFromEvent(schema.current, errorMessages.current, event);
        }
    };
};

export const useDnDFunctions = (validationSchema, schemaErrorMessages, validateSubSchemaFromEvent) => {
    const schema = useRef(validationSchema);
    const errorMessages = useRef(schemaErrorMessages);

    const skills = useSelector((state) => state.assembleYourTeam.skills);

    const dispatch = useDispatch();

    const onCustomMandatorySkillChange = (event) => {
        if (
            event.nativeEvent.inputType === NativeEventTypes.ON_INPUT &&
            event.target.value.length > CharsLimit.CUSTOM_SKILL
        ) {
            return;
        }

        dispatch(setCustomSkillMandatory(event.target.value));
    };

    const onCustomDesiredSkillChange = (event) => {
        if (
            event.nativeEvent.inputType === NativeEventTypes.ON_INPUT &&
            event.target.value.length > CharsLimit.CUSTOM_SKILL
        ) {
            return;
        }

        dispatch(setCustomSkillDesired(event.target.value));
    };

    const processCustomSkillInput = (event, container) => {
        if (!event.target.value.trim()) return;

        const indexOnState = skills.findIndex((skill) => {
            const nameInput = event.target.value.trim().toLowerCase();

            let nameExists = nameInput === skill.name.trim().toLowerCase();

            if (!nameExists && skill.alias) {
                nameExists = skill.alias.includes(nameInput);
            }

            return nameExists;
        });

        let newState;

        if (indexOnState === -1) {
            newState = [
                ...skills,
                {
                    key: uuidv4(),
                    custom: true,
                    name: event.target.value.trim(),
                    container: container,
                },
            ];
        } else {
            newState = [...skills];

            newState = skills.map((skill, i) => {
                if (indexOnState !== i) {
                    return skill;
                }

                return { ...skill, container };
            });
        }

        dispatch(setSkills(newState));

        if (container === ContainerType.MANDATORY_SKILLS) {
            dispatch(setCustomSkillMandatory(""));
        } else {
            dispatch(setCustomSkillDesired(""));
        }
    };

    const onCustomMandatorySkillKeyDown = (event) => {
        if (event.key !== "Enter") return;

        event.preventDefault();

        processCustomSkillInput(event, ContainerType.MANDATORY_SKILLS);
    };

    const onMobileCustomMandatorySkillAdd = (event) => {
        processCustomSkillInput(event, ContainerType.MANDATORY_SKILLS);
    };

    const onCustomDesiredSkillKeyDown = (event) => {
        if (event.key !== "Enter") return;

        event.preventDefault();

        processCustomSkillInput(event, ContainerType.DESIRED_SKILLS);
    };

    const onMobileCustomDesiredSkillAdd = (event) => {
        processCustomSkillInput(event, ContainerType.DESIRED_SKILLS);
    };

    const moveSkill = (skill, to) => {
        if (skill.container === to) return;

        const index = skills.findIndex((skillPrevState) => skillPrevState.key === skill.key);

        let newState;

        if (skill.custom && to === ContainerType.AVAILABLE_SKILLS) {
            newState = [...skills.filter((s) => s.key !== skill.key)];
        } else {
            newState = skills.map((skill, i) => {
                if (index !== i) return skill;

                return { ...skill, container: to };
            });
        }

        validateSubSchemaFromEvent(schema.current, errorMessages.current, {
            target: { name: "skills", value: newState },
        });

        dispatch(setSkills(newState));
    };

    const onPillClick = (skill) => {
        // Check where the skill comes from
        switch (skill.container) {
            case ContainerType.AVAILABLE_SKILLS:
                moveSkill(skill, ContainerType.MANDATORY_SKILLS);
                break;
            case ContainerType.MANDATORY_SKILLS:
            case ContainerType.DESIRED_SKILLS:
                moveSkill(skill, ContainerType.AVAILABLE_SKILLS);
                break;
            default:
                throw new Error("Unrecognized ContainerType", skill.container);
        }
    };

    const onAvailableSkillsContainerDrop = (skill) => {
        moveSkill(skill, ContainerType.AVAILABLE_SKILLS);
    };

    const onMandatorySkillsContainerDrop = (skill) => {
        moveSkill(skill, ContainerType.MANDATORY_SKILLS);
    };

    const onDesiredSkillsContainerDrop = (skill) => {
        moveSkill(skill, ContainerType.DESIRED_SKILLS);
    };

    const onChange = (event) => {
        switch (event.target.id) {
            case ssElementsIds.INPUT_CUSTOM_MANDATORY_SKILL:
                onCustomMandatorySkillChange(event);
                break;

            case ssElementsIds.INPUT_CUSTOM_DESIRED_SKILL:
                onCustomDesiredSkillChange(event);
                break;
            default:
                throw new Error("Unrecognized ID won't be handled", event.target.id);
        }
    };

    // Handles events of type key pressing
    const onKeyDown = (event) => {
        switch (event.target.id) {
            case ssElementsIds.INPUT_CUSTOM_MANDATORY_SKILL: {
                onCustomMandatorySkillKeyDown(event);
                break;
            }
            case ssElementsIds.INPUT_CUSTOM_DESIRED_SKILL: {
                onCustomDesiredSkillKeyDown(event);
                break;
            }
            default:
                throw new Error(`Unrecognized id ${event.target.id}`);
        }
    };

    return {
        onChange,
        onKeyDown,
        onMobileCustomMandatorySkillAdd,
        onMobileCustomDesiredSkillAdd,
        onPillClick,
        onAvailableSkillsContainerDrop,
        onMandatorySkillsContainerDrop,
        onDesiredSkillsContainerDrop,
    };
};
