import style from "./MenuItem.module.scss";

const MenuItem = ({ children, onClick, index, selected = false }) => {
    const onItemClick = () => {
        onClick(index);
    };

    return (
        <li className="mb-3" onClick={onItemClick}>
            <span className={`${style.item} ${selected ? style.itemActive : style.itemInactive} py-2 pe-2`}>
                {children}
            </span>
        </li>
    );
};

export default MenuItem;
