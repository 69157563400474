import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Routes from "../../../../base/js/routes";
import styles from "./MenuItem.module.scss";

const MenuItem = ({ item, onClick }) => {
    const { t } = useTranslation();

    const handleLinkStatus = ({ isActive }) => {
        return `${styles.container} ${isActive ? styles.itemActive : ""} px-3 py-2`;
    };

    const onItemClick = (event) => {
        onClick(event);
    };

    return (
        <NavLink className={handleLinkStatus} to={item.to} onClick={onItemClick}>
            {t(item.label)}
        </NavLink>
    );
};

export default MenuItem;
