import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { resetPasswordAsync } from "../../services/AuthService";
import Joi from "joi";
import Routes from "../../base/js/routes";
import Button, { textVariants, variants as btnVariants } from "../common/Button";
import toast from "react-hot-toast";
import useJoiValidation from "../hooks/UseJoiValidation";
import Loading from "../loading/Loading";

import style from "./ResetPassword.module.scss";
import sharedStyle from "../shared-styles/FormStyle.module.scss";

const baseSchema = {
    email: Joi.string()
        .max(255)
        .email({ tlds: { allow: false } })
        .required(),
};

// (Joi) Object containing errors' messages by its type
const schemaErrorMessages = {
    "string.empty": "requiredFieldIsEmpty",
    "string.email": "invalidEmailFormat",
};
const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [email, setEmail] = useState("");
    const handleOnChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    };

    const handleOnBlur = (event) => {
        validateSubSchemaFromEvent(baseSchema, schemaErrorMessages, event);
    };

    const onSignUpClick = (event) => {
        event.preventDefault();

        navigate(Routes.SIGNUP);
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        // Validate the schema and update the state that contains all the errors
        const numberOfErrors = validateSchema(baseSchema, schemaErrorMessages, { email: email });
        // If there are errors, abort the upload of the info
        if (numberOfErrors > 0) {
            return;
        }

        try {
            setIsLoading(true);
            const response = await resetPasswordAsync(email);

            if (!response.ok) {
                if (response.status === 401) {
                    toast.error(t("invalidLoginCredentials"));
                } else {
                    const parsedBody = await response.json();
                    toast.error(parsedBody.message ? parsedBody.message : `Unexpected error (${response.status})`);
                }
                return;
            }

            setEmail("");
            toast.success(t("resetPasswordSuccess"));
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
            return;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className={`${style.mainContainer} d-flex justify-content-center`}>
            <section className={style.leftSection}>
                <div className={`${style.resetPasswordContainer} m-auto d-flex flex-column  p-3 p-md-5`}>
                    <h1>{t("forgotYourPassword")}</h1>
                    <p className={style.subHeadingText}>{t("forgotYourPasswordText")}</p>
                    <form onSubmit={handleLoginSubmit} className="position-relative d-flex flex-column">
                        <label className="my-2">{t("email")}</label>
                        <input
                            name="email"
                            className={`${errors.email ? sharedStyle.invalidField : ""} ${style.input} border-0`}
                            type="email"
                            value={email}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                        {errors.email && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.email.message}</p>
                            </div>
                        )}

                        <div className="position-relative d-flex flex-column mt-4">
                            <Button variant={btnVariants.PRIMARY} cssClasses={[style.loginBtn]} type="submit">
                                {t("resetYourPassword")}
                            </Button>

                            <span className={`${style.spacing} text-center text-uppercase my-2`}>or</span>

                            <Button
                                variant={btnVariants.PRIMARY_INVERSE}
                                textVariant={textVariants.BROWN}
                                cssClasses={[style.signUpBtn]}
                                onClick={onSignUpClick}
                            >
                                {t("signUp")}
                            </Button>
                        </div>
                    </form>
                    {isLoading && <Loading />}
                </div>
            </section>
        </main>
    );
};

export default ResetPassword;
