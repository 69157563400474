import Image from "../../common/Image";
import plusIcon from "../../../assets/plus-icon.svg";
import style from "./TeamMember.module.scss";

const TeamMember = ({ name, position, image }) => {
    return (
        <div className={`${style.teamMember} col`}>
            <div className="position-relative">
                <Image src={image} alt={name} cssClass={style.teamMemberImg} />
                <img src={plusIcon} alt="Member details icon" className="position-absolute bottom-0 end-0" />
            </div>
            <p className="text-center mt-1">
                {name} - {position}
            </p>
        </div>
    );
};

export default TeamMember;
