import style from "./Footer.module.scss";
import HelpSection from "./help/HelpSection";
import NavigationSection from "./navigation/NavigationSection";
import SocialsSection from "./socials/SocialsSection";

const Footer = () => (
    <footer className={`${style.container} d-flex flex-column flex-lg-row justify-content-between gap-5 gap-lg-0`}>
        <HelpSection />
        <NavigationSection />
        <SocialsSection />
    </footer>
);

export default Footer;
