import style from "./GuideMenu.module.scss";
import MenuItem from "./MenuItem";

const Menu = ({ items, onChange, selectedItemIndex }) => {
    const onItemClick = (index) => {
        onChange({ itemIndex: index });
    };

    return (
        <div className={`${style.container} py-4`}>
            <ul className="ps-0">
                {items.map((item, index) => (
                    <MenuItem key={index} onClick={onItemClick} index={index} selected={index === selectedItemIndex}>
                        {item.title}
                    </MenuItem>
                ))}
            </ul>
        </div>
    );
};

export default Menu;
