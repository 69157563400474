import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import Modal from "../../common/modal/Modal";
import ModalButton from "../../common/modal/buttons/ModalButton";
import { ModalButtonVariant } from "../../../base/js/constants";
import style from "./AddCreatorModal.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import { getUsersByUserRole } from "../../../services/AdminService";
import { useForceLogout } from "../../../utils/useForceLogout";

const selectorStyle = getSelectorStyle(false);

const AddCreatorModal = ({ show, onAccept, onCancel }) => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);

    const onAcceptBtnClick = () => {
        onAccept(selectedUser);
    };

    // Fetch users' list from the API
    useEffect(() => {
        (async () => {
            try {
                const response = await getUsersByUserRole();

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else if (response.status === 403) {
                        // Insufficient permissions
                        toast.error(t("insufficientPermissions"));
                    } else {
                        toast.error(await response.text());
                    }

                    return;
                }

                const data = await response.json();
                const mappedData = data.map((user) => ({ label: user.name, value: user.id }));

                setUsers(mappedData);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <Modal show={show}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("addUserCreator")}</span>

            <Selector
                isDisabled={!users.length}
                isLoading={isLoading}
                options={users}
                onChange={setSelectedUser}
                styles={selectorStyle}
                className="mt-3"
            />

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={onAcceptBtnClick}>
                    {t("accept")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onCancel}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

AddCreatorModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AddCreatorModal;
