import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./i18n";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/store";

/* Fonts */
import "@fontsource/noto-sans/400-italic.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/600-italic.css";
import "@fontsource/noto-sans/600.css";
import "@fontsource/noto-sans/700-italic.css";
import "@fontsource/noto-sans/700.css";
import "@fontsource/noto-sans/800.css";
import "@fontsource/ramaraja/400.css";
import "@fontsource/roboto-mono";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
