import { Trans } from "react-i18next";
import style from "./SecondSectionLeft.module.scss";

const SecondSectionLeft = () => (
    <div className={style.section}>
        <h1>
            <Trans components={{ span: <span className="fst-italic" /> }}>tellAStory</Trans>
        </h1>
    </div>
);

export default SecondSectionLeft;
