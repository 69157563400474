import { API_BASE, request } from "../utils/httpRequest";

export const addHoursValidationAsync = async (data) => {
    return await request(`${API_BASE}/api/validationhour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const sendToClientAsync = async (data) => {
    return await request(`${API_BASE}/api/validationhour/send-to-client`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const validateHoursValidationAsync = async (id) => {
    return await request(`${API_BASE}/api/validationhour/${id}/validate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
};

export const updateHoursValidationAsync = async (data) => {
    return await request(`${API_BASE}/api/validationhour`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getHoursValidationOverviewAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        API_BASE +
            `/api/validationhour/projects-overview?page=${page}&pageSize=${pageSize}${
                searchText ? "&searchText=" + searchText : ""
            }`,
        {
            method: "GET",
        }
    );
};

export const getHoursValidationByProjectAndPeriodAsync = async (projectId, period) => {
    return await request(API_BASE + `/api/validationhour?projectId=${projectId}&period=${period}`, {
        method: "GET",
    });
};

export const getHoursValidationPendingByProjectAsync = async (projectId) => {
    return await request(API_BASE + `/api/validationhour/pending-by-project?projectId=${projectId}`, {
        method: "GET",
    });
};

export const addNoteToHourValidation = async (data) => {
    return await request(`${API_BASE}/api/validationhournote`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};
