import PropTypes from "prop-types";
import style from "./SocialItem.module.scss";

const SocialItem = ({ image, title, children }) => {
    return (
        <div className={`${style.socialItem} mb-5 d-flex`}>
            <img src={image} alt="" className="me-4" />
            <div className="d-flex flex-column">
                <h5 className={style.socialItemTitle}>{title}</h5>
                {children}
            </div>
        </div>
    );
};

SocialItem.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default SocialItem;
