import FirstSection from "./first-section/FirstSection";
import SecondSection from "./second-section/SecondSection";
import ThirdSection from "./third-section/ThirdSection";

const MeetTheTeam = () => (
    <main>
        <FirstSection />
        <SecondSection />
        <ThirdSection />
    </main>
);

export default MeetTheTeam;
