import { useTranslation } from "react-i18next";
import style from "./Contact.module.scss";
import locationIcon from "../../assets/icons/location.svg";
import whatsappIcon from "../../assets/icons/whatsapp.svg";
import messageIcon from "../../assets/icons/message.svg";
import linkedinIcon from "../../assets/icons/linkedin.svg";
import Button from "../common/Button";
import SocialItem from "./social-item/SocialItem";
import useFormLogic, { FormFieldsName } from "./UseFormLogic";
import ErrorMessages from "../common/error-messages/ErrorMessages";
import Loading from "../loading/Loading";

const Contact = () => {
    const { t } = useTranslation();
    const { onChange, onSubmit, data, errors, onEmailBlur, isLoading } = useFormLogic();

    return (
        <>
            <main className={`${style.view} d-flex justify-content-between position-relative`}>
                <div className={`${style.leftSection} d-none d-lg-flex flex-column mt-4`}>
                    <SocialItem image={locationIcon} title={t("address")}>
                        <p className={style.socialItemContent}>
                            14425 Falcon Head Blvd.
                            <br />
                            Austin, TX
                        </p>
                    </SocialItem>

                    <SocialItem image={whatsappIcon} title="Whatsapp">
                        <p className={style.socialItemContent}>+1737 2013616</p>
                    </SocialItem>

                    <SocialItem image={messageIcon} title="Email">
                        <a href="mailto:info@thedevblock.com" className={style.socialItemContent}>
                            info@thedevblock.com
                        </a>
                    </SocialItem>

                    <SocialItem image={linkedinIcon} title="Linkedin">
                        <a
                            href="https://www.linkedin.com/company/thedevblock"
                            target="_blank"
                            rel="noreferrer"
                            className={style.socialItemContent}
                        >
                            thedevblock
                        </a>
                    </SocialItem>
                </div>

                <div className={style.rightSection}>
                    <h3 className={style.rightSectionTitle}>{t("tellUsHelp")}</h3>
                    <form className="d-flex flex-column mt-4" onSubmit={onSubmit}>
                        <div className="d-flex flex-column">
                            <input
                                type="text"
                                placeholder={`${t("fullName")} *`}
                                name={FormFieldsName.NAME}
                                className={style.input}
                                value={data.name}
                                onChange={onChange}
                                autoComplete="off"
                                required
                            />
                            {errors.name && <ErrorMessages errors={[errors.name.message]} />}
                        </div>

                        <div className="d-flex flex-column">
                            <input
                                type="tel"
                                placeholder={`${t("phoneNumber")} *`}
                                name={FormFieldsName.PHONE}
                                className={`${style.input} mt-3`}
                                value={data.phone}
                                onChange={onChange}
                                autoComplete="off"
                                required
                            />
                            {errors.phone && <ErrorMessages errors={[errors.phone.message]} />}
                        </div>

                        <div className="d-flex flex-column">
                            <input
                                type="text"
                                placeholder={`${t("company")} *`}
                                name={FormFieldsName.COMPANY}
                                className={`${style.input} mt-3`}
                                value={data.company}
                                onChange={onChange}
                                autoComplete="off"
                                required
                            />
                            {errors.company && <ErrorMessages errors={[errors.company.message]} />}
                        </div>

                        <div className="d-flex flex-column">
                            <input
                                type="email"
                                placeholder={`${t("businessEmailAddress")} *`}
                                name={FormFieldsName.EMAIL}
                                className={`${style.input} mt-3`}
                                value={data.email}
                                onChange={onChange}
                                onBlur={onEmailBlur}
                                required
                            />
                            {errors.email && <ErrorMessages errors={[errors.email.message]} />}
                        </div>

                        <div className="d-flex flex-column">
                            <textarea
                                placeholder={`${t("writeMessage")} *`}
                                name={FormFieldsName.MESSAGE}
                                autoComplete="off"
                                rows="4"
                                className={`${style.input} ${style.textarea} mt-3`}
                                value={data.message}
                                onChange={onChange}
                                required
                            />
                            {errors.message && <ErrorMessages errors={[errors.message.message]} />}
                        </div>

                        <div className={`${style.rightSectionNewsletter} d-flex align-items-center`}>
                            <input
                                type="checkbox"
                                name={FormFieldsName.NEWSLETTER}
                                checked={data.newsletter}
                                onChange={onChange}
                            />
                            <span className="ms-2">{t("receiveNewsletter")}</span>
                        </div>

                        <Button cssClasses={[style.rightSectionBtn, "ms-lg-auto"]} type="submit">
                            {t("sendMessage")}
                        </Button>
                    </form>
                </div>
            </main>

            {isLoading && <Loading />}
        </>
    );
};

export default Contact;
