import { format } from "date-fns";
import Joi from "joi";
import { useEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { BREAKPOINTS, DATE_FORMAT, PROJECT_STATUS_IDS, TAM_ID, TAM_LABEL } from "../../../base/js/constants";
import Routes from "../../../base/js/routes";
import { uploadFileAsync } from "../../../services/FileService";
import { getRolesAsync, getSenioritiesAsync, postPositionsAsync } from "../../../services/PositionService";
import { setPositions as setPositionsAction } from "../../../store/slices/assembleYourTeamSlice";
import { useForceLogout } from "../../../utils/useForceLogout";
import Button, { variants as btnVariants } from "../../common/Button";
import useWindowWidth from "../../hooks/UseWindowWidth";
import Loading from "../../loading/Loading";
import { ALLOWED_MIMETYPES, MAX_ATTACHMENT_FILESIZE } from "../assemble-your-team/constants";
import Resource from "./resource/Resource";
import ResourceMobile from "./resourceMobile/ResourceMobile";
import styles from "./TeamInfo.module.scss";
import { TeamInfoFormIds } from "./TeamInfoConstant";

const DEFAULT_POSITION = {
    role: null,
    seniority: null,
    jobSkills: [],
    total: 1,
    time: false,
    estimatedStartDate: null,
    estimatedEndDate: null,
    jobDescription: {
        isLink: true,
        isValid: true,
        link: "",
        file: {
            name: "",
            url: null,
        },
    },
};

const SENIOR_ONLY_ROLE_NAMES = ["Team Leader", "Architect", "Technical Account Manager"];

const CARD_SCHEMA = {
    role: Joi.object({
        value: Joi.number(),
        label: Joi.string(),
    })
        .empty(null)
        .required(),
    seniority: Joi.object({
        value: Joi.number(),
        label: Joi.string(),
    })
        .empty(null)
        .required(),
    jobSpecificSkills: Joi.array().items(Joi.string()),
    count: Joi.number().empty("").required(),
    time: Joi.boolean(),
    estimatedDateToStartOptional: Joi.date().empty(""),
    estimatedDateToEndOptional: Joi.date().empty(""),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.with": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const TeamInfo = ({ previousPhase, nextPhase }) => {
    const dispatch = useDispatch();
    const forceLogout = useForceLogout();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const innerWidth = useWindowWidth();
    const [searchParams, setSearchParams] = useSearchParams();

    const projectId = useSelector((state) => state.assembleYourTeam.id);
    const teamName = useSelector((state) => state.assembleYourTeam.teamName);
    const startDate = useSelector((state) => state.assembleYourTeam.startDate);
    const endDate = useSelector((state) => state.assembleYourTeam.endDate);
    const cachedPositions = useSelector((state) => state.assembleYourTeam.positions);
    const projectStatus = useSelector((state) => state.assembleYourTeam.projectStatus);
    const [project] = useState({
        teamName,
        startDate,
        endDate,
    });
    const [roleTypes, setRoleTypes] = useState([]);
    const [seniorityTypes, setSeniorityTypes] = useState([]);
    const [positions, setPositions] = useState(() => {
        return cachedPositions.length > 0 ? cachedPositions : [];
    });

    const [newAddPositions, setNewAddPositions] = useState(() => {
        const existingTam = cachedPositions.find((position) => position.id === TAM_ID);
        if (existingTam) {
            return [
                // Empty position
                { ...DEFAULT_POSITION, id: uuidv4() },
            ];
        } else {
            return [
                // TAM
                { ...DEFAULT_POSITION, estimatedStartDate: startDate, estimatedEndDate: endDate, id: TAM_ID },
                // Empty position
                { ...DEFAULT_POSITION, id: uuidv4() },
            ];
        }
    });

    const [loading, setLoading] = useState(false);
    // Contains a list of roles' names that only allow Senior seniority
    const [seniorOnlyRoles, setSeniorOnlyRoles] = useState([]);
    const [isFileUploading, setIsFileUploading] = useState({ loading: false, positionId: "" });
    const [childFormsStatus, setChildFormsStatus] = useState({});
    const [dataLoadFinished, setDataLoadFinished] = useState({ roles: false, seniorities: false });

    const goesToTeamInfoResume = useMemo(() => {
        const idsForRedirection = [
            PROJECT_STATUS_IDS.ALL_RESOURCES_ALLOCATED,
            PROJECT_STATUS_IDS.PARTIAL_RESELECTING,
            PROJECT_STATUS_IDS.INTERVIEWS,
        ];
        return projectStatus && idsForRedirection.indexOf(projectStatus.id) !== -1;
    }, [projectStatus]);

    // Fetch information about roles and seniorities
    useEffect(() => {
        // Fetch roles
        (async () => {
            const rolesApiResponse = await getRolesAsync();

            if (!rolesApiResponse.ok) {
                if (rolesApiResponse.status === 401) {
                    await forceLogout();
                } else {
                    console.error("Error when trying to fetch roles from API:", await rolesApiResponse.text());
                }

                return;
            }

            const roles = await rolesApiResponse.json();
            const mappedRoles = roles.map((role) => {
                return { value: role.id, label: role.name };
            });

            setRoleTypes(mappedRoles);

            roles.forEach((role) => {
                if (!SENIOR_ONLY_ROLE_NAMES.includes(role.name)) {
                    return;
                }

                setSeniorOnlyRoles((prevState) => {
                    return [...prevState, role.id];
                });
            });

            // Find the role "Technical Account Manager" and set it to the TAM position
            setNewAddPositions((prevState) => {
                const tamRole = mappedRoles.find((role) => role.label === TAM_LABEL);
                const tamPosition = prevState.find((position) => position.id === TAM_ID);
                const remainingPositions = prevState.filter((position) => position.id !== TAM_ID);

                if (tamPosition) return [{ ...tamPosition, role: tamRole }, ...remainingPositions];

                return prevState;
            });

            setPositions((prevState) => {
                const tamRole = mappedRoles.find((role) => role.label === TAM_LABEL);
                const tamPosition = prevState.find((position) => position.id === TAM_ID);
                const remainingPositions = prevState.filter((position) => position.id !== TAM_ID);

                if (tamPosition) return [{ ...tamPosition, role: tamRole }, ...remainingPositions];

                return prevState;
            });

            setDataLoadFinished((prevState) => ({ ...prevState, roles: true }));
        })();

        // Fetch seniorities
        (async () => {
            const senioritiesApiResponse = await getSenioritiesAsync();

            if (!senioritiesApiResponse.ok) {
                if (senioritiesApiResponse.status === 401) {
                    await forceLogout();
                } else {
                    console.error(
                        "Error when trying to fetch seniorities from API:",
                        await senioritiesApiResponse.text()
                    );
                }

                return;
            }

            const seniorities = await senioritiesApiResponse.json();
            const mappedSeniorities = seniorities.map((seniority) => {
                return { value: seniority.id, label: seniority.name };
            });

            setSeniorityTypes(mappedSeniorities);

            // Find the seniority "Senior" and set it to the TAM position
            setNewAddPositions((prevState) => {
                const seniorSeniority = mappedSeniorities.find((seniority) => seniority.label === "Senior");
                const tamPosition = prevState.find((position) => position.id === TAM_ID);
                const remainingPositions = prevState.filter((position) => position.id !== TAM_ID);

                if (tamPosition) return [{ ...tamPosition, seniority: seniorSeniority }, ...remainingPositions];

                return prevState;
            });

            setPositions((prevState) => {
                const seniorSeniority = mappedSeniorities.find((seniority) => seniority.label === "Senior");
                const tamPosition = prevState.find((position) => position.id === TAM_ID);
                const remainingPositions = prevState.filter((position) => position.id !== TAM_ID);

                if (tamPosition) return [{ ...tamPosition, seniority: seniorSeniority }, ...remainingPositions];

                return prevState;
            });

            setDataLoadFinished((prevState) => ({ ...prevState, seniorities: true }));
        })();
    }, []);

    useEffect(() => {
        if (!dataLoadFinished.roles || !dataLoadFinished.seniorities) {
            return;
        }

        // Initialize the status of the global validation
        setChildFormsStatus(() => {
            let state = {};
            newAddPositions.forEach((position, index) => {
                // The first element should be the position of the TAM, which is valid by default because can't be modified
                if (!goesToTeamInfoResume && cachedPositions.length === 0 && index === 0) {
                    state = { [newAddPositions[0].id]: { valid: true, forceGlobalValidationFlag: 0 } };
                    return;
                }

                // For the rest of positions, we have to check if at least they have set a role and a seniority
                state = {
                    ...state,
                    [position.id]: { valid: !!position.role && !!position.seniority, forceGlobalValidationFlag: 0 },
                };
            });

            if (!goesToTeamInfoResume && cachedPositions.length > 0) {
                cachedPositions.forEach((position, index) => {
                    // The first element should be the position of the TAM, which is valid by default because can't be modified
                    if (index === 0) {
                        state = { [cachedPositions[0].id]: { valid: true, forceGlobalValidationFlag: 0 }, ...state };
                        return;
                    }

                    // For the rest of positions, we have to check if at least they have set a role and a seniority
                    state = {
                        ...state,
                        [position.id]: { valid: !!position.role && !!position.seniority, forceGlobalValidationFlag: 0 },
                    };
                });
            }

            return state;
        });
    }, [dataLoadFinished]);

    const handleOnChange = async (event) => {
        switch (event.target.id) {
            case TeamInfoFormIds.ROLE:
            case TeamInfoFormIds.SENIORITY:
                setNewAddPositions((prevState) => {
                    return prevState.map((resource) => {
                        if (resource.id !== event.target.resourceId) {
                            return resource;
                        }

                        return {
                            ...resource,
                            [event.target.id]: event.target.value,
                        };
                    });
                });

                setPositions((prevState) => {
                    return prevState.map((resource) => {
                        if (resource.id !== event.target.resourceId) {
                            return resource;
                        }

                        return {
                            ...resource,
                            [event.target.id]: event.target.value,
                        };
                    });
                });

                break;

            case TeamInfoFormIds.TOTAL:
                setNewAddPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            [event.target.id]: event.target.value,
                        };
                    });
                });

                setPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            [event.target.id]: event.target.value,
                        };
                    });
                });

                break;

            case TeamInfoFormIds.TIME:
                setNewAddPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            [event.target.id]: event.target.checked,
                        };
                    });
                });

                setPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            [event.target.id]: event.target.checked,
                        };
                    });
                });

                break;

            case TeamInfoFormIds.JOB_SKILLS:
                setNewAddPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            jobSkills: event.target.value,
                        };
                    });
                });

                setPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            jobSkills: event.target.value,
                        };
                    });
                });

                break;

            case TeamInfoFormIds.ESTIMATED_START_DATE:
            case TeamInfoFormIds.ESTIMATED_END_DATE:
                setNewAddPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            [event.target.id]: format(event.target.value, DATE_FORMAT),
                        };
                    });
                });

                setPositions((prevState) => {
                    return prevState.map((p) => {
                        if (p.id !== event.target.resourceId) {
                            return p;
                        }

                        return {
                            ...p,
                            [event.target.id]: format(event.target.value, DATE_FORMAT),
                        };
                    });
                });

                break;

            case TeamInfoFormIds.JOB_DESCRIPTION_LINK:
                if (event.target.isLink) {
                    setNewAddPositions((prevState) => {
                        return prevState.map((position) => {
                            if (position.id !== event.target.resourceId) {
                                return position;
                            }

                            return {
                                ...position,
                                jobDescription: {
                                    ...position.jobDescription,
                                    isLink: true,
                                    isValid: event.target.isValid,
                                    link: event.target.value,
                                    file: {
                                        name: "",
                                        url: null,
                                    },
                                },
                            };
                        });
                    });

                    setPositions((prevState) => {
                        return prevState.map((position) => {
                            if (position.id !== event.target.resourceId) {
                                return position;
                            }

                            return {
                                ...position,
                                jobDescription: {
                                    ...position.jobDescription,
                                    isLink: true,
                                    isValid: event.target.isValid,
                                    link: event.target.value,
                                    file: {
                                        name: "",
                                        url: null,
                                    },
                                },
                            };
                        });
                    });
                } else {
                    let positionsCache = [];
                    let positionFound = false;

                    for (let i = 0; i < newAddPositions.length; i++) {
                        if (newAddPositions[i].id !== event.target.resourceId || !event.target.value) {
                            positionsCache.push(newAddPositions[i]);
                            continue;
                        }

                        positionFound = true;

                        const fileSize = event.target.files[0].size;
                        const fileType = event.target.files[0].type;
                        let blobName = null;

                        const errors = [];

                        if (fileSize > MAX_ATTACHMENT_FILESIZE) {
                            errors.push("fileSizeLimit");
                        } else if (!ALLOWED_MIMETYPES.includes(fileType)) {
                            errors.push("allowedFileTypes");
                        }

                        if (errors.length > 0) {
                            return;
                        }

                        if (Object.keys(event.target.files).length > 0) {
                            const formData = new FormData();
                            formData.append("file", event.target.files[0]);

                            setIsFileUploading({ loading: true, positionId: newAddPositions[i].id });

                            const response = await uploadFileAsync(formData);

                            if (response.ok) {
                                blobName = await response.json();
                            }

                            setIsFileUploading({ loading: false, positionId: "" });
                        }

                        positionsCache.push({
                            ...newAddPositions[i],
                            jobDescription: {
                                ...newAddPositions[i].jobDescription,
                                isLink: false,
                                isValid: event.target.isValid,
                                link: "",
                                file: {
                                    name: event.target.files[0].name,
                                    blobName: blobName?.name,
                                },
                            },
                        });
                    }

                    setNewAddPositions(positionsCache);

                    // If the position has been found, return and avoid the iteration over the other state
                    if (positionFound) {
                        return;
                    }

                    positionsCache = [];

                    for (let i = 0; i < positions.length; i++) {
                        if (positions[i].id !== event.target.resourceId || !event.target.value) {
                            positionsCache.push(positions[i]);
                            continue;
                        }

                        positionFound = true;

                        const fileSize = event.target.files[0].size;
                        const fileType = event.target.files[0].type;
                        let blobName = null;

                        const errors = [];

                        if (fileSize > MAX_ATTACHMENT_FILESIZE) {
                            errors.push("fileSizeLimit");
                        } else if (!ALLOWED_MIMETYPES.includes(fileType)) {
                            errors.push("allowedFileTypes");
                        }

                        if (errors.length > 0) {
                            return;
                        }

                        if (Object.keys(event.target.files).length > 0) {
                            const formData = new FormData();
                            formData.append("file", event.target.files[0]);

                            setIsFileUploading({ loading: true, positionId: positions[i].id });

                            const response = await uploadFileAsync(formData);

                            if (response.ok) {
                                blobName = await response.json();
                            }

                            setIsFileUploading({ loading: false, positionId: "" });
                        }

                        positionsCache.push({
                            ...positions[i],
                            jobDescription: {
                                ...positions[i].jobDescription,
                                isLink: false,
                                isValid: event.target.isValid,
                                link: "",
                                file: {
                                    name: event.target.files[0].name,
                                    blobName: blobName?.name,
                                },
                            },
                        });
                    }

                    setPositions(positionsCache);
                }

                break;

            default:
                throw new Error(`Unrecognized id ${event.target.id}`);
        }
    };

    const handleAddResource = () => {
        const newPosition = { ...DEFAULT_POSITION, id: uuidv4() };

        setNewAddPositions((prevState) => [...prevState, newPosition]);

        setChildFormsStatus((prevState) => {
            return {
                ...prevState,
                [newPosition.id]: {
                    valid: false,
                    forceGlobalValidationFlag: 0,
                },
            };
        });
    };

    const handleDeletePosition = (positionId) => {
        let newResources = newAddPositions.filter((position) => position.id !== positionId);
        setNewAddPositions(newResources);

        let newCachedResources = positions.filter((position) => position.id !== positionId);
        setPositions(newCachedResources);

        setChildFormsStatus((prevState) => {
            const newState = { ...prevState };
            delete newState[positionId];

            return newState;
        });
    };

    const arePositionsInvalid = () => {
        let formInvalid = false;

        const newState = {};

        Object.keys(childFormsStatus).forEach((key) => {
            newState[key] = {
                ...childFormsStatus[key],
                forceGlobalValidationFlag: ++childFormsStatus[key].forceGlobalValidationFlag,
            };
        });

        // Check if there is some form reported as invalid
        Object.keys(newState).forEach((key) => {
            if (!newState[key].valid) {
                formInvalid = true;
            }
        });

        // Force the validation on all the children
        setChildFormsStatus(newState);

        return formInvalid;
    };

    const handleSubmitTeamInfoForm = async (event) => {
        event.preventDefault();

        const positionsInvalid = arePositionsInvalid();
        const positionsLength = newAddPositions.length + positions.length;

        // There should be at least 1 position, but just in case...
        if (positionsInvalid || positionsLength === 1) {
            return;
        }

        setLoading(true);

        // Filter the position of the TAM, since it's managed internally
        const newFilteredPositions = newAddPositions.filter((position) => position.role.label !== TAM_LABEL);

        const newData = newFilteredPositions?.map((position) => {
            return {
                projectId,
                developmentRolId: position.role.value,
                seniorityId: position.seniority.value,
                total: position.total,
                partTime: position.time,
                tags: position.jobSkills.map((jobSkill) => ({ type: "Tag", value: jobSkill })),
                estimatedStartDate: position.estimatedStartDate,
                estimatedEndDate: position.estimatedEndDate,
                jobDescriptionLink: position.jobDescription.link,
                jobDescriptionFile: position.jobDescription.file.name,
                jobDescriptionBlob: position.jobDescription.file.blobName,
            };
        });

        const filteredPositions = positions.filter((position) => position.role.label !== TAM_LABEL);

        const data = filteredPositions?.map((position) => {
            return {
                projectId,
                developmentRolId: position.role.value,
                seniorityId: position.seniority.value,
                total: position.total,
                partTime: position.time,
                tags: position.jobSkills.map((jobSkill) => ({ type: "Tag", value: jobSkill })),
                estimatedStartDate: position.estimatedStartDate,
                estimatedEndDate: position.estimatedEndDate,
                jobDescriptionLink: position.jobDescription.link,
                jobDescriptionFile: position.jobDescription.file.name,
                jobDescriptionBlob: position.jobDescription.file.blobName,
            };
        });

        const dataToSend = goesToTeamInfoResume ? newData : newData.concat(data);

        const response = await postPositionsAsync(dataToSend, !goesToTeamInfoResume);

        setLoading(false);

        if (!response.ok) {
            if (response.status === 401) {
                await forceLogout();
            } else {
                toast.error(t("failedAddingNewPosition"));
                console.error("Unable to post new positions:", await response.text());
            }

            return;
        }

        const tamPosition =
            newAddPositions.find((position) => position.role.label === TAM_LABEL) ||
            positions.find((position) => position.role.label === TAM_LABEL);

        dispatch(setPositionsAction([tamPosition, ...filteredPositions, ...newFilteredPositions]));

        toast.success(t("addedNewPositions"));

        if (goesToTeamInfoResume) {
            navigate(Routes.buildTeamInfoResumePath(projectId));
            return;
        }

        nextPhase();
    };

    const onChildValidationChange = (event) => {
        setChildFormsStatus((prevState) => {
            return {
                ...prevState,
                [event.id]: {
                    ...prevState[event.id],
                    valid: event.valid,
                    forceGlobalValidationFlag: prevState[event.id].forceGlobalValidationFlag,
                },
            };
        });
    };

    const handleBack = () => {
        setSearchParams({ back: true });
        previousPhase();
    };

    const positionsList = positions
        .slice()
        .sort((a, b) => {
            if (a.id === TAM_ID) return -1;

            return 0;
        })
        .map((position) => {
            if (innerWidth >= BREAKPOINTS.SM) {
                return (
                    <Resource
                        key={position.id}
                        roleTypes={roleTypes}
                        seniorityTypes={seniorityTypes}
                        totalPositions={positions.length + newAddPositions.length}
                        positionInfo={position}
                        projectInfo={project}
                        deletePosition={handleDeletePosition}
                        onChange={handleOnChange}
                        disableFileInput={isFileUploading.loading}
                        inputFileIsLoading={isFileUploading.positionId === position.id}
                        onValidationChange={onChildValidationChange}
                        forceGlobalValidationFlag={childFormsStatus[position.id]?.forceGlobalValidationFlag || 0}
                        cardSchema={CARD_SCHEMA}
                        schemaErrorMessages={SCHEMA_ERROR_MESSAGES}
                        seniorOnlyRoles={seniorOnlyRoles}
                        disabled={goesToTeamInfoResume}
                    />
                );
            } else {
                return (
                    <ResourceMobile
                        key={position.id}
                        roleTypes={roleTypes}
                        seniorityTypes={seniorityTypes}
                        totalPositions={positions.length + newAddPositions.length}
                        positionInfo={position}
                        projectInfo={project}
                        deletePosition={handleDeletePosition}
                        onChange={handleOnChange}
                        disableFileInput={isFileUploading.loading}
                        inputFileIsLoading={isFileUploading.positionId === position.id}
                        onValidationChange={onChildValidationChange}
                        forceGlobalValidationFlag={childFormsStatus[position.id]?.forceGlobalValidationFlag || 0}
                        cardSchema={CARD_SCHEMA}
                        schemaErrorMessages={SCHEMA_ERROR_MESSAGES}
                        seniorOnlyRoles={seniorOnlyRoles}
                        disabled={goesToTeamInfoResume}
                    />
                );
            }
        });

    const newAddPositionsList = newAddPositions
        .slice()
        .sort((a, b) => {
            if (a.id === TAM_ID) return -1;

            return 0;
        })
        .map((position) => {
            if (innerWidth >= BREAKPOINTS.SM) {
                return (
                    <Resource
                        key={position.id}
                        roleTypes={roleTypes}
                        seniorityTypes={seniorityTypes}
                        totalPositions={positions.length + newAddPositions.length}
                        positionInfo={position}
                        projectInfo={project}
                        deletePosition={handleDeletePosition}
                        onChange={handleOnChange}
                        disableFileInput={isFileUploading.loading}
                        inputFileIsLoading={isFileUploading.positionId === position.id}
                        onValidationChange={onChildValidationChange}
                        forceGlobalValidationFlag={childFormsStatus[position.id]?.forceGlobalValidationFlag || 0}
                        cardSchema={CARD_SCHEMA}
                        schemaErrorMessages={SCHEMA_ERROR_MESSAGES}
                        seniorOnlyRoles={seniorOnlyRoles}
                    />
                );
            } else {
                return (
                    <ResourceMobile
                        key={position.id}
                        roleTypes={roleTypes}
                        seniorityTypes={seniorityTypes}
                        totalPositions={positions.length + newAddPositions.length}
                        positionInfo={position}
                        projectInfo={project}
                        deletePosition={handleDeletePosition}
                        onChange={handleOnChange}
                        disableFileInput={isFileUploading.loading}
                        inputFileIsLoading={isFileUploading.positionId === position.id}
                        onValidationChange={onChildValidationChange}
                        forceGlobalValidationFlag={childFormsStatus[position.id]?.forceGlobalValidationFlag || 0}
                        cardSchema={CARD_SCHEMA}
                        schemaErrorMessages={SCHEMA_ERROR_MESSAGES}
                        seniorOnlyRoles={seniorOnlyRoles}
                    />
                );
            }
        });

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>{t("selectYourTeam")}</h2>
            <p className={styles.textLabel}>
                {t("projectName")}: <span className={styles.teamName}>{project.teamName}</span>
            </p>
            <p className={styles.textLabel}>{t("buildYourDreamTeam")}:</p>
            <p className={`${styles.textLabel} ${styles.subTitle}`}>{t("completeAgainInformation")}</p>
            <form onSubmit={handleSubmitTeamInfoForm}>
                <div className="d-flex flex-column gap-4">
                    {innerWidth >= BREAKPOINTS.SM ? (
                        positionsList.concat(newAddPositionsList)
                    ) : (
                        <Accordion>{positionsList.concat(newAddPositionsList)}</Accordion>
                    )}
                </div>
                <div className="d-flex gap-4 mt-5 justify-content-center position-relative">
                    {!goesToTeamInfoResume && (
                        <Button
                            type="button"
                            cssClasses={[styles.btn, styles.btnDefaultOutline, styles.btnBack]}
                            variant={btnVariants.PRIMARY_INVERSE}
                            onClick={handleBack}
                            disabled={isFileUploading.loading}
                        >
                            {t("back")}
                        </Button>
                    )}
                    <Button
                        type="button"
                        cssClasses={[styles.btn, styles.btnDefaultOutline]}
                        variant={btnVariants.PRIMARY_INVERSE}
                        onClick={handleAddResource}
                        disabled={isFileUploading.loading}
                    >
                        <Trans components={{ span: <span className="" /> }}>addResource</Trans>
                    </Button>
                    <Button
                        type="submit"
                        cssClasses={[styles.btn, styles.btnSuccess]}
                        disabled={isFileUploading.loading}
                    >
                        {t("continue")}
                    </Button>
                </div>
            </form>

            {loading && <Loading />}
        </div>
    );
};

export default TeamInfo;
