import { API_BASE, request } from "../utils/httpRequest";
import { SURVEYS_EXPORT_FILE_TYPES } from "../base/js/constants";

export const getSurveyListAsync = async (
    page = 1,
    pageSize = 5,
    surveyType = null,
    organizationId = null,
    startDate = null,
    endDate = null
) => {
    return await request(
        API_BASE +
            `/api/survey?page=${page}&pageSize=${pageSize}${surveyType ? "&surveyType=" + surveyType : ""}
                ${organizationId ? "&organizationId=" + organizationId : ""}
                ${startDate ? "&startDate=" + startDate : ""}
                ${endDate ? "&endDate=" + endDate : ""}`,
        {
            method: "GET",
        }
    );
};

export const generateExportSurveyUrl = (
    fileType = SURVEYS_EXPORT_FILE_TYPES.EXCEL,
    surveyType = null,
    organizationId = null,
    startDate = null,
    endDate = null
) => {
    const args = [];

    if (surveyType) {
        args.push(`surveyType=${surveyType}`);
    }

    if (organizationId) {
        args.push(`organizationId=${organizationId}`);
    }

    if (startDate) {
        args.push(`startDate=${startDate}`);
    }

    if (endDate) {
        args.push(`endDate=${endDate}`);
    }

    return `${API_BASE}/api/survey/${fileType}${args.length > 0 ? "?" + args.join("&") : ""}`;
};

export const postSurvey = (survey) => {
    return request(`${API_BASE}/api/survey/initial`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(survey),
    });
};
