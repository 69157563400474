import { API_BASE, request } from "../utils/httpRequest";

export const getTechnologyAsync = async (id) => {
    return await request(API_BASE + "/api/technology/" + id);
};

export const getTechnologyListAsync = async () => {
    return await request(API_BASE + "/api/technology", {
        method: "GET",
    });
};

export const getTechnologiesWithPagingAsync = async (page, pageSize, searchText = "") => {
    return await request(
        `${API_BASE}/api/technology/with-paging?page=${page}&pageSize=${pageSize}${
            searchText ? `&searchText=${searchText}` : ""
        }`
    );
};

export const removeTechnology = async (id) => {
    return await request(`${API_BASE}/api/technology/${id}`, {
        method: "DELETE",
    });
};

export const updateTechnology = async (id, updatedTechnology) => {
    return await request(`${API_BASE}/api/technology/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedTechnology),
    });
};

export const createTechnology = async (technology) => {
    return await request(`${API_BASE}/api/technology`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(technology),
    });
};
