import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import Modal from "../../common/modal/Modal";
import ModalButton from "../../common/modal/buttons/ModalButton";
import { ModalButtonVariant, PasswordRules, JoiPasswordStrengthValidator } from "../../../base/js/constants";
import Label from "../../common/custom-label/Label";
import useJoiValidation from "../../hooks/UseJoiValidation";
import PasswordStrengthIndicator from "../../login-signup/login-form/password-strength-indicator/PasswordStrengthIndicator";

const FormNames = {
    CURRENT_PASSWORD: "current-password",
    NEW_PASSWORD: "new-password",
};

const DEFAULT_STATE = {
    [FormNames.CURRENT_PASSWORD]: "",
    [FormNames.NEW_PASSWORD]: "",
};

const SCHEMA = {
    [FormNames.CURRENT_PASSWORD]: Joi.string().min(8).max(255).required(),
    [FormNames.NEW_PASSWORD]: Joi.string().min(8).max(255).required().custom(JoiPasswordStrengthValidator),
};

// (Joi) Object containing errors' messages by its type
const SCHEMA_ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "fieldTooShort8Chars",
    "string.email": "invalidEmailFormat",
    "any.required": "weakPassword",
};

const UpdatePasswordModal = ({ show, onAcceptClick, onCancelClick }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(DEFAULT_STATE);
    const { errors, validateSubSchemaFromEvent, validateSchema, resetErrors } = useJoiValidation();

    const onUpdateBtnClick = async () => {
        const numberOfErrors = validateSchema(SCHEMA, SCHEMA_ERROR_MESSAGES, data);

        if (numberOfErrors > 0) {
            return;
        }

        const thereAreErrors = await onAcceptClick({
            currentPassword: data[FormNames.CURRENT_PASSWORD],
            newPassword: data[FormNames.NEW_PASSWORD],
        });

        if (thereAreErrors) return;

        // Update states after modal dismiss
        setTimeout(() => {
            setData(DEFAULT_STATE);
            resetErrors();
        }, 1000);
    };

    const onCancelBtnClick = () => {
        onCancelClick();

        // Update states after modal dismiss
        setTimeout(() => {
            setData(DEFAULT_STATE);
            resetErrors();
        }, 1000);
    };

    const onChange = (event) => {
        setData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
            };
        });
    };

    const onBlur = (event) => {
        validateSubSchemaFromEvent(SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    return (
        <Modal show={show}>
            <form className="d-flex flex-column gap-3">
                <h3 className="text-center">{t("changePassword")}</h3>

                <div className="d-flex flex-column">
                    <Label htmlFor={FormNames.CURRENT_PASSWORD} requiredIndicator>
                        {t("currentPassword")}
                    </Label>

                    <input
                        type="password"
                        id={FormNames.CURRENT_PASSWORD}
                        name={FormNames.CURRENT_PASSWORD}
                        value={data[FormNames.CURRENT_PASSWORD]}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={`${sharedStyle.inputText} ${
                            errors[FormNames.CURRENT_PASSWORD] ? sharedStyle.invalidField : ""
                        }`}
                    />

                    {errors[FormNames.CURRENT_PASSWORD] && (
                        <div className="mt-1">
                            <p className={sharedStyle.errorMsg}>{errors[FormNames.CURRENT_PASSWORD].message}</p>
                        </div>
                    )}
                </div>

                <div className="d-flex flex-column">
                    <Label htmlFor={FormNames.NEW_PASSWORD} requiredIndicator>
                        {t("newPassword")}
                    </Label>

                    <input
                        type="password"
                        id={FormNames.NEW_PASSWORD}
                        name={FormNames.NEW_PASSWORD}
                        value={data[FormNames.NEW_PASSWORD]}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={`${sharedStyle.inputText} ${
                            errors[FormNames.NEW_PASSWORD] ? sharedStyle.invalidField : ""
                        }`}
                    />

                    {errors[FormNames.NEW_PASSWORD] && (
                        <div className="mt-1">
                            <p className={sharedStyle.errorMsg}>{errors[FormNames.NEW_PASSWORD].message}</p>
                        </div>
                    )}

                    <PasswordStrengthIndicator
                        password={data[FormNames.NEW_PASSWORD]}
                        rules={PasswordRules}
                        className="my-2"
                        translateLabels
                    />
                </div>
            </form>

            <div className="d-flex gap-3 mt-4 justify-content-center">
                <ModalButton variant={ModalButtonVariant.RED} onClick={onCancelBtnClick}>
                    {t("cancel")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.BLUE} onClick={onUpdateBtnClick}>
                    {t("update")}
                </ModalButton>
            </div>
        </Modal>
    );
};

UpdatePasswordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onAcceptClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};

export default UpdatePasswordModal;
