import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import linkedinIcon from "../../../assets/linkedin.svg";
import whatsappIcon from "../../../assets/whatsapp.svg";
import messageIcon from "../../../assets/message.svg";
import locationIcon from "../../../assets/pin-location.svg";
import Routes from "../../../base/js/routes";

const SocialsSection = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column gap-3">
            <span className="fw-bold mb-2">{t("contactUs2")}</span>

            <a href="https://www.linkedin.com/company/thedevblock" className="d-flex gap-2 align-items-center">
                <img src={linkedinIcon} alt="" />
                <span>thedevblock</span>
            </a>

            <span className="d-flex gap-2 align-items-center">
                <img src={whatsappIcon} alt="" />
                +1737 2013616
            </span>

            <a href="mailto:info@thedevblock.com" className="d-flex gap-2 align-items-center">
                <img src={messageIcon} alt="" />
                <span>info@thedevblock.com</span>
            </a>

            <span className="d-flex gap-2 align-items-center">
                <img src={locationIcon} alt="" />
                14425 Falcon Head Blvd. Austin, TX
            </span>

            <Link to={Routes.TERMS_AND_CONDITIONS} className="d-none d-lg-block fw-bold mt-2">
                {t("termsAndConditions")}
            </Link>
        </div>
    );
};

export default SocialsSection;
