import { API_BASE, request } from "../utils/httpRequest";

export const addProjectAsync = async (data) => {
    return await request(`${API_BASE}/api/project`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const updateProjectAsync = async (data, projectId) => {
    return await request(`${API_BASE}/api/project/${projectId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const addProjectCreatorAsync = async (id, userId) => {
    return await request(`${API_BASE}/api/project/${id}/add-creator`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userId),
    });
};

export const getProjectAsync = async (id, resume = false) => {
    return await request(`${API_BASE}/api/project/${id}${resume ? "?resume=true" : ""}`);
};

export const finishProjectCreationAsync = async (id) => {
    return await request(`${API_BASE}/api/project/finish-creation/${id}`, {
        method: "POST",
    });
};

export const setHiringManagerAsync = async (id, hiringManagerId) => {
    return await request(`${API_BASE}/api/project/${id}/set-hiring-manager`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: hiringManagerId }),
    });
};

export const setTechnicalAccountManagerAsync = async (id, technicalAccountManagerId) => {
    return await request(`${API_BASE}/api/project/${id}/set-technical-account-manager`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: technicalAccountManagerId }),
    });
};

export const setAllResourcesAllocated = async (projectId) => {
    return await request(`${API_BASE}/api/project/${projectId}/all-resources-allocated`, {
        method: "POST",
    });
};

export const getProjectsListAsync = async (page = 1, pageSize = 5, status = [], searchText = null) => {
    return await request(
        `${API_BASE}/api/project?page=${page}&pageSize=${pageSize}${
            status.length > 0 ? "&status=" + status.join(",") : ""
        }${searchText ? "&searchText=" + searchText : ""}`
    );
};

export const getProjectsByUserAsync = async (
    page = 1,
    pageSize = 5,
    startDate = null,
    endDate = null,
    searchText = null
) => {
    return await request(
        `${API_BASE}/api/project/projects-by-user?page=${page}&pageSize=${pageSize}${
            startDate ? "&startDate=" + startDate : ""
        }${endDate ? "&endDate=" + endDate : ""}${searchText ? "&searchText=" + searchText : ""}`
    );
};

export const rejectAllCandidates = async (projectId) => {
    return request(`${API_BASE}/api/project/${projectId}/reject-all-candidates`, {
        method: "POST",
    });
};

export const cancelProjectAsync = async (id) => {
    return await request(`${API_BASE}/api/project/${id}/cancel`, {
        method: "POST",
    });
};

export const removeProject = async (id) => {
    return await request(`${API_BASE}/api/project/${id}`, {
        method: "DELETE",
    });
};

export const setTeamConfirmed = async (projectId, rejectedStaff) => {
    return await request(`${API_BASE}/api/project/${projectId}/team-confirmed`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(rejectedStaff),
    });
};

export const getProjectHistory = async (projectId) => {
    return await request(`${API_BASE}/api/ProjectHistory/${projectId}`);
};

export const getAllProjectsBasic = async (organizationId = null, onlyWithStaff = false) => {
    const args = [];

    if (organizationId) {
        args.push(`organizationId=${organizationId}`);
    }

    if (onlyWithStaff) {
        args.push(`onlyWithStaff=${onlyWithStaff}`);
    }

    return await request(`${API_BASE}/api/project/all${args ? "?" + args.join("&") : ""}`);
};

export const getProjectsBasicByOrganization = async (organizationId, onlyWithStaff = false) => {
    return await request(`${API_BASE}/api/project/by-organization/${organizationId}?onlyWithStaff=${onlyWithStaff}`);
};

export const verifyProjectAsync = async (projectId) => {
    return await request(`${API_BASE}/api/project/${projectId}/validate-project`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
};

export const rejectProjectAsync = async (projectId, cause) => {
    return await request(`${API_BASE}/api/project/${projectId}/reject-project`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cause: cause }),
    });
};
