import Tags from "@yaireo/tagify/dist/react.tagify";
import { format, parse, parseISO } from "date-fns";
import Joi from "joi";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { DATE_FORMAT, JoiWebsiteValidator, SEX, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import { toggleResourceStatus } from "../../../services/AdminService";
import { createLinkDownloadStaffCvFile } from "../../../services/FileService";
import { getLanguageListAsync } from "../../../services/LanguageService";
import { getSenioritiesAsync } from "../../../services/PositionService";
import { getRolListAsync } from "../../../services/RolService";
import { addStaffAsync, getResourcesListAsync, updateStaffAsync } from "../../../services/StaffService";
import { getTechnologyListAsync } from "../../../services/TechnologyService";
import { useForceLogout } from "../../../utils/useForceLogout";
import { ALLOWED_TYPE_NAMES } from "../../build-your-team/assemble-your-team/constants";
import Checkbox from "../../common/Checkbox";
import InputFile from "../../common/custom-input-file/CustomInputFile";
import Label from "../../common/custom-label/Label";
import Paginator from "../../common/paginator/Paginator";
import SenioritySelector from "../../common/seniority-selector/SenioritySelector";
import SexSelector from "../../common/sex-selector/SexSelector";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminResources.module.scss";
import ConfirmResourceStatusModal from "./confirm-resource-status-modal/ConfirmResourceStatusModal";
import {
    handleAttachmentChange,
    handleResourceBirthdayChange,
    handleResourceLanguagesChange,
    handleResourceTagsChange,
    handleRolesChange,
    handleTechnologiesChange,
    handleTextFieldChange,
} from "./hooks/UseFormChangeFunctions";

const validateArrayEmpty = (value, helpers) => {
    return value.length > 0 ? value : helpers.error("any.required");
};

const BASE_SCHEMA = {
    search: Joi.string(),
    name: Joi.string().max(50).empty("").required(),
    lastname: Joi.string().max(50).empty("").required(),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .empty("")
        .required(),
    resourceSex: Joi.string().max(1),
    summary: Joi.string().max(250).empty("").required(),
    resourceLanguages: Joi.array()
        .items(
            Joi.object({
                value: Joi.number().required(),
                label: Joi.string().required(),
            })
        )
        .custom(validateArrayEmpty)
        .required(),
    resourceTechnologies: Joi.array().items(Joi.string()).custom(validateArrayEmpty).required(),
    resourceRoles: Joi.array().items(Joi.number()).custom(validateArrayEmpty).required(),
    englishInterviewUrl: Joi.string().empty("").custom(JoiWebsiteValidator),
    languageEvaluation: Joi.string().empty("").max(3000),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "string.email": "invalidEmailFormat",
    "object.with": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "any.invalid": "invalidFormatUrl",
};

const BirthdayInput = forwardRef(({ value, onClick, startDate, isValid }, ref) => (
    <input
        ref={ref}
        id="input-birthday"
        type="text"
        className={`${sharedStyle.inputDate} ${isValid ? "" : sharedStyle.invalidField} px-3 py-2`}
        value={value}
        onClick={onClick}
        readOnly
    />
));

const fileInitialState = {
    fileName: "",
    blobName: "",
    isLoading: false,
    errors: [],
    isValid: true,
};

const AdminResources = () => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const { validateSchema, validateSubSchemaFromEvent, errors, resetErrors } = useJoiValidation();
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [seniorities, setSeniorities] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [roles, setRoles] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [resourcesData, setResourcesData] = useState([]);
    const [resourceActiveId, setResourceActiveId] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [resourceId, setResourceId] = useState(null);
    const [resourceName, setResourceName] = useState("");
    const [resourceLastname, setResourceLastname] = useState("");
    const [resourceEmail, setResourceEmail] = useState("");
    const [resourceSex, setResourceSex] = useState(SEX.OTHER);
    const [resourceSeniority, setResourceSeniority] = useState(0);
    const [resourceCvFile, setResourceCvFile] = useState(fileInitialState);
    const [resourcePictureFile, setResourcePictureFile] = useState(fileInitialState);
    const [shortSheetFile, setShortSheetFile] = useState(fileInitialState);
    const [basicInformationFile, setBasicInformationFile] = useState(fileInitialState);
    const [resourceDescription, setResourceDescription] = useState("");
    const [resourceTags, setResourceTags] = useState([]);
    const [resourceBirthday, setResourceBirthday] = useState("");
    const [resourceLanguages, setResourceLanguages] = useState([]);
    const [resourceTechnologies, setResourceTechnologies] = useState([]);
    const [resourceRoles, setResourceRoles] = useState([]);
    const [englishInterviewUrl, setEnglishInterviewUrl] = useState("");
    const [languageEvaluation, setLanguageEvaluation] = useState("");

    useEffect(() => {
        (async () => {
            const response = await getLanguageListAsync();
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }
                return;
            }

            const responseData = await response.json();
            setLanguages(
                responseData.map((lang) => ({
                    value: lang.id,
                    label: lang.name,
                }))
            );
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getSenioritiesAsync();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }
                return;
            }

            const responseData = await response.json();
            setSeniorities(responseData);
            setResourceSeniority(responseData[0]?.id);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getRolListAsync();
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }
                return;
            }

            const responseData = await response.json();
            setRoles(responseData);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getTechnologyListAsync();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }
                return;
            }

            const responseData = await response.json();
            setTechnologies(responseData);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getResourcesListAsync(activePage, totalPerPage, searchInput);
            const responseData = await response.json();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }
                return;
            }

            responseData.staffs = responseData.staffs.map((resource) => {
                const handleEdit = async (e) => {
                    e.preventDefault();

                    setResourceId(resource.id);

                    try {
                        setLoading(true);

                        setResourceName(resource.name);
                        setResourceLastname(resource.lastName);
                        setResourceSex(resource.sex);
                        setResourceEmail(resource.email);
                        setResourceSeniority(resource.seniority?.id);
                        setResourceCvFile({
                            ...fileInitialState,
                            fileName: resource.cvFileName,
                            blobName: resource.cvFileBlobName,
                        });
                        setResourcePictureFile({
                            ...fileInitialState,
                            fileName: resource.pictureName,
                            blobName: resource.pictureBlobName,
                        });
                        setShortSheetFile({
                            ...fileInitialState,
                            fileName: resource.shortSheetFileName,
                            blobName: resource.shortSheetFileBlobName,
                        });
                        setBasicInformationFile({
                            ...fileInitialState,
                            fileName: resource.informationFileName,
                            blobName: resource.informationFileBlobName,
                        });
                        setResourceDescription(resource.summary);
                        setResourceTags(resource.staffCustomValues?.map((cv) => cv.customValue.value)); // TODO Set this
                        setResourceBirthday(resource.birthday ? format(parseISO(resource.birthday), DATE_FORMAT) : "");
                        setResourceLanguages(resource.idioms?.map((i) => ({ value: i.id, label: i.name })));
                        setResourceTechnologies(resource.technologies?.map((t) => t.id));
                        setResourceRoles(resource.developmentRols?.map((r) => r.id));
                        setEnglishInterviewUrl(resource.englishInterviewUrl || "");
                        setLanguageEvaluation(resource.languageEvaluation || "");

                        // Scroll to the top of the page
                        window.scrollTo(0, 0);

                        setShowForm(true);
                    } catch (error) {
                        toast.error(`${t("unexpectedError")}: ${error.message}`);
                    } finally {
                        setLoading(false);
                    }
                };

                return {
                    ...resource,
                    actions: [{ label: "edit", action: handleEdit, color: "" }],
                };
            });

            setResourcesData(responseData);
        })();
    }, [activePage, totalPerPage, searchInput, reloadData]);

    const onTechnologiesChange = (event) => {
        const recreatedEvent = handleTechnologiesChange(event, resourceTechnologies, setResourceTechnologies);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, recreatedEvent);
    };

    const onRolesChange = (event) => {
        const recreatedEvent = handleRolesChange(event, resourceRoles, setResourceRoles);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, recreatedEvent);
    };

    const onTextFieldChange = (event) => {
        event.preventDefault();
        handleTextFieldChange(
            event,
            setSearchInput,
            setResourceName,
            setResourceLastname,
            setResourceEmail,
            setResourceDescription,
            setEnglishInterviewUrl,
            setLanguageEvaluation
        );
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const onTextFieldChangeWithoutValidation = (event) => {
        event.preventDefault();

        handleTextFieldChange(
            event,
            setSearchInput,
            setResourceName,
            setResourceLastname,
            setResourceEmail,
            setResourceDescription,
            setEnglishInterviewUrl,
            setLanguageEvaluation
        );
    };

    const onTextFieldBlur = (event) => {
        event.preventDefault();
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const onAttachmentChange = async (event) => {
        await handleAttachmentChange(
            event,
            setResourceCvFile,
            setResourcePictureFile,
            setShortSheetFile,
            setBasicInformationFile
        );
    };

    const onResourceTagsChange = (event) => {
        handleResourceTagsChange(event, setResourceTags);
    };

    const onResourceBirthdayChange = (date) => {
        handleResourceBirthdayChange(date, setResourceBirthday);
    };

    const onResourceLanguagesChange = (languages) => {
        handleResourceLanguagesChange(languages, setResourceLanguages);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "resourceLanguages", value: languages, name: "resourceLanguages" },
        });
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const clearForm = () => {
        setResourceId(null);
        setResourceName("");
        setResourceLastname("");
        setResourceEmail("");
        setResourceSex(SEX.OTHER);
        setResourceSeniority(0);
        setResourceCvFile(fileInitialState);
        setResourcePictureFile(fileInitialState);
        setShortSheetFile(fileInitialState);
        setBasicInformationFile(fileInitialState);
        setResourceDescription("");
        setResourceTags([]);
        setResourceBirthday("");
        setResourceLanguages([]);
        setResourceTechnologies([]);
        setResourceRoles([]);
        setEnglishInterviewUrl("");
        setLanguageEvaluation("");

        // Reset errors managed by the hook useJoiValidation
        resetErrors();
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            name: resourceName,
            lastname: resourceLastname,
            email: resourceEmail,
            resourceSex: resourceSex,
            summary: resourceDescription,
            resourceLanguages: resourceLanguages,
            resourceTechnologies: resourceTechnologies,
            resourceRoles: resourceRoles,
            englishInterviewUrl,
            languageEvaluation,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!resourceCvFile.fileName || !resourceCvFile.blobName) {
            setResourceCvFile((prevState) => {
                const newErrors = [...prevState.errors, "requiredFieldIsEmpty"];
                return { ...prevState, isValid: false, errors: newErrors };
            });
        } else {
            setResourceCvFile((prevState) => {
                return { ...prevState, isValid: true, errors: [] };
            });
        }

        if (!!cantErrors || !!resourceCvFile.errors.length > 0) {
            return;
        }

        try {
            const dataToSend = {
                seniorityId: resourceSeniority,
                name: resourceName,
                lastName: resourceLastname,
                sex: resourceSex,
                birthday: resourceBirthday,
                pictureName: resourcePictureFile.fileName,
                pictureBlobName: resourcePictureFile.blobName,
                summary: resourceDescription,
                email: resourceEmail,
                cvFileName: resourceCvFile.fileName,
                cvFileBlobName: resourceCvFile.blobName,
                shortSheetFileName: shortSheetFile.fileName,
                shortSheetFileBlobName: shortSheetFile.blobName,
                informationFileName: basicInformationFile.fileName,
                informationFileBlobName: basicInformationFile.blobName,
                idiomsIds: resourceLanguages.map((lang) => lang.value),
                developmentsRolsIds: resourceRoles,
                technologiesIds: resourceTechnologies,
                customValues: resourceTags?.map((tag) => ({
                    value: tag,
                })),
                englishInterviewUrl,
                languageEvaluation,
            };

            setLoading(true);

            const response = resourceId
                ? await updateStaffAsync(resourceId, dataToSend)
                : await addStaffAsync(dataToSend);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success(t(resourceId ? "resourceUpdated" : "resourceCreated"));

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormCancel = () => {
        setShowForm(false);
        clearForm();
    };

    const handleClickAdd = () => {
        setShowForm(true);
    };

    const getResourcesData = () => {
        if (resourcesData.staffs) {
            return resourcesData?.staffs.map((resource) => {
                const handleActiveChange = async (e) => {
                    e.preventDefault();
                    setResourceActiveId(resource.id);
                    setShowConfirmationModal(true);
                };

                return {
                    name: resource.name,
                    email: resource.email,
                    sex: resource.sex === SEX.MALE ? "MALE" : resource.sex === SEX.FEMALE ? "FEMALE" : "",
                    roles: resource.developmentRols.map((rol) => rol.name).join(", "),
                    cvitae: resource.cvFileBlobName ? (
                        <a className={styles.downloadLink} href={createLinkDownloadStaffCvFile(resource.id)} download>
                            {resource.cvFileName}
                        </a>
                    ) : null,
                    active: { isActive: resource.active, action: handleActiveChange },
                    actions: resource.actions,
                };
            });
        } else {
            return [];
        }
    };

    const handleAcceptChangeResourceStatus = async () => {
        try {
            setLoading(true);

            const response = await toggleResourceStatus(resourceActiveId);
            if (!response.ok) {
                toast.error(t("unexpectedError") + ": " + (await response.text()));
                return;
            }

            toast.success(t("resourceUpdateSuccessfully"));
            setReloadData(!reloadData);
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setShowConfirmationModal(false);
            setLoading(false);
        }
    };

    const handleCancelChangeResourceStatus = () => {
        setShowConfirmationModal(false);
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        <Label htmlFor="search">{t("search")}</Label>
                        <input
                            id="search"
                            name="search"
                            className={`${sharedStyle.inputText} px-2`}
                            value={searchInput}
                            onChange={onTextFieldChange}
                        />
                    </div>
                </div>

                <div className="d-flex gap-2 mx-3">
                    <button
                        className={`${styles.btnAdd} btn btn-success text-uppercase`}
                        disabled={showForm}
                        onClick={handleClickAdd}
                    >
                        {showForm ? (resourceId ? t("editing") : t("adding")) : t("add")}
                    </button>
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={styles.formContainer}>
                        <form onSubmit={handleFormSubmit} className="py-3">
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="name" requiredIndicator>
                                        {t("name")}
                                    </Label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className={`${sharedStyle.inputText} ${
                                            !errors.name ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        value={resourceName}
                                        onChange={onTextFieldChange}
                                    />
                                    {errors.name && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.name.message}</p>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="lastname" requiredIndicator>
                                        {t("lastname")}
                                    </Label>
                                    <input
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                        className={`${sharedStyle.inputText} ${
                                            !errors.lastname ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        value={resourceLastname}
                                        onChange={onTextFieldChange}
                                    />
                                    {errors.lastname && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.lastname.message}</p>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="email" requiredIndicator>
                                        {t("email")}
                                    </Label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className={`${sharedStyle.inputText} ${
                                            !errors.email ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        value={resourceEmail}
                                        onChange={onTextFieldChangeWithoutValidation}
                                        onBlur={onTextFieldBlur}
                                    />
                                    {errors.email && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.email.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="sex">{t("sex")}</Label>
                                    <SexSelector itemActive={resourceSex} setItemActive={setResourceSex} />
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="birthday">{t("birthday")}</Label>
                                    <div>
                                        <DatePicker
                                            id="birthday"
                                            selected={
                                                resourceBirthday
                                                    ? parse(resourceBirthday, DATE_FORMAT, new Date())
                                                    : null
                                            }
                                            onChange={onResourceBirthdayChange}
                                            customInput={<BirthdayInput isValid={!errors.birthday} />}
                                            dateFormat={DATE_FORMAT}
                                            showPopperArrow={false}
                                        />
                                        {errors.birthday && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.birthday.message}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceLanguages" requiredIndicator>
                                        {t("languages")}
                                    </Label>
                                    <Select
                                        id="resourceLanguages"
                                        name="resourceLanguages"
                                        options={languages}
                                        styles={getSelectorStyle(!!errors.resourceLanguages)}
                                        value={resourceLanguages}
                                        onChange={onResourceLanguagesChange}
                                        placeholder={`${t("select")}...`}
                                        isMulti
                                    />
                                    {errors.resourceLanguages && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.resourceLanguages.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceTechnologies" requiredIndicator>
                                        {t("technologies")}
                                    </Label>
                                    <div className={styles.checkboxListContainer}>
                                        {technologies.map((technology) => {
                                            return (
                                                <Checkbox
                                                    key={technology.id}
                                                    id={`technology-${technology.id}`}
                                                    checked={resourceTechnologies.includes(technology.id)}
                                                    name={technology.name}
                                                    isValid={!errors.resourceTechnologies}
                                                    onChange={onTechnologiesChange}
                                                >
                                                    {technology.name}
                                                </Checkbox>
                                            );
                                        })}
                                    </div>
                                    {errors.resourceTechnologies && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>
                                                {errors.resourceTechnologies.message}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceRoles" requiredIndicator>
                                        {t("roles")}
                                    </Label>
                                    <div className={styles.checkboxListContainer}>
                                        {roles.map((rol) => {
                                            return (
                                                <Checkbox
                                                    key={rol.id}
                                                    id={`role-${rol.id}`}
                                                    checked={resourceRoles.includes(rol.id)}
                                                    name={rol.id}
                                                    isValid={!errors.resourceRoles}
                                                    onChange={onRolesChange}
                                                >
                                                    {rol.name}
                                                </Checkbox>
                                            );
                                        })}
                                    </div>
                                    {errors.resourceRoles && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.resourceRoles.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex m-3 col-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceSeniority" requiredIndicator>
                                        {t("seniority")}
                                    </Label>
                                    <SenioritySelector
                                        items={seniorities}
                                        itemActive={resourceSeniority}
                                        setItemActive={setResourceSeniority}
                                    />
                                </div>
                            </div>

                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceCvFile" requiredIndicator>
                                        {t("cVitae")}
                                    </Label>

                                    <InputFile
                                        id="resourceCvFile"
                                        inputFileChange={onAttachmentChange}
                                        invalid={!resourceCvFile.isValid}
                                        isLoading={resourceCvFile.isLoading}
                                        disabled={resourceCvFile.isLoading}
                                        initialValue={{ name: resourceCvFile.fileName, value: resourceCvFile.blobName }}
                                    />

                                    {!resourceCvFile.isValid && (
                                        <div className="mt-1">
                                            {resourceCvFile.errors?.length >= 1 && (
                                                <p className={sharedStyle.errorMsg}>
                                                    {resourceCvFile.errors[0] === "allowedFileTypes"
                                                        ? t(resourceCvFile.errors[0]) +
                                                          " " +
                                                          ALLOWED_TYPE_NAMES.join(", ")
                                                        : t(resourceCvFile.errors[0])}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourcePictureFile">{t("picture")}</Label>

                                    <InputFile
                                        id="resourcePictureFile"
                                        inputFileChange={onAttachmentChange}
                                        invalid={!resourcePictureFile.isValid}
                                        isLoading={resourcePictureFile.isLoading}
                                        disabled={resourcePictureFile.isLoading}
                                        initialValue={{
                                            name: resourcePictureFile.fileName,
                                            value: resourcePictureFile.blobName,
                                        }}
                                    />

                                    <div className={!resourcePictureFile.isValid ? "mt-1" : ""}>
                                        {!resourcePictureFile.isValid &&
                                            resourcePictureFile.errors.map((error, i) => (
                                                <p key={i} className={sharedStyle.errorMsg}>
                                                    {error === "allowedFileTypes"
                                                        ? t(error) + " " + ALLOWED_TYPE_NAMES.join(", ")
                                                        : t(error)}
                                                </p>
                                            ))}
                                    </div>
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceBasicInformationFile">{t("basicInformation")}</Label>

                                    <InputFile
                                        id="resourceBasicInformationFile"
                                        inputFileChange={onAttachmentChange}
                                        invalid={!basicInformationFile.isValid}
                                        isLoading={basicInformationFile.isLoading}
                                        disabled={basicInformationFile.isLoading}
                                        initialValue={{
                                            name: basicInformationFile.fileName,
                                            value: basicInformationFile.blobName,
                                        }}
                                    />

                                    {!basicInformationFile.isValid && (
                                        <div className="mt-1">
                                            {basicInformationFile.errors.map((error, i) => (
                                                <p key={i} className={sharedStyle.errorMsg}>
                                                    {error === "allowedFileTypes"
                                                        ? t(error) + " " + ALLOWED_TYPE_NAMES.join(", ")
                                                        : t(error)}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="shortSheetFile">{t("shortSheet")}</Label>

                                    <InputFile
                                        id="shortSheetFile"
                                        inputFileChange={onAttachmentChange}
                                        invalid={!shortSheetFile.isValid}
                                        isLoading={shortSheetFile.isLoading}
                                        disabled={shortSheetFile.isLoading}
                                        initialValue={{ name: shortSheetFile.fileName, value: shortSheetFile.blobName }}
                                    />

                                    {!shortSheetFile.isValid && (
                                        <div className="mt-1">
                                            {shortSheetFile.errors.map((error, i) => (
                                                <p key={i} className={sharedStyle.errorMsg}>
                                                    {error === "allowedFileTypes"
                                                        ? t(error) + " " + ALLOWED_TYPE_NAMES.join(", ")
                                                        : t(error)}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="resourceTags">{t("tags")}</Label>
                                    <Tags
                                        onChange={onResourceTagsChange}
                                        defaultValue={resourceTags}
                                        className="w-100"
                                    />
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="summary" requiredIndicator>
                                        {t("summary")}
                                    </Label>
                                    <textarea
                                        id="summary"
                                        name="summary"
                                        value={resourceDescription}
                                        onChange={onTextFieldChange}
                                        className={`${sharedStyle.textArea} ${
                                            !errors.summary ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        rows="4"
                                    />
                                    {errors.summary && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.summary.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="englishInterviewUrl">{t("englishInterviewUrl")}</Label>
                                    <input
                                        id="englishInterviewUrl"
                                        name="englishInterviewUrl"
                                        onChange={onTextFieldChangeWithoutValidation}
                                        onBlur={onTextFieldBlur}
                                        value={englishInterviewUrl}
                                        className={`${sharedStyle.inputText} ${
                                            !errors.englishInterviewUrl ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                    />
                                    {errors.englishInterviewUrl && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.englishInterviewUrl.message}</p>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.fieldContainer}>
                                    <Label htmlFor="languageEvaluation">{t("languageEvaluation")}</Label>
                                    <textarea
                                        id="languageEvaluation"
                                        name="languageEvaluation"
                                        value={languageEvaluation}
                                        onChange={onTextFieldChange}
                                        className={`${sharedStyle.textArea} ${
                                            !errors.languageEvaluation ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        rows="4"
                                    />
                                    {errors.languageEvaluation && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.languageEvaluation.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex m-3 justify-content-end gap-3">
                                <button type="reset" className={`btn btn-danger`} onClick={handleFormCancel}>
                                    {t("cancel").toUpperCase()}
                                </button>
                                <button type="submit" className={`btn btn-success`}>
                                    {t("save").toUpperCase()}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <AdminTable
                    headers={["name", "email", "sex", "roles", "cVitae", "active", ""]}
                    data={getResourcesData()}
                />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(resourcesData.total ? resourcesData.total / totalPerPage : 1)}
                    />
                </div>
            </div>
            {loading && <Loading />}
            <ConfirmResourceStatusModal
                show={showConfirmationModal}
                onAccept={handleAcceptChangeResourceStatus}
                onCancel={handleCancelChangeResourceStatus}
            />
        </>
    );
};

export default AdminResources;
