import colors from "../../base/js/colors";

export const getSelectorStyle = (invalid = false) => ({
    control: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
        minHeight: "40px",
        boxShadow: "none",
        border: `${invalid || state.isFocused ? 2 : 1}px solid ${
            invalid ? colors.ERROR : state.isFocused ? colors.GRAY550 : colors.GRAY350
        }`,
        ":hover": {
            borderColor: invalid ? colors.ERROR : colors.GRAY550,
        },
    }),
});
