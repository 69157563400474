import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";
import style from "./Header.module.scss";
import logo from "../../assets/logos/tdb-logo.png";
import langIcon from "../../assets/lang.svg";
import NotificationIndicator from "./notifications/NotificationIndicator";
import Menu from "./menu/Menu";
import AdminMenu from "../admin/admin-menu/AdminMenu";
import Routes from "../../base/js/routes";
import useClickOutside from "../hooks/UseClickOutside";
import useWindowWidth from "../hooks/UseWindowWidth";
import { BREAKPOINTS } from "../../base/js/constants";

const Header = ({ languages }) => {
    const { i18n, t } = useTranslation();
    const [lang, setLang] = useState(i18n.resolvedLanguage);
    const [langsMenuOpen, setLangsMenuOpen] = useState(0);
    const matchAdminPath = useMatch("admin/*");
    const [showAdminMenu, setShowAdminMenu] = useState(false);

    useEffect(() => {
        if (matchAdminPath) {
            setShowAdminMenu(true);
        } else {
            setShowAdminMenu(false);
        }
    }, [matchAdminPath]);

    const username = useSelector((state) => state.user.username);
    const email = useSelector((state) => state.user.email);

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLang(i18n.resolvedLanguage);
    };

    const closeLangsMenu = () => {
        setLangsMenuOpen(0);
    };

    const toggleLangsMenu = () => {
        setLangsMenuOpen(!langsMenuOpen);
    };

    const langMenuRef = useRef(null);
    useClickOutside(langMenuRef, closeLangsMenu);

    const langsOptions = Object.keys(languages).map((lang) => {
        const styleOption = {
            fontWeight: i18n.resolvedLanguage === lang ? "bold" : "normal",
        };

        const onOptionClick = () => {
            setLanguage(lang);
        };

        return (
            <span key={lang} style={styleOption} onClick={onOptionClick}>
                {languages[lang].name}
            </span>
        );
    });

    const innerWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState(innerWidth < BREAKPOINTS.LG);

    useEffect(() => {
        setIsMobile(innerWidth < BREAKPOINTS.LG);
    }, [innerWidth]);

    return (
        <header className={`${style.header} position-fixed top-0 start-0 end-0 d-flex align-items-center`}>
            <Link to={Routes.HOME}>
                <img className={`${style.logo} align-self-center`} src={logo} alt={t("tdbIsLogo")} />
            </Link>

            <div className="d-flex flex-grow-1 justify-content-end">
                {(!username || !email) && (
                    <div
                        className={`${style.lang} d-none d-lg-flex align-items-center ms-2 ms-xl-5`}
                        onClick={toggleLangsMenu}
                        alt={t("btnToChangeLang")}
                        ref={langMenuRef}
                    >
                        <img src={langIcon} alt="" />
                        <span className={style.lngSelected}>{lang}</span>
                        <div className={langsMenuOpen ? style.lngSelect : `${style.lngSelect} d-none`}>
                            {langsOptions}
                        </div>
                    </div>
                )}
            </div>

            <div className="d-none d-lg-flex align-self-center">
                {username && email && (
                    <Link
                        to={Routes.USER_PROFILE}
                        className="d-flex flex-column align-items-end justify-content-center me-2 text-decoration-none"
                    >
                        <span className={`${style.username} d-block text-truncate`}>{username}</span>
                        <span className={`${style.email} d-block text-truncate`}>{email}</span>
                    </Link>
                )}
            </div>

            <div className="d-none d-lg-flex align-items-center ms-4">
                {username && email && <NotificationIndicator />}
            </div>

            {!(username && email) && !isMobile ? null : (
                <Menu
                    languages={languages}
                    currentLang={lang}
                    onLangChange={setLanguage}
                    isMobile={isMobile}
                    user={{ username, email }}
                />
            )}
            {showAdminMenu && <AdminMenu />}
        </header>
    );
};

export default Header;
