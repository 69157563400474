import { format } from "date-fns";
import { uploadFileAsync } from "../../../../services/FileService";
import {
    ALLOWED_MIMETYPES,
    DATE_FORMAT,
    MAX_ATTACHMENT_FILESIZE,
} from "../../../build-your-team/assemble-your-team/constants";

const dateToString = (date) => format(date, DATE_FORMAT);

export const handleTechnologiesChange = (event, resourceTechnologies, setResourceTechnologies) => {
    let newTechnologies = resourceTechnologies;
    const techId = event.target.id.split("technology-")[1];

    if (event.target.checked) {
        newTechnologies.push(techId);
    } else {
        newTechnologies = newTechnologies.filter((tech) => tech !== techId);
    }

    setResourceTechnologies(newTechnologies);

    return {
        ...event,
        target: {
            ...event.target,
            name: "resourceTechnologies",
            value: newTechnologies,
        },
    };
};

export const handleRolesChange = (event, resourceRoles, setResourceRoles) => {
    let newRoles = resourceRoles;
    const roleId = event.target.id.split("-")[1];

    if (event.target.checked) {
        newRoles.push(parseInt(roleId));
    } else {
        newRoles = newRoles.filter((tech) => tech !== parseInt(roleId));
    }

    setResourceRoles(newRoles);

    return {
        ...event,
        target: {
            ...event.target,
            name: "resourceRoles",
            value: newRoles,
        },
    };
};

export const handleTextFieldChange = (
    event,
    setSearchInput,
    setResourceName,
    setResourceLastname,
    setResourceEmail,
    setResourceDescription,
    setEnglishInterviewUrl,
    setLanguageEvaluation
) => {
    switch (event.target.name) {
        case "search":
            setSearchInput(event.target.value);
            break;
        case "name":
            setResourceName(event.target.value);
            break;
        case "lastname":
            setResourceLastname(event.target.value);
            break;
        case "email":
            setResourceEmail(event.target.value);
            break;
        case "summary":
            setResourceDescription(event.target.value);
            break;
        case "englishInterviewUrl":
            setEnglishInterviewUrl(event.target.value);
            break;
        case "languageEvaluation":
            setLanguageEvaluation(event.target.value);
            break;
    }
};

export const handleAttachmentChange = async (
    event,
    setResourceCvFile,
    setResourcePictureFile,
    setShortSheetFile,
    setBasicInformationFile
) => {
    if (
        event.target.id !== "resourceCvFile" &&
        event.target.id !== "resourcePictureFile" &&
        event.target.id !== "resourceBasicInformationFile" &&
        event.target.id !== "shortSheetFile"
    ) {
        throw new Error(`Unrecongized element (ID "${event.target.id}") to handle attachment`);
    }

    const fileSize = event.target.files[0].size;
    const fileType = event.target.files[0].type;
    let blobName = null;

    const errors = [];

    if (fileSize > MAX_ATTACHMENT_FILESIZE) {
        errors.push("fileSizeLimit");
        return;
    } else if (!ALLOWED_MIMETYPES.includes(fileType)) {
        errors.push("allowedFileTypes");
        return;
    }

    if (event.target.files.length > 0) {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);

        if (event.target.id === "resourceCvFile") {
            setResourceCvFile((prevState) => {
                return { ...prevState, isLoading: true };
            });
        } else if (event.target.id === "resourcePictureFile") {
            setResourcePictureFile((prevState) => {
                return { ...prevState, isLoading: true };
            });
        } else if (event.target.id === "resourceBasicInformationFile") {
            setBasicInformationFile((prevState) => {
                return { ...prevState, isLoading: true };
            });
        } else if (event.target.id === "shortSheetFile") {
            setShortSheetFile((prevState) => {
                return { ...prevState, isLoading: true };
            });
        }

        const response = await uploadFileAsync(formData);

        if (response.ok) {
            blobName = await response.json();
        }
    } else {
        errors.push("requiredFieldIsEmpty");
    }

    const finalObj = errors.length
        ? {
              fileName: "",
              blobName: "",
              isValid: false,
              isLoading: false,
              errors,
          }
        : {
              fileName: event.target.files[0]?.name,
              blobName: blobName?.name,
              isValid: true,
              isLoading: false,
              errors: [],
          };

    if (event.target.id === "resourceCvFile") {
        setResourceCvFile(finalObj);
    } else if (event.target.id === "resourcePictureFile") {
        setResourcePictureFile(finalObj);
    } else if (event.target.id === "resourceBasicInformationFile") {
        setBasicInformationFile(finalObj);
    } else if (event.target.id === "shortSheetFile") {
        setShortSheetFile(finalObj);
    }
};

export const handleResourceTagsChange = (event, setResourceTags) => {
    let tagsList = event.detail.tagify.getCleanValue().map((tag) => {
        return tag.value;
    });
    setResourceTags(tagsList);
};

export const handleResourceBirthdayChange = (date, setResourceBirthday) => {
    setResourceBirthday(dateToString(date));
};

export const handleResourceLanguagesChange = (languages, setResourceLanguages) => {
    setResourceLanguages(languages);
};
