import { useTranslation } from "react-i18next";
import LoginForm from "./login-form/LoginForm";
import SignupForm from "./signup-form/SignupForm";

import style from "./LoginSignup.module.scss";

export const componentType = {
    LOGIN: "login",
    SIGNUP: "signup",
};

const LoginSignup = ({ type = componentType.LOGIN }) => {
    const { t } = useTranslation();

    return (
        <main className={`${style.mainContainer} d-flex justify-content-center`}>
            <section className={style.leftSection}>
                <div className={`${style.loginContainer} m-auto d-flex flex-column  p-3 p-md-5`}>
                    {type === componentType.LOGIN ? <LoginForm /> : <SignupForm />}
                </div>
            </section>
        </main>
    );
};

export default LoginSignup;
