import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_FORMAT_FULL_MONTH } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import {
    addNoteToHourValidation,
    getHoursValidationPendingByProjectAsync,
    validateHoursValidationAsync,
} from "../../services/HourValidationService";
import { getProjectAsync } from "../../services/ProjectService";
import { useForceLogout } from "../../utils/useForceLogout";
import Button, { variants as btnVariants } from "../common/Button";
import NotesValidateHoursModal from "../common/validate-hours/notes-validate-hours-modal/NotesValidateHoursModal";
import TableResume from "../home-resume/table-resume/TableResume";
import Loading from "../loading/Loading";
import styles from "../manage-teams/ManageTeams.module.scss";
import style from "./ValidateHours.module.scss";

const ValidateHours = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const forceLogout = useForceLogout();
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [validationHoursRaw, setValidationHoursRaw] = useState([]);
    const [validationHours, setValidationHours] = useState([]);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [activeNotes, setActiveNotes] = useState([]);
    const [activeHourValidationId, setActiveHourValidationId] = useState("");
    const [activePeriodIndex, setActivePeriodIndex] = useState(0);
    const [project, setProject] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const projectId = params.projectId;

                if (!projectId) {
                    toast.error(t("projectIdNotFound2"));
                    navigate(Routes.HOME);

                    return;
                }

                setLoading(true);

                const response = await getProjectAsync(projectId, true);

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        const errorTitle = await response.json()?.title;
                        toast.error(t("unexpectedError") + ": " + errorTitle);
                    }

                    return;
                }

                const data = await response.json();
                setProject(data);

                const responseValidationHours = await getHoursValidationPendingByProjectAsync(projectId);

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        const errorTitle = await response.json()?.title;
                        toast.error(t("unexpectedError") + ": " + errorTitle);
                    }

                    return;
                }

                const dataValidationHours = await responseValidationHours.json();
                const onValidateHours = async (id) => {
                    try {
                        setLoading(true);
                        const response = await validateHoursValidationAsync(id);

                        if (!response.ok) {
                            if (response.status === 401) {
                                await forceLogout();
                            } else {
                                const errorTitle = await response.json()?.title;
                                toast.error(t("unexpectedError") + ": " + errorTitle);
                            }

                            return;
                        }

                        toast.success(t("successfullyValidated"));
                        setReloadData(!reloadData);
                    } catch (error) {
                        toast.error(t("unexpectedError") + ": " + error.message);
                    } finally {
                        setLoading(false);
                    }
                };

                setValidationHoursRaw(dataValidationHours);
                setValidationHours(
                    dataValidationHours.map((dataValidation) => {
                        return dataValidation.map((period) => {
                            const handleValidateHours = (e) => {
                                e.preventDefault();
                                onValidateHours(period.id);
                            };

                            return {
                                id: period.id,
                                name: `${period.staff.name} ${period.staff.lastName}`,
                                hours: period.hours,
                                status: period.status,
                                notes: period.notes.sort((a, b) => {
                                    if (a.createdAt > b.createdAt) return 1;
                                    if (a.createdAt < b.createdAt) return -1;
                                    return 0;
                                }),
                                actions: [
                                    {
                                        label: "validate",
                                        action: handleValidateHours,
                                        color: "green",
                                    },
                                ],
                            };
                        });
                    })
                );
            } catch (error) {
                toast.error(t("unexpectedError") + ": " + error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, [reloadData]);

    const handleBack = (e) => {
        e.preventDefault();
        navigate(Routes.HOME);
    };

    const handleClickReadMore = (hourValidationId, notes) => {
        setActiveNotes(notes);
        setActiveHourValidationId(hourValidationId);
        setShowNotesModal(true);
    };

    const handleClickModalClose = () => {
        setShowNotesModal(false);
        setActiveNotes([]);
    };

    const handleGoPrevious = (e) => {
        e.preventDefault();
        if (activePeriodIndex === 0) return;

        setActivePeriodIndex((prev) => prev - 1);
    };

    const handleGoNext = (e) => {
        e.preventDefault();
        if (activePeriodIndex === validationHours.length - 1) return;

        setActivePeriodIndex((prev) => prev + 1);
    };

    const handleSubmitNote = async (hourValidationId, newNote) => {
        try {
            setLoading(true);

            const dataToSend = {
                validationHourId: activeHourValidationId,
                note: newNote,
            };

            const response = await addNoteToHourValidation(dataToSend);

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }

                return;
            }

            toast.success(t("noteAddedSuccessfully"));
            setReloadData(!reloadData);
        } catch (error) {
            toast.error(t("unexpectedError") + ": " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className={style.mainContainer}>
            <h1 className={style.title}>{`${t("validatingHoursFor")}: ${project?.name ? project?.name : ""}`}</h1>
            <div className={`${style.sectionContainer}`}>
                <div className="d-flex justify-content-center pb-4">
                    <div>
                        {activePeriodIndex > 0 && (
                            <FontAwesomeIcon icon={faChevronLeft} className={style.icon} onClick={handleGoPrevious} />
                        )}
                        <span className={style.subTittle}>
                            {validationHoursRaw.length
                                ? format(
                                      parseISO(validationHoursRaw[activePeriodIndex][0].period),
                                      DATE_FORMAT_FULL_MONTH
                                  )
                                : ""}
                        </span>
                        {activePeriodIndex < validationHours.length - 1 && (
                            <FontAwesomeIcon icon={faChevronRight} className={style.icon} onClick={handleGoNext} />
                        )}
                    </div>
                </div>

                <div className="position-relative">
                    <TableResume
                        data={validationHours[activePeriodIndex]}
                        total={validationHours.length}
                        header={["staff", "hours", "status", "notes", "actions"]}
                        showFootContent={false}
                        handleClickNotes={handleClickReadMore}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Button cssClasses={[styles.btnBack]} variant={btnVariants.PRIMARY_INVERSE} onClick={handleBack}>
                    {t("back")}
                </Button>
            </div>
            <NotesValidateHoursModal
                notes={activeNotes}
                onClose={handleClickModalClose}
                show={showNotesModal}
                validationHourId={activeHourValidationId}
                onSubmitNote={handleSubmitNote}
            />
            {loading && <Loading />}
        </section>
    );
};

export default ValidateHours;
