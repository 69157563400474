import { useTranslation } from "react-i18next";
import style from "./SixthSection.module.scss";
import arrowRightFilled from "../../../assets/arrow-right-filled.svg";

const SixthSection = () => {
    const { t } = useTranslation();

    return (
        <div
            className={`${style.section} d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-center p-5`}
        >
            <h3 className="text-white mb-3 mb-lg-0">{t("wantToStart")}</h3>
            <div className={`${style.schedule} ms-lg-5 d-flex align-items-center`}>
                <img src={arrowRightFilled} alt="" />
                <span className="text-white ms-3">{t("scheduleMeeting2")}</span>
            </div>
        </div>
    );
};

export default SixthSection;
