import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./CustomInputFile.module.scss";

export const ALLOWED_FORMATS = [
    { name: ".doc", type: ".doc", mimeType: "application/msword" },
    {
        name: ".docx",
        type: ".docx",
        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    { name: ".pdf", type: ".pdf", mimeType: "application/pdf" },
    { name: ".png", type: "image/png", mimeType: "image/png" },
    { name: ".jpeg", type: "image/jpeg", mimeType: "image/jpeg" },
    { name: ".svg", type: "image/svg+xml", mimeType: "image/svg+xml" },
];

const CustomInputFile = ({
    inputFileChange,
    id = null,
    invalid = false,
    short = false,
    isLoading = false,
    disabled = false,
    initialValue = { name: "", value: "" },
}) => {
    const { t } = useTranslation();
    const inputFileRef = useRef(null);
    const [inputFileValue, setInputFileValue] = useState(initialValue);

    const handleInputFileChange = (event) => {
        if (typeof inputFileChange === "function") {
            inputFileChange(event);
        }

        if (event.target.value) {
            setInputFileValue({ name: event.target.files[0].name, value: event.target.value });
        }
    };

    const handleResetFile = () => {
        inputFileRef.current.value = "";
    };

    return (
        <div className={`${short ? "" : "col-12"} position-relative`}>
            <div className={`${styles.customInputFile} d-flex`}>
                <span
                    className={`${styles.fileName} ${invalid ? styles.invalid : ""} ${
                        short ? "d-none" : "d-flex"
                    } align-items-center`}
                >
                    {inputFileValue.name ? inputFileValue.name : ""}
                </span>
                <span
                    className={`${styles.attachButton} ${
                        disabled ? styles.disabledButton : ""
                    } d-flex align-items-center`}
                >
                    {isLoading ? <Spinner size="sm" animation="border" role="status" /> : t("attachFile")}
                </span>
            </div>
            <input
                id={id}
                ref={inputFileRef}
                type="file"
                onChange={handleInputFileChange}
                onClick={handleResetFile}
                className={`${styles.inputFile} opacity-0 position-absolute ${disabled ? "d-none" : ""}`}
                accept={ALLOWED_FORMATS.map((t) => t.type).join(",")}
                disabled={disabled}
            />
        </div>
    );
};

export default CustomInputFile;
