import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Label from "../../../../common/custom-label/Label";
import SkillsContainerMobile from "../skills-containers/SkillsContainerMobile";
import { useDnDFunctions } from "../../hooks/UseAssembleYourTeamFunctions";
import { ContainerType } from "../../constants";
import sharedStyle from "../../../../shared-styles/FormStyle.module.scss";

const SkillsMobileSelector = ({ ids, errors, validateSubSchemaFromEvent, validationSchema, schemaErrorMessages }) => {
    const { t } = useTranslation();

    const skills = useSelector((state) => state.assembleYourTeam.skills);
    const mandatorySkillInput = useSelector((state) => state.assembleYourTeam.customSkillMandatory);
    const desiredSkillInput = useSelector((state) => state.assembleYourTeam.customSkillDesired);

    const { onChange, onMobileCustomMandatorySkillAdd, onMobileCustomDesiredSkillAdd, onPillClick } = useDnDFunctions(
        validationSchema,
        schemaErrorMessages,
        validateSubSchemaFromEvent
    );

    return (
        <>
            <div className="mb-4">
                <Label requiredIndicator>{t("knowledgeRequired")}</Label>
                <SkillsContainerMobile
                    skills={skills}
                    onPillClick={onPillClick}
                    containerType={ContainerType.MANDATORY_SKILLS}
                    onInputChange={onChange}
                    inputState={mandatorySkillInput}
                    onCustomSkillAdd={onMobileCustomMandatorySkillAdd}
                    inputId={ids.INPUT_CUSTOM_MANDATORY_SKILL}
                    invalid={!!errors.skills}
                />
                {errors.skills && (
                    <div className="mt-1">
                        <p className={sharedStyle.errorMsg}>{errors.skills.message}</p>
                    </div>
                )}
            </div>

            <div>
                <Label>{t("desiredKnowledge")}</Label>
                <SkillsContainerMobile
                    skills={skills}
                    onPillClick={onPillClick}
                    containerType={ContainerType.DESIRED_SKILLS}
                    onInputChange={onChange}
                    inputState={desiredSkillInput}
                    onCustomSkillAdd={onMobileCustomDesiredSkillAdd}
                    inputId={ids.INPUT_CUSTOM_DESIRED_SKILL}
                />
            </div>
        </>
    );
};

export default SkillsMobileSelector;
