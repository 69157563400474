import { useTranslation } from "react-i18next";
import MobilePill from "../skills-pills/MobilePill";
import { ContainerType, PillVariants } from "../../constants";
import style from "./DropSkillsContainer.module.scss";
import sharedStyle from "../../../../shared-styles/FormStyle.module.scss";
import colors from "../../../../../base/js/colors";

const SkillsContainerMobile = ({
    skills,
    onPillClick,
    containerType,
    onCustomSkillAdd,
    onInputChange,
    inputState,
    inputId,
    invalid = false,
}) => {
    const { t } = useTranslation();
    const pillsVariant = containerType === ContainerType.MANDATORY_SKILLS ? PillVariants.GREEN : PillVariants.ORANGE;

    const onButtonClick = (event) => {
        const customEvent = { ...event, target: { value: inputState } };

        onCustomSkillAdd(customEvent);
    };

    return (
        <div>
            <div
                className={`${style.customSkill} ${
                    invalid ? sharedStyle.invalidField : ""
                } px-3 d-flex align-items-center`}
            >
                <input
                    id={inputId}
                    className={style.inputStyleRemoval}
                    placeholder={`+ ${t("addKnowledge")}`}
                    value={inputState}
                    onChange={onInputChange}
                    autoComplete="off"
                />
                <button
                    className="rounded-circle d-flex align-items-center justify-content-center p-0 border-0"
                    onClick={onButtonClick}
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{ width: "25px", height: "25px", transform: ["rotate(180deg)"] }}
                    >
                        <path
                            fill={inputState ? colors.BLACK : colors.GRAY550}
                            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z"
                        />
                    </svg>
                </button>
            </div>
            {!invalid && (
                <div className="d-flex flex-wrap gap-2 mt-2">
                    {skills
                        .filter((skill) => skill.container === containerType)
                        .map((skill) => (
                            <MobilePill
                                skill={skill}
                                key={skill.key}
                                onClick={onPillClick}
                                variant={pillsVariant}
                                selected
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

export default SkillsContainerMobile;
