import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getSelectorStyle } from "../../../shared-styles/StylesFunctions";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import toast from "react-hot-toast";
import Tags from "@yaireo/tagify/dist/react.tagify";
import PropTypes from "prop-types";
import { getRolListAsync } from "../../../../services/RolService";
import { useForceLogout } from "../../../../utils/useForceLogout";
import { getSenioritiesAsync } from "../../../../services/PositionService";
import ResourceCard from "./resource-card/ResourceCard";
import Paginator from "../../../common/paginator/Paginator";
import Label from "../../../common/custom-label/Label";
import { getResourcesAvailableListAsync } from "../../../../services/StaffService";
import { getTechnologyListAsync } from "../../../../services/TechnologyService";
import styles from "./ResourceCardList.module.scss";
import Loading from "../../../loading/Loading";

const tagifySettings = {
    dropdown: {
        enabled: 1,
        classname: styles.tagifyDropdown,
    },
};

const PAGE_SIZE = 5;

const ResourceCardList = ({ onResourceClick, preselectedFilters }) => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const [roleList, setRoleList] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [page, setPage] = useState(1);
    const [role, setRole] = useState(null);
    const [seniorityList, setSeniorityList] = useState([]);
    const [seniority, setSeniority] = useState(null);
    const [technologiesList, setTechnologiesList] = useState([]);
    const [technology, setTechnology] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // Fetch available resources
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const response = await getResourcesAvailableListAsync(
                    page,
                    PAGE_SIZE,
                    role?.value,
                    seniority?.value,
                    technology,
                    null
                );
                setIsLoading(false);
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setResourceList(responseData);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [page, role, seniority, technology]);

    // Fetch roles
    useEffect(() => {
        (async () => {
            try {
                const response = await getRolListAsync();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                const rolesMapped = responseData.map((role) => {
                    return {
                        value: role.id,
                        label: role.name,
                    };
                });
                setRoleList(rolesMapped);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    // Fetch seniorities
    useEffect(() => {
        (async () => {
            try {
                const response = await getSenioritiesAsync();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                const senioritiesMapped = responseData.map((seniority) => {
                    return {
                        value: seniority.id,
                        label: seniority.name,
                    };
                });
                setSeniorityList(senioritiesMapped);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    // Fetch technologies
    useEffect(() => {
        (async () => {
            const response = await getTechnologyListAsync();
            const data = await response.json();

            const mappedData = data.map((t) => t.name);

            setTechnologiesList(mappedData);
        })();
    }, []);

    // Update preselected filters
    useEffect(() => {
        if (preselectedFilters.technologies) {
            const mappedTechnologies = [];

            preselectedFilters.technologies.forEach((t) => {
                const tech = technologiesList.find((t2) => t2.toLowerCase() === t.toLowerCase());

                if (!tech) {
                    mappedTechnologies.push(t);
                } else {
                    mappedTechnologies.push(tech);
                }
            });

            setTechnology(mappedTechnologies);
        }

        if (preselectedFilters.seniority) {
            const selectedSeniority = seniorityList.find((s) => s.label === preselectedFilters.seniority.name);
            setSeniority(selectedSeniority);
        }

        if (preselectedFilters.role) {
            const selectedRole = roleList.find((r) => r.label === preselectedFilters.role.name);
            setRole(selectedRole);
        }
    }, [preselectedFilters]);

    const handleRoleChange = (roleSelected) => {
        setRole(roleSelected);
    };

    const handleSeniorityChange = (senioritySelected) => {
        setSeniority(senioritySelected);
    };

    const handleTechnologyChange = (e) => {
        let tagsList = e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        });

        setTechnology(tagsList);
    };

    return (
        <div className={styles.mainContainer}>
            {isLoading && (
                <div className={styles.loadingContainer}>
                    <Loading allScreen={false} />
                </div>
            )}
            <div className={styles.filterSection}>
                <div className={styles.filterItem}>
                    <Label htmlFor="role">{t("role")}</Label>
                    <Select
                        id="role"
                        name="role"
                        options={roleList}
                        styles={getSelectorStyle()}
                        value={role}
                        onChange={handleRoleChange}
                        placeholder={`${t("select")}...`}
                        isClearable={true}
                    />
                </div>
                <div className={styles.filterItem}>
                    <Label htmlFor="seniority">{t("seniority")}</Label>
                    <Select
                        id="seniority"
                        name="seniority"
                        options={seniorityList}
                        styles={getSelectorStyle()}
                        value={seniority}
                        onChange={handleSeniorityChange}
                        placeholder={`${t("select")}...`}
                        isClearable={true}
                    />
                </div>
                <div className={`${styles.filterItem} ${styles.technologyFilter} mt-2`}>
                    <Label htmlFor="technology">{t("technology")}</Label>
                    <Tags
                        id="technology"
                        value={technology}
                        onChange={handleTechnologyChange}
                        whitelist={technologiesList}
                        settings={tagifySettings}
                    />
                </div>
            </div>
            <div className={styles.contentSection}>
                {resourceList.staffs?.map((resource) => {
                    return <ResourceCard onCardClick={onResourceClick} key={uuidv4()} resource={resource} />;
                })}
            </div>
            <div className={styles.paginatorSection}>
                <Paginator
                    activePage={page}
                    lastPage={resourceList.total ? Math.ceil(resourceList.total / PAGE_SIZE) : 1}
                    setPage={setPage}
                />
            </div>
        </div>
    );
};

ResourceCardList.propTypes = {
    onResourceClick: PropTypes.func.isRequired,
    preselectedFilters: PropTypes.object,
};

export default ResourceCardList;
