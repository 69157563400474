import FirstSection from "./first-section/FirstSection";
import SecondSection from "./second-section/SecondSection";
import ThirdSection from "./third-section/ThirdSection";
import FourthSection from "./fourth-section/FourthSection";
import FifthSection from "./fifth-section/FifthSection";
import SixthSection from "./sixth-section/SixthSection";

const About = () => (
    <main id="section-about" className="overflow-hidden">
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
    </main>
);

export default About;
