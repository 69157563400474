import { request, API_BASE } from "../utils/httpRequest";

export const getUsersByUserRole = async () => {
    return request(`${API_BASE}/api/user/by-user-role`);
};

export const getUserAsync = async (userId) => {
    return request(`${API_BASE}/api/user/${userId}`);
};

export const getUserListAsync = async (
    page = 1,
    pageSize = 5,
    searchText = null,
    filterRole = null,
    filterOrganization = null,
    filterInactive = false
) => {
    return request(
        `${API_BASE}/api/user?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}
        ${filterRole ? "&role=" + filterRole : ""}${filterOrganization ? "&organization=" + filterOrganization : ""}
        ${filterInactive ? "&inactive=" + filterInactive : ""}`,
        {
            method: "GET",
        }
    );
};

export const adminUpdateUserAsync = async (id, data) => {
    return await request(`${API_BASE}/api/user/${id}/admin-update`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getRoleListAsync = async () => {
    return request(`${API_BASE}/api/role`);
};

export const toggleUserStatus = (userId) => {
    return request(`${API_BASE}/api/user/${userId}/toggle-status`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
};

export const toggleResourceStatus = (resourceId) => {
    return request(`${API_BASE}/api/staff/${resourceId}/toggle-status`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
};
