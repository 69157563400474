import { useTranslation } from "react-i18next";
import DetailItem from "./detail-item/DetailItem";
import style from "./ProjectInfoTemplate.module.scss";

const ProjectInfoTemplate = ({ projectData, sectionTitle }) => {
    const { t } = useTranslation();

    return (
        <div className={`${style.informationContainer} d-flex flex-column flex-lg-row mt-4`}>
            <div className="d-flex flex-column gap-2 gap-lg-4 col-lg-4 mb-3 mb-lg-0">
                <h1 className={style.title}>{sectionTitle}</h1>
                <span className={style.projectDetails}>
                    {t("projectName")}: <span className={style.projectName}>{projectData.name}</span>
                </span>

                <span className={style.projectDetails}>
                    {t("projectStatus")}:{" "}
                    <span className={`${style.projectStatus} py-1 px-2 text-white`}>{t(projectData.status)}</span>
                </span>
            </div>

            <div
                className={`${style.detailsContainer} d-flex flex-grow-1 justify-content-evenly justify-content-lg-start mt-3`}
            >
                <div className="d-flex flex-column gap-3 gap-lg-4">
                    <DetailItem title={`${t("monthPrice")}:`}>{projectData.monthPrice}</DetailItem>
                </div>

                {/*<div className="d-flex flex-column gap-3 gap-lg-4">*/}
                {/*    <DetailItem title={`${t("finalPrice")}:`}>{projectData.finalPrice}</DetailItem>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default ProjectInfoTemplate;
