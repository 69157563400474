import { Children, forwardRef, useState } from "react";
import styles from "../AdminMenu.module.scss";

const CustomMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
        <div ref={ref} style={style} className={`${className} w-100`} aria-labelledby={labeledBy}>
            <ul className={styles.dropdownList}>
                {Children.toArray(children).filter(
                    (child) => !value || child.props.children.toLowerCase().startsWith(value)
                )}
            </ul>
        </div>
    );
});

export default CustomMenu;
