import Joi from "joi";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { addProjectAsync, updateProjectAsync } from "../../../services/ProjectService";
import { setId } from "../../../store/slices/assembleYourTeamSlice";
import { useForceLogout } from "../../../utils/useForceLogout";
import Button from "../../common/Button";
import useJoiValidation from "../../hooks/UseJoiValidation";
import useOnBeforeUnload from "../../hooks/UseOnBeforeUnload";
import Loading from "../../loading/Loading";
import style from "./AssembleYourTeam.module.scss";
import { ContainerType } from "./constants";
import FirstSection, { RoleCheckboxNames as FirstSectionRoleCheckboxNames } from "./first-section/FirstSection";
import { useFormOnChange } from "./hooks/UseAssembleYourTeamFunctions";
import SecondSection from "./second-section/SecondSection";

const booleanValidation = (value) => (value ? value : undefined);
const skillsValidation = (value, helpers) => {
    const mandatorySkills = value?.filter((skill) => skill.container === ContainerType.MANDATORY_SKILLS);

    if (!mandatorySkills.length) {
        return helpers.error("any.required");
    }

    return value;
};

const BASE_SCHEMA = {
    /* **** First section **** */
    teamName: Joi.string().max(50).empty("").required(),
    // investmentExpectation: Joi.number().empty(""),
    // yourDecisionFlow: Joi.string().max(3000).empty().required(),
    // rolesInYourCompany: Joi.object({
    //     [FirstSectionRoleCheckboxNames.DECISION_MAKER]: Joi.boolean().custom(booleanValidation),
    //     [FirstSectionRoleCheckboxNames.RECOMMENDER]: Joi.boolean().custom(booleanValidation),
    //     [FirstSectionRoleCheckboxNames.USER]: Joi.boolean().custom(booleanValidation),
    //     [FirstSectionRoleCheckboxNames.OTHERS]: Joi.boolean().custom(booleanValidation),
    //     otherRolesInput: Joi.string().empty(""),
    // })
    //     .or("decisionMaker", "recommender", "user", "others")
    //     .with("others", "otherRolesInput"),
    // estimatedDateToStart: Joi.date().empty("").required(),
    // estimatedDateToEnd: Joi.date().empty("").required(),
    timeZone: Joi.object({
        label: Joi.string(),
        rawHours: Joi.number(),
        value: Joi.string(),
    })
        .empty(null)
        .required(),
    // expectedDateToConfirm: Joi.date().empty("").required(),

    /* **** Second section **** */
    skills: Joi.array()
        .items(
            Joi.object({
                key: Joi.string().required(),
                name: Joi.string().required(),
                custom: Joi.boolean().required(),
                container: Joi.number().min(1).max(3).required(),
                alias: Joi.array().items(Joi.string()),
            })
        )
        .custom(skillsValidation)
        .required(),
    // teamWorkArea: Joi.array().items(
    //     Joi.object({
    //         value: Joi.number().required(),
    //         label: Joi.string().required(),
    //     })
    // ),
    // typeOfProject: Joi.object({
    //     value: Joi.number().required(),
    //     label: Joi.string().required(),
    // }).empty(""),
    additionalComments: Joi.string().max(3000).empty(""),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.with": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong50",
    // "string.min": "****",
    // "string.email": "invalidEmailFormat",
};

const AssembleYourTeam = ({ nextPhase }) => {
    const teamName = useSelector((state) => state.assembleYourTeam.teamName);
    const investmentExpectation = useSelector((state) => state.assembleYourTeam.investmentExpectation);
    const decisionFlow = useSelector((state) => state.assembleYourTeam.decisionFlow);
    const timezone = useSelector((state) => state.assembleYourTeam.timezone);
    const selectedCheckboxes = useSelector((state) => state.assembleYourTeam.rolesInteracting);
    const othersCheckboxInput = useSelector((state) => state.assembleYourTeam.roleInteractingCustom);
    const startDate = useSelector((state) => state.assembleYourTeam.startDate);
    const endDate = useSelector((state) => state.assembleYourTeam.endDate);
    const estimatedConfDate = useSelector((state) => state.assembleYourTeam.confirmationDate);
    const teamArea = useSelector((state) => state.assembleYourTeam.teamArea);
    const projectType = useSelector((state) => state.assembleYourTeam.projectType);
    const additionalComments = useSelector((state) => state.assembleYourTeam.additionalComments);
    const attachment = useSelector((state) => state.assembleYourTeam.attachment);
    const skills = useSelector((state) => state.assembleYourTeam.skills);
    const projectId = useSelector((state) => state.assembleYourTeam.id);

    const [creatingProject, setCreatingProject] = useState(false);
    const forceLogout = useForceLogout();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const comesFromBack = useRef(false);

    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const onChange = useFormOnChange(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, validateSubSchemaFromEvent);

    useEffect(() => {
        const back = searchParams.get("back");

        if (!back) {
            return;
        }

        comesFromBack.current = true;
        setSearchParams("");
    }, []);

    // Keep track if there is content in some field. If there is, we'll show a dialog in case
    // of the user leaving the page.
    const contentFound = useMemo(() => {
        return (
            !!teamName ||
            // !!investmentExpectation ||
            // !!decisionFlow ||
            !!timezone ||
            // !!startDate ||
            // !!endDate ||
            // !!estimatedConfDate ||
            // !!teamArea?.length > 0 ||
            // !!projectType ||
            !!additionalComments ||
            !!attachment.fileName ||
            skills?.filter(
                (skill) =>
                    skill.container === ContainerType.MANDATORY_SKILLS ||
                    skill.container === ContainerType.DESIRED_SKILLS
            ).length > 0
        );
    }, [
        teamName,
        // investmentExpectation,
        // decisionFlow,
        timezone,
        // startDate,
        // endDate,
        // estimatedConfDate,
        // teamArea,
        // projectType,
        additionalComments,
        attachment,
        skills,
    ]);

    const viewState = {
        teamName,
        // investmentExpectation,
        // yourDecisionFlow: decisionFlow,
        // rolesInYourCompany: {
        //     decisionMaker: selectedCheckboxes[FirstSectionRoleCheckboxNames.DECISION_MAKER],
        //     recommender: selectedCheckboxes[FirstSectionRoleCheckboxNames.RECOMMENDER],
        //     user: selectedCheckboxes[FirstSectionRoleCheckboxNames.USER],
        //     others: selectedCheckboxes[FirstSectionRoleCheckboxNames.OTHERS],
        //     otherRolesInput: othersCheckboxInput,
        // },
        // estimatedDateToStart: startDate,
        // estimatedDateToEnd: endDate,
        timeZone: timezone,
        // expectedDateToConfirm: estimatedConfDate,
        skills,
        // teamWorkArea: teamArea,
        // typeOfProject: projectType,
        additionalComments,
        // attachFileAboutProject: attachment,
    };

    // Show a message to the user if it wants to leave the page while some forms have content.
    useOnBeforeUnload((event) => {
        event = event || Window.event;

        // Compatibility with old browsers and Safari
        if (event) {
            event.returnValue = "Are you sure you want to leave the current page?";
        }

        return "Are you sure you want to leave the current page?";
    }, contentFound);

    const onSubmit = async (event) => {
        event.preventDefault();

        const numberOfErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, viewState);

        if (numberOfErrors > 0) {
            return;
        }

        // const teamAreaIds = teamArea.map((area) => {
        //     return area.value;
        // });

        let technologies = [];
        let customValues = [];

        skills.forEach((skill) => {
            if (skill.custom) {
                customValues.push({
                    type: "skill",
                    value: skill.name,
                    mandatory: skill.container === ContainerType.MANDATORY_SKILLS,
                });
            } else if (skill.container > 1) {
                technologies.push({
                    technologyId: skill.key,
                    mandatory: skill.container === ContainerType.MANDATORY_SKILLS,
                });
            }
        });

        // let roleInvolvedInHiringProcess = [];
        // Object.keys(selectedCheckboxes).forEach((element) => {
        //     if (selectedCheckboxes[element]) {
        //         if (element === "others") {
        //             roleInvolvedInHiringProcess.push(othersCheckboxInput);
        //         } else {
        //             roleInvolvedInHiringProcess.push(element);
        //         }
        //     }
        // });

        let data = {
            name: teamName,
            // budget: investmentExpectation,
            // description: decisionFlow,
            // roleInvolvedInHiringProcess: roleInvolvedInHiringProcess.join(","),
            // dateStart: startDate,
            // dateEnd: endDate,
            timeZone: timezone.value,
            // dateClientNeedsConfirmation: estimatedConfDate,
            projectTechnologies: technologies,
            customValues: customValues,
            // businessSectorsIds: teamAreaIds,
            // newProject: projectType?.value === 1,
            additionalInfo: additionalComments.trim(),
            projectDoc: attachment?.fileName,
            projectBlobDoc: attachment?.blobName,
        };

        setCreatingProject(true);

        const response = comesFromBack.current
            ? await updateProjectAsync(data, projectId)
            : await addProjectAsync(data);

        if (response.ok && !comesFromBack.current) {
            const projectId = await response.json();

            dispatch(setId(projectId));
        }

        setCreatingProject(false);

        if (response.ok) {
            nextPhase();
            toast.success(t(comesFromBack.current ? "projectUpdated" : "projectCreated"));
            return;
        }

        if (response.status === 401) {
            await forceLogout();
        } else {
            toast.error(t(comesFromBack.current ? "failedProjectUpdate" : "failedProjectCreation"));
            console.error("Error when trying to submit the project:", await response.text());
        }
    };

    return (
        <>
            {creatingProject && <Loading />}
            <form onSubmit={onSubmit}>
                {/*<FirstSection*/}
                {/*    teamNameState={teamName}*/}
                {/*    investmentExpectationState={investmentExpectation}*/}
                {/*    decisionFlowState={decisionFlow}*/}
                {/*    timezoneState={timezone}*/}
                {/*    selectedCheckboxesState={selectedCheckboxes}*/}
                {/*    othersCheckboxInputState={othersCheckboxInput}*/}
                {/*    startDateState={startDate}*/}
                {/*    endDateState={endDate}*/}
                {/*    estimatedConfDateState={estimatedConfDate}*/}
                {/*    onChange={onChange}*/}
                {/*    errors={errors}*/}
                {/*/>*/}
                <SecondSection
                    teamNameState={teamName}
                    teamAreasState={teamArea}
                    projectTypesState={projectType}
                    additionalCommentsState={additionalComments}
                    attachmentState={attachment}
                    timezoneState={timezone}
                    onChange={onChange}
                    errors={errors}
                    validateSubSchemaFromEvent={validateSubSchemaFromEvent}
                    validationSchema={BASE_SCHEMA}
                    schemaErrorMessages={SCHEMA_ERROR_MESSAGES}
                />
                <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button type="submit" cssClasses={[style.button]} disabled={attachment.isLoading}>
                        {t("continue")}
                    </Button>
                </div>
            </form>
        </>
    );
};

export default AssembleYourTeam;
