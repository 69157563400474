import Joi from "joi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { v4 as uuidV4 } from "uuid";
import colors from "../../../base/js/colors";
import { TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import { getBusinessSectorsAsync } from "../../../services/BusinessSectorService";
import { getCountriesAsync } from "../../../services/CountryService";
import { createLinkDownloadStatementFile, uploadFileAsync } from "../../../services/FileService";
import {
    addOrganizationAsync,
    editOrganizationAsync,
    getOrganizationAsync,
    getOrganizationListAsync,
} from "../../../services/OrganizationService";
import { UnitedStatesEINRegex, UnitedStatesTINRegex, UrlRegex } from "../../../utils/formValidation";
import { useForceLogout } from "../../../utils/useForceLogout";
import {
    ALLOWED_MIMETYPES,
    ALLOWED_TYPE_NAMES,
    MAX_ATTACHMENT_FILESIZE,
} from "../../build-your-team/assemble-your-team/constants";
import InputFile from "../../common/custom-input-file/CustomInputFile";
import Label from "../../common/custom-label/Label";
import Paginator from "../../common/paginator/Paginator";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import AdminTable from "../admin-tables/AdminTable";
import AddStatementModal from "./add-statement-modal/AddStatementModal";
import styles from "./AdminOrganization.module.scss";

// This is not moved to the file of constants because it's only relevant in this context.
const FormElementIds = {
    COMPANY_NAME: "input-company-name",
    NUMBER: "input-company-number",
    COMPANY_POSITION: "input-company-position",
    BUSINESS_NAME: "input-business-name",
    ADDRESS: "input-address",
    WEBSITE: "input-website",
    BUSINESS_SECTOR: "selector-business-sector",
    COUNTRY: "selector-country-or-jurisdiction",
    TAX_ID: "input-tax-id",
    FILE_ATTACH: "input-file-contract",
};

const validateWebSite = (value, helpers) => {
    if (value === "") {
        return value;
    }
    if (UrlRegex.test(value)) {
        return value;
    } else {
        return helpers.error("any.invalid");
    }
};

const validateTaxId = (value, helpers) => {
    //The country selected is United State of America and need to validate the taxtId format
    if (!value.value) return value;

    if (
        value.selectedCountry === 1 &&
        !(UnitedStatesTINRegex.test(value.value) || UnitedStatesEINRegex.test(value.value))
    ) {
        return helpers.error("string.invalid");
    }
    return value;
};

const BASE_SCHEMA = {
    name: Joi.string().max(50).empty("").required(),
    webSite: Joi.string().empty("").custom(validateWebSite),
    businessSector: Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
    }),
    country: Joi.object({
        value: Joi.number().empty("").required(),
        label: Joi.string().required(),
    }),
    taxId: Joi.object({
        value: Joi.string().empty(""),
        selectedCountry: Joi.any(),
    }).custom(validateTaxId),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalid": "invalidTaxIDFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const ORGANIZATION_ACTIONS = {
    ADD: "adding",
    EDIT: "editing",
};

const inputDefaultState = { value: "", label: "" };

const getSelectorStyle = (invalid = false) => ({
    control: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
        height: "40px",
        boxShadow: "none",
        border: `${invalid || state.isFocused ? 2 : 1}px solid ${
            invalid ? colors.ERROR : state.isFocused ? colors.GRAY550 : colors.GRAY350
        }`,
        ":hover": {
            borderColor: invalid ? colors.ERROR : colors.GRAY550,
        },
    }),
});

const AdminOrganization = () => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [addBtnLabel, setAddBtnLabel] = useState(ORGANIZATION_ACTIONS.ADD);
    const forceLogout = useForceLogout();
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [organizationData, setOrganizationData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [countriesOptions, setCountriesOptions] = useState({ data: [], loading: true });
    const [businessSectorsOptions, setBusinessSectorsOptions] = useState({ data: [], loading: true });
    const [organizationActive, setOrganizationActive] = useState(null);
    const [showAddStatementModal, setShowAddStatementModal] = useState(false);

    const [companyName, setCompanyName] = useState("");
    const [number, setNumber] = useState("");
    const [companyPosition, setCompanyPosition] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [address, setAddress] = useState("");
    const [website, setWebsite] = useState("");
    const [businessSector, setBusinessSector] = useState(inputDefaultState);
    const [country, setCountry] = useState(inputDefaultState);
    const [taxId, setTaxId] = useState({
        value: "",
        selectedCountry: "",
    });
    const [contractFile, setContractFile] = useState({
        fileName: "",
        blobName: "",
        isLoading: false,
        errors: [],
        isValid: true,
    });
    const [organizationDocumentList, setOrganizationDocumentList] = useState(null);
    const [newFileList, setNewFileList] = useState([]);

    const onInputChange = (event) => {
        switch (event.target.id) {
            case FormElementIds.COMPANY_NAME:
                setCompanyName(event.target.value);
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
                break;
            case FormElementIds.NUMBER:
                setNumber(event.target.value);
                break;
            case FormElementIds.COMPANY_POSITION:
                setCompanyPosition(event.target.value);
                break;
            case FormElementIds.BUSINESS_NAME:
                setBusinessName(event.target.value);
                break;
            case FormElementIds.ADDRESS:
                setAddress(event.target.value);
                break;
            case FormElementIds.WEBSITE:
                setWebsite(event.target.value);
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
                break;
            case FormElementIds.TAX_ID:
                setTaxId({ ...taxId, value: event.target.value });
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
                    target: {
                        id: FormElementIds.TAX_ID,
                        value: { ...taxId, value: event.target.value },
                        name: "taxId",
                    },
                });
                break;
            default:
                throw new Error("Unable to handle unexpected element", event.target);
        }
    };

    const onBusinessSelectorChange = (selection) => {
        setBusinessSector(selection);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: FormElementIds.BUSINESS_SECTOR, value: selection, name: "businessSector" },
        });
    };

    const onCountrySelectorChange = (selection) => {
        setCountry(selection);
        setTaxId((prevState) => {
            return { ...prevState, selectedCountry: selection.value };
        });

        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: FormElementIds.COUNTRY, value: selection, name: "country" },
        });

        // Each time that the selected country changes, we need to check the format
        // of the Tax ID again. This is to make sure that is the desired one for the selected
        // country.
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                id: FormElementIds.TAX_ID,
                value: { ...taxId, selectedCountry: selection.value },
                name: "taxId",
            },
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await getCountriesAsync();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setCountriesOptions({
                    data: responseData.map((country) => {
                        return {
                            value: country.id,
                            label: country.name,
                        };
                    }),
                    loading: false,
                });
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await getBusinessSectorsAsync();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setBusinessSectorsOptions({
                    data: responseData.map((businessSector) => {
                        return {
                            value: businessSector.id,
                            label: businessSector.sectorEn,
                        };
                    }),
                    loading: false,
                });
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await getOrganizationListAsync(activePage, totalPerPage, searchInput);
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                responseData.organizations = responseData.organizations.map((organization) => {
                    const handleOrganizationEdit = async (e) => {
                        e.preventDefault();
                        setOrganizationActive(organization.id);
                        const response = await getOrganizationAsync(organization.id);
                        if (!response.ok) {
                            if (response.status === 401) {
                                await forceLogout();
                                return;
                            }

                            toast.error(t("unexpectedError") + ": " + (await response.text()));
                            return;
                        }

                        let organizationData = await response.json();

                        setCompanyName(organizationData.name);
                        setNumber(organizationData.number);
                        setCompanyPosition(organizationData.companyPosition);
                        setBusinessName(organizationData.fantasyName);
                        setAddress(organizationData.address);
                        setWebsite(organizationData.webSite);
                        setBusinessSector({
                            value: organizationData.businessSector.id,
                            label: organizationData.businessSector.sectorEn,
                        });
                        setCountry({
                            value: organizationData.country.id,
                            label: organizationData.country.name,
                        });
                        setTaxId({ value: organizationData.taxId, selectedCountry: organizationData.country.id });
                        setOrganizationDocumentList(
                            organizationData.organizationDocuments.map((document) => {
                                return {
                                    id: document.id,
                                    projectId: document.projectId,
                                    projectName: document.project.name,
                                    organizationId: document.organizationId,
                                    fileName: document.statementOfWorkName,
                                    fileBlobName: document.statementOfWorkBlob,
                                };
                            })
                        );

                        setAddBtnLabel(ORGANIZATION_ACTIONS.EDIT);
                        setShowForm(true);
                    };

                    return {
                        ...organization,
                        actions: [
                            {
                                label: "edit",
                                action: handleOrganizationEdit,
                                color: "",
                            },
                        ],
                    };
                });
                setOrganizationData(responseData);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [activePage, totalPerPage, searchInput, reloadData]);

    const handleSearchInputChange = (event) => {
        event.preventDefault();
        setSearchInput(event.target.value);
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const handleClickAdd = () => {
        setAddBtnLabel(ORGANIZATION_ACTIONS.ADD);
        setShowForm(true);
    };

    const clearForm = () => {
        setCompanyName("");
        setNumber("");
        setCompanyPosition("");
        setBusinessName("");
        setAddress("");
        setWebsite("");
        setBusinessSector(inputDefaultState);
        setCountry(inputDefaultState);
        setTaxId({ value: "", selectedCountry: "" });
        setNewFileList([]);
    };

    const handleFormCancel = () => {
        setShowForm(false);
        clearForm();
    };

    const getOrganizationData = () => {
        if (organizationData.organizations) {
            return organizationData?.organizations.map((organization) => {
                return {
                    fantasyName: organization.fantasyName,
                    name: organization.name,
                    number: organization.number,
                    bussinessSector: organization.businessSector.sectorEn,
                    country: organization.country.name,
                    webSite: organization.webSite,
                    actions: organization.actions,
                };
            });
        } else {
            return [];
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: companyName,
            webSite: website,
            businessSector: businessSector,
            country: country,
            taxId: taxId,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!!cantErrors) {
            return;
        }

        switch (addBtnLabel) {
            case ORGANIZATION_ACTIONS.ADD:
                await handleFormSubmitAdd();
                break;
            case ORGANIZATION_ACTIONS.EDIT:
                await handleFormSubmitEdit(organizationActive);
                break;
        }
    };

    const handleFormSubmitEdit = async (organizationId) => {
        try {
            setLoading(true);

            //Upload the new files list
            let newOrganizationDocumentList = [];

            for (const fileData of newFileList) {
                const formData = new FormData();
                formData.append("file", fileData.file);
                const fileResponse = await uploadFileAsync(formData);
                if (!fileResponse.ok) {
                    toast.error("Unexpected error: " + (await fileResponse.text()));
                    continue;
                }

                const fileResponseData = await fileResponse.json();

                newOrganizationDocumentList.push({
                    id: uuidV4(),
                    projectId: fileData.project.value,
                    projectName: fileData.project.label,
                    organizationId: organizationId,
                    fileName: fileData.file.name,
                    fileBlobName: fileResponseData.name,
                });
            }

            const finalData = {
                name: companyName,
                number: number,
                address: address,
                fantasyName: businessName,
                webSite: website,
                businessSectorId: businessSector.value,
                countryId: country.value,
                taxId: taxId.value || "",
                companyPosition: companyPosition,
                contractName: contractFile.fileName,
                contractBlob: contractFile.blobName,
                organizationDocuments: organizationDocumentList.concat(newOrganizationDocumentList).map((document) => {
                    return {
                        id: document.id,
                        organizationId: document.organizationId,
                        projectId: document.projectId,
                        statementOfWorkName: document.fileName,
                        statementOfWorkBlob: document.fileBlobName,
                    };
                }),
            };

            const response = await editOrganizationAsync(organizationId, finalData);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success("Organization successfully updated");

            handleFormCancel();
            setNewFileList([]);
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmitAdd = async () => {
        try {
            const finalData = {
                name: companyName,
                number: number,
                address: address,
                fantasyName: businessName,
                webSite: website,
                businessSectorId: businessSector.value,
                countryId: country.value,
                taxId: taxId.value || "",
                companyPosition: companyPosition,
                contractName: contractFile.fileName,
                contractBlob: contractFile.blobName,
            };

            setLoading(true);

            const response = await addOrganizationAsync(finalData);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success("Organization successfully created");

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleContractChange = async (event) => {
        /*
         * Disclosure: this is the only handler that will keep handling errors
         * by itself. The reason is that is too complex to put that logic inside
         * the hook useJoiValidation (mainly because we need to do checks with
         * the file directly), at least at this moment.
         *
         * Recommendations are welcomed :)
         * */

        if (event.target.id !== FormElementIds.FILE_ATTACH) {
            throw new Error(`Unrecongized element (ID "${event.target.id}") to handle attachment`);
        }

        const fileSize = event.target.files[0].size;
        const fileType = event.target.files[0].type;
        let blobName = null;

        const errors = [];

        if (fileSize > MAX_ATTACHMENT_FILESIZE) {
            errors.push("fileSizeLimit");
            return;
        } else if (!ALLOWED_MIMETYPES.includes(fileType)) {
            errors.push("allowedFileTypes");
            return;
        }

        if (event.target.files.length > 0) {
            const formData = new FormData();
            formData.append("file", event.target.files[0]);

            setContractFile({
                ...contractFile,
                isLoading: true,
            });
            const response = await uploadFileAsync(formData);

            if (response.ok) {
                blobName = await response.json();
            }
        }

        let finalObj;

        if (errors.length) {
            finalObj = {
                fileName: "",
                blobName: "",
                isValid: false,
                isLoading: false,
                errors,
            };
        } else {
            finalObj = {
                fileName: event.target.files[0]?.name,
                blobName: blobName?.name,
                isValid: true,
                isLoading: false,
            };
        }

        setContractFile(finalObj);
    };

    const handleAddStatementClick = (e) => {
        e.preventDefault();
        setShowAddStatementModal(true);
    };

    const handleAddStatementCancel = (e) => {
        e.preventDefault();
        setShowAddStatementModal(false);
    };

    const handleAddStatementAccept = (data) => {
        setNewFileList([...newFileList, { project: data.project, file: data.file }]);
        setShowAddStatementModal(false);
    };

    return (
        <>
            {loading && <Loading />}
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        <Label htmlFor="search">{t("search")}</Label>
                        <input
                            id="search"
                            className={`${sharedStyle.inputText} px-2`}
                            value={searchInput}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    <button
                        className={`${styles.btnAdd} btn btn-success text-uppercase`}
                        disabled={showForm}
                        onClick={handleClickAdd}
                    >
                        {showForm ? t(addBtnLabel) : t("add")}
                    </button>
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={styles.formContainer}>
                        <form onSubmit={handleFormSubmit} className="py-3">
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.COMPANY_NAME} requiredIndicator>
                                        {t("companyName")}:
                                    </Label>
                                    <input
                                        id={FormElementIds.COMPANY_NAME}
                                        name="name"
                                        type="text"
                                        className={`${sharedStyle.inputText} ${
                                            !errors.name ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        value={companyName}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                    {errors.name && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.name.message}</p>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.NUMBER}>{t("number")}:</Label>
                                    <input
                                        id={FormElementIds.NUMBER}
                                        type="text"
                                        className={`${sharedStyle.inputText} px-3 py-2`}
                                        value={number}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.COMPANY_POSITION}>{t("companyPosition")}:</Label>
                                    <input
                                        id={FormElementIds.COMPANY_POSITION}
                                        type="text"
                                        className={`${sharedStyle.inputText} px-3 py-2`}
                                        value={companyPosition}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.BUSINESS_NAME}>{t("businessName")}:</Label>
                                    <input
                                        id={FormElementIds.BUSINESS_NAME}
                                        type="text"
                                        className={`${sharedStyle.inputText} px-3 py-2`}
                                        value={businessName}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.ADDRESS}>{t("address")}:</Label>
                                    <input
                                        id={FormElementIds.ADDRESS}
                                        type="text"
                                        className={`${sharedStyle.inputText} px-3 py-2`}
                                        value={address}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.WEBSITE}>{t("website")}:</Label>
                                    <input
                                        id={FormElementIds.WEBSITE}
                                        name="webSite"
                                        type="text"
                                        className={`${sharedStyle.inputText} ${
                                            !errors.webSite ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        value={website}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                    {errors.webSite && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.webSite.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.BUSINESS_SECTOR} requiredIndicator>
                                        {t("businessSector")}:
                                    </Label>
                                    <Selector
                                        id={FormElementIds.BUSINESS_SECTOR}
                                        name="businessSector"
                                        styles={getSelectorStyle(!!errors.businessSector)}
                                        options={businessSectorsOptions.data}
                                        isLoading={businessSectorsOptions.loading}
                                        onChange={onBusinessSelectorChange}
                                        value={businessSector}
                                    />
                                    {errors.businessSector && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.businessSector.message}</p>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.COUNTRY} requiredIndicator>
                                        {t("countryJurisdiction")}:
                                    </Label>
                                    <Selector
                                        id={FormElementIds.COUNTRY}
                                        name="country"
                                        isLoading={countriesOptions.loading}
                                        options={countriesOptions.data}
                                        styles={getSelectorStyle(!!errors.country)}
                                        onChange={onCountrySelectorChange}
                                        value={country}
                                    />
                                    {errors.country && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.country.message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-between gap-5">
                                <div className={styles.fieldContainer}>
                                    <Label htmlFor={FormElementIds.TAX_ID}>{t("taxID")}:</Label>
                                    <input
                                        id={FormElementIds.TAX_ID}
                                        name="taxId"
                                        type="text"
                                        className={`${sharedStyle.inputText} ${
                                            !errors.taxId ? "" : sharedStyle.invalidField
                                        } px-3 py-2`}
                                        value={taxId.value}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                        disabled={!country}
                                    />
                                    {errors.taxId && (
                                        <div className="mt-1">
                                            <p className={sharedStyle.errorMsg}>{errors.taxId.message}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4 align-items-start w-100">
                                    <Label htmlFor={FormElementIds.FILE_ATTACH}>{t("contractFile")}</Label>
                                    <InputFile
                                        id={FormElementIds.FILE_ATTACH}
                                        inputFileChange={handleContractChange}
                                        invalid={!contractFile.isValid}
                                        isLoading={contractFile.isLoading}
                                        disabled={contractFile.isLoading}
                                    />
                                    <div className={!contractFile.isValid ? "mt-1" : ""}>
                                        {!contractFile.isValid &&
                                            contractFile.errors.map((error, i) => (
                                                <p key={i} className={sharedStyle.errorMsg}>
                                                    {error === "allowedFileTypes"
                                                        ? t(error) + " " + ALLOWED_TYPE_NAMES.join(", ")
                                                        : t(error)}
                                                </p>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            {addBtnLabel === ORGANIZATION_ACTIONS.EDIT && (
                                <div className="d-flex m-3 justify-content-between gap-5">
                                    <div className={styles.fieldContainer}>
                                        <div className="d-flex justify-content-between">
                                            <Label>{t("statementOfWork")}</Label>
                                            <button
                                                className={styles.btnAddStatement}
                                                onClick={handleAddStatementClick}
                                            >
                                                {t("addStatement")}
                                            </button>
                                        </div>
                                        <table className={styles.statementTable}>
                                            <thead>
                                                <tr>
                                                    <th>{t("projectName")}</th>
                                                    <th>{t("files")}</th>
                                                    <th>{t("actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {organizationDocumentList.length > 0 || newFileList.length > 0 ? (
                                                    organizationDocumentList
                                                        .concat(
                                                            newFileList.map((fileData) => {
                                                                return {
                                                                    id: uuidV4,
                                                                    projectId: fileData.project.value,
                                                                    projectName: fileData.project.label,
                                                                    organizationId: organizationActive,
                                                                    fileName: fileData.file.name,
                                                                };
                                                            })
                                                        )
                                                        .map((document) => {
                                                            const handleDeleteStatement = (e) => {
                                                                e.preventDefault();
                                                                setOrganizationDocumentList(
                                                                    organizationDocumentList.filter((doc) => {
                                                                        return doc.id !== document.id;
                                                                    })
                                                                );
                                                            };

                                                            return (
                                                                <tr key={document.id}>
                                                                    <td>{document.projectName}</td>
                                                                    <td>
                                                                        <a
                                                                            className={styles.downloadCv}
                                                                            href={createLinkDownloadStatementFile(
                                                                                document.id
                                                                            )}
                                                                        >
                                                                            {document.fileName}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className={styles.btnDeleteStatement}
                                                                            onClick={handleDeleteStatement}
                                                                        >
                                                                            {t("delete")}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                ) : (
                                                    <tr className={styles.noData}>
                                                        <td colSpan="3">{t("noDataToShow")}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot className={styles.statementFooter}>
                                                <tr>
                                                    <td colSpan="3"></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex m-3 justify-content-end gap-3">
                                <button
                                    type="reset"
                                    className={`btn btn-danger text-uppercase`}
                                    onClick={handleFormCancel}
                                >
                                    {t("cancel")}
                                </button>
                                <button type="submit" className={`btn btn-success text-uppercase`}>
                                    {t("save")}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <AdminTable
                    headers={["fantasyName", "name", "number", "businessSector", "country", "webSite", ""]}
                    data={getOrganizationData()}
                />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(organizationData.total ? organizationData.total / totalPerPage : 1)}
                    />
                </div>
            </div>
            <AddStatementModal
                onCancel={handleAddStatementCancel}
                show={showAddStatementModal}
                onAccept={handleAddStatementAccept}
                organizationId={organizationActive}
            />
        </>
    );
};

export default AdminOrganization;
