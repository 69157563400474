import { format, parseISO } from "date-fns";
import { enUS, es } from "date-fns/locale";
import i18n from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    LANGUAGES,
    NotificationPriority,
    NOTIFICATION_AREAS,
    NOTIFICATION_DATE_FORMAT,
    NOTIFICATION_TYPES,
} from "../../../../base/js/constants";
import { replaceMark } from "../../../../utils/strings";
import style from "./NotificationItem.module.scss";

const getPriorityColor = (priority) => {
    switch (priority) {
        case NotificationPriority.HIGH:
            return style.highPriority;
        case NotificationPriority.MEDIUM:
            return style.mediumPriority;
        case NotificationPriority.LOW:
            return style.lowPriority;
        default:
            throw new Error(`Unrecognized notification priority "${priority}"`);
    }
};

const NotificationItem = ({
    id,
    title,
    content,
    priority,
    timestamp,
    projectName,
    link = null,
    onRemove,
    area,
    type,
}) => {
    const [lang] = useState(i18n.resolvedLanguage);
    const onRemoveClick = () => {
        onRemove(id);
    };

    const { t } = useTranslation();

    const generateNotificationContent = () => {
        switch (area) {
            case NOTIFICATION_AREAS.PAYMENT:
                switch (type) {
                    case NOTIFICATION_TYPES.PAYMENT_REJECTED:
                        return (
                            <>
                                {replaceMark(
                                    t(content),
                                    "{-}",
                                    <a key={id} className={style.link} href={link}>
                                        {projectName}
                                    </a>
                                )}
                            </>
                        );
                }
                break;
            case NOTIFICATION_AREAS.PROJECT:
                switch (type) {
                    case NOTIFICATION_TYPES.PROJECT_CREATION:
                        return (
                            <>
                                {replaceMark(
                                    t(content),
                                    "{-}",
                                    <a key={id} className={style.link} href={link}>
                                        {projectName}
                                    </a>
                                )}
                            </>
                        );
                    case NOTIFICATION_TYPES.PROJECT_VALIDATION_HOURS_PENDING:
                        return (
                            <>
                                {replaceMark(
                                    t(content),
                                    "{-}",
                                    <a key={id} className={style.link} href={link}>
                                        {projectName}
                                    </a>
                                )}
                            </>
                        );
                    case NOTIFICATION_TYPES.PROJECT_SOW_SIGNED:
                        return (
                            <>
                                {replaceMark(
                                    t(content),
                                    "{-}",
                                    <span key={id} className={style.spanImportant}>
                                        {projectName}
                                    </span>
                                )}
                            </>
                        );
                    case NOTIFICATION_TYPES.PROJECT_ALL_CANDIDATES_ALLOCATED:
                        return (
                            <>
                                {replaceMark(
                                    t(content),
                                    "{-}",
                                    <a key={id} className={style.link} href={link}>
                                        {projectName}
                                    </a>
                                )}
                            </>
                        );
                    case NOTIFICATION_TYPES.PROJECT_REJECTED:
                        return <>{replaceMark(t(content), "{-}", <strong key={id}>{projectName}</strong>)}</>;
                }
        }
    };

    return (
        <div className="d-flex flex-column">
            {/* Timestamp */}
            <span className={`${style.timestamp} me-2`}>
                {format(parseISO(timestamp), NOTIFICATION_DATE_FORMAT, { locale: lang === LANGUAGES.EN ? enUS : es })}
            </span>

            {/* Main container */}
            <div className={`${style.container} d-flex flex-column px-3 py-2 gap-1`}>
                <span className={`${style.title} text-uppercase d-block`}>{t(title)}</span>
                <span className={style.content}>{generateNotificationContent()}</span>

                <button type="button" className={`${style.remove} ms-auto mt-2`} onClick={onRemoveClick}>
                    {t("remove")}
                </button>
            </div>
        </div>
    );
};

export default NotificationItem;
