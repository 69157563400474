import Joi from "joi";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import Routes from "../../../base/js/routes";
import {
    createLinkDownloadPositionDescriptionFile,
    createLinkDownloadProjectDescriptionFile,
} from "../../../services/FileService";
import { getUsersByHiringManagerRole } from "../../../services/HiringManagerService";
import { addPositionStaffAsync } from "../../../services/PositionService";
import {
    getProjectAsync,
    setAllResourcesAllocated,
    setHiringManagerAsync,
    setTechnicalAccountManagerAsync,
} from "../../../services/ProjectService";
import { getUsersByTechnicalAccountManagerRole } from "../../../services/TechnicalAccountManagerService";
import { responseErrorFormat } from "../../../utils/responseFormat";
import { useForceLogout } from "../../../utils/useForceLogout";
import Button, { variants } from "../../common/Button";
import Label from "../../common/custom-label/Label";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import styles from "./AllocationResource.module.scss";
import PositionCard from "./position-card/PositionCard";
import ResourceCardList from "./resource-card-list/ResourceCardList";

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
};

const AllocationResource = () => {
    const [loading, setLoading] = useState(false);
    const forceLogout = useForceLogout();
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [refreshPositions, setRefreshPositions] = useState(false);
    const [project, setProject] = useState(null);
    const [positionActive, setPositionActive] = useState(null);
    const [hiringManagerList, setHiringManagerList] = useState([]);
    const [hiringManager, setHiringManager] = useState(null);
    const [technicalAccountManagerList, setTechnicalAccountManagerList] = useState([]);
    const [technicalAccountManager, setTechnicalAccountManager] = useState(null);
    const [positionActiveInfo, setPositionActiveInfo] = useState(null);

    const projectTechnologies = useMemo(() => {
        if (!project) {
            return "";
        }

        const techs = project.projectTechnologies.map((t) => t.technology.name);

        return techs.join(", ");
    }, [project]);

    // Fetch project information
    useEffect(() => {
        (async () => {
            try {
                const response = await getProjectAsync(params.id);
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();

                // Check if there is a description file for the project. If it is, generate the URL of download
                // for it.
                if (responseData.projectBlobDoc) {
                    responseData.descriptionFile = createLinkDownloadProjectDescriptionFile(responseData.id);
                }

                // Check if there is a description file for each position. If there is one, generate the URL of
                // download for it.
                responseData.positions.forEach((p) => {
                    if (p.jobDescriptionBlob) {
                        p.descriptionFile = createLinkDownloadPositionDescriptionFile(p.id);
                    }
                });

                setProject(responseData);

                if (responseData && responseData.hiringManager) {
                    setHiringManager({ label: responseData.hiringManager.name, value: responseData.hiringManager.id });
                }

                if (responseData && responseData.tam) {
                    setTechnicalAccountManager({ label: responseData.tam.name, value: responseData.tam.id });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [refreshPositions]);

    // Fetch users with "Hiring Manager" role
    useEffect(() => {
        (async () => {
            try {
                const response = await getUsersByHiringManagerRole();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setHiringManagerList(
                    responseData.map((hiringManager) => {
                        return {
                            label: hiringManager.name,
                            value: hiringManager.id,
                        };
                    })
                );
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await getUsersByTechnicalAccountManagerRole();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setTechnicalAccountManagerList(
                    responseData.map((technical) => {
                        return {
                            label: technical.name,
                            value: technical.id,
                        };
                    })
                );
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    const getTotalResourceToAllocate = () => {
        let total = 0;
        project?.positions?.forEach((position) => {
            total += position.total;
        });
        return total;
    };

    const validatePositionResource = (value, helpers) => {
        return value === getTotalResourceToAllocate() ? value : helpers.error("any.required");
    };

    const BASE_SCHEMA = {
        hiringManager: Joi.object({
            label: Joi.string(),
            value: Joi.string(),
        })
            .empty(null)
            .required(),
        technicalAccountManager: Joi.object({
            label: Joi.string(),
            value: Joi.string(),
        })
            .empty(null)
            .required(),
        positionResource: Joi.number().custom(validatePositionResource),
    };

    const handleGoBackClick = (e) => {
        e.preventDefault();
        navigate(`${Routes.ADMIN}/${Routes.ADMIN_PROJECTS}`);
    };

    const handleFinishAllocationClick = async (e) => {
        e.preventDefault();

        let totalResourceAssigned = 0;
        project?.positions?.forEach((position) => {
            totalResourceAssigned += position?.positionStaffs?.length;
        });

        const formData = {
            hiringManager: hiringManager,
            technicalAccountManager: technicalAccountManager,
            positionResource: totalResourceAssigned,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);
        if (!!cantErrors) {
            return;
        }

        try {
            setLoading(true);

            const response = await setAllResourcesAllocated(project.id);
            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            toast.success("All resources allocated");
            navigate(`${Routes.ADMIN}/${Routes.ADMIN_PROJECTS}`);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleHiringManagerChange = async (hiringManagerSelected) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "hiringManager", value: hiringManagerSelected, name: "hiringManager" },
        });

        try {
            setLoading(true);

            const response = await setHiringManagerAsync(project.id, hiringManagerSelected.value);
            //Checks if the response status is different of 200
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                    return;
                }

                toast.error(t("hiringManagerChangeFailed") + ": " + (await response.text()));
                return;
            }

            setHiringManager(hiringManagerSelected);
            toast.success(t("hiringManagerChangeSuccess"));
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTechnicalAccountManagerChange = async (technicalAccountManagerSelected) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                id: "technicalAccountManager",
                value: technicalAccountManagerSelected,
                name: "technicalAccountManager",
            },
        });

        try {
            setLoading(true);

            const response = await setTechnicalAccountManagerAsync(project.id, technicalAccountManagerSelected.value);
            //Checks if the response status is different of 200
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                    return;
                }

                toast.error(t("technicalAccountManagerChangeFailed") + ": " + (await response.text()));
                return;
            }

            setTechnicalAccountManager(technicalAccountManagerSelected);
            toast.success(t("technicalAccountManagerChangeSuccess"));
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleResourceClick = async (resourceId) => {
        if (positionActive) {
            try {
                setLoading(true);

                //Retrieve the id from the structure: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxx_index_#
                const positionId = positionActive.slice(0, 36);
                const response = await addPositionStaffAsync(positionId, resourceId);

                //Checks if the response status is different of 200
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(
                        <div>
                            {t("resourceAddFailed") + ": "}
                            {responseErrorFormat(await response.json())}
                        </div>
                    );
                    return;
                }

                setRefreshPositions(!refreshPositions);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setLoading(false);
            }

            toast.success(t("resourceAddSuccess"));
            setPositionActive(null);
            return;
        }
        toast.error(t("positionMustBeSelected"));
    };

    const handlePositionActiveChange = async (position) => {
        setPositionActive(position);

        if (!position) {
            setPositionActiveInfo(null);
            return;
        }

        const positionId = position.split("_")[0];
        const selectedPosition = project.positions?.find((p) => p.id === positionId);

        setPositionActiveInfo(selectedPosition);
    };

    const preselectedFilters = useMemo(() => {
        return {
            role: positionActiveInfo?.developmentRol,
            seniority: positionActiveInfo?.seniority,
            technologies: positionActiveInfo?.positionCustomValues?.map((cv) => cv.customValue.value),
        };
    }, [positionActiveInfo]);

    return (
        <>
            {loading && <Loading />}

            <div className={styles.mainContainer}>
                <div className={styles.contentContainer}>
                    <div className={`${styles.leftContent} ${styles.content}`}>
                        <div className={styles.info}>
                            <div className={`${styles.userInfo} col-5`}>
                                <span>
                                    {t("userHeader")}:{" "}
                                    {
                                        project?.userProjectCreators[project.userProjectCreators?.length - 1]?.creator
                                            .name
                                    }
                                </span>
                                <span>
                                    {t("organization")}:{" "}
                                    {
                                        project?.userProjectCreators[project.userProjectCreators?.length - 1]?.creator
                                            .organization?.name
                                    }
                                </span>
                                <span>
                                    {t("email")}:{" "}
                                    {
                                        project?.userProjectCreators[project.userProjectCreators?.length - 1]?.creator
                                            .email
                                    }
                                </span>
                                <span>
                                    {t("phone")}:{" "}
                                    {
                                        project?.userProjectCreators[project.userProjectCreators?.length - 1]?.creator
                                            .phoneNumber
                                    }
                                </span>
                            </div>
                            <div className={`${styles.projectInfo} col-5`}>
                                <span>
                                    {t("projectName")}: {project?.name}
                                </span>
                                <span>
                                    {t("technologies")}: {projectTechnologies}
                                </span>
                                {project?.descriptionFile && (
                                    <span className="d-inline-block text-truncate">
                                        {t("descriptionFile")}:{" "}
                                        <a href={project.descriptionFile} className={sharedStyle.link} download>
                                            {project?.projectDoc}
                                        </a>
                                    </span>
                                )}
                            </div>
                        </div>
                        {project?.positions?.map((position) => {
                            let positionList = [];

                            for (let i = 0; i < position.total; i++) {
                                positionList.push(
                                    <div key={`${position.id}-${i}`}>
                                        <div className={styles.horizontalDivider}></div>
                                        <PositionCard
                                            positionIndex={i}
                                            position={position}
                                            setActive={handlePositionActiveChange}
                                            active={positionActive === `${position.id}_index_${i}`}
                                            refreshPositions={refreshPositions}
                                            setRefreshPositions={setRefreshPositions}
                                            isValid={!!errors.positionResource}
                                        />
                                    </div>
                                );
                            }

                            return positionList;
                        })}
                        <div className={styles.horizontalDivider}></div>
                        <Label htmlFor="hiringManager" requiredIndicator>
                            {t("hiringManager")}
                        </Label>
                        <Select
                            id="hiringManager"
                            name="hiringManager"
                            options={hiringManagerList}
                            styles={getSelectorStyle(!!errors.hiringManager)}
                            value={hiringManager}
                            onChange={handleHiringManagerChange}
                            placeholder={`${t("select")}...`}
                        />
                        {errors.hiringManager && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.hiringManager.message}</p>
                            </div>
                        )}

                        <Label htmlFor="technicalAccountManager" requiredIndicator>
                            {t("technicalAccountManager")}
                        </Label>
                        <Select
                            id="technicalAccountManager"
                            name="technicalAccountManager"
                            options={technicalAccountManagerList}
                            styles={getSelectorStyle(!!errors.technicalAccountManager)}
                            value={technicalAccountManager}
                            onChange={handleTechnicalAccountManagerChange}
                            placeholder={`${t("select")}...`}
                        />
                        {errors.technicalAccountManager && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.technicalAccountManager.message}</p>
                            </div>
                        )}
                        <div className={styles.buttonContainer}>
                            <Button
                                variant={variants.SECONDARY}
                                cssClasses={[styles.backBtn]}
                                onClick={handleGoBackClick}
                            >
                                {t("goBack")}
                            </Button>
                            <Button cssClasses={[styles.backBtn]} onClick={handleFinishAllocationClick}>
                                {t("finishAllocation")}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.verticalDivider}></div>

                    <div className={`${styles.rightContent} ${styles.content}`}>
                        <ResourceCardList
                            onResourceClick={handleResourceClick}
                            preselectedFilters={preselectedFilters}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllocationResource;
