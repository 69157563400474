import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { removePositionStaffAsync } from "../../../../services/PositionService";
import { useForceLogout } from "../../../../utils/useForceLogout";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import ResourceCard from "../resource-card-list/resource-card/ResourceCard";
import styles from "./PositionCard.module.scss";

const PositionCard = ({
    positionIndex = 0,
    setActive,
    position,
    active = false,
    refreshPositions,
    setRefreshPositions,
    isValid = true,
}) => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();

    const handleToggleActive = () => {
        if (!position.positionStaffs[positionIndex]) {
            active ? setActive(null) : setActive(`${position.id}_index_${positionIndex}`);
        }
    };

    const handleDeleteClick = async (resourceId) => {
        if (resourceId && position.id) {
            try {
                const response = await removePositionStaffAsync(position.id, resourceId);
                //Checks if the response status is different of 200
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("positionResourceRemoveFailed") + ": " + (await response.text()));
                    return;
                }

                setRefreshPositions(!refreshPositions);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
            toast.success(t("positionResourceRemoveSuccess"));
            setActive(null);
        }
    };

    return (
        <div className={`${styles.mainContainer} d-flex justify-content-between`}>
            <div className="d-flex flex-column col-6">
                <span>
                    {position.developmentRol.name} ({position.seniority.name})
                </span>
                <span>
                    {t("technologies")}:{" "}
                    {position.positionCustomValues?.map((cv) => cv.customValue.value)?.join(", ") || "-"}
                </span>
                {(position.jobDescriptionLink || position.descriptionFile) && (
                    <span className="d-inline-block w-100 text-truncate">
                        {t("descriptionFile")}:{" "}
                        <a
                            href={position.jobDescriptionLink || position.descriptionFile}
                            className={sharedStyle.link}
                            target={position.jobDescriptionLink ? "_blank" : undefined}
                            download={!!position.descriptionFile}
                        >
                            {position.jobDescriptionLink || position.jobDescriptionFile}
                        </a>
                    </span>
                )}
                <span className={`${styles.timePill} ${position.partTime ? styles.partTimePill : styles.fullTimePill}`}>
                    {position.partTime ? t("partTime") : t("fullTime")}
                </span>
            </div>
            {position.positionStaffs[positionIndex] ? (
                <ResourceCard
                    resource={position.positionStaffs[positionIndex]?.staff}
                    deleteOption={true}
                    onDeleteClick={handleDeleteClick}
                    cursorPointer={false}
                />
            ) : (
                <div
                    className={`${styles.rightContent} ${active ? styles.active : ""} ${
                        isValid && !active ? styles.invalid : ""
                    } d-flex flex-column align-items-center justify-content-center col-5 flex-shrink-0`}
                    onClick={handleToggleActive}
                >
                    <span className={styles.selectText}>{active ? t("pending") : t("clickToSelect")}</span>
                    <span className={styles.selectText}>{isValid && !active ? t("requiredFieldIsEmpty") : ""}</span>
                </div>
            )}
        </div>
    );
};

export default PositionCard;
