import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Joi from "joi";
import style from "./CompanyInfo.module.scss";
import Label from "../../common/custom-label/Label";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import colors from "../../../base/js/colors";
import useProcessInputs from "../hooks/UseProcessInputs";
import { UnitedStatesEINRegex, UnitedStatesTINRegex, UrlRegex } from "../../../utils/formValidation";
import { getCountries } from "../../../utils/countries";
import {
    addOrganizationAsync,
    getUserOrganization,
    updateUserOrganization,
} from "../../../services/OrganizationService";
import { useForceLogout } from "../../../utils/useForceLogout";
import { getBusinessSectorsAsync } from "../../../services/BusinessSectorService";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Routes from "../../../base/js/routes";
import { addPaymentAsync } from "../../../services/PaymentService";
import Loading from "../../loading/Loading";
import { JoiWebsiteValidator, JoiTaxIdValidator } from "../../../base/js/constants";
import { createCustomer } from "../../../services/StripeService";

// This is not moved to the file of constants because it's only relevant in this context.
const FormNames = {
    COMPANY_NAME: "input-company-name",
    COMPANY_POSITION: "input-company-position",
    BUSINESS_NAME: "input-business-name",
    ADDRESS: "input-address",
    WEBSITE: "input-website",
    BUSINESS_SECTOR: "selector-business-sector",
    COUNTRY: "selector-country-or-jurisdiction",
    TAX_ID: "input-tax-id",
};

const BASE_SCHEMA = {
    id: Joi.string().empty(""),
    [FormNames.COMPANY_NAME]: Joi.string().max(50).empty("").required(),
    [FormNames.COMPANY_POSITION]: Joi.string().max(50).empty(""),
    [FormNames.BUSINESS_NAME]: Joi.string().max(50).empty(""),
    [FormNames.ADDRESS]: Joi.string().max(50).empty(""),
    [FormNames.WEBSITE]: Joi.string().empty("").custom(JoiWebsiteValidator),
    [FormNames.BUSINESS_SECTOR]: Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
    }),
    [FormNames.COUNTRY]: Joi.object({
        value: Joi.number().empty("").required(),
        label: Joi.string().required(),
    }),
    [FormNames.TAX_ID]: Joi.object({
        value: Joi.string().required(),
        selectedCountry: Joi.any(),
    })
        .custom(JoiTaxIdValidator)
        .required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalid": "invalidTaxIDFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const getSelectorStyle = (invalid = false) => ({
    control: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
        height: "40px",
        boxShadow: "none",
        border: `${invalid || state.isFocused ? 2 : 1}px solid ${
            invalid ? colors.ERROR : state.isFocused ? colors.GRAY550 : colors.GRAY350
        }`,
        ":hover": {
            borderColor: invalid ? colors.ERROR : colors.GRAY550,
        },
    }),
});

const INITIAL_STATE = {
    id: "",
    [FormNames.COMPANY_NAME]: "",
    [FormNames.COMPANY_POSITION]: "",
    [FormNames.BUSINESS_NAME]: "",
    [FormNames.ADDRESS]: "",
    [FormNames.WEBSITE]: "",
    [FormNames.BUSINESS_SECTOR]: "",
    [FormNames.COUNTRY]: "",
    [FormNames.TAX_ID]: {
        value: "",
        selectedCountry: "",
    },
};

const CompanyInfo = () => {
    const { t, i18n } = useTranslation();
    const forceLogout = useForceLogout();
    const { validateSubSchemaFromEvent, validateSchema, errors } = useJoiValidation();
    const navigate = useNavigate();
    const params = useParams();

    const [countriesOptions, setCountriesOptions] = useState({ data: [], loading: true });
    const [businessSectorsOptions, setBusinessSectorsOptions] = useState({ data: [], loading: true });
    const [loading, setLoading] = useState(false);

    const projectId = useRef(null);
    const [data, setData] = useState(INITIAL_STATE);

    const { processLettersAndNumbersInput, processLettersInput, processNoSpacesInput, processAlphanumeric } =
        useProcessInputs();

    useEffect(() => {
        const projectIdParam = params.projectId;

        if (!projectIdParam) {
            toast.error("Project ID not found");
            navigate(Routes.LETS_START);

            return;
        }

        projectId.current = projectIdParam;

        // Try to create a customer on Stripe
        (async () => {
            try {
                const response = await createCustomer();

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        const error = await response.json();
                        toast.error(`${t("errorCreatingStripeCustomer")}: ${error.title}`);
                    }

                    return;
                }
            } catch (error) {
                toast.error(t("unexpectedError") + ": " + error.message);
            }
        })();
    }, []);

    useEffect(() => {
        if (countriesOptions.loading || businessSectorsOptions.loading) return;

        // Try to get information about the organization of the user (if they have one)
        (async () => {
            try {
                const response = await getUserOrganization();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        const errorTitle = await response.json()?.title;
                        toast.error(`${t("unexpectedError")}: ${errorTitle}`);
                    }

                    return;
                }

                // If the http status code is 204, the user has no organization assigned
                if (response.status === 204) {
                    return;
                }

                const responseBody = await response.json();

                const businessSector =
                    businessSectorsOptions.data.find((bs) => bs.value === responseBody.businessSectorId) || "";

                const country = countriesOptions.data.find((c) => c.value === responseBody.countryId) || "";

                setData({
                    id: responseBody.id,
                    [FormNames.COMPANY_NAME]: responseBody.fantasyName,
                    [FormNames.COMPANY_POSITION]: responseBody.companyPosition,
                    [FormNames.BUSINESS_NAME]: responseBody.name,
                    [FormNames.ADDRESS]: responseBody.address,
                    [FormNames.WEBSITE]: responseBody.webSite,
                    [FormNames.BUSINESS_SECTOR]: businessSector,
                    [FormNames.COUNTRY]: country,
                    [FormNames.TAX_ID]: { value: responseBody.taxId, selectedCountry: country?.value },
                });
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            }
        })();
    }, [countriesOptions, businessSectorsOptions]);

    const userHasOrganization = useMemo(() => !!data.id, [data.id]);

    const processInput = (elementName, prevState, event) => {
        if (event.target.value === "") {
            return event.target.value;
        }

        switch (elementName) {
            case FormNames.COMPANY_NAME:
                return processLettersAndNumbersInput(prevState, event);
            case FormNames.COMPANY_POSITION:
                return processLettersInput(prevState, event);
            case FormNames.BUSINESS_NAME:
                return processLettersAndNumbersInput(prevState, event);
            case FormNames.ADDRESS:
                return processAlphanumeric(prevState, event);
            case FormNames.WEBSITE:
                return processNoSpacesInput(prevState, event);
            case FormNames.TAX_ID:
                return processNoSpacesInput(prevState, event);
            default:
                throw new Error(`Unexpected element. Name: ${elementName}`);
        }
    };

    const onInputChange = (event) => {
        setData((prevState) => {
            let newValue = processInput(event.target.name, prevState[event.target.name], event);

            if (event.target.name !== FormNames.WEBSITE && event.target.name !== FormNames.TAX_ID) {
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
            }

            if (event.target.name === FormNames.TAX_ID) {
                newValue = { ...prevState[event.target.name], value: newValue };
            }

            return {
                ...prevState,
                [event.target.name]: newValue,
            };
        });
    };

    const onBusinessSelectorChange = (selection) => {
        setData((prevState) => {
            return {
                ...prevState,
                [FormNames.BUSINESS_SECTOR]: selection,
            };
        });
    };

    const onCountrySelectorChange = (selection) => {
        setData((prevState) => {
            return {
                ...prevState,
                [FormNames.COUNTRY]: selection,
                [FormNames.TAX_ID]: { ...prevState[FormNames.TAX_ID], selectedCountry: selection.value },
            };
        });

        // Trigger the validation of Tax ID due to the change of the country
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: FormNames.TAX_ID, value: { ...data[FormNames.TAX_ID], selectedCountry: selection.value } },
        });
    };

    const validateUrl = (event) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const checkTaxId = (event) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: event.target.name, value: { ...data[FormNames.TAX_ID], value: event.target.value } },
        });
    };

    useEffect(() => {
        const fetchCountriesData = async () => {
            const response = await getCountries();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(`${t("unexpectedError")}: ${errorTitle}`);
                }

                return;
            }

            const data = await response.json();

            setCountriesOptions({ data: data.map((c) => ({ label: c.name, value: c.id })), loading: false });
        };

        const fetchBusinessSectorsData = async () => {
            const response = await getBusinessSectorsAsync();

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(`${t("unexpectedError")}: ${errorTitle}`);
                }

                return;
            }

            const data = await response.json();

            const englishLang = i18n.resolvedLanguage === "en";

            setBusinessSectorsOptions({
                data: data.map((bs) => ({ label: englishLang ? bs.sectorEn : bs.sectorEs, value: bs.id })),
                loading: false,
            });
        };

        fetchCountriesData();
        fetchBusinessSectorsData();
    }, []);

    const onSave = async (event) => {
        event.preventDefault();

        const numberOfErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, data);

        if (numberOfErrors > 0) {
            return;
        }

        const finalData = {
            id: data.id || undefined,
            fantasyName: data[FormNames.COMPANY_NAME],
            companyPosition: data[FormNames.COMPANY_POSITION],
            name: data[FormNames.BUSINESS_NAME],
            address: data[FormNames.ADDRESS],
            webSite: data[FormNames.WEBSITE],
            businessSectorId: data[FormNames.BUSINESS_SECTOR].value,
            countryId: data[FormNames.COUNTRY].value,
            taxId: data[FormNames.TAX_ID].value,
        };

        try {
            setLoading(true);

            let response = userHasOrganization
                ? await updateUserOrganization(finalData)
                : await addOrganizationAsync(finalData);

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(`${t("unexpectedError")}: ${errorTitle}`);
                }

                return;
            }

            response = await addPaymentAsync(projectId.current);

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(`${t("unexpectedError")}: ${errorTitle}`);
                }

                return;
            }

            toast.success(userHasOrganization ? t("organizationUpdated") : t("organizationCreated"));
        } catch (error) {
            toast.error(`${t("unexpectedError")}: ${error.message}`);
            return;
        } finally {
            setLoading(false);
        }

        navigate(Routes.buildPaymentProofUploader(projectId.current));
    };

    return (
        <>
            <main className={style.mainContainer}>
                <div className={style.innerContainer}>
                    <h1 className={`${style.title} text-center`}>{t("checkout")}</h1>
                    <form className="d-flex flex-column gap-3 w-75 mx-auto mt-4">
                        <div>
                            <Label htmlFor={FormNames.COMPANY_NAME} requiredIndicator>
                                {t("companyName")}:
                            </Label>
                            <input
                                name={FormNames.COMPANY_NAME}
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    !errors[FormNames.COMPANY_NAME] ? "" : sharedStyle.invalidField
                                } px-3 py-2`}
                                value={data[FormNames.COMPANY_NAME]}
                                onChange={onInputChange}
                                autoComplete="off"
                                placeholder={t("pleaseInsertCompanyName")}
                            />
                            {errors[FormNames.COMPANY_NAME] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.COMPANY_NAME].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.COMPANY_POSITION}>{t("companyPosition")}:</Label>
                            <input
                                name={FormNames.COMPANY_POSITION}
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    !errors[FormNames.COMPANY_POSITION] ? "" : sharedStyle.invalidField
                                } px-3 py-2`}
                                value={data[FormNames.COMPANY_POSITION]}
                                onChange={onInputChange}
                                autoComplete="off"
                                placeholder={t("pleaseInsertCompanyPosition")}
                            />
                            {errors[FormNames.COMPANY_POSITION] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.COMPANY_POSITION].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.BUSINESS_NAME}>{t("businessName")}:</Label>
                            <input
                                name={FormNames.BUSINESS_NAME}
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    !errors[FormNames.BUSINESS_NAME] ? "" : sharedStyle.invalidField
                                } px-3 py-2`}
                                value={data[FormNames.BUSINESS_NAME]}
                                onChange={onInputChange}
                                autoComplete="off"
                                placeholder={t("pleaseInsertBusinessName")}
                            />
                            {errors[FormNames.BUSINESS_NAME] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.BUSINESS_NAME].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.ADDRESS}>{t("address")}:</Label>
                            <input
                                name={FormNames.ADDRESS}
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    !errors[FormNames.ADDRESS] ? "" : sharedStyle.invalidField
                                } px-3 py-2`}
                                value={data[FormNames.ADDRESS]}
                                onChange={onInputChange}
                                autoComplete="off"
                                placeholder={t("pleaseInsertAddress")}
                            />
                            {errors[FormNames.ADDRESS] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.ADDRESS].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.WEBSITE}>{t("website")}:</Label>
                            <input
                                name={FormNames.WEBSITE}
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    !errors[FormNames.WEBSITE] ? "" : sharedStyle.invalidField
                                } px-3 py-2`}
                                value={data[FormNames.WEBSITE]}
                                onChange={onInputChange}
                                autoComplete="off"
                                placeholder={t("pleaseInsertWebsite")}
                                onBlur={validateUrl}
                            />
                            {errors[FormNames.WEBSITE] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.WEBSITE].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.BUSINESS_SECTOR} requiredIndicator>
                                {t("businessSector")}:
                            </Label>
                            <Selector
                                name={FormNames.BUSINESS_SECTOR}
                                styles={getSelectorStyle(!!errors[FormNames.BUSINESS_SECTOR])}
                                placeholder={t("pleaseInsertBusinessSector")}
                                options={businessSectorsOptions.data}
                                isLoading={businessSectorsOptions.loading}
                                onChange={onBusinessSelectorChange}
                                value={data[FormNames.BUSINESS_SECTOR]}
                            />
                            {errors[FormNames.BUSINESS_SECTOR] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.BUSINESS_SECTOR].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.COUNTRY} requiredIndicator>
                                {t("countryJurisdiction")}:
                            </Label>
                            <Selector
                                name={FormNames.COUNTRY}
                                isLoading={countriesOptions.loading}
                                options={countriesOptions.data}
                                styles={getSelectorStyle(!!errors[FormNames.COUNTRY])}
                                placeholder={t("pleaseInsertCountry")}
                                onChange={onCountrySelectorChange}
                                value={data[FormNames.COUNTRY]}
                            />
                            {errors[FormNames.COUNTRY] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.COUNTRY].message}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor={FormNames.TAX_ID} requiredIndicator>
                                {t("taxID")}:
                            </Label>
                            <input
                                name={FormNames.TAX_ID}
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    !errors[FormNames.TAX_ID] ? "" : sharedStyle.invalidField
                                } px-3 py-2`}
                                value={data[FormNames.TAX_ID].value}
                                onChange={onInputChange}
                                onBlur={checkTaxId}
                                autoComplete="off"
                                placeholder={t("pleaseInsertTaxID")}
                                disabled={!data[FormNames.COUNTRY]}
                            />
                            {errors[FormNames.TAX_ID] && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors[FormNames.TAX_ID].message}</p>
                                </div>
                            )}
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                            <button
                                type="submit"
                                onClick={onSave}
                                className={`${style.onSaveBtn} px-4 py-3 text-uppercase`}
                            >
                                {t("save")}
                            </button>
                        </div>
                    </form>
                </div>
            </main>

            {loading && <Loading />}
        </>
    );
};

export default CompanyInfo;
