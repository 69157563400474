import { useTranslation, Trans } from "react-i18next";
import style from "./FourthSection.module.scss";

const RowContent = ({ number, title, children, bottomBorder = false }) => (
    <div
        className={`${style.rowContent} ${style.topBorderDark} row justify-content-start py-4 top-border-dark ${
            bottomBorder ? style.bottomBorderDark : ""
        }`}
    >
        <span className="col-2 px-0">{number}</span>
        <p className="col col-lg-4">{title}</p>
        <p className="col-lg row">{children}</p>
    </div>
);

const FourthSection = () => {
    const { t } = useTranslation();

    return (
        <div className={style.section}>
            <h1>
                <Trans components={{ span: <span className="fst-italic" /> }}>valuesWeStandFor</Trans>
            </h1>
            <div className="ms-lg-auto">
                <RowContent number="01" title={t("speedAndQuality")}>
                    {t("whatWeProvide")}
                </RowContent>
                <RowContent number="02" title={t("customerService")}>
                    {t("ourPriority")}
                </RowContent>
                <RowContent number="03" title={t("innovation")}>
                    {t("ourMission")}
                </RowContent>
                <RowContent number="04" title={t("diversity")} bottomBorder={true}>
                    {t("ourEmployees")}
                </RowContent>
            </div>
        </div>
    );
};

export default FourthSection;
