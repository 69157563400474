import style from "./DetailItem.module.scss";

const DetailItem = ({ title, children }) => (
    <div className="d-flex flex-column">
        <span className={style.title}>{title}</span>
        <span className={style.content}>{children}</span>
    </div>
);

export default DetailItem;
