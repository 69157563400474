import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import sharedStyle from "../../../../shared-styles/FormStyle.module.scss";
import AvailableSkillsContainer from "../skills-containers/AvailableSkillsContainer";
import { ContainerType, ContainerVariants } from "../../constants";
import { useDnDFunctions } from "../../hooks/UseAssembleYourTeamFunctions";
import DropSkillsContainer from "../skills-containers/DropSkillsContainer";
import Label from "../../../../common/custom-label/Label";
import style from "./DragAndDrop.module.scss";

const DragAndDrop = ({ ids, errors, validateSubSchemaFromEvent, validationSchema, schemaErrorMessages }) => {
    const { t } = useTranslation();

    const skills = useSelector((state) => state.assembleYourTeam.skills);
    const mandatorySkillInput = useSelector((state) => state.assembleYourTeam.customSkillMandatory);
    const desiredSkillInput = useSelector((state) => state.assembleYourTeam.customSkillDesired);

    const {
        onChange,
        onKeyDown,
        onPillClick,
        onAvailableSkillsContainerDrop,
        onMandatorySkillsContainerDrop,
        onDesiredSkillsContainerDrop,
    } = useDnDFunctions(validationSchema, schemaErrorMessages, validateSubSchemaFromEvent);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="mb-2">
                <Label requiredIndicator>{t("knowledgeRequired")}</Label>
                <span className={`${style.instructions} d-block`}>{t("dragAndDropHelp")}</span>
            </div>

            <AvailableSkillsContainer
                skills={skills?.filter((s) => s.container === ContainerType.AVAILABLE_SKILLS)}
                onPillClick={onPillClick}
                onDrop={onAvailableSkillsContainerDrop}
            />

            <div className="d-flex justify-content-between">
                <div className={`${style.container} d-flex flex-column`}>
                    <DropSkillsContainer
                        variant={ContainerVariants.GREEN}
                        title={t("mandatorySkills")}
                        skills={skills?.filter((s) => s.container === ContainerType.MANDATORY_SKILLS)}
                        onPillClick={onPillClick}
                        onDrop={onMandatorySkillsContainerDrop}
                        onInputEnter={onKeyDown}
                        inputState={mandatorySkillInput}
                        onInputChange={onChange}
                        inputId={ids.INPUT_CUSTOM_MANDATORY_SKILL}
                        invalid={!!errors.skills}
                    />
                    {errors.skills && (
                        <div className="mt-1">
                            <p className={sharedStyle.errorMsg}>{errors.skills.message}</p>
                        </div>
                    )}
                </div>

                <div className={`${style.container} d-flex flex-column`}>
                    <DropSkillsContainer
                        variant={ContainerVariants.ORANGE}
                        title={t("desiredSkills")}
                        skills={skills?.filter((s) => s.container === ContainerType.DESIRED_SKILLS)}
                        onPillClick={onPillClick}
                        onDrop={onDesiredSkillsContainerDrop}
                        onInputEnter={onKeyDown}
                        inputState={desiredSkillInput}
                        onInputChange={onChange}
                        inputId={ids.INPUT_CUSTOM_DESIRED_SKILL}
                    />
                </div>
            </div>
        </DndProvider>
    );
};

export default DragAndDrop;
