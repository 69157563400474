import { API_BASE, request } from "../utils/httpRequest";

export const postPositionsAsync = async (positions, initialRange = true) => {
    return await request(`${API_BASE}/api/position/add-range?initialRange=${initialRange}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(positions),
    });
};

export const addPositionStaffAsync = async (positionId, resourceId) => {
    return await request(API_BASE + "/api/positionstaff/add", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ positionId: positionId, staffId: resourceId }),
    });
};

export const removePositionStaffAsync = async (positionId, resourceId) => {
    return await request(API_BASE + "/api/positionstaff/remove", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ positionId: positionId, staffId: resourceId }),
    });
};

export const getPositionsByProject = async (projectId) => {
    return await request(`${API_BASE}/api/position/positions-by-project/${projectId}`);
};

export const getPositionsForResumeListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        API_BASE +
            `/api/position/resume-list?page=${page}&pageSize=${pageSize}${
                searchText ? "&searchText=" + searchText : ""
            }`,
        {
            method: "GET",
        }
    );
};

export const getSenioritiesAsync = async () => {
    return await request(API_BASE + "/api/seniority");
};

export const getRolesAsync = async () => {
    return await request(API_BASE + "/api/developmentRol");
};

export const updatePositionStaff = async (resourceId, positionId, newStatus) => {
    return await request(`${API_BASE}/api/position/update-position-staff`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ positionId: positionId, staffId: resourceId, status: newStatus }),
    });
};
