import style from "./GuideContent.module.scss";
import Image from "../../../common/Image";

const GuideContent = ({ item }) => {
    return (
        <div className={`${style.mainContainer} d-flex justify-content-between`}>
            <div className={`${style.descriptionContainer} d-flex flex-column`}>
                <h4 className={`${style.title} mb-3`}>{item.title}</h4>
                <p className={style.contentText}>{item.content.text}</p>
            </div>
            {item.content.images.length > 0 && (
                <div className={`${style.imgContainer} ms-4 d-flex flex-column gap-2`}>
                    {item.content.images.map((image) => (
                        <Image key={image.id} src={image.src} alt={image.alt} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default GuideContent;
