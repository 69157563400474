import styles from "./SenioritySelector.module.scss";

const SenioritySelector = ({ items, itemActive, setItemActive }) => {
    const handleSelectSeniority = (event) => {
        setItemActive(parseInt(event.target.id));
    };

    return (
        <>
            <div className={styles.mainContainer}>
                {items?.map((item) => {
                    return (
                        <div
                            key={item.id}
                            id={item.id}
                            className={`${styles.itemContainer} ${itemActive === item.id ? styles.itemActive : null}`}
                            onClick={handleSelectSeniority}
                        >
                            {item.name}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default SenioritySelector;
