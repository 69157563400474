import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { forwardRef, useEffect, useState } from "react";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import colors from "../../base/js/colors";
import { DATE_FORMAT, TABLE_ROWS_LIMITS } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getReviewListByProjectAsync, removeReview } from "../../services/ReviewService";
import { useForceLogout } from "../../utils/useForceLogout";
import Paginator from "../common/paginator/Paginator";
import ReviewViewModal from "../common/review-view-modal/ReviewViewModal";
import Loading from "../loading/Loading";
import tableStyles from "../shared-styles/TableStyle.module.scss";
import ConfirmReviewDeleteModal from "./confirm-review-delete-modal/ConfirmReviewDeleteModal";
import styles from "./Reviews.module.scss";

const Reviews = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const forceLogout = useForceLogout();
    const [headers] = useState(["createdAt", "staff", "subject", "review", "actions"]);
    const [filterText, setFilterText] = useState("");
    const [filterChange, setFilterChange] = useState(false);
    const [reviewList, setReviewList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [reloadData, setReloadData] = useState(true);
    const [showConfirmReviewDelete, setShowConfirmReviewDelete] = useState(false);
    const [showReviewViewModal, setShowReviewViewModal] = useState(false);
    const [reviewActive, setReviewActive] = useState(null);
    const [total, setTotal] = useState(null);
    const [limit, setLimit] = useState(5);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await getReviewListByProjectAsync(params.projectId, activePage, limit, filterText);
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else if (response.status === 403) {
                    toast.error(t("insufficientPermissions"));
                } else {
                    toast.error(await response.text());
                }

                return;
            }

            const responseData = await response.json();
            const data = responseData.reviews.map((review) => {
                const handleReviewEdit = async (e) => {
                    e.preventDefault();
                    const url = Routes.buildEditReviewPath(params.projectId, review.id);
                    navigate(url);
                };

                const handleReviewDelete = async (e) => {
                    e.preventDefault();
                    setReviewActive(review);
                    setShowConfirmReviewDelete(true);
                };

                const handleReviewView = (e) => {
                    e.preventDefault();
                    setReviewActive(review);
                    setShowReviewViewModal(true);
                };

                return {
                    createdAt: format(parseISO(review.createdAt), DATE_FORMAT),
                    staff: review.staff.name,
                    subject: review.subject,
                    review: `${review.comment.slice(0, 50)}...`,
                    actions: [
                        {
                            label: "view",
                            action: handleReviewView,
                            color: "",
                        },
                        {
                            label: "edit",
                            action: handleReviewEdit,
                            color: "",
                        },
                        {
                            label: "delete",
                            action: handleReviewDelete,
                            color: "",
                        },
                    ],
                };
            });
            setTotal(responseData.total);
            setReviewList(data);
        })();
    }, [filterChange, activePage, limit, reloadData]);

    const handleOnChangeLimit = (event) => {
        setLimit(event.target.value);
    };

    const handleChangeFilterText = (event) => {
        setFilterText(event.target.value);
    };

    const handleKeyDownFilterText = (event) => {
        if (event.key !== "Enter") return;

        setActivePage(1);
        setFilterChange(!filterChange);
    };

    const handleClickCreateReview = () => {
        const projectId = params.projectId;
        const url = Routes.buildCreateReviewPath(projectId);
        navigate(url);
    };

    const handleClickBack = () => {
        const projectId = params.projectId;
        const url = Routes.buildInDevelopmentPath(projectId);
        navigate(url);
    };

    const handleConfirmReviewDeleteAccept = async () => {
        if (!reviewActive) return;

        setIsLoading(true);
        const response = await removeReview(reviewActive.id);
        if (!response.ok) {
            if (response.status === 401) {
                await forceLogout();
                return;
            }
            toast.error(t("unexpectedError") + ": " + (await response.text()));
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        setShowConfirmReviewDelete(false);
        toast.success("Review deleted successfully");
        setReloadData(!reloadData);
    };

    const handleConfirmReviewDeleteCancel = () => {
        setReviewActive(null);
        setShowConfirmReviewDelete(false);
    };

    const handleCloseReviewViewModal = () => {
        setShowReviewViewModal(false);
    };

    return (
        <main className={`${styles.mainContainer} mx-auto`}>
            <div className="pt-5">
                <h1 className={styles.title}>{t("reviews")}</h1>
            </div>
            <div className="pt-4">
                <div
                    className={`${tableStyles.filtersContainer} col-12 d-flex gap-2 gap-md-0 flex-column flex-md-row flex-lg-row justify-content-between align-items-center`}
                >
                    <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                        <FontAwesomeIcon icon={faFilter} color={colors.GRAY850} />
                        <label className={`${tableStyles.filterLabel} px-2`}>{t("filter")}</label>
                        <input
                            className={`${tableStyles.inputTextFilter} col-10`}
                            value={filterText}
                            onKeyDown={handleKeyDownFilterText}
                            onChange={handleChangeFilterText}
                        ></input>
                    </div>
                    <div
                        className={`${tableStyles.btnDateRangeFilter} d-flex gap-2 justify-content-end align-items-center col-12 col-md-6 col-lg-6`}
                    >
                        <div>
                            <label className={tableStyles.selectLimitLabel} htmlFor="select-limit">
                                {t("show")}
                            </label>
                            <select
                                id="select-limit"
                                className={`${tableStyles.showFilter} py-1 px-2`}
                                defaultValue={limit}
                                onChange={handleOnChangeLimit}
                            >
                                {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                    return (
                                        <option key={index} value={rowLimit}>
                                            {rowLimit}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <table className="text-left col-12">
                    <thead>
                        <tr>
                            {headers.map((head, index) => {
                                return <th key={index}>{t(head)}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {reviewList.map((columns, index) => {
                            const rowContent = [];
                            for (const key in columns) {
                                if (key === "actions") {
                                    rowContent.push(
                                        <td key={uuidv4()}>
                                            <DropdownButton
                                                id="dropdown-basic-button"
                                                title={t("actions")}
                                                size="sm"
                                                variant="secondary"
                                            >
                                                {columns[key].map((action) => {
                                                    const CustomDropdownItem = forwardRef(
                                                        ({ children, onClick }, ref) => (
                                                            <a
                                                                className={`dropdown-item-${action.color}`}
                                                                ref={ref}
                                                                href={"#"}
                                                                onClick={action.action}
                                                            >
                                                                {t(action.label)}
                                                            </a>
                                                        )
                                                    );

                                                    return (
                                                        <Dropdown.Item
                                                            as={CustomDropdownItem}
                                                            key={uuidv4()}
                                                            href={"#"}
                                                        ></Dropdown.Item>
                                                    );
                                                })}
                                            </DropdownButton>
                                        </td>
                                    );
                                } else {
                                    rowContent.push(<td key={uuidv4()}>{columns[key]}</td>);
                                }
                            }

                            return <tr key={index}>{rowContent}</tr>;
                        })}
                        {!reviewList.length && (
                            <tr>
                                <td colSpan={headers.length} className={tableStyles.noDataContent}>
                                    {t("noDataToShow")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Paginator activePage={activePage} lastPage={Math.ceil(total / limit)} setPage={setActivePage} />
            </div>
            <div className="d-flex gap-3 justify-content-center my-5">
                <button
                    className={`${styles.btn} ${styles.btnCreateReview} text-uppercase`}
                    onClick={handleClickCreateReview}
                >
                    {t("createReview")}
                </button>
                <button className={`${styles.btn} text-uppercase`} onClick={handleClickBack}>
                    {t("back")}
                </button>
            </div>
            <ConfirmReviewDeleteModal
                onCancel={handleConfirmReviewDeleteCancel}
                show={showConfirmReviewDelete}
                onAccept={handleConfirmReviewDeleteAccept}
            />
            <ReviewViewModal show={showReviewViewModal} onClose={handleCloseReviewViewModal} review={reviewActive} />
            {isLoading && <Loading />}
        </main>
    );
};

export default Reviews;
