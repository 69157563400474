import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import style from "./NavigationSection.module.scss";
import Routes from "../../../base/js/routes";

const NavigationSection = () => {
    const { t } = useTranslation();

    return (
        <div className={`${style.container} d-none d-lg-flex flex-column gap-2`}>
            <span className="fw-bold mb-2">{t("navigation")}</span>
            <NavLink to={Routes.HOME}>{t("home")}</NavLink>
            <NavLink to={Routes.FAQ}>FAQ</NavLink>
            <NavLink to={Routes.BUILD_YOUR_TEAM}>{t("getYourTeam")}</NavLink>
            <NavLink to={Routes.HOW_TO_START}>{t("howToStart")}</NavLink>
            <NavLink to={Routes.ABOUT}>{t("aboutUs")}</NavLink>
            <NavLink to={Routes.CONTACT}>{t("contactUs2")}</NavLink>
        </div>
    );
};

export default NavigationSection;
