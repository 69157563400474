import styles from "./SecondSection.module.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Image from "../../common/Image";
import Column from "./column/Column";
import MemberQuote from "./member-quote/MemberQuote";

import jackieImg from "../../../assets/meet-the-team/jackie.jpg";
import julioImg from "../../../assets/meet-the-team/julio.jpg";
import sample1Img from "../../../assets/meet-the-team/sample1.jpg";
import sample2Img from "../../../assets/meet-the-team/sample2.jpg";

const SecondSection = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.section}>
            <h1 className={`${styles.title} text-uppercase pb-4`}>Core team</h1>
            <div className="row flex-column flex-lg-row justify-content-between">
                <Column>
                    <Image src={jackieImg} alt="Jacqueline Mermelstein" cssClass={styles.columnImgTop} />
                    <span className={`${styles.columnQuoteName} text-center mt-1 mb-3 d-block d-lg-none`}>
                        Jacqueline Mermelstein - CEO
                    </span>

                    <p className="mt-5 d-none d-lg-block">{t("jackieSpeech")}</p>
                </Column>

                <Column>
                    <p className="mt-4 mt-lg-0 mb-0">{t("santiagoBeginnings")}</p>

                    <NavLink to="" className={`${styles.readMore} fw-bold d-block d-lg-none p-0 mt-2`}>
                        {t("readMore")}
                    </NavLink>

                    <p className="d-none d-lg-block mt-4">{t("santiagoChallenges")}</p>

                    <p className="d-none d-lg-block mt-4">{t("santiagoBusinessVisionP1")}</p>
                </Column>

                <Column cssClasses="d-none d-lg-block">
                    <p>{t("santiagoBusinessVisionP2")}</p>

                    <p className="mt-4">{t("santiagoSpeech")}</p>

                    <p className="mt-4">{t("santiagoMeetsJackie")}</p>
                </Column>
            </div>

            <div className="row flex-column flex-lg-row justify-content-between">
                <Column />

                <Column>
                    <MemberQuote name="Sample 2" position="CTO" image={sample2Img}>
                        {t("member1Quote")}
                    </MemberQuote>

                    <MemberQuote name="Sample 1" position="HR Manager" image={sample1Img}>
                        {t("member1Quote")}
                    </MemberQuote>
                </Column>

                <Column>
                    <MemberQuote name="Julio Ribas" position="CMO" image={julioImg}>
                        {t("member2Quote")}
                    </MemberQuote>
                </Column>
            </div>
        </div>
    );
};

export default SecondSection;
