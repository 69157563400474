import { useTranslation } from "react-i18next";
import style from "./Carousel.module.scss";
import CarouselPage from "./CarouselPage";

import testimonio1Img from "../../../assets/testimonials/persona1.jpg";
import testimonio2Img from "../../../assets/testimonials/persona3.jpg";
import testimonio3Img from "../../../assets/testimonials/persona2.jpg";

const Carousel = () => {
    const { t } = useTranslation();

    return (
        <div className={`${style.reviewsCarousel} d-flex justify-content-center mt-lg-5`}>
            <CarouselPage
                image={testimonio1Img}
                imageAlt="Derek Demuro"
                reviewerName="Derek Demuro"
                reviewerPosition="Lead Software Engineer and consultant"
                onFront={false}
            >
                {t("reviewDerek")}
            </CarouselPage>
            <CarouselPage
                image={testimonio2Img}
                imageAlt="Derek Demuro"
                reviewerName="Derek Demuro"
                reviewerPosition="Lead Software Engineer and consultant"
                onFront={true}
            >
                {t("reviewDerek")}
            </CarouselPage>
            <CarouselPage
                image={testimonio3Img}
                imageAlt="Derek Demuro"
                reviewerName="Derek Demuro"
                reviewerPosition="Lead Software Engineer and consultant"
                onFront={false}
            >
                {t("reviewDerek")}
            </CarouselPage>
        </div>
    );
};

export default Carousel;
