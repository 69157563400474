import { createSlice } from "@reduxjs/toolkit";
import { RoleCheckboxNames as firstSectionCheckboxNames } from "../../components/build-your-team/assemble-your-team/first-section/FirstSection";

const defaultCheckboxValues = {
    [firstSectionCheckboxNames.DECISION_MAKER]: false,
    [firstSectionCheckboxNames.RECOMMENDER]: false,
    [firstSectionCheckboxNames.USER]: false,
    [firstSectionCheckboxNames.OTHERS]: false,
};

const initialState = {
    id: null,
    teamName: "",
    investmentExpectation: "",
    decisionFlow: "",
    rolesInteracting: defaultCheckboxValues,
    roleInteractingCustom: "",
    startDate: "",
    endDate: "",
    timezone: null,
    confirmationDate: "",
    skills: [],
    customSkillMandatory: "",
    customSkillDesired: "",
    teamArea: null,
    projectType: "",
    additionalComments: "",
    // Attachment is the only state that will keep the structure of
    // errors and validity ({errors: [], isValid: true}) due to its
    // complexity to handle it outside the processing function
    attachment: {
        fileName: "",
        blobName: "",
        isLoading: false,
        errors: [],
        isValid: true,
    },
    positions: [],
    projectStatus: null,
};

const assembleYourTeamSlice = createSlice({
    name: "assembleYourTeam",
    initialState,
    reducers: {
        setId(state, { payload }) {
            state.id = payload;
        },
        // Required field
        setTeamName(state, { payload }) {
            state.teamName = payload;
        },
        setInvestmentExpectation(state, { payload }) {
            state.investmentExpectation = payload;
        },
        // Required field
        setDecisionFlow(state, { payload }) {
            state.decisionFlow = payload;
        },
        // Required field
        setRolesInteracting(state, { payload }) {
            state.rolesInteracting = payload;
        },
        setRoleInteractingCustom(state, { payload }) {
            state.roleInteractingCustom = payload;
        },
        // Required field
        setStartDate(state, { payload }) {
            state.startDate = payload;
        },
        // Required field
        setEndDate(state, { payload }) {
            state.endDate = payload;
        },
        // Required field
        setTimezone(state, { payload }) {
            state.timezone = payload;
        },
        // Required field
        setConfirmationDate(state, { payload }) {
            state.confirmationDate = payload;
        },
        setSkills(state, { payload }) {
            state.skills = payload;
        },
        setCustomSkillMandatory(state, { payload }) {
            state.customSkillMandatory = payload;
        },
        setCustomSkillDesired(state, { payload }) {
            state.customSkillDesired = payload;
        },
        setTeamArea(state, { payload }) {
            state.teamArea = payload;
        },
        setProjectType(state, { payload }) {
            state.projectType = payload;
        },
        setAdditionalComments(state, { payload }) {
            state.additionalComments = payload;
        },
        setAttachment(state, { payload }) {
            state.attachment = { ...state.attachment, ...payload };
        },
        setPositions(state, { payload }) {
            state.positions = payload;
        },
        setProjectStatus(state, { payload }) {
            state.projectStatus = payload;
        },
        restartState: () => initialState,
    },
});

export const {
    setId,
    setTeamName,
    setInvestmentExpectation,
    setDecisionFlow,
    setRolesInteracting,
    setRoleInteractingCustom,
    setStartDate,
    setEndDate,
    setTimezone,
    setConfirmationDate,
    setTeamArea,
    setProjectType,
    setAdditionalComments,
    setAttachment,
    setSkills,
    setCustomSkillMandatory,
    setCustomSkillDesired,
    setPositions,
    setProjectStatus,
    restartState,
} = assembleYourTeamSlice.actions;

export default assembleYourTeamSlice.reducer;
