export const API_BASE = "https://platform-api.thedevblock.com";

export const request = async (url, options) => {
    let request = await fetch(url, { ...options, credentials: "include" });

    if (request.status !== 401) {
        return request;
    }

    request = await fetch(API_BASE + "/api/authentication/refresh-token", {
        method: "POST",
        credentials: "include",
    });

    if (!request.ok) {
        return request;
    }

    return await fetch(url, { ...options, credentials: "include" });
};
