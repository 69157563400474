import { addDays, addYears } from "date-fns";
import { ALLOWED_FORMATS } from "../../common/custom-input-file/CustomInputFile";

export const CharsLimit = {
    TEAM_NAME: 250,
    INVESTMENT_EXPECTATION: 10,
    DECISION_FLOW: 3000,
    ROLES_OTHER: 250,
    ADDITIONAL_COMMENTS: 3000,
    CUSTOM_SKILL: 20,
};

export const NativeEventTypes = {
    ON_INPUT: "insertText",
    ON_PASTE: "insertFromPaste",
};

export const ContainerType = {
    AVAILABLE_SKILLS: 1,
    MANDATORY_SKILLS: 2,
    DESIRED_SKILLS: 3,
};

export const PillVariants = {
    GREEN: 1,
    ORANGE: 2,
    NEUTRAL: 3,
};

export const ContainerVariants = {
    GREEN: 1,
    ORANGE: 2,
};

// 5MB
export const MAX_ATTACHMENT_FILESIZE = 5 * 1024 * 1024;
export const ALLOWED_MIMETYPES = ALLOWED_FORMATS.map((t) => t.mimeType);
export const ALLOWED_TYPE_NAMES = ALLOWED_FORMATS.map((t) => t.name);

export const DATE_FORMAT = "MM/dd/yyyy";

const CURRENT_DATE = new Date();

export const ProjectTimeLimits = {
    MIN_START: addDays(CURRENT_DATE, 7),
    MAX_END: addYears(CURRENT_DATE, 4),
    MIN_CONFIRMATION_DATE: addDays(CURRENT_DATE, 7),
};
