import { API_BASE, request } from "../utils/httpRequest";

export const createCustomer = async () => {
    return request(`${API_BASE}/api/stripe/create-customer`, {
        method: "POST",
    });
};

export const createCheckoutSessionAsync = async (projectId) => {
    return request(`${API_BASE}/api/stripe/${projectId}/create-checkout-session`, {
        method: "POST",
    });
};

export const getInvoiceListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        API_BASE + `/api/stripe?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
        {
            method: "GET",
        }
    );
};
