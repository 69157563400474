import { useEffect, useState } from "react";
import useWindowWidth from "../../../hooks/UseWindowWidth";
import { BREAKPOINTS } from "../../../../base/js/constants";
import DragAndDrop from "./drag-and-drop/DragAndDrop";
import SkillsMobileSelector from "./mobile-selector/SkillsMobileSelector";

const SkillsSelector = ({ ids, errors, validateSubSchemaFromEvent, validationSchema, schemaErrorMessages }) => {
    const windowWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState(windowWidth < BREAKPOINTS.LG);

    useEffect(() => {
        setIsMobile(windowWidth < BREAKPOINTS.LG);
    }, [windowWidth]);

    return isMobile ? (
        <SkillsMobileSelector
            ids={ids}
            errors={errors}
            validateSubSchemaFromEvent={validateSubSchemaFromEvent}
            validationSchema={validationSchema}
            schemaErrorMessages={schemaErrorMessages}
        />
    ) : (
        <DragAndDrop
            ids={ids}
            errors={errors}
            validateSubSchemaFromEvent={validateSubSchemaFromEvent}
            validationSchema={validationSchema}
            schemaErrorMessages={schemaErrorMessages}
        />
    );
};

export default SkillsSelector;
