import { API_BASE, request } from "../utils/httpRequest";

export const addStaffAsync = async (data) => {
    return await request(API_BASE + "/api/staff", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const updateStaffAsync = async (staffId, data) => {
    return await request(`${API_BASE}/api/staff/${staffId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getStaffAsync = async (id, complete = false) => {
    return await request(`${API_BASE}/api/staff/${id}?complete=${complete}`);
};

export const getStaffByProjectIdAsync = async (projectId) => {
    return await request(`${API_BASE}/api/staff/by-project/${projectId}`);
};

export const getResourcesListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        API_BASE + `/api/staff?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
        {
            method: "GET",
        }
    );
};

export const getResourcesAvailableListAsync = async (
    page = 1,
    pageSize = 5,
    roleId = null,
    seniorityId = null,
    technology = null,
    searchText = null
) => {
    return await request(
        API_BASE +
            `/api/staff/available?page=${page}&pageSize=${pageSize}${roleId ? "&roleId=" + roleId : ""}${
                seniorityId ? "&seniorityId=" + seniorityId : ""
            }${technology ? "&technology=" + technology : ""}${searchText ? "&searchText=" + searchText : ""}`,
        {
            method: "GET",
        }
    );
};
