import { useEffect } from "react";

const useOnBeforeUnload = (callback, enabled) => {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        window.addEventListener("beforeunload", callback);

        return () => {
            window.removeEventListener("beforeunload", callback);
        };
    }, [enabled]);
};

export default useOnBeforeUnload;
