const useOnCardInputChange = (setStartDate, setEndDate, setTimeStart, setTimeEnd, hourSelectorOptions) => {
    const onStartDateChange = (date) => {
        setStartDate((prevState) => ({
            ...prevState,
            value: date,
            errors: [],
            isValid: true,
        }));

        setEndDate((prevState) => {
            if (!prevState.value || prevState.value > date) return { ...prevState, minValue: date };

            return {
                ...prevState,
                value: date,
                isValid: true,
                errors: [],
                minValue: date,
            };
        });
    };

    const onEndDateChange = (date) => {
        setEndDate((prevState) => ({
            ...prevState,
            value: date,
            isValid: true,
            errors: [],
        }));
    };

    const onTimeStartChange = (newValue) => {
        setTimeStart((prevState) => ({
            ...prevState,
            value: newValue,
            isValid: true,
            errors: [],
        }));

        setTimeEnd((prevState) => {
            if (!prevState.value || prevState.value.value > newValue.value)
                return {
                    ...prevState,
                    options: hourSelectorOptions.filter((hourOption) => hourOption.value > newValue.value),
                };

            return {
                value: null,
                isValid: true,
                errors: [],
                options: hourSelectorOptions.filter((hourOption) => hourOption.value > newValue.value),
            };
        });
    };

    const onTimeEndChange = (newValue) => {
        setTimeEnd((prevState) => ({
            ...prevState,
            value: newValue,
            isValid: true,
            errors: [],
        }));
    };

    return {
        onStartDateChange,
        onEndDateChange,
        onTimeStartChange,
        onTimeEndChange,
    };
};

export default useOnCardInputChange;
