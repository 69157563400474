import style from "./Pill.module.scss";
import { PillVariants } from "../../constants";

const MobilePill = ({ skill, onClick, selected = false, variant = PillVariants.NEUTRAL }) => {
    let variantCss;

    switch (variant) {
        case PillVariants.NEUTRAL:
            variantCss = "";
            break;
        case PillVariants.GREEN:
            variantCss = style.greenButton;
            break;
        case PillVariants.ORANGE:
            variantCss = style.orangeButton;
            break;
        default:
            throw new Error(`Unrecognized Pill variant "${variant}"`);
    }

    const handlePillClick = () => onClick(skill);

    return (
        <div
            onClick={handlePillClick}
            className={`${style.container} ${selected ? variantCss : ""}`}
            title={skill.name}
        >
            {!selected && <span className={style.indicator}>+</span>}
            <span className={style.text}>{skill.name}</span>
        </div>
    );
};

export default MobilePill;
