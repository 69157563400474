import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { changePasswordAsync, resetPasswordAsync, signUpAsync } from "../../services/AuthService";
import Joi from "joi";
import Routes from "../../base/js/routes";
import Button, { textVariants, variants as btnVariants } from "../common/Button";
import toast from "react-hot-toast";
import useJoiValidation from "../hooks/UseJoiValidation";
import Loading from "../loading/Loading";
import PasswordStrengthIndicator from "../login-signup/login-form/password-strength-indicator/PasswordStrengthIndicator";

import style from "./ChangePassword.module.scss";
import sharedStyle from "../shared-styles/FormStyle.module.scss";
import { setEmail, setRoles, setUsername } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";

const passwordRules = [
    { label: "passwordCharacterLimit", rule: /^.{8,255}$/ },
    { label: "passwordSpecialCharacter", rule: /[@#$%^&+=.\-_*]+/ },
    { label: "passwordNumberCharacter", rule: /\d+/ },
    { label: "passwordCharacterLower", rule: /^(?=.*[a-z]).+$/ },
    { label: "passwordCharacterUpper", rule: /^(?=.*[A-Z]).+$/ },
];

const validatePasswordStrength = (value, helpers) => {
    let validPassword = true;
    passwordRules.forEach((rule) => {
        if (!rule.rule.test(value)) {
            validPassword = false;
        }
    });
    return validPassword ? value : helpers.error("any.required");
};

// (Joi) Definition of the base schema
const baseSchema = {
    emailAddress: Joi.string()
        .max(255)
        .email({ tlds: { allow: false } })
        .required(),
    password: Joi.string().min(8).max(255).required().custom(validatePasswordStrength),
};

// (Joi) Object containing errors' messages by its type
const schemaErrorMessages = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "****",
    "string.email": "invalidEmailFormat",
    "any.required": "weakPassword",
};

const ChangePassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParam, setSearchParam] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const passwordRef = useRef(null);
    const eyeRef = useRef(null);
    const [data, setData] = useState({
        emailAddress: "",
        password: "",
    });

    const validateForm = async (event) => {
        event.preventDefault();

        // Validate the schema and update the state that contains all the errors
        const numberOfErrors = validateSchema(baseSchema, schemaErrorMessages, data);
        console.log(errors);
        // If there are errors, abort the upload of the info
        if (numberOfErrors > 0) {
            return;
        }

        setIsLoading(true);

        try {
            const dataToSend = {
                code: searchParam.get("code"),
                email: data.emailAddress,
                password: data.password,
            };

            const response = await changePasswordAsync(dataToSend);

            if (!response.ok) {
                toast.error("Unexpected error: " + (await response.text()));
                return;
            }

            const responseData = await response.json();

            dispatch(setUsername(responseData?.name));
            dispatch(setEmail(responseData?.email));
            dispatch(setRoles(responseData?.roles));
            navigate(Routes.LETS_START);
        } catch (e) {
            setIsLoading(false);
            toast.error("Unexpected error: " + e.message);
        }
    };

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        setData((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    const handleOnBlur = (event) => {
        validateSubSchemaFromEvent(baseSchema, schemaErrorMessages, event);
    };

    const handlePasswordVisibility = () => {
        if (passwordRef.current.type === "password") {
            passwordRef.current.type = "text";
            eyeRef.current.classList.add("d-none");
        } else {
            passwordRef.current.type = "password";
            eyeRef.current.classList.remove("d-none");
        }
    };

    return (
        <main className={`${style.mainContainer} d-flex justify-content-center`}>
            <section className={style.leftSection}>
                <div className={`${style.changePasswordContainer} m-auto d-flex flex-column  p-3 p-md-5`}>
                    <h1>{t("changePassword")}</h1>
                    <form onSubmit={validateForm} className="position-relative d-flex flex-column">
                        <label className="my-2" htmlFor="input-email">
                            {t("emailAddress")}
                        </label>
                        <input
                            id="input-email"
                            name="emailAddress"
                            className={`${errors.emailAddress ? sharedStyle.invalidField : ""} ${style.input} border-0`}
                            type="text"
                            value={data.emailAddress}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                        {errors.emailAddress && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.emailAddress.message}</p>
                            </div>
                        )}

                        <label className="my-2" htmlFor="input-password">
                            {t("newPassword")}
                        </label>
                        <div className="position-relative d-flex flex-column">
                            <input
                                id="input-password"
                                name="password"
                                ref={passwordRef}
                                className={`${style.signPassword} ${errors.password ? sharedStyle.invalidField : ""} ${
                                    style.input
                                } border-0`}
                                type="password"
                                value={data.password}
                                onChange={handleOnChange}
                                autoComplete="new-password"
                                onBlur={handleOnBlur}
                            />
                            <i
                                className={`${style.eye} position-absolute`}
                                ref={eyeRef}
                                onClick={handlePasswordVisibility}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="#aaa">
                                    <path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" />
                                </svg>
                            </i>
                            <i className={`${style.eye} position-absolute`} onClick={handlePasswordVisibility}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#aaa">
                                    <path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" />
                                </svg>
                            </i>
                        </div>
                        {errors.password && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.password.message}</p>
                            </div>
                        )}

                        <PasswordStrengthIndicator
                            password={data.password}
                            rules={passwordRules}
                            className="my-2"
                            translateLabels
                        />

                        <div className="position-relative d-flex flex-column mt-4">
                            <Button
                                disabled={isLoading}
                                variant={btnVariants.PRIMARY}
                                cssClasses={[style.changePasswordBtn]}
                                type="submit"
                            >
                                {t("changePassword")}
                            </Button>
                        </div>
                    </form>
                    {isLoading && <Loading />}
                </div>
            </section>
        </main>
    );
};

export default ChangePassword;
