export const replaceMark = (string, from, to) => {
    let result;
    if (typeof to === "object") {
        const array = string.split(from);
        result = [];

        for (let i = 0; i < array.length; i++) {
            let mapping = [array[i]];

            if (i !== array.length - 1) {
                mapping.push(to);
            }

            result = result.concat(mapping);
        }
    } else if (typeof to === "string") {
        result = string.replaceAll(from, to);
    }

    return result;
};
