import { useTranslation } from "react-i18next";
import logo from "../../assets/logos/tdb-logo.png";
import { createLinkDownloadStatementFile } from "../../services/FileService";
import style from "./NotFound.module.scss";

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className={style.container}>
                <div className={style.content}>
                    <img className="align-self-center" src={logo} alt={t("tdbIsLogo")} />
                    <span className={style.notFoundText}>{t("Page not found")}</span>
                    <a className={style.backToHome} href={"/"}>
                        {t("backToHome")}
                    </a>
                </div>
            </div>
        </>
    );
};

export default NotFound;
