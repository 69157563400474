import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import { format, parseISO } from "date-fns";
import style from "./RequestInterview.module.scss";
import ProjectInfoTemplate from "../common/project-info-template/ProjectInfoTemplate";
import Card from "./card/Card";
import Routes from "../../base/js/routes";
import Button, { variants as btnVariants } from "../common/Button";
import { getProjectAsync } from "../../services/ProjectService";
import { DATE_FORMAT, FORMATTER } from "../../base/js/constants";
import { useForceLogout } from "../../utils/useForceLogout";

const RequestInterview = () => {
    const [project, setProject] = useState({});

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const forceLogout = useForceLogout();

    useEffect(() => {
        (async () => {
            const projectId = params.projectId;

            const response = await getProjectAsync(projectId, true);
            if (!response.ok) {
                if (response.status === 404) {
                    toast.error(t("projectNotFound"));
                } else if (response.status === 401) {
                    await forceLogout();
                } else {
                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                }

                return;
            }

            const data = await response.json();

            setProject({
                id: data.id,
                name: data.name,
                status: data.projectStatus[0].status.value,
                startDate: format(parseISO(data.dateStart), DATE_FORMAT),
                endDate: format(parseISO(data.dateEnd), DATE_FORMAT),
                monthPrice: FORMATTER.format(data.monthPrice),
                finalPrice: FORMATTER.format(data.finalPrice),
                team: data.positions.map((p) => {
                    // Generate an ID for the position
                    const position = { ...p, id: uuidV4() };

                    // Convert each tag object to a string
                    position.tags = position.tags?.map((t) => t.value);

                    // Generate an ID for each (if any) staff assigned for that position
                    position.staff = position.positionStaffs?.map((s) => ({ ...s.staff }));

                    return position;
                }),
            });
        })();
    }, []);

    const onBackClick = () => {
        navigate(Routes.buildTeamInfoResumePath(project.id));
    };

    return (
        <main className={style.mainContainer}>
            <ProjectInfoTemplate projectData={project} sectionTitle={t("teamInfo")} />

            <div className="d-flex flex-column px-3 px-lg-0">
                <h1 className={style.title}>{t("availableDates")}</h1>
                <div className="d-flex flex-column gap-3 mt-5">
                    {project.team?.map((position) =>
                        position.staff.length
                            ? position.staff.map((resource) => <Card key={resource.id} resource={resource} />)
                            : null
                    )}
                </div>
            </div>

            <div className="d-flex gap-4 justify-content-between mt-5 mb-4">
                <Button cssClasses={["fw-normal"]} onClick={onBackClick} variant={btnVariants.PRIMARY_INVERSE}>
                    {t("back")}
                </Button>
                <Button onClick={onBackClick}>{t("continue")}</Button>
            </div>

            <div className="d-flex justify-content-center">
                <p className={`${style.indicationText} text-center`}>
                    <Trans components={{ span: <span className={style.fw600} /> }}>interviewModeDescription</Trans>
                </p>
            </div>
        </main>
    );
};

export default RequestInterview;
