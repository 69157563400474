import { useTranslation } from "react-i18next";
import Button from "../../common/Button";
import style from "./SecondSectionRight.module.scss";

const SecondSectionRight = () => {
    const { t } = useTranslation();

    return (
        <div className={`${style.section} d-flex flex-column`}>
            <p className="mt-4">
                {t("foundationStory")}
                <br />
                <br />
                {t("ourStaff")}
            </p>
            <div className={`${style.learnMore} d-flex flex-column flex-lg-row align-items-lg-center mt-4`}>
                <span className="fw-bold mb-3 mb-lg-0 me-lg-5">{t("wantToLearnMore")}</span>
                <Button>{t("scheduleMeeting")}</Button>
            </div>
        </div>
    );
};

export default SecondSectionRight;
