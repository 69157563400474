import style from "./CarouselPage.module.scss";

const CarouselPage = ({ image, imageAlt, children, reviewerName, reviewerPosition, onFront }) => (
    <div className={`${style.reviewsCarouselPage} position-relative mx-2 ${onFront ? "d-flex" : "d-none d-lg-block"}`}>
        <img
            src={image}
            alt={imageAlt}
            className={`${style.reviewsCarouselPageImg} ${!onFront ? style.grayFilter : ""}`}
        />
        {onFront && (
            <div className="d-flex flex-column px-2 pb-4 position-relative">
                <span className={`${style.quoteSymbol} position-absolute`}>“</span>
                <p className={style.reviewsCarouselPageContent}>{children}</p>
                <span className={style.reviewsCarouselPageName}>{reviewerName}</span>
                <span className={style.reviewsCarouselPagePosition}>{reviewerPosition}</span>
            </div>
        )}
    </div>
);

export default CarouselPage;
