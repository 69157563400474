import { Outlet } from "react-router-dom";
import styles from "./Admin.module.scss";

const Admin = () => {
    return (
        <div className={styles.adminContainer}>
            <Outlet />
        </div>
    );
};

export default Admin;
