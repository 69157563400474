import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styles from "../../../components/shared-styles/OrganizationStatementList.module.scss";
import { createLinkDownloadStatementFile } from "../../../services/FileService";
import { getOrganizationAsync } from "../../../services/OrganizationService";
import { useForceLogout } from "../../../utils/useForceLogout";
import Label from "../custom-label/Label";

const OrganizationStatementList = ({ organizationId }) => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const [organizationDocumentList, setOrganizationDocumentList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (organizationId) {
                    const response = await getOrganizationAsync(organizationId);

                    if (!response.ok) {
                        if (response.status === 401) {
                            await forceLogout();
                        } else {
                            const error = await response.json();
                            toast.error(t("unexpectedError") + ": " + error);
                        }

                        return;
                    }

                    const data = await response.json();
                    setOrganizationDocumentList(data.organizationDocuments);
                }
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            }
        })();
    }, [organizationId]);

    return (
        <div className="ms-5">
            <Label>{t("statementOfWork")}</Label>
            <table className={`${styles.statementTable} w-100`}>
                <thead>
                    <tr>
                        <th>{t("projectName")}</th>
                        <th>{t("files")}</th>
                    </tr>
                </thead>
                <tbody>
                    {organizationDocumentList.length > 0 ? (
                        organizationDocumentList.map((document) => {
                            return (
                                <tr key={document.id}>
                                    <td>{document.project.name}</td>
                                    <td>
                                        <a
                                            className={styles.downloadCv}
                                            href={createLinkDownloadStatementFile(document.id)}
                                        >
                                            {document.statementOfWorkName}
                                        </a>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr className={styles.noData}>
                            <td colSpan="3">{t("noDataToShow")}</td>
                        </tr>
                    )}
                </tbody>
                <tfoot className={styles.statementFooter}>
                    <tr>
                        <td colSpan="3"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

OrganizationStatementList.propTypes = {
    organizationId: PropTypes.string,
};

export default OrganizationStatementList;
