import { API_BASE, request } from "../utils/httpRequest";

export const getRolAsync = async (id) => {
    return await request(API_BASE + "/api/developmentrol/" + id);
};

export const getRolListAsync = async () => {
    return await request(API_BASE + `/api/developmentrol`, {
        method: "GET",
    });
};
