import { useTranslation } from "react-i18next";
import style from "./ThirdSection.module.scss";
import TeamMember from "./TeamMember";

/* Team members */
import jackieImg from "../../../assets/team-members/Jackie.png";
import julioImg from "../../../assets/team-members/Julio.png";
import sample1Img from "../../../assets/team-members/sample-1.png";
import sample2Img from "../../../assets/team-members/sample-2.png";
import arrowRightIcon from "../../../assets/arrow-right-colored.svg";

const ReadMore = () => {
    const { t } = useTranslation();

    return (
        <div className={`${style.teamMoreInfo} d-flex flex-column justify-content-lg-between`}>
            <h1>{t("knowTheTeam")}</h1>
            <div className="d-flex justify-content-between pb-1 mt-3 mt-lg-auto">
                <span>{t("readMore")}</span>
                <img src={arrowRightIcon} alt="" />
            </div>
        </div>
    );
};

const ThirdSection = () => {
    const { t } = useTranslation();

    return (
        <div className={style.section}>
            <h1>{t("meetTheTeam2")}</h1>
            <div className="row justify-content-center justify-content-lg-between">
                <TeamMember name="Jacqueline Mermelstein" position="CEO" image={jackieImg} />
                <TeamMember name="Julio Ribas" position="CMO" image={julioImg} />
                <TeamMember name="Sample 1" position="HR Manager" image={sample1Img} />
            </div>
            <div className="row justify-content-center justify-content-lg-between">
                <TeamMember name="Sample 2" position="CTO" image={sample2Img} />
                <TeamMember name="Sample 1" position="HR Manager" image={sample1Img} />
                <ReadMore />
            </div>
        </div>
    );
};

export default ThirdSection;
