import { request, API_BASE } from "../utils/httpRequest";

export const loginAsync = async (credentials) => {
    return await request(API_BASE + "/api/authentication/authenticate", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
    });
};

export const signUpAsync = async (data) => {
    return await request(API_BASE + "/api/authentication/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const resetPasswordAsync = async (email) => {
    return await request(API_BASE + "/api/authentication/reset-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(email),
    });
};

export const changePasswordAsync = async (data) => {
    return await request(API_BASE + "/api/authentication/change-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const updatePasswordWhileLoggedAsync = async (data) => {
    return await request(API_BASE + "/api/authentication/update-password", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const adminUserRegisterAsync = (data) => {
    return request(`${API_BASE}/api/authentication/admin-register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const logoutAsync = async () => {
    return await request(API_BASE + "/api/authentication/logout", {
        method: "POST",
    });
};

export const confirmEmailAsync = async (userId, code) => {
    return await request(`${API_BASE}/api/authentication/confirm-email?userId=${userId}&code=${code}`);
};
