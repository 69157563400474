import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import style from "./Card.module.scss";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { DATE_FORMAT } from "../../../base/js/constants";
import colors from "../../../base/js/colors";
import ErrorMessages from "../../common/error-messages/ErrorMessages";
import useOnCardInputChange from "./hooks/UseOnCardInputChange";
import { HourSelectorOptions } from "../../../base/js/constants";
import Modal from "../../common/modal/Modal";
import ModalButton from "../../common/modal/buttons/ModalButton";
import { ModalButtonVariant } from "../../../base/js/constants";

const CURRENT_DATE = new Date();

const getSelectorStyle = (invalid = false) => ({
    control: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
        height: "40px",
        minHeight: "40px",
        boxShadow: "none",
        width: "135px",
        border: `${invalid || state.isFocused ? 2 : 1}px solid ${
            invalid ? colors.ERROR : state.isFocused ? colors.GRAY550 : colors.GRAY350
        }`,
        ":hover": {
            borderColor: invalid ? colors.ERROR : colors.GRAY550,
        },
    }),
    container: (provided, state) => ({
        ...provided,
        flexGrow: 1,
    }),
});

const validateState = (state) => ({
    ...state,
    errors: state.value ? [] : ["mandatoryField"],
    isValid: !!state.value,
});

const StartDateInput = forwardRef(({ value, onClick, startDate }, ref) => (
    <input
        ref={ref}
        id="input-start-date"
        type="text"
        className={`${sharedStyle.inputDate} ${startDate.isValid ? "" : sharedStyle.invalidField} px-3 py-2`}
        value={value}
        onClick={onClick}
        readOnly
    />
));

const EndDateInput = forwardRef(({ value, onClick, startDate, endDate }, ref) => (
    <input
        ref={ref}
        id="input-end-date"
        type="text"
        className={`${sharedStyle.inputDate} ${endDate.isValid ? "" : sharedStyle.invalidField} ${
            !startDate.value ? sharedStyle.disabledInput : ""
        } px-3 py-2`}
        value={value}
        onClick={onClick}
        disabled={!startDate.value}
        readOnly
    />
));

const cardDefaultState = {
    value: null,
    errors: [],
    isValid: true,
};

const Card = ({ resource }) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState({
        ...cardDefaultState,
        minValue: CURRENT_DATE,
        maxValue: addDays(CURRENT_DATE, 15),
    });
    const [endDate, setEndDate] = useState({
        ...cardDefaultState,
        minValue: CURRENT_DATE,
        maxValue: addDays(CURRENT_DATE, 15),
    });
    const [timeStart, setTimeStart] = useState({
        ...cardDefaultState,
        options: HourSelectorOptions.slice(0, HourSelectorOptions.length - 1),
    });
    const [timeEnd, setTimeEnd] = useState({
        ...cardDefaultState,
        options: HourSelectorOptions.slice(1, HourSelectorOptions.length),
    });

    const [contactHiring, setContactHiring] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const { onStartDateChange, onEndDateChange, onTimeStartChange, onTimeEndChange } = useOnCardInputChange(
        setStartDate,
        setEndDate,
        setTimeStart,
        setTimeEnd,
        HourSelectorOptions
    );

    const indicateErrors = () => {
        setStartDate((prevState) => validateState(prevState));
        setEndDate((prevState) => validateState(prevState));
        setTimeStart((prevState) => validateState(prevState));
        setTimeEnd((prevState) => validateState(prevState));
    };

    const onCheckBtnClick = (event) => {
        if (!(startDate.value && endDate.value && timeStart.value && timeEnd.value)) {
            indicateErrors();
            return;
        }

        setContactHiring(true);
        setShowDialog(true);
    };

    const toggleModal = () => {
        setShowDialog(false);
    };

    return (
        <>
            <div className={`${style.container} px-3 px-md-5 py-4`}>
                <span className={`${style.title} d-block`}>
                    <span className={style.resourceName}>
                        {resource.name} {resource.lastName}
                    </span>{" "}
                    {resource.developmentRol?.name} {resource.seniority?.name}
                </span>

                <div>
                    <div className="row mt-4">
                        <div className="col-11 col-md-5">
                            <span className="d-block mb-2">{t("startDate2")}:</span>
                            <div className="d-flex flex-column">
                                <DatePicker
                                    selected={startDate.value}
                                    onChange={onStartDateChange}
                                    customInput={<StartDateInput startDate={startDate} />}
                                    dateFormat={DATE_FORMAT}
                                    minDate={startDate.minValue}
                                    maxDate={startDate.maxValue}
                                    showPopperArrow={false}
                                />
                                {!startDate.isValid && <ErrorMessages errors={startDate.errors} />}
                            </div>
                        </div>

                        <div className="col-11 col-md-5 mt-3 mt-md-0">
                            <span className="d-block mb-2">{t("endDate2")}:</span>
                            <div className="d-flex flex-column">
                                <DatePicker
                                    selected={endDate.value}
                                    onChange={onEndDateChange}
                                    customInput={<EndDateInput startDate={startDate} endDate={endDate} />}
                                    dateFormat={DATE_FORMAT}
                                    minDate={endDate.minValue}
                                    maxDate={startDate.maxValue}
                                    showPopperArrow={false}
                                />
                                {!endDate.isValid && <ErrorMessages errors={endDate.errors} />}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-11 col-md-5">
                            <span className="d-block mb-2">{t("timeRange")}:</span>
                            <div className="d-flex align-items-center gap-2">
                                <span className={`${style.mutedText} align-self-start`}>{t("between")}</span>

                                <div className="d-flex flex-column flex-md-row gap-2">
                                    <div className="d-flex gap-2">
                                        <Select
                                            styles={getSelectorStyle(!timeStart.isValid)}
                                            options={timeStart.options}
                                            value={timeStart.value}
                                            onChange={onTimeStartChange}
                                        />

                                        <span className={style.mutedText}>{t("to")}</span>
                                    </div>

                                    <Select
                                        styles={getSelectorStyle(!timeEnd.isValid)}
                                        options={timeEnd.options}
                                        value={timeEnd.value}
                                        onChange={onTimeEndChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-11 col-md-5 d-flex mt-3 mt-md-0 align-items-end justify-content-start">
                            {!contactHiring ? (
                                <button
                                    type="button"
                                    className={`${style.btnCheck} px-2 px-md-4 py-2 py-md-0 w-100`}
                                    onClick={onCheckBtnClick}
                                >
                                    {t("checkAgendaHManager")}
                                </button>
                            ) : (
                                <div className="d-flex flex-column align-items-start">
                                    <span className="mb-2">{t("hiringManagerEmail")}:</span>
                                    <span className={style.hiringManagerEmail}>hiring.manager@example.com</span>
                                </div>
                            )}
                        </div>
                    </div>
                    {(!timeStart.isValid || !timeEnd.isValid) && (
                        <ErrorMessages errors={!timeStart.isValid ? timeStart.errors : timeEnd.errors} />
                    )}
                </div>
            </div>

            <Modal show={showDialog}>
                <span className={style.modalText}>{t("yourHiringManagerWillContactYouShortly")}</span>
                <div className="d-flex justify-content-center gap-4 mt-4">
                    <ModalButton variant={ModalButtonVariant.GREEN} onClick={toggleModal}>
                        {t("accept")}
                    </ModalButton>
                </div>
            </Modal>
        </>
    );
};

export default Card;
