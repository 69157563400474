import { useTranslation } from "react-i18next";
import style from "./PhaseIndicator.module.scss";
import checkIcon from "../../assets/icons/white-check.svg";

// "Enum" to identify the different phases.
export const phases = {
    LOGIN: "login",
    ASSEMBLE_YOUR_TEAM: "assemble-your-team",
    TEAM_INFO: "team-info",
    RESUME: "resume",
    CONFIRMATION: "confirmation",
};

// Array that keeps track of which order the phases should have.
export const phasesOrder = [
    phases.LOGIN,
    phases.ASSEMBLE_YOUR_TEAM,
    phases.TEAM_INFO,
    phases.RESUME,
    phases.CONFIRMATION,
];

// Possible variants of an indicator.
const indicatorStatus = {
    PREVIOUS_PHASE: "previous-phase",
    ACTIVE_PHASE: "active",
    NEXT_PHASE: "next-phase",
};

// Determines the variant of an indicator.
const getIndicatorStatus = (currentPosition, elementPhase) => {
    const elementPhasePosition = phasesOrder.indexOf(elementPhase);

    if (elementPhasePosition < currentPosition) {
        return indicatorStatus.PREVIOUS_PHASE;
    } else if (elementPhasePosition === currentPosition) {
        return indicatorStatus.ACTIVE_PHASE;
    } else {
        return indicatorStatus.NEXT_PHASE;
    }
};

const Indicator = ({ sectionNumber, children, phase = indicatorStatus.NEXT_PHASE }) => (
    <div className="d-flex align-items-center">
        <div
            className={`${style.indicatorNumberContainer} ${
                phase === indicatorStatus.NEXT_PHASE ? style.bgGray : style.bgColored
            } rounded-circle text-white d-flex justify-content-center align-items-center me-lg-2`}
        >
            {phase === indicatorStatus.PREVIOUS_PHASE ? (
                <img src={checkIcon} alt="" />
            ) : (
                <span className={style.indicatorNumber}>{sectionNumber}</span>
            )}
        </div>
        <span className={`${style.indicatorText} d-none d-lg-block`}>{children}</span>
    </div>
);

const PhaseIndicator = ({ activePhase }) => {
    const { t } = useTranslation();

    const activePhasePosition = phasesOrder.indexOf(activePhase);

    if (activePhasePosition === -1) {
        throw new Error(`Unrecognized phase "${activePhase}"`);
    }

    return (
        <div className="d-flex mb-4">
            <Indicator sectionNumber="1" phase={getIndicatorStatus(activePhasePosition, phases.LOGIN)}>
                {t("login")}
            </Indicator>

            <div className={`${style.line} mx-2`} />

            <Indicator sectionNumber="2" phase={getIndicatorStatus(activePhasePosition, phases.ASSEMBLE_YOUR_TEAM)}>
                {t("setup")}
            </Indicator>

            <div className={`${style.line} mx-2`} />

            <Indicator sectionNumber="3" phase={getIndicatorStatus(activePhasePosition, phases.TEAM_INFO)}>
                {t("team")}
            </Indicator>

            <div className={`${style.line} mx-2`} />

            <Indicator sectionNumber="4" phase={getIndicatorStatus(activePhasePosition, phases.RESUME)}>
                {t("review")}
            </Indicator>

            <div className={`${style.line} mx-2`} />

            <Indicator sectionNumber="5" phase={getIndicatorStatus(activePhasePosition, phases.CONFIRMATION)}>
                {t("confirmation")}
            </Indicator>
        </div>
    );
};

export default PhaseIndicator;
