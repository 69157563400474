import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MenuContainer from "./menu-container/MenuContainer";
import useClickOutside from "../../hooks/UseClickOutside";

import styles from "./Menu.module.scss";

const Menu = ({ languages, currentLang, onLangChange, isMobile, user }) => {
    const [isContainerOpen, setIsContainerOpen] = useState(false);
    const containerRef = useRef(null);

    const handleOutsideClick = (target) => {
        if (containerRef?.current === target || containerRef?.current.contains(target)) {
            return;
        }

        setIsContainerOpen(false);
    };

    useClickOutside(containerRef, handleOutsideClick);

    const handleBtnClick = () => {
        setIsContainerOpen((prevState) => !prevState);
    };

    const onItemClick = (event) => {
        setIsContainerOpen(false);
    };

    // Do not show the menu in the case that the user is not logged and we're not in
    // the desktop version
    // if (!user && !isMobile) {
    //     return null;
    // }

    return (
        <div ref={containerRef} className="ps-5">
            <button type="button" className={styles.container} onClick={handleBtnClick}>
                <FontAwesomeIcon icon={faBars} className={`${styles.icon} ${isContainerOpen ? styles.iconOpen : ""}`} />
            </button>
            {isContainerOpen && (
                <MenuContainer
                    user={user}
                    languages={languages}
                    currentLang={currentLang}
                    onLangChange={onLangChange}
                    isMobile={isMobile}
                    onItemClick={onItemClick}
                />
            )}
        </div>
    );
};

export default Menu;
