import Joi from "joi";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../../base/js/constants";
import Label from "../../../common/custom-label/Label";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import style from "./HoursValidationModal.module.scss";

const BASE_SCHEMA = {
    hours: Joi.number().empty("").required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalid": "invalidTaxIDFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const HoursValidationModal = ({ show, data, onAccept, onCancel }) => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, resetErrors, errors } = useJoiValidation();
    const [hours, setHours] = useState(data ? data.hours : "");

    const onHoursChange = (e) => {
        e.preventDefault();
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
        setHours(e.target.value);
    };

    const handleOnAccept = (e) => {
        e.preventDefault();
        const hoursToSend = hours;
        setHours("");

        onAccept({ ...data, hours: hoursToSend });
    };

    const handleOnCancel = (e) => {
        e.preventDefault();
        resetErrors();
        setHours("");

        onCancel();
    };

    return (
        <Modal show={show}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("editingHours")}</span>

            <form className="mt-3">
                <div>
                    <span className={style.subTitle}>{`${t("staff")}: `}</span>
                    <span>{data ? data.staffName : ""}</span>
                </div>
                <div>
                    <span className={style.subTitle}>{`${t("status")}: `}</span>
                    <span>{data ? data.status : ""}</span>
                </div>

                <div className="mt-4">
                    <Label boldLabel htmlFor="hours" requiredIndicator>
                        {t("hours")}
                    </Label>
                    <input
                        type="number"
                        id="hours"
                        name="hours"
                        className={`${sharedStyle.inputText} ${
                            !errors.hours ? "" : sharedStyle.invalidField
                        } px-3 py-2`}
                        value={hours}
                        onChange={onHoursChange}
                    />
                    {errors.hours && (
                        <div className="mt-1">
                            <p className={sharedStyle.errorMsg}>{errors.hours.message}</p>
                        </div>
                    )}
                </div>
            </form>

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={handleOnAccept}>
                    {t("accept")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={handleOnCancel}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

HoursValidationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default HoursValidationModal;
