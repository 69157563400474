import { useState } from "react";
import style from "./Guide.module.scss";
import GuideMenu from "./guide-menu/GuideMenu";
import GuideContent from "./guide-content/GuideContent";

const Guide = ({ items }) => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);

    const onMenuChange = ({ itemIndex }) => {
        if (itemIndex === currentItemIndex) return;
        setCurrentItemIndex(itemIndex);
    };

    return (
        <div className={`${style.container} d-flex justify-content-between w-100`}>
            <GuideMenu items={items} onChange={onMenuChange} selectedItemIndex={currentItemIndex} />
            <GuideContent item={items[currentItemIndex]} />
        </div>
    );
};

export default Guide;
