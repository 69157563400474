import { faGripLines, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import colors from "../../base/js/colors";
import { FORMATTER, ModalButtonVariant, PositionStaffStatus, TAM_LABEL } from "../../base/js/constants";
import { default as Routes, default as RoutesPath } from "../../base/js/routes";
import { getProjectAsync, setTeamConfirmed } from "../../services/ProjectService";
import { useForceLogout } from "../../utils/useForceLogout";
import Button, { variants as btnVariants } from "../common/Button";
import ModalButton from "../common/modal/buttons/ModalButton";
import Modal from "../common/modal/Modal";
import Loading from "../loading/Loading";
import sharedStyles from "../shared-styles/FormStyle.module.scss";
import styles from "./AcceptAndBuy.module.scss";

const TAM_POSITION = {
    id: -1,
    role: TAM_LABEL,
    seniority: "Senior",
    skills: "",
    rate: 0,
    selected: true,
    order: null,
    positionStaffs: [
        {
            status: PositionStaffStatus.ASSIGNED,
            staff: {
                name: "-",
                developmentRols: [{ name: TAM_LABEL }],
                seniority: { name: "Senior" },
                technologies: [],
                monthPrice: 0,
            },
        },
    ],
};

const AcceptAndBuy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showHiringManagerEmail, setShowHiringManagerEmail] = useState(false);
    const [headers] = useState(["resource", "role", "seniority", "jobskills", "monthPrice2"]);
    const [project, setProject] = useState({});
    const [totalResources, setTotalResources] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const forceLogout = useForceLogout();

    // Fetch project information from the API
    useEffect(() => {
        (async () => {
            try {
                const projectId = params.projectId;

                if (!projectId) {
                    toast.error("Project ID not found");
                    navigate(Routes.LETS_START);

                    return;
                }

                setLoading(true);

                const response = await getProjectAsync(projectId, false);

                setLoading(false);

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        const errorTitle = await response.json()?.title;
                        toast.error(t("unexpectedError") + ": " + errorTitle);
                    }

                    return;
                }

                const data = await response.json();

                const mappedData = {
                    ...data,
                    team: [
                        TAM_POSITION,
                        ...data.positions.filter(
                            (position) =>
                                position.positionStaffs.length > 0 &&
                                position.positionStaffs[0].status === PositionStaffStatus.ASSIGNED
                        ),
                    ],
                };

                setProject(mappedData);
            } catch (error) {
                toast.error(t("unexpectedError") + ": " + error.message);
            }
        })();
    }, []);

    // Calculate the final price
    useEffect(() => {
        let positionsSelected = 0;
        let finalPrice = 0;

        project.team?.forEach((position) => {
            if (
                position.positionStaffs.length > 0 &&
                position.positionStaffs[0].status === PositionStaffStatus.ASSIGNED
            ) {
                positionsSelected++;
                // TODO Add the month price of the resource
                finalPrice += position?.rate ? position.rate * (position.partTime ? 80 : 160) : 0;
            }
        });

        setTotalResources(positionsSelected);
        setTotalPrice(finalPrice);
    }, [project.team]);

    const isAllStaffRejected = useMemo(() => {
        if (!project.team) {
            return false;
        }

        const rejectedStaff = project.team?.filter(
            (position) => position.positionStaffs && position.positionStaffs[0].status === PositionStaffStatus.REJECTED
        );

        return rejectedStaff.length === project.team.length - 1;
    }, [project.team]);

    const handleChangeSelectedResource = (event) => {
        const newTeam = project.team?.map((position) => {
            if (position.id === event.target.id) {
                return {
                    ...position,
                    positionStaffs: [
                        {
                            staff: position.positionStaffs[0].staff,
                            status: event.target.checked ? PositionStaffStatus.ASSIGNED : PositionStaffStatus.REJECTED,
                        },
                    ],
                };
            }

            return position;
        });

        const assignedPositions = newTeam.filter(
            (position) => position.positionStaffs[0].status === PositionStaffStatus.ASSIGNED
        );
        const remainingPositions = newTeam.filter(
            (position) => position.positionStaffs[0].status !== PositionStaffStatus.ASSIGNED
        );

        // Set the assigned positions first
        setProject({ ...project, team: [...assignedPositions, ...remainingPositions] });
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleClickTalkToHiringManager = () => {
        setShowHiringManagerEmail(true);
        toggleModal();
    };

    const handleClickAcceptTeamAndBuy = async () => {
        try {
            const rejectedStaff = project.team?.filter(
                (position) =>
                    position.positionStaffs && position.positionStaffs[0].status === PositionStaffStatus.REJECTED
            );

            if (rejectedStaff.length === project.team.length - 1) {
                toast.error(t("unableToContinueWithoutAssignations"));
                return;
            }

            const rejectedStaffMapped = rejectedStaff.map((position) => ({
                positionId: position.id,
                staffId: position.positionStaffs[0].staff?.id,
            }));

            const response = await setTeamConfirmed(project.id, rejectedStaffMapped);

            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                } else {
                    const errorTitle = await response.json()?.title;
                    toast.error(t("unexpectedError") + ": " + errorTitle);
                }

                return;
            }

            toast.success(t("teamConfirmedMessage"));

            const url = RoutesPath.buildInDevelopmentPath(params.projectId);
            navigate(url);
        } catch (error) {
            toast.error(t("unexpectedError") + ": " + error.message);
        }
    };

    const handleClickAddResource = () => {
        if (!project.id) {
            return;
        }

        navigate(`${Routes.BUILD_YOUR_TEAM}?from=${project.id}`);
    };

    const handleClickBack = () => {
        navigate(Routes.buildTeamInfoResumePath(project.id));
    };

    const setTeamOrder = (team) => {
        const newTeamOrder = team.map((position, index) => {
            return { ...position, order: index + 1 };
        });

        setProject({ ...project, team: newTeamOrder });
    };

    return (
        <>
            <main className={`${styles.mainContainer} mx-auto`}>
                <section>
                    <h1 className={`${styles.title} mb-4`}>{t("addResourcesToDeal")}</h1>

                    <div className={styles.tableContainer}>
                        <table className="text-left col-12">
                            <thead>
                                <tr>
                                    <th></th>
                                    {headers.map((head, index) => {
                                        return <th key={index}>{t(head)}</th>;
                                    })}
                                </tr>
                            </thead>

                            {/*<ReactSortable*/}
                            {/*    handle={`.${styles.orderHandle}`}*/}
                            {/*    tag="tbody"*/}
                            {/*    list={project.team ? project.team : []}*/}
                            {/*    setList={setTeamOrder}*/}
                            {/*>*/}
                            <tbody>
                                {project.team?.map((position) => {
                                    if (!position.positionStaffs || position.positionStaffs.length === 0) {
                                        return null;
                                    }

                                    const staff = position.positionStaffs[position.positionStaffs.length - 1].staff;
                                    const positionStaff = position.positionStaffs[position.positionStaffs.length - 1];
                                    const isTam =
                                        staff.developmentRols.length === 1 &&
                                        staff.developmentRols[0].name === TAM_LABEL;

                                    return (
                                        <tr key={position.id} className="">
                                            <td className="d-flex pt-3">
                                                {/*<div className={`${styles.orderHandle} order_handle d-flex pt-1`}>*/}
                                                {/*    <FontAwesomeIcon icon={faGripLines} className="me-2" />*/}
                                                {/*</div>*/}
                                                <div>
                                                    <Form.Check
                                                        id={position.id}
                                                        type="switch"
                                                        checked={positionStaff.status === PositionStaffStatus.ASSIGNED}
                                                        className={`${sharedStyles.customSwitch}`}
                                                        onChange={handleChangeSelectedResource}
                                                        disabled={isTam}
                                                    />
                                                </div>
                                            </td>
                                            <td>{!isTam ? `${staff.name} ${staff.lastName[0]}.` : staff.name}</td>
                                            <td>{staff.developmentRols.map((r) => r.name).join(", ")}</td>
                                            <td>{staff.seniority.name}</td>
                                            <td>{staff.technologies.map((t) => t.name).join(", ")}</td>
                                            <td>
                                                {FORMATTER.format(
                                                    position?.rate ? position.rate * (position.partTime ? 80 : 160) : 0
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            {/*</ReactSortable>*/}
                        </table>
                    </div>

                    <div className="ps-4 py-4">
                        <button className={styles.addResource} onClick={handleClickAddResource}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" color={colors.COLOR_ACTION} />
                            {t("addResource2")}
                        </button>
                    </div>
                    <div
                        className={`${styles.rowTotals} py-4 d-flex flex-column flex-md-row align-items-center justify-content-md-end gap-md-0`}
                    >
                        <div className={styles.totalStatsContainer}>
                            <span className="pe-2">{t("totalResources")}:</span>
                            <span>{totalResources}</span>
                        </div>

                        <div className={`${styles.totalStatsContainer} d-none d-md-block`}>
                            <span className="pe-2">{t("totalValue")}:</span>
                            <span>{FORMATTER.format(totalPrice)}</span>
                        </div>
                    </div>

                    <div className="d-flex gap-3 py-5 justify-content-center justify-content-md-between">
                        <Button
                            onClick={handleClickBack}
                            variant={btnVariants.PRIMARY_INVERSE}
                            cssClasses={["fw-normal"]}
                        >
                            {t("back")}
                        </Button>

                        <Button
                            onClick={handleClickAcceptTeamAndBuy}
                            cssClasses={["fw-normal"]}
                            disabled={isAllStaffRejected}
                        >
                            <Trans components={{ span: <span className="fw-bold" /> }}>acceptAndPurchase</Trans>
                        </Button>
                    </div>

                    <div
                        className={`${styles.tailorMadeProposal} d-flex flex-column flex-md-row gap-3 gap-md-5 justify-content-center align-items-center py-4 mb-5`}
                    >
                        <span>{t("ifYouNeedATailorMadeProposal")}</span>
                        {showHiringManagerEmail && project.hiringManager ? (
                            <a className={styles.hiringManagerEmail} href={`mailto:${project.hiringManager.email}`}>
                                {project.hiringManager.email}
                            </a>
                        ) : (
                            <button
                                className={`${styles.btn} ${styles.btnTalkToManager}`}
                                onClick={handleClickTalkToHiringManager}
                            >
                                {t("talkToHiringManager")}
                            </button>
                        )}

                        <Modal show={showModal}>
                            <div>
                                <h1 className={`${styles.modal} ${styles.modalTitle}`}>{t("tailorMadeProposal")}</h1>
                                <span className={`${styles.modal} ${styles.modalMessage}`}>
                                    {t("yourHiringManagerWillContactYouShortly")}
                                </span>
                            </div>

                            <div className={`d-flex justify-content-center gap-4 mt-4`}>
                                <ModalButton variant={ModalButtonVariant.GREEN} onClick={toggleModal}>
                                    {t("accept")}
                                </ModalButton>
                            </div>
                        </Modal>
                    </div>
                </section>
            </main>

            {loading && <Loading />}
        </>
    );
};

export default AcceptAndBuy;
