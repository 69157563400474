import { request, API_BASE } from "../utils/httpRequest";

export const addReviewAsync = async (data) => {
    return await request(API_BASE + "/api/review", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const editReviewAsync = async (data) => {
    return await request(API_BASE + "/api/review", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getReviewAsync = async (reviewId) => {
    return await request(`${API_BASE}/api/review/${reviewId}`);
};

export const getReviewListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        `${API_BASE}/api/review?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`
    );
};

export const getReviewListByProjectAsync = async (projectId, page = 1, pageSize = 5, searchText = null) => {
    return await request(
        `${API_BASE}/api/review/${projectId}/get-review-by-project-id?page=${page}&pageSize=${pageSize}${
            searchText ? "&searchText=" + searchText : ""
        }`
    );
};

export const removeReview = async (id) => {
    return await request(`${API_BASE}/api/review/${id}`, {
        method: "DELETE",
    });
};
