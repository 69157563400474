import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import styles from "./TariffDetails.module.scss";
import { useTranslation } from "react-i18next";

const TariffDetails = ({ roles, seniorities, tariff, setRole, setSeniority, setRate }) => {
    const { t } = useTranslation();

    return (
        <table className={`${styles.tariffDetailsTable} m-3`}>
            <thead>
                <tr>
                    <td>{t("role")}</td>
                    <td>{t("seniority")}</td>
                    <td>{t("rate")}</td>
                </tr>
            </thead>
            <tbody>
                {roles?.map((role) => {
                    return seniorities?.map((seniority, index) => {
                        const tariffExist = tariff.find((t) => {
                            return t.developmentRoleId == role.value && t.seniorityId == seniority.value;
                        });
                        const selectRow = () => {
                            setRole(role);
                            setSeniority(seniority);
                            if (tariffExist && tariffExist?.rate != null) {
                                setRate(tariffExist.rate);
                            } else {
                                setRate("");
                            }
                        };

                        return index == 0 ? (
                            <tr key={uuidv4()} className={index % 2 == 0 ? styles.rowspanOdd : styles.rowspanEven}>
                                <th rowSpan={seniorities.length} scope="rowgroup">
                                    {role.label}
                                </th>
                                <th scope="row" className={styles.cursorPointer} onClick={selectRow}>
                                    {seniority.label}
                                </th>
                                <td className={styles.cursorPointer} onClick={selectRow}>
                                    {tariffExist ? tariffExist.rate : 0}
                                </td>
                            </tr>
                        ) : (
                            <tr key={uuidv4()}>
                                <th scope="row" className={styles.cursorPointer} onClick={selectRow}>
                                    {seniority.label}
                                </th>
                                <td className={styles.cursorPointer} onClick={selectRow}>
                                    {tariffExist ? tariffExist.rate : 0}
                                </td>
                            </tr>
                        );
                    });
                })}
            </tbody>
        </table>
    );
};

TariffDetails.propTypes = {
    roles: PropTypes.array.isRequired,
    seniorities: PropTypes.array.isRequired,
    tariff: PropTypes.array.isRequired,
    setName: PropTypes.func,
    setRole: PropTypes.func,
    setSeniority: PropTypes.func,
    setRate: PropTypes.func,
};
export default TariffDetails;
