import { useDispatch } from "react-redux";
import { setEmail, setPendingSurvey, setRoles, setUsername } from "../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Routes from "../base/js/routes";
import { logoutAsync } from "../services/AuthService";

export const useForceLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return async () => {
        dispatch(setUsername(""));
        dispatch(setEmail(""));
        dispatch(setRoles(""));
        dispatch(setPendingSurvey(""));

        const response = await logoutAsync();
        if (!response.ok) {
            console.error("Error when trying to request logout");
        }

        navigate(Routes.LOGIN);
    };
};
