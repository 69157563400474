import { API_BASE, request } from "../utils/httpRequest";

export const addOrganizationAsync = async (data) => {
    return await request(`${API_BASE}/api/organization`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const editOrganizationAsync = async (id, data) => {
    return await request(`${API_BASE}/api/organization/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const getOrganizationAsync = async (id) => {
    return await request(API_BASE + "/api/organization/" + id);
};

export const getOrganizationListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        API_BASE +
            `/api/organization?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
        {
            method: "GET",
        }
    );
};

export const getAllOrganizationListAsync = async () => {
    return await request(API_BASE + `/api/organization/all`, {
        method: "GET",
    });
};

export const getUserOrganization = async () => {
    return await request(`${API_BASE}/api/organization/user-organization`);
};

export const updateUserOrganization = async (newOrganizationData) => {
    return await request(`${API_BASE}/api/organization/user-organization`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newOrganizationData),
    });
};
