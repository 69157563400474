const colors = {
    PRIMARY_COLOR: "#b65936",
    WHITE: "#fff",
    BLACK: "#333",
    GRAY: "#444",
    GRAY100: "#f6f6f6",
    GRAY200: "#efefef",
    GRAY300: "#eee",
    GRAY350: "#e2e2e2",
    GRAY400: "#ddd",
    GRAY450: "#d1d1d1",
    GRAY500: "#d1cdcd",
    GRAY550: "#a2a2a2",
    GRAY600: "#b1b3b3",
    GRAY650: "#9e9e9e",
    GRAY700: "#9b9b9b",
    GRAY800: "#8f8f8f",
    GRAY850: "#868686",
    GRAY900: "#6e6b6b",
    BLUE: "#4062dd",
    BLUE100: "#337ab7",
    BLUE200: "#0d99ff",
    ORANGE: "#f09304",
    ORANGE100: "#f6dfa3",
    ORANGE200: "#f2b033",
    ERROR: "rgb(197, 0, 0)",
    GREEN: "#5cb85c",

    // New colors
    COLOR_PRIMARY: "#ff9900",
    COLOR_ACTION: "#0d99ff",
    COLOR_DANGER: "rgb(197, 0, 0)",
    COLOR_SUCCESS: "#5cb85c",
    COLOR_SUCCESS_100: "#f9fff9",
    COLOR_TEXT: "#333333",
    COLOR_BACKGROUND: "#f6f6f6",
    COLOR_BACKGROUND_100: "#f5f5f5",
    COLOR_BACKGROUND_200: "#eeeeee",
    COLOR_BACKGROUND_300: "#d1d1d1",
    COLOR_BACKGROUND_400: "#d3d3d3",
    COLOR_BORDER: "#c4c4c4",
};

export default colors;
