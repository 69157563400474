import style from "./Button.module.scss";

export const variants = {
    PRIMARY: "primary",
    PRIMARY_INVERSE: "primary_inverse",
    SECONDARY: "secondary",
    LIGHT_BROWN: "light_brown",
};

export const textVariants = {
    LIGHT: "tdb-text-light",
    BROWN: "primary_color",
    DARK: "tdb-text-dark",
};

const Button = ({
    id = null,
    type = "button",
    children,
    variant = variants.PRIMARY,
    textVariant = textVariants.LIGHT,
    cssClasses = [],
    onClick = null,
    disabled = false,
}) => {
    let btnVariant;

    switch (variant) {
        case variants.PRIMARY:
            btnVariant = "filled-btn";
            break;
        case variants.PRIMARY_INVERSE:
            btnVariant = "inverse-btn";
            break;
        case variants.SECONDARY:
            btnVariant = "filled-gray-btn";
            break;
        case variants.LIGHT_BROWN:
            btnVariant = "filled-brown-btn";
            break;
        default:
            throw new Error(`Unrecognized variant '${variant}'`);
    }

    switch (textVariant) {
        case textVariants.LIGHT:
            break;
        case textVariants.DARK:
            break;
        case textVariants.BROWN:
            break;
        default:
            throw new Error(`Unrecognized text variant '${textVariant}'`);
    }

    const disabledStyle = disabled ? style.disabled : "";

    const onBtnClick = (event) => {
        if (!onClick) return;
        onClick(event);
    };

    return (
        <button
            id={id}
            className={[...cssClasses, btnVariant, textVariant, disabledStyle].join(" ")}
            type={type}
            onClick={onBtnClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
