export const responseErrorFormat = (response) => {
    return (
        <div>
            <span>{response.title}</span>
            {response.traceId && (
                <div>
                    <p>Please contact to the administrator:</p>
                    <p>TraceId: response.traceId</p>
                </div>
            )}
        </div>
    );
};
