import { useDrop } from "react-dnd";
import DraggablePill from "../skills-pills/DraggablePill";

const AvailableSkillsContainer = ({ skills, onPillClick, onDrop }) => {
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "pill",
            drop: (item) => onDrop(item),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [skills]
    );

    return (
        <div ref={drop} className="mt-3 mb-2 p-2 d-flex flex-wrap gap-2 justify-content-center">
            {skills?.map((skill) => (
                <DraggablePill key={skill.key} onClick={onPillClick} skill={skill} />
            ))}
        </div>
    );
};

export default AvailableSkillsContainer;
