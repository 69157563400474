import style from "./FifthSection.module.scss";
import Carousel from "./Carousel";
import { useTranslation } from "react-i18next";

const FifthSection = () => {
    const { t } = useTranslation();

    return (
        <div className={style.section}>
            <h1 className="text-lg-center">{t("whatClientsSay")}</h1>
            <Carousel />
        </div>
    );
};

export default FifthSection;
