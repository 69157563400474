import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../../base/js/constants";
import Joi from "joi";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import Modal from "../../../common/modal/Modal";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Label from "../../../common/custom-label/Label";
import PropTypes from "prop-types";

import style from "./ConfirmTariffReadjustModal.module.scss";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";

const BASE_SCHEMA = {
    percentage: Joi.number().empty("").required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};
const ConfirmUserStatusModal = ({ show, onAccept, onCancel }) => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [percentage, setPercentage] = useState("");
    const [isConfirming, setIsConfirming] = useState(false);

    const handlePercentageChange = (e) => {
        e.preventDefault();
        setPercentage(e.target.value);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleAcceptClick = () => {
        const formData = {
            percentage: percentage,
        };
        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);
        if (!!cantErrors) {
            return;
        }

        if (isConfirming) {
            onAccept(percentage);
            setPercentage("");
            setIsConfirming(false);
        } else {
            setIsConfirming(true);
        }
    };

    const handleCancelClick = () => {
        setPercentage("");
        setIsConfirming(false);
        onCancel();
    };

    return (
        <Modal show={show}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("readjustTariff")}</span>

            {!isConfirming && (
                <div className={style.fieldContainer}>
                    <Label htmlFor="percentage" requiredIndicator>
                        {t("percentage")}
                    </Label>
                    <input
                        type="number"
                        id="percentage"
                        name="percentage"
                        className={`${sharedStyle.inputText} ${
                            !errors.percentage ? "" : sharedStyle.invalidField
                        } px-3 py-2`}
                        value={percentage}
                        onChange={handlePercentageChange}
                    />
                    {errors.percentage && (
                        <div className="mt-1">
                            <p className={sharedStyle.errorMsg}>{errors.percentage.message}</p>
                        </div>
                    )}
                </div>
            )}

            {isConfirming && <span>{t("canNotBeRevertedMessage")}</span>}

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={handleAcceptClick}>
                    {t("accept")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={handleCancelClick}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

ConfirmUserStatusModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmUserStatusModal;
