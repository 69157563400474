import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import style from "./DropSkillsContainer.module.scss";
import sharedStyle from "../../../../shared-styles/FormStyle.module.scss";
import DraggablePill from "../skills-pills/DraggablePill";
import { PillVariants, ContainerVariants } from "../../constants";

const DropSkillsContainer = ({
    variant,
    title,
    skills,
    onPillClick,
    onDrop,
    inputState,
    onInputEnter,
    onInputChange,
    inputId,
    invalid = false,
}) => {
    const { t } = useTranslation();

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "pill",
            drop: (item) => onDrop(item),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [skills]
    );

    const dropContainerStyle = invalid
        ? `${style.dropContainer} ${sharedStyle.invalidField} d-flex flex-wrap gap-2 p-2`
        : `${style.dropContainer} ${
              variant === ContainerVariants.GREEN ? style.greenBorder1 : style.orangeBorder1
          } d-flex flex-wrap gap-2 p-2`;

    return (
        <div className={`position-relative d-flex flex-column`}>
            <div>
                <div
                    className={`${style.titleContainer} ${
                        variant === ContainerVariants.GREEN
                            ? style.greenHeader + " " + style.greenBorder2
                            : style.orangeHeader + " " + style.orangeBorder2
                    } d-flex justify-content-center py-2 mb-1`}
                >
                    <span className={`${style.title} text-uppercase`}>{title}</span>
                </div>
                <div ref={drop} className={dropContainerStyle}>
                    {skills?.map((skill) => (
                        <DraggablePill
                            key={skill.key}
                            skill={skill}
                            variant={variant === ContainerVariants.GREEN ? PillVariants.GREEN : PillVariants.ORANGE}
                            onClick={onPillClick}
                            selected
                        />
                    ))}
                </div>
            </div>
            <input
                id={inputId}
                className={`${style.customSkill} mt-2 px-3`}
                placeholder={`+ ${t("addCustom")}`}
                value={inputState}
                onKeyDown={onInputEnter}
                onChange={onInputChange}
                autoComplete="off"
            />
            <span className={`${style.dropHere} position-absolute`}>{t("dropHere")}</span>
        </div>
    );
};

export default DropSkillsContainer;
