import { useEffect, useMemo } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import { ROLES } from "./base/js/constants";
import RoutesPath from "./base/js/routes";
import About from "./components/about/About";
import CompanyInfo from "./components/accept-and-buy-company-info/company-info/CompanyInfo";
import AcceptAndBuy from "./components/accept-and-buy/AcceptAndBuy";
import Admin from "./components/admin/Admin";
import AllocationResource from "./components/admin/admin-allocation-resource/AllocationResource";
import AdminValidationHours from "./components/admin/admin-hour-validation/AdminHoursValidation";
import AdminInterview from "./components/admin/admin-interviews/AdminInterview";
import AdminOrganization from "./components/admin/admin-organization/AdminOrganization";
import AdminPayments from "./components/admin/admin-payments/AdminPayments";
import AdminProjectTracing from "./components/admin/admin-project-tracing/AdminProjectTracing";
import AdminProjects from "./components/admin/admin-projects/AdminProjects";
import AdminResources from "./components/admin/admin-resources/AdminResources";
import AdminReviews from "./components/admin/admin-reviews/AdminReviews";
import AdminSettings from "./components/admin/admin-settings/AdminSettings";
import AdminSurveys from "./components/admin/admin-surveys/AdminSurveys";
import AdminTariffs from "./components/admin/admin-tariffs/AdminTariffs";
import AdminTechnologies from "./components/admin/admin-technologies/AdminTechnologies";
import AdminUsers from "./components/admin/admin-users/AdminUsers";
import PrivateRoutes from "./components/auth/PrivateRoutes";
import BuildYourTeam from "./components/build-your-team/BuildYourTeam";
import ChangePassword from "./components/change-password/ChangePassword";
import Contact from "./components/contact/Contact";
import DevelopmentTimeline from "./components/development-timeline/DevelopmentTimeline";
import Faq from "./components/faq/Faq";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import HomeResume from "./components/home-resume/HomeResume";
import Home from "./components/home/Home";
import useScrollToTop from "./components/hooks/UseScrollToTop";
import HowToStart from "./components/how-to-start/HowToStart";
import InvoiceList from "./components/invoice-list/InvoiceList";
import LetStart from "./components/let-start/LetStart";
import LoginSignup, { componentType } from "./components/login-signup/LoginSignup";
import ManageTeams from "./components/manage-teams/ManageTeams";
import MeetTheTeam from "./components/meet-the-team/MeetTheTeam";
import NotFound from "./components/not-found/NotFound";
import PaymentProofUploader from "./components/payment-proof-uploader/PaymentProofUploader";
import ProfileInformation from "./components/profile-information/ProfileInformation";
import ProjectDevelopment from "./components/project-development/ProjectDevelopment";
import RequestInterview from "./components/request-interview/RequestInterview";
import ResetPassword from "./components/reset-password/ResetPassword";
import FormReview from "./components/reviews/form-review/FormReview";
import Reviews from "./components/reviews/Reviews";
import StaffList from "./components/staff-list/StaffList";
import SurveyChecker from "./components/survey-checker/SurveyChecker";
import Survey from "./components/survey/Survey";
import TeamInfoResume from "./components/team-info-resume/TeamInfoResume";
import UserProfile from "./components/user-profile/UserProfile";
import ValidateHours from "./components/validate-hours/ValidateHours";
import { getUserContextualInfo } from "./services/UserService";
import { setPendingSurvey } from "./store/slices/userSlice";
import { useForceLogout } from "./utils/useForceLogout";

function App() {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const userLogged = useMemo(() => !!user.email && !!user.username, [user]);
    const forceLogout = useForceLogout();

    const languages = {
        en: {
            name: t("english"),
        },
        es: {
            name: t("spanish"),
        },
    };

    useEffect(() => {
        if (!userLogged) return;

        (async () => {
            try {
                const response = await getUserContextualInfo();
                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                    } else {
                        const errorTitle = await response.json()?.title;
                        toast.error(t("unexpectedError") + ": " + errorTitle);
                    }

                    return;
                }

                const data = await response.json();

                dispatch(setPendingSurvey(data.surveyNeeded));
            } catch (error) {
                toast.error(t("unexpectedError") + ": " + error.message);
            }
        })();
    }, [userLogged]);

    useScrollToTop(location);

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#fff",
                        color: "#363636",
                    },

                    // Default options for specific types
                    success: {
                        duration: 5000,
                        theme: {
                            primary: "green",
                            secondary: "black",
                        },
                    },
                }}
            />
            <Header languages={languages} />

            <Routes>
                <Route element={<SurveyChecker />}>
                    <Route
                        path={RoutesPath.HOME}
                        element={userLogged ? <HomeResume /> : <Navigate to={RoutesPath.LOGIN} replace={true} />}
                        exact
                    />
                    <Route
                        path={RoutesPath.RESET_PASSWORD}
                        element={
                            !userLogged ? <ResetPassword /> : <Navigate to={RoutesPath.LETS_START} replace={true} />
                        }
                        exact
                    />
                    <Route
                        path={RoutesPath.CHANGE_PASSWORD}
                        element={
                            !userLogged ? <ChangePassword /> : <Navigate to={RoutesPath.LETS_START} replace={true} />
                        }
                        exact
                    />
                    <Route path={RoutesPath.ABOUT} element={<About />} />
                    <Route path={RoutesPath.MEET_THE_TEAM} element={<MeetTheTeam />} />
                    <Route path={RoutesPath.CONTACT} element={<Contact />} />
                    <Route path={RoutesPath.LETS_START} element={<LetStart />} />
                    <Route
                        element={
                            <PrivateRoutes
                                rolesNeeded={[ROLES.USER, ROLES.ADMIN, ROLES.HIRING_MANAGER]}
                                redirectTo={RoutesPath.LOGIN}
                            />
                        }
                    >
                        <Route path={RoutesPath.BUILD_YOUR_TEAM} element={<BuildYourTeam />} />
                    </Route>
                    <Route
                        path={RoutesPath.LOGIN}
                        element={
                            userLogged ? (
                                <Navigate to={RoutesPath.HOME} replace={true} />
                            ) : (
                                <LoginSignup type={componentType.LOGIN} />
                            )
                        }
                    />
                    <Route
                        path={RoutesPath.SIGNUP}
                        element={
                            userLogged ? (
                                <Navigate to={RoutesPath.LETS_START} replace={true} />
                            ) : (
                                <LoginSignup type={componentType.SIGNUP} />
                            )
                        }
                    />
                    <Route path={RoutesPath.FAQ} element={<Faq />} />
                    <Route path={RoutesPath.HOW_TO_START} element={<HowToStart />} />
                    <Route path={RoutesPath.SURVEY} element={<Survey />} />
                    <Route element={<PrivateRoutes rolesNeeded={[ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.USER]} />}>
                        <Route path={RoutesPath.USER_PROFILE} element={<UserProfile />} />
                        <Route path="/profile/:id" element={<ProfileInformation />} />
                        <Route path={RoutesPath.TEAM_INFO_RESUME} element={<TeamInfoResume />} />
                        <Route path={RoutesPath.REQUEST_INTERVIEW} element={<RequestInterview />} />
                        <Route path={RoutesPath.MANAGE_TEAMS} element={<ManageTeams />} />
                        <Route path={RoutesPath.ACCEPT_TEAM_AND_BUY} element={<AcceptAndBuy />} />
                        {/*<Route path={RoutesPath.CHECKOUT} element={<CompanyInfo />} />*/}
                        {/*<Route path={RoutesPath.PAYMENT_PROOF_UPLOADER} element={<PaymentProofUploader />} />*/}
                        <Route path={RoutesPath.PROJECT_TIMELINE} element={<DevelopmentTimeline />} />
                        <Route path={RoutesPath.IN_DEVELOPMENT} element={<ProjectDevelopment />} />
                        <Route path={RoutesPath.REVIEWS} element={<Reviews />} />
                        <Route path={RoutesPath.CREATE_REVIEW} element={<FormReview />} />
                        <Route path={RoutesPath.EDIT_REVIEW} element={<FormReview create={false} />} />
                        <Route path={RoutesPath.STAFF_LIST} element={<StaffList />} />
                        <Route path={RoutesPath.INVOICE_LIST} element={<InvoiceList />} />
                        <Route path={RoutesPath.VALIDATE_HOURS} element={<ValidateHours />} />
                    </Route>
                    <Route element={<PrivateRoutes rolesNeeded={[ROLES.ADMIN, ROLES.HIRING_MANAGER]} />}>
                        <Route path={`${RoutesPath.ADMIN}/*`} element={<Admin />}>
                            <Route path={RoutesPath.ADMIN_INTERVIEWS} element={<AdminInterview />} />
                            <Route path={RoutesPath.ADMIN_PROJECTS} element={<AdminProjects />} />
                            <Route
                                path={RoutesPath.ADMIN_PROJECT_ALLOCATION_RESOURCES}
                                element={<AllocationResource />}
                            />
                            <Route path={RoutesPath.ADMIN_PROJECT_TRACING} element={<AdminProjectTracing />} />
                            <Route path={`${RoutesPath.ADMIN_SETTINGS}/*`} element={<AdminSettings />}>
                                <Route path={RoutesPath.ADMIN_SETTINGS_RESOURCES} element={<AdminResources />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_TECHNOLOGIES} element={<AdminTechnologies />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_USERS} element={<AdminUsers />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_ORGANIZATION} element={<AdminOrganization />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_SURVEYS} element={<AdminSurveys />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_TARIFFS} element={<AdminTariffs />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_REVIEWS} element={<AdminReviews />} />
                                <Route path={RoutesPath.ADMIN_SETTINGS_PAYMENTS} element={<AdminPayments />} />
                                <Route
                                    path={RoutesPath.ADMIN_SETTINGS_HOURS_VALIDATION}
                                    element={<AdminValidationHours />}
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path={"*"} element={<NotFound />} />
            </Routes>

            <Footer />
        </>
    );
}

export default App;
