import { useState, forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { DropdownButton } from "react-bootstrap";
import { format, parseISO, eachDayOfInterval } from "date-fns";
import { DATE_FORMAT } from "../../../base/js/constants";
import { createLinkDownloadProofOfPaymentFile } from "../../../services/FileService";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";

import styles from "./ProjectCard.module.scss";

const ProjectCard = ({ project }) => {
    const { t } = useTranslation();
    const [porcentage, setPorcentage] = useState(100);
    const [totalResource, setTotalResource] = useState(0);
    const [totalAssigned, setTotalAssigned] = useState(0);

    useEffect(() => {
        let porc = 0;
        if (parseISO(project.dateStart) < new Date()) {
            porc = Math.ceil(
                (eachDayOfInterval({ start: parseISO(project.dateStart), end: new Date() }).length * 100) /
                    eachDayOfInterval({
                        start: parseISO(project.dateStart),
                        end: parseISO(project.dateEnd),
                    }).length
            );
        }

        if (porc < 100) {
            setPorcentage(porc);
        }

        let totalResource = 0;
        let totalAsigned = 0;
        project.positions?.forEach((position) => {
            totalResource += position.total;
            totalAsigned += position.positionStaffs.length;
        });
        setTotalResource(totalResource);
        setTotalAssigned(totalAsigned);
    }, [project]);

    return (
        <div className={styles.cardContainer}>
            <div className={styles.statusSection}>
                <span className={styles.status}>
                    {project.projectStatus.length > 0
                        ? project.projectStatus[project.projectStatus.length - 1]?.status?.value
                        : t("deleted")}
                </span>
                <div className={styles.actions}>
                    <DropdownButton
                        id="dropdown-basic-button"
                        title={t("actions")}
                        size="sm"
                        variant="secondary"
                        disabled={project.actions?.length === 0}
                    >
                        {project.actions?.map((action) => {
                            const CustomDropdownItem = forwardRef(({ children, onClick }, ref) => (
                                <a
                                    className={`dropdown-item-${action.color} `}
                                    ref={ref}
                                    href="#"
                                    onClick={action.action}
                                >
                                    {t(action.label)}
                                </a>
                            ));

                            return <Dropdown.Item as={CustomDropdownItem} key={uuidv4()} href={"#"}></Dropdown.Item>;
                        })}
                    </DropdownButton>
                </div>
            </div>
            <div className={styles.cardBody}>
                <div className={styles.projectName}>
                    <span>{project.name}</span>
                </div>
                <div>
                    <span className={styles.projectDescription}>
                        {t("description")}: {project.description}
                    </span>
                </div>
                <div className={`${styles.muteText} ${styles.projectCreatorName}`}>
                    <span>
                        {t("creator")}:{" "}
                        {project.userProjectCreators[project.userProjectCreators.length - 1].creator.name}
                    </span>
                </div>
                <div className={`${styles.muteText} ${styles.deliveryManager}`}>
                    <span>
                        {t("technicalAccountManager")}: {project.tam?.name ? project.tam?.name : "-"}
                    </span>
                </div>
                <div className={`${styles.muteText} ${styles.proofpayment}`}>
                    <span>
                        {t("proofOfPayment")}:{" "}
                        {project?.payment?.proofOfPaymentName ? (
                            <a href={createLinkDownloadProofOfPaymentFile(project?.payment?.id)} download>
                                {project?.payment?.proofOfPaymentName}
                            </a>
                        ) : (
                            <span>-</span>
                        )}
                    </span>
                </div>
                <div className={styles.dates}>
                    <span>
                        {t("confirmedAt")}:{" "}
                        <span>{project.confirmedAt ? format(parseISO(project.confirmedAt), DATE_FORMAT) : "-"}</span>
                    </span>
                    <span>
                        {t("finishedAt")}:{" "}
                        <span>{project.finishedAt ? format(parseISO(project.finishedAt), DATE_FORMAT) : "-"}</span>
                    </span>
                    {/*<span>*/}
                    {/*    {t("dateEnd")}:{" "}*/}
                    {/*    <span>{project.dateEnd ? format(parseISO(project.dateEnd), DATE_FORMAT) : "-"}</span>*/}
                    {/*</span>*/}
                </div>
                <div>
                    <span>
                        {t("resources")}:{" "}
                        <span className={styles.resources}>{`${totalAssigned} / ${totalResource}`}</span>
                    </span>
                    {/*<div className={styles.resourcesItemContainer}>*/}
                    {/*    <div className={styles.resourcesItem}></div>*/}
                    {/*    <div className={styles.resourcesItem}></div>*/}
                    {/*    <div className={styles.resourcesItem}></div>*/}
                    {/*    <div className={styles.resourcesItem}></div>*/}
                    {/*</div>*/}
                </div>
            </div>
            {/*<div className={styles.progressContainer}>*/}
            {/*    <div className={styles.progressLabelContainer}>*/}
            {/*        <span>{porcentage}%</span>*/}
            {/*        <span>{t("done")}</span>*/}
            {/*    </div>*/}
            {/*    <div className={styles.progressLine}>*/}
            {/*        <span className={styles.line} style={{ width: `${porcentage}%` }}></span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

ProjectCard.propTypes = {
    project: PropTypes.object.isRequired,
};

export default ProjectCard;
