import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_ALTERNATIVE, FORMATTER, PROJECT_STATUS_IDS } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getPositionsForResumeListAsync } from "../../services/PositionService";
import { getProjectsByUserAsync, removeProject } from "../../services/ProjectService";
import { getInvoiceListAsync } from "../../services/StripeService";
import { getNotifications } from "../../services/UserService";
import { setNotifications } from "../../store/slices/userSlice";
import { useForceLogout } from "../../utils/useForceLogout";
import ConfirmProjectDeleteModal from "../common/confirm-project-delete-modal/ConfirmProjectDeleteModal";
import Loading from "../loading/Loading";
import style from "./HomeResume.module.scss";
import TableResume from "./table-resume/TableResume";

const HomeResume = () => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [projectList, setProjectList] = useState([]);
    const [projectTotal, setProjectTotal] = useState(0);
    const [projectListIsLoading, setProjectListIsLoading] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [staffTotal, setStaffTotal] = useState(0);
    const [staffListIsLoading, setStaffListIsLoading] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [invoiceListIsLoading, setInvoiceListIsLoading] = useState(false);
    const [showDeleteProjectConfirmationModal, setShowDeleteProjectConfirmationModal] = useState(false);
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [reloadData, setReloadData] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setProjectListIsLoading(true);
                const response = await getProjectsByUserAsync();

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setProjectList(
                    responseData.projects?.map((project) => {
                        const handleEditProjectAction = (e) => {
                            e.preventDefault();
                            navigate(Routes.BUILD_YOUR_TEAM + `?from=${project.id}`);
                        };

                        const handleManageProjectAction = (e) => {
                            e.preventDefault();

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                PROJECT_STATUS_IDS.IN_DEVELOPMENT
                            ) {
                                navigate(Routes.buildInDevelopmentPath(project.id));
                                return;
                            }

                            navigate(Routes.buildTeamInfoResumePath(project.id));
                        };

                        const handleDeleteProjectAction = (e) => {
                            e.preventDefault();
                            setActiveProjectId(project.id);
                            setShowDeleteProjectConfirmationModal(true);
                        };

                        const handleDetailsProjectAction = (e) => {
                            e.preventDefault();
                        };

                        const handleValidateHoursAction = (e) => {
                            e.preventDefault();
                            navigate(Routes.buildValidateHoursPath(project.id));
                        };

                        const generateDefaultActions = () => {
                            let actions = [];

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ==
                                    PROJECT_STATUS_IDS.CREATING_NEW_PROJECT ||
                                project.projectStatus[project.projectStatus.length - 1].status.id ==
                                    PROJECT_STATUS_IDS.STARTING_SELECTION
                            ) {
                                actions.push({
                                    label: "edit",
                                    action: handleEditProjectAction,
                                    color: "green",
                                });
                            }

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id !=
                                    PROJECT_STATUS_IDS.CREATING_NEW_PROJECT &&
                                project.projectStatus[project.projectStatus.length - 1].status.id !=
                                    PROJECT_STATUS_IDS.STARTING_SELECTION &&
                                project.projectStatus[project.projectStatus.length - 1].status.id !=
                                    PROJECT_STATUS_IDS.CANCELLED
                            ) {
                                actions.push({
                                    label: "manage",
                                    action: handleManageProjectAction,
                                    color: "green",
                                });
                            }

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                PROJECT_STATUS_IDS.CANCELLED
                            ) {
                                actions.push({
                                    label: "details",
                                    action: handleDetailsProjectAction,
                                    color: "green",
                                });
                            }

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                    PROJECT_STATUS_IDS.FINISHED ||
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                    PROJECT_STATUS_IDS.CANCELLED
                            ) {
                                actions.push({
                                    label: "delete",
                                    action: handleDeleteProjectAction,
                                    color: "red",
                                });
                            }

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                    PROJECT_STATUS_IDS.IN_DEVELOPMENT &&
                                project.hasValidationHoursPending
                            ) {
                                actions.push({
                                    label: "validateHours",
                                    action: handleValidateHoursAction,
                                    color: "green",
                                });
                            }

                            return actions;
                        };
                        return {
                            pendingConfirmation: project.hasValidationHoursPending,
                            name: project.name,
                            status: project.projectStatus[project.projectStatus.length - 1].status.value,
                            actions: generateDefaultActions(project),
                        };
                    })
                );
                setProjectTotal(responseData.total);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setProjectListIsLoading(false);
            }
        })();
    }, [reloadData]);

    useEffect(() => {
        (async () => {
            try {
                setStaffListIsLoading(true);
                const response = await getPositionsForResumeListAsync();

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setStaffList(
                    responseData.positions.map((position) => {
                        const handleAddReviewAction = (e) => {
                            e.preventDefault();
                            navigate(
                                Routes.buildCreateReviewPathWithStaff(
                                    position.projectId,
                                    position.positionStaffs[position.positionStaffs.length - 1].staff.id
                                )
                            );
                        };

                        return {
                            name: `${position.positionStaffs[position.positionStaffs.length - 1].staff.name} ${
                                position.positionStaffs[position.positionStaffs.length - 1].staff.lastName
                            }`,
                            rate: `U$S ${position.rate} / ${t("hour")}`,
                            partTime: position.partTime ? "Part Time" : "Full Time",
                            actions: [
                                {
                                    label: "addReview",
                                    action: handleAddReviewAction,
                                    color: "green",
                                },
                            ],
                        };
                    })
                );
                setStaffTotal(responseData.total);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setStaffListIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setInvoiceListIsLoading(true);
                const response = await getInvoiceListAsync();

                if (!response.ok) {
                    if (response.status === 401) {
                        await forceLogout();
                        return;
                    }

                    toast.error(t("unexpectedError") + ": " + (await response.text()));
                    return;
                }

                let responseData = await response.json();
                setInvoiceList(
                    responseData.invoices.map((invoice) => {
                        const actions = [];
                        if (invoice.hostedInvoiceUrl) {
                            actions.push({
                                label: "viewDetails",
                                action: invoice.hostedInvoiceUrl,
                                color: "green",
                            });
                        }

                        return {
                            projectName: invoice.projectName,
                            date: format(parseISO(invoice.date), DATE_FORMAT_ALTERNATIVE),
                            total: FORMATTER.format(invoice.amount),
                            actions: actions,
                        };
                    })
                );
                setInvoiceTotal(responseData.total);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setInvoiceListIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const userNotificationsResponse = await getNotifications();
                const parsedBodyNotification = await userNotificationsResponse.json();
                if (!userNotificationsResponse.ok) {
                    toast.error(
                        parsedBodyNotification.message
                            ? parsedBodyNotification.message
                            : `Unexpected error (${parsedBodyNotification.status})`
                    );
                    return;
                }

                dispatch(setNotifications({ lastUpdate: new Date(), items: parsedBodyNotification }));
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    const handleAcceptDeleteProject = async () => {
        try {
            const response = await removeProject(activeProjectId);
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                    return;
                }

                toast.error(t("unexpectedError") + ": " + (await response.text()));
                return;
            }

            toast.success(t("removeProjectSuccess"));
            setShowDeleteProjectConfirmationModal(false);
            setReloadData(!reloadData);
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        }
    };

    const handleCancelDeleteProject = () => {
        setShowDeleteProjectConfirmationModal(false);
    };

    return (
        <>
            <section className={style.mainContainer}>
                <div className={style.firstSection}>
                    <div>
                        <span className={style.tableTitle}>{t("staff")}</span>
                        <div className="position-relative">
                            {staffListIsLoading && (
                                <div className={style.loadingContainer}>
                                    <Loading allScreen={false} />
                                </div>
                            )}
                            <TableResume
                                header={["name", "rate", "time", "actions"]}
                                data={staffList}
                                total={staffTotal}
                                viewMoreLink={Routes.STAFF_LIST}
                            />
                        </div>
                    </div>
                    <div>
                        <span className={style.tableTitle}>{t("projects")}</span>
                        <div className="position-relative">
                            {projectListIsLoading && (
                                <div className={style.loadingContainer}>
                                    <Loading allScreen={false} />
                                </div>
                            )}
                            <TableResume
                                header={["", "name", "status", "actions"]}
                                data={projectList}
                                total={projectTotal}
                                viewMoreLink={Routes.MANAGE_TEAMS}
                                showHoursPendingHelp
                            />
                        </div>
                    </div>
                </div>
                <div className={style.secondSection}>
                    <div>
                        <span className={style.tableTitle}>{t("invoices")}</span>
                        <div className="position-relative">
                            {invoiceListIsLoading && (
                                <div className={style.loadingContainer}>
                                    <Loading allScreen={false} />
                                </div>
                            )}
                            <TableResume
                                header={["project", "date", "finalPrice", "actions"]}
                                data={invoiceList}
                                total={invoiceTotal}
                                viewMoreLink={Routes.INVOICE_LIST}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmProjectDeleteModal
                onCancel={handleCancelDeleteProject}
                show={showDeleteProjectConfirmationModal}
                onAccept={handleAcceptDeleteProject}
            />
        </>
    );
};

export default HomeResume;
