import { API_BASE, request } from "../utils/httpRequest";

export const getTariffAsync = async (id) => {
    return await request(API_BASE + "/api/tariff/" + id);
};

export const getTariffListAsync = async () => {
    return await request(API_BASE + "/api/tariff", {
        method: "GET",
    });
};

export const getTariffsWithPagingAsync = async (page, pageSize, searchText = "") => {
    return await request(
        `${API_BASE}/api/tariff/with-paging?page=${page}&pageSize=${pageSize}${
            searchText ? `&searchText=${searchText}` : ""
        }`
    );
};

export const addTariffAsync = async (data) => {
    return await request(`${API_BASE}/api/tariff`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const updateTariffAsync = async (id, data) => {
    return await request(`${API_BASE}/api/tariff/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const setTariffRateAsync = async (id, data) => {
    return await request(`${API_BASE}/api/tariff/${id}/set-tariff-rate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
};

export const readjustTariffAsync = async (id, percentage) => {
    return await request(`${API_BASE}/api/tariff/${id}/readjust`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(percentage),
    });
};
