import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";
import { DropdownButton, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";
import colors from "../../../base/js/colors";
import { TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import Paginator from "../../common/paginator/Paginator";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import styles from "../../shared-styles/TableStyle.module.scss";

const StartDateInput = forwardRef(({ value, onClick, startDate }, ref) => (
    <div className="d-flex">
        <input
            ref={ref}
            id="input-date-filter"
            type="text"
            className={styles.datepickerInput}
            value={value}
            onClick={onClick}
            readOnly
        />
    </div>
));

const Table = ({
    headers,
    rows,
    totalRows,
    currentPage,
    rowsPerPage,
    onPageChange,
    onFilterChange,
    onRowsPerPageChange,
    showDateFilter = true,
}) => {
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [filterText, setFilterText] = useState("");

    const handleChangeFilterText = (event) => {
        setFilterText(event.target.value);
    };

    const handleDateRangeFilterChange = (newRange) => {
        setDateRange(newRange);
        onFilterChange({ searchText: filterText, startDate: newRange[0], endDate: newRange[1] });
    };

    const handleKeyDownFilterText = (event) => {
        if (event.key !== "Enter") return;

        onFilterChange({ searchText: filterText, startDate, endDate });
    };

    return (
        <div className="col-12">
            <div
                className={`${styles.filtersContainer} col-12 d-flex gap-2 gap-md-0 flex-column flex-lg-row justify-content-lg-between align-items-lg-center`}
            >
                <div className="col-12 col-lg-6 d-flex flex-column flex-lg-row justify-content-start align-items-lg-center">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faFilter} color={colors.GRAY850} />
                        <label className={`${styles.filterLabel} px-2`}>{t("filter")}</label>
                    </div>

                    <div className="d-flex w-100">
                        <input
                            className={`${styles.inputTextFilter} w-100`}
                            value={filterText}
                            onKeyDown={handleKeyDownFilterText}
                            onChange={handleChangeFilterText}
                        ></input>
                    </div>
                </div>

                {showDateFilter && (
                    <div
                        className={`${styles.btnDateRangeFilter} d-flex flex-column flex-lg-row justify-content-start align-items-lg-center`}
                    >
                        <label className={`${styles.filterLabel} pe-2 flex-shrink-0`}>{t("dateFilter")}</label>
                        <DatePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateRangeFilterChange}
                            customInput={<StartDateInput startDate={startDate} />}
                            className="flex-grow-1"
                            selectsRange
                            isClearable
                        />
                    </div>
                )}

                <div className="d-flex flex-column flex-lg-row justify-content-start align-items-lg-center">
                    <label className={styles.selectLimitLabel} htmlFor="select-limit">
                        {t("show")}
                    </label>

                    <select
                        id="select-limit"
                        className={`${styles.showFilter} py-1 px-2`}
                        defaultValue={rowsPerPage}
                        onChange={onRowsPerPageChange}
                    >
                        {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                            return (
                                <option key={index} value={rowLimit}>
                                    {rowLimit}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className={`${styles.tableContainer} w-100`}>
                <table className="text-left col-12">
                    <thead>
                        <tr>
                            {headers.map((head, index) => {
                                return <th key={index}>{t(head)}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((columns, index) => {
                            const rowContent = [];

                            for (const key in columns) {
                                if (key === "actions") {
                                    rowContent.push(
                                        <td key={uuidV4()}>
                                            <DropdownButton
                                                id="dropdown-basic-button"
                                                title={t("actions")}
                                                size="sm"
                                                variant="secondary"
                                                disabled={columns[key].length === 0}
                                            >
                                                {columns[key].map((action) => {
                                                    const CustomDropdownItem = forwardRef(
                                                        ({ children, onClick }, ref) => {
                                                            if (typeof action.action === "function") {
                                                                return (
                                                                    <a
                                                                        className={`dropdown-item-${action.color}`}
                                                                        ref={ref}
                                                                        href={"#"}
                                                                        onClick={action.action}
                                                                    >
                                                                        {t(action.label)}
                                                                    </a>
                                                                );
                                                            } else {
                                                                return (
                                                                    <a target="_blank" href={action.action}>
                                                                        {t(action.label)}
                                                                    </a>
                                                                );
                                                            }
                                                        }
                                                    );

                                                    return (
                                                        <Dropdown.Item
                                                            as={CustomDropdownItem}
                                                            key={uuidV4()}
                                                            href="#"
                                                        ></Dropdown.Item>
                                                    );
                                                })}
                                            </DropdownButton>
                                        </td>
                                    );
                                } else if (key === "active") {
                                    rowContent.push(
                                        <td key={uuidV4()}>
                                            <Form.Check
                                                id={`active-${uuidV4()}`}
                                                type="switch"
                                                checked={columns[key].isActive}
                                                className={`${sharedStyles.customSwitch}`}
                                                onChange={columns[key].action}
                                            />
                                        </td>
                                    );
                                } else if (key === "pendingConfirmation") {
                                    rowContent.push(
                                        columns[key] ? (
                                            <td key={uuidV4()} style={{ width: "5px" }}>
                                                <div className="d-flex align-items-center gap-1">
                                                    <span className={`${styles.pendingConfirmation} me-2`}></span>
                                                </div>
                                            </td>
                                        ) : (
                                            <td key={uuidV4()}></td>
                                        )
                                    );
                                } else {
                                    rowContent.push(<td key={uuidV4()}>{columns[key]}</td>);
                                }
                            }

                            return <tr key={index}>{rowContent}</tr>;
                        })}

                        {!rows.length && (
                            <tr>
                                <td colSpan={headers.length} className={styles.noDataContent}>
                                    {t("noDataToShow")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Paginator activePage={currentPage} lastPage={Math.ceil(totalRows / rowsPerPage)} setPage={onPageChange} />
        </div>
    );
};

Table.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    totalRows: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
};

export default Table;
