const FAQ_DATA = [
    {
        id: 1,
        name: "Why hire software developers through TheDevBlock?",
        questions: [
            {
                question: "How is the Team Building Process?",
                answer: "faqTeamBuildingProcess",
            },
            {
                question: "How does TheDevBlock verify candidates?",
                answer: "faqCandidatesVerification",
            },
            {
                question: "What is the minimum term service contract?",
                answer: "faqMinimumTermServiceContract",
            },
            {
                question: "Where is the tech talent located?",
                answer: "faqWhereIsTalentLocated",
            },
            {
                question: "What is the time limit for receiving the proposed team?",
                answer: "faqTimeLimitForReceivingProposedTeam",
            },
            {
                question:
                    "If a tech language or skill is not mentioned on the platform, it means that you don’t work with it?",
                answer: "faqSkillNotMentionedOnThePlatform",
            },
            {
                question: "If I reject a candidate, what is next?",
                answer: "faqCandidateRejection",
            },
        ],
    },
    // {
    //     id: 2,
    //     name: "Interviews and selection process FAQ",
    //     questions: [
    //         {
    //             question: "How is the Team Building Process?",
    //             answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora laborum, nobis, nostrum iure quasi, quidem expedita impedit unde sed exercitationem et repudiandae quia! Saepe at natus deserunt alias dignissimos hic",
    //         },
    //         {
    //             question: "How does TheDevBlock verify candidates?",
    //             answer: "Ab, eum dolorem. Minus dicta explicabo delectus animi illo voluptate nobis enim nemo nisi saepe alias quia impedit perferendis hic quos vitae, cum accusamus sed dolor! Quidem deserunt natus architecto.",
    //         },
    //         {
    //             question: "What is the minimum term service contract?",
    //             answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora laborum, nobis, nostrum iure quasi, quidem expedita impedit unde sed exercitationem et repudiandae quia! Saepe at natus deserunt alias dignissimos hic",
    //         },
    //         {
    //             question: "Where is the tech talent located?",
    //             answer: "Ab, eum dolorem. Minus dicta explicabo delectus animi illo voluptate nobis enim nemo nisi saepe alias quia impedit perferendis hic quos vitae, cum accusamus sed dolor! Quidem deserunt natus architecto.",
    //         },
    //         {
    //             question: "What is the time limit for receiving the proposed team?",
    //             answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora laborum, nobis, nostrum iure quasi, quidem expedita impedit unde sed exercitationem et repudiandae quia! Saepe at natus deserunt alias dignissimos hic",
    //         },
    //     ],
    // },
    {
        id: 3,
        name: "Payment and Service Management",
        questions: [
            {
                question: "faqMethodsAcceptedQ",
                answer: "faqMethodsAcceptedA",
            },
            {
                question: "faqBillFrequencyQ",
                answer: "faqBillFrequencyA",
            },
            {
                question: "faqServiceModificationsQ",
                answer: "faqServiceModificationsA",
            },
            {
                question: "faqRefundPolicyQ",
                answer: "faqRefundPolicyA",
            },
            {
                question: "faqServiceSupportQ",
                answer: "faqServiceSupportA",
            },
            {
                question: "faqHiddenFeesQ",
                answer: "faqHiddenFeesA",
            },
            {
                question: "faqDataSecurityQ",
                answer: "faqDataSecurityA",
            },
        ],
    },
    {
        id: 4,
        name: "Service Changes - Replacements",
        questions: [
            {
                question: "faqHowToRequestChangesQ",
                answer: "faqHowToRequestChangesA",
            },
            {
                question: "faqProcessForAddingOrRemovingTeamMembersQ",
                answer: "faqProcessForAddingOrRemovingTeamMembersA",
            },
            {
                question: "faqNoticeINeedQ",
                answer: "faqNoticeINeedA",
            },
            {
                question: "faqCanIPauseServiceQ",
                answer: "faqCanIPauseServiceA",
            },
            {
                question: "faqWillChangesAffectCostQ",
                answer: "faqWillChangesAffectCostA",
            },
            {
                question: "faqChangeSkillSetOrExpertiseQ",
                answer: "faqChangeSkillSetOrExpertiseA",
            },
            {
                question: "faqFeesForMakingChangesQ",
                answer: "faqFeesForMakingChangesA",
            },
            {
                question: "faqHowLongTakesImplementingChangesQ",
                answer: "faqHowLongTakesImplementingChangesA",
            },
        ],
    },
    // {
    //     id: 5,
    //     name: "Intellectual Property",
    //     questions: [
    //         {
    //             question: "What is the time limit to interview and make a decision?",
    //             answer: "Ab, eum dolorem. Minus dicta explicabo delectus animi illo voluptate nobis enim nemo nisi saepe alias quia impedit perferendis hic quos vitae, cum accusamus sed dolor! Quidem deserunt natus architecto.",
    //         },
    //         {
    //             question: "If I reject a candidate, what is next?",
    //             answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora laborum, nobis, nostrum iure quasi, quidem expedita impedit unde sed exercitationem et repudiandae quia! Saepe at natus deserunt alias dignissimos hic",
    //         },
    //     ],
    // },
];

export default FAQ_DATA;
