import { OverlayTrigger, Tooltip } from "react-bootstrap";
import style from "./Label.module.scss";

const RequiredField = () => <span className={style.requiredField}> *</span>;

const Label = ({
    htmlFor,
    children,
    requiredIndicator = false,
    boldLabel = true,
    informationIndicator = false,
    informationAboutField = null,
}) => (
    <label className={`${style.label} ${boldLabel ? style.textBold : style.textNormal} mb-1`} htmlFor={htmlFor}>
        {children}

        {requiredIndicator && <RequiredField />}

        {informationIndicator && (
            <OverlayTrigger placement="top" overlay={<Tooltip>{informationAboutField}</Tooltip>}>
                <div
                    className={`${style.informationIcon} ms-1 d-inline-flex justify-content-center align-items-center rounded-circle position-relative`}
                >
                    i
                </div>
            </OverlayTrigger>
        )}
    </label>
);

export default Label;
