import { useRef } from "react";
import { useTranslation } from "react-i18next";
import style from "./NotificationContainer.module.scss";
import NotificationItem from "../notification-item/NotificationItem";
import useClickOutside from "../../../hooks/UseClickOutside";

const NotificationContainer = ({ notifications, onNotificationRemove, onClickOutside }) => {
    const containerRef = useRef(null);
    const { t } = useTranslation();

    useClickOutside(containerRef, onClickOutside);

    return (
        <div ref={containerRef} className={`${style.container} position-absolute d-flex flex-column p-2`}>
            <div className="overflow-auto">
                {notifications?.length ? (
                    notifications.map((notification) => (
                        <NotificationItem key={notification.id} {...notification} onRemove={onNotificationRemove} />
                    ))
                ) : (
                    <div className={`${style.noNotifications} m-4 text-center`}>
                        <span className={style.noNotifications}>{t("noNotificationsToShow")}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationContainer;
