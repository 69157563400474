import Tags from "@yaireo/tagify/dist/react.tagify";
import { parse } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { DATE_FORMAT, TAM_ID, TAM_LABEL } from "../../../../base/js/constants";
import Checkbox from "../../../common/Checkbox";
import InputFile from "../../../common/custom-input-file/CustomInputFile";
import Label from "../../../common/custom-label/Label";
import ErrorMessages from "../../../common/error-messages/ErrorMessages";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import { TeamInfoFormIds, TotalMinMaxLimits } from "../TeamInfoConstant";
import { useTeamInfoFunctions } from "../useTeamInfoFunctions";
import CustomResource from "./CustomResource";
import styles from "./ResourceMobile.module.scss";

const ResourceMobile = ({
    positionInfo,
    projectInfo,
    roleTypes,
    seniorityTypes,
    totalPositions,
    onChange,
    deletePosition,
    onValidationChange,
    forceGlobalValidationFlag,
    cardSchema,
    schemaErrorMessages,
    inputFileIsLoading = false,
    disableFileInput = false,
    seniorOnlyRoles = [],
    disabled = false,
}) => {
    const { t } = useTranslation();
    const [confirmationControlVisible, setConfirmationControlVisible] = useState(false);
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const firstRunFlag = useRef(true);

    const {
        StartDateInput,
        EndDateInput,
        getSelectorStyle,
        handleChangeRole,
        handleChangeSeniority,
        handleChangeJobSkills,
        handleChangeTotal,
        handleChangeTime,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeJobDescription,
        changeUploadJobDescription,
        deleteJobDescriptionFile,
        validateTotalFieldValue,
        validateJobDescriptionValue,
        handleDeleteResource,
        toggleConfirmationControls,
    } = useTeamInfoFunctions(
        positionInfo,
        onChange,
        deletePosition,
        confirmationControlVisible,
        setConfirmationControlVisible,
        cardSchema,
        schemaErrorMessages,
        validateSchema,
        validateSubSchemaFromEvent,
        firstRunFlag,
        seniorOnlyRoles,
        seniorityTypes
    );

    const isTam = useMemo(() => positionInfo.id === TAM_ID, [positionInfo.id]);
    const availableRoles = useMemo(() => roleTypes.filter((r) => r.label !== TAM_LABEL), [roleTypes]);

    const componentState = {
        role: positionInfo.role,
        seniority: positionInfo.seniority,
        jobSpecificSkills: positionInfo.jobSkills,
        count: positionInfo.total,
        time: positionInfo.time,
        estimatedDateToStartOptional: projectInfo.startDate,
        estimatedDateToEndOptional: projectInfo.endDate,
    };

    // Report to the parent the validity of the form
    useEffect(() => {
        // If it's the first run of the component, do not execute the validation callback
        if (firstRunFlag.current || isTam) {
            return;
        }

        onValidationChange({
            id: positionInfo.id,
            valid: Object.keys(errors).length === 0 && positionInfo.jobDescription.isValid,
        });
    }, [errors]);

    // Execute a global validation when forceGlobalValidationFlag changes its value
    useEffect(() => {
        if (isTam) {
            return;
        }

        validateSchema(cardSchema, schemaErrorMessages, componentState);
    }, [forceGlobalValidationFlag]);

    return (
        <div className={`${styles.resourceContainer} py-4 my-2`}>
            <div>
                <CustomResource eventKey={positionInfo.id}>{positionInfo.role?.label}</CustomResource>
            </div>
            <Accordion.Collapse eventKey={positionInfo.id}>
                <div className="px-3">
                    <div className="d-flex flex-column">
                        <div className={`${styles.formElementContainer} d-flex align-items-center gap-3 mt-3 col-12`}>
                            <Label htmlFor={TeamInfoFormIds.ROLE}>{t("role")}</Label>
                            <Select
                                id={TeamInfoFormIds.ROLE}
                                className="col-10"
                                styles={getSelectorStyle(!!errors.role && forceGlobalValidationFlag > 0)}
                                options={availableRoles}
                                onChange={handleChangeRole}
                                value={positionInfo.role}
                                placeholder={`${t("select")}...`}
                                isDisabled={isTam || disabled}
                            />
                        </div>

                        {errors.role && forceGlobalValidationFlag > 0 && (
                            <ErrorMessages errors={[errors.role.message]} />
                        )}
                    </div>

                    <div className="d-flex flex-column">
                        <div className={`${styles.formElementContainer} d-flex align-items-center gap-3 mt-3 col-12`}>
                            <Label htmlFor={TeamInfoFormIds.SENIORITY}>{t("seniority")}</Label>
                            <Select
                                id={TeamInfoFormIds.SENIORITY}
                                className="col-8"
                                styles={getSelectorStyle(!!errors.seniority && forceGlobalValidationFlag > 0)}
                                options={seniorityTypes}
                                onChange={handleChangeSeniority}
                                value={positionInfo.seniority}
                                placeholder={`${t("select")}...`}
                                isDisabled={seniorOnlyRoles.includes(positionInfo.role?.value) || isTam || disabled}
                            />
                        </div>
                        {errors.seniority && forceGlobalValidationFlag > 0 && (
                            <ErrorMessages errors={[errors.seniority.message]} />
                        )}{" "}
                    </div>

                    <div className={`${styles.formElementContainer} d-flex align-items-center gap-3 mt-3 col-12`}>
                        <Label htmlFor={TeamInfoFormIds.JOB_SKILLS}>{t("jobskills")}</Label>
                        <div className="col-8">
                            <Tags
                                onChange={handleChangeJobSkills}
                                defaultValue={positionInfo.jobSkills}
                                disabled={isTam || disabled}
                            />
                        </div>
                    </div>

                    <div className="row d-flex">
                        <div className="d-flex flex-column col-4 mt-3">
                            <div className={`${styles.formElementContainer} d-flex align-items-center gap-3`}>
                                <Label htmlFor={TeamInfoFormIds.TOTAL}>{t("count")}</Label>

                                <input
                                    id={TeamInfoFormIds.TOTAL}
                                    type="number"
                                    min={TotalMinMaxLimits.MIN}
                                    max={TotalMinMaxLimits.MAX}
                                    className={`${sharedStyle.inputText} ${
                                        !errors.count ? "" : sharedStyle.invalidField
                                    } ${isTam || disabled ? sharedStyle.disabledInput : ""} px-2`}
                                    value={positionInfo.total}
                                    onChange={handleChangeTotal}
                                    onBlur={validateTotalFieldValue}
                                    disabled={isTam || disabled}
                                />
                            </div>

                            {errors.count && <ErrorMessages errors={[errors.count.message]} />}
                        </div>

                        <div className={`${styles.formElementContainer} col-6 d-flex align-items-center gap-3 mt-3`}>
                            <Label
                                htmlFor={TeamInfoFormIds.TIME}
                                informationAboutField="Maecenas sodales dapibus lectus, id pulvinar tortor iaculis non."
                                informationIndicator
                            >
                                {t("time")}
                            </Label>

                            <Checkbox
                                checked={positionInfo.time}
                                onChange={handleChangeTime}
                                name={TeamInfoFormIds.TIME}
                                className={`${sharedStyle.inputMinHeight} text-uppercase`}
                                isValid={true}
                                disabled={isTam || disabled}
                            >
                                {t("partial")}
                            </Checkbox>
                        </div>
                    </div>
                    <div className={`${styles.formElementContainer} col-12 mt-3`}>
                        <Label htmlFor={TeamInfoFormIds.ESTIMATED_START_DATE}>
                            {t("estimatedDateToStartOptional")}
                        </Label>
                        <DatePicker
                            id={TeamInfoFormIds.ESTIMATED_START_DATE}
                            selected={
                                positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE]
                                    ? parse(positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE], DATE_FORMAT, new Date())
                                    : null
                            }
                            onChange={handleChangeStartDate}
                            customInput={<StartDateInput disabled={isTam || disabled} />}
                            minDate={parse(projectInfo.startDate, DATE_FORMAT, new Date())}
                            maxDate={parse(projectInfo.endDate, DATE_FORMAT, new Date())}
                            dateFormat={DATE_FORMAT}
                            showPopperArrow={false}
                            disabled={isTam || disabled}
                        />
                    </div>
                    <div className={`${styles.formElementContainer} col-12 mt-3`}>
                        <Label htmlFor={TeamInfoFormIds.ESTIMATED_END_DATE}>{t("estimatedDateToEndOptional")}</Label>
                        <DatePicker
                            id={TeamInfoFormIds.ESTIMATED_END_DATE}
                            selected={
                                positionInfo[TeamInfoFormIds.ESTIMATED_END_DATE]
                                    ? parse(positionInfo[TeamInfoFormIds.ESTIMATED_END_DATE], DATE_FORMAT, new Date())
                                    : null
                            }
                            onChange={handleChangeEndDate}
                            customInput={
                                <EndDateInput
                                    disabled={!positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE] || isTam || disabled}
                                />
                            }
                            minDate={parse(positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE], DATE_FORMAT, new Date())}
                            maxDate={parse(projectInfo.endDate, DATE_FORMAT, new Date())}
                            dateFormat={DATE_FORMAT}
                            disabled={!positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE] || isTam || disabled}
                            showPopperArrow={false}
                        />
                    </div>

                    <div className={`${styles.formElementContainer} col-12 mt-3`}>
                        <Label
                            htmlFor={TeamInfoFormIds.JOB_DESCRIPTION_LINK}
                            informationAboutField="Maecenas sodales dapibus lectus, id pulvinar tortor iaculis non."
                            informationIndicator
                        >
                            {t("jobDescriptionLinkOptional")}
                        </Label>
                        <div className="d-flex align-content-center">
                            <input
                                id={TeamInfoFormIds.JOB_DESCRIPTION_LINK}
                                className={`${sharedStyle.inputText} ${styles.jobDescriptionLink} ${
                                    isTam || disabled ? sharedStyle.disabledInput : ""
                                } ps-2 col-12`}
                                value={
                                    positionInfo?.jobDescription.isLink
                                        ? positionInfo.jobDescription.link
                                        : positionInfo.jobDescription.file.name
                                }
                                onChange={handleChangeJobDescription}
                                onBlur={validateJobDescriptionValue}
                                disabled={!positionInfo.jobDescription?.isLink || isTam || disabled}
                            />
                            <span
                                className={`${styles.btnCloseCircle} ${
                                    positionInfo.jobDescription.isLink ? "d-none" : "d-flex"
                                } position-relative`}
                                onClick={deleteJobDescriptionFile}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
                                </svg>
                            </span>
                        </div>
                        <div className="d-flex col-12 mt-2">
                            <span className="p-1 align-items-center">{t("or")}</span>
                            <InputFile
                                inputFileChange={changeUploadJobDescription}
                                isLoading={inputFileIsLoading}
                                disabled={disableFileInput || isTam || disabled}
                                short
                            />
                        </div>

                        {!positionInfo.jobDescription?.isValid && (
                            <div className="d-flex">
                                <span className={sharedStyle.errorMsg}>{t("errorLinkFormat")}</span>
                            </div>
                        )}
                    </div>

                    <div className="d-flex justify-content-center mt-5">
                        {totalPositions > 2 &&
                            !isTam &&
                            !disabled &&
                            (confirmationControlVisible ? (
                                <div className={`d-flex justify-content-center position-relative col-12`}>
                                    <span
                                        className={`${styles.btnDeleteResource} ${styles.cancelIcon} d-block col-4`}
                                        onClick={toggleConfirmationControls}
                                    >
                                        {t("cancel")}
                                    </span>
                                    <span
                                        className={`${styles.btnDeleteResource} ${styles.acceptIcon} d-block col-4`}
                                        onClick={handleDeleteResource}
                                    >
                                        {t("ok")}
                                    </span>
                                </div>
                            ) : (
                                <div className={`d-flex justify-content-center position-relative col-12`}>
                                    <span
                                        className={`${styles.btnDeleteResource} col-8`}
                                        onClick={toggleConfirmationControls}
                                    >
                                        {t("deleteResource")}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
            </Accordion.Collapse>
        </div>
    );
};

ResourceMobile.propTypes = {
    positionInfo: PropTypes.object,
    projectInfo: PropTypes.object,
    roleTypes: PropTypes.array,
    seniorityTypes: PropTypes.array,
    totalPositions: PropTypes.number,
    onChange: PropTypes.func,
    deletePosition: PropTypes.func,
    onValidationChange: PropTypes.func,
    forceGlobalValidationFlag: PropTypes.number,
    cardSchema: PropTypes.object,
    schemaErrorMessages: PropTypes.object,
    inputFileIsLoading: PropTypes.bool,
    disableFileInput: PropTypes.bool,
    seniorOnlyRoles: PropTypes.array,
    disabled: PropTypes.bool,
};

export default ResourceMobile;
