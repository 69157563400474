import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../../base/js/constants";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import style from "./ValidateProjectModal.module.scss";

const ValidateProjectModal = ({ show, onValidate, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show}>
            <div className={`${style.closeBtn}`} onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
            </div>

            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("validateProject")}</span>

            <span className="d-block text-center">{t("canNotBeRevertedMessage")}</span>

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={onValidate}>
                    {t("confirm")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onClose}>
                    {t("close")}
                </ModalButton>
            </div>
        </Modal>
    );
};

ValidateProjectModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ValidateProjectModal;
