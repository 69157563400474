import { API_BASE, request } from "../utils/httpRequest";

export const getInterviewRequestAsync = async (id) => {
    return await request(API_BASE + "/api/interviewrequest/" + id);
};

export const getInterviewRequestListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    return await request(
        API_BASE +
            `/api/interviewrequest?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
        {
            method: "GET",
        }
    );
};
