import { useTranslation } from "react-i18next";
import style from "./ErrorMessages.module.scss";

const ErrorMessages = ({ errors }) => {
    const { t } = useTranslation();

    return (
        <div className="mt-1">
            {errors.map((error, index) => (
                <p key={index} className={style.errorMsg}>
                    {t(error)}
                </p>
            ))}
        </div>
    );
};

export default ErrorMessages;
