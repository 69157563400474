import { t } from "i18next";
import { ProjectTraceParameterTypes, ProjectTraceTypes } from "../../../base/js/constants";
import { createLinkDownloadProofOfPaymentFile } from "../../../services/FileService";
import { replaceMark } from "../../../utils/strings";

const useProcessTraceDescription = () => {
    const processTracesDescription = (traces) => {
        const projectTraces = [...traces];

        // Generate the description section of each entry
        for (let i = 0; i < projectTraces.length; i++) {
            switch (projectTraces[i].type) {
                case ProjectTraceTypes.PAYMENT: {
                    // We're expecting to have one parameter with the ID of the payment.
                    const paymentId = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.PAYMENT_ID
                    )?.content;
                    const paymentProofName = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.PAYMENT_PROOF_NAME
                    )?.content;

                    projectTraces[i].downloadLink = {
                        fileName: paymentProofName,
                        url: createLinkDownloadProofOfPaymentFile(paymentId),
                    };

                    projectTraces[i].processedDescription = replaceMark(
                        t(projectTraces[i].description),
                        "{fileDownload}",
                        <a
                            key={`${projectTraces[i].id}_downloadLink`}
                            href={projectTraces[i].downloadLink.url}
                            download
                        >
                            {projectTraces[i].downloadLink.fileName}
                        </a>
                    );

                    break;
                }

                case ProjectTraceTypes.PROJECT_ADMINISTRATION_UPDATE: {
                    const hiringManager = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.HIRING_MANAGER_NAME
                    )?.content;

                    const tam = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.TAM_NAME
                    )?.content;

                    const projectCreator = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.PROJECT_CREATOR_NAME
                    )?.content;

                    projectTraces[i].processedDescription = t(projectTraces[i].description);

                    if (hiringManager) {
                        projectTraces[i].processedDescription = replaceMark(
                            projectTraces[i].processedDescription,
                            "{hiringManager}",
                            hiringManager
                        );
                    }

                    if (tam) {
                        projectTraces[i].processedDescription = replaceMark(
                            projectTraces[i].processedDescription,
                            "{tam}",
                            tam
                        );
                    }

                    if (projectCreator) {
                        projectTraces[i].processedDescription = replaceMark(
                            projectTraces[i].processedDescription,
                            "{creator}",
                            projectCreator
                        );
                    }

                    break;
                }

                case ProjectTraceTypes.PROJECT_STATUS: {
                    const amountOfPositions = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.AMOUNT_OF_POSITIONS
                    )?.content;

                    const amountOfResources = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.AMOUNT_OF_RESOURCES
                    )?.content;

                    projectTraces[i].processedDescription = t(projectTraces[i].description);

                    if (amountOfPositions) {
                        projectTraces[i].processedDescription = replaceMark(
                            projectTraces[i].processedDescription,
                            "{amountOfPositions}",
                            amountOfPositions
                        );
                    }

                    if (amountOfResources) {
                        projectTraces[i].processedDescription = replaceMark(
                            projectTraces[i].processedDescription,
                            "{amountOfResources}",
                            amountOfResources
                        );
                    }

                    break;
                }

                case ProjectTraceTypes.PROJECT_REJECTION: {
                    projectTraces[i].processedDescription =
                        t("cause") +
                        ": " +
                        projectTraces[i].parameters.find(
                            (p) => p.type === ProjectTraceParameterTypes.PROJECT_REJECTION_CAUSE
                        )?.content;

                    break;
                }

                case ProjectTraceTypes.RESOURCE_ASSIGNATION_UPDATE: {
                    const resourceName = projectTraces[i].parameters.find(
                        (p) => p.type === ProjectTraceParameterTypes.RESOURCE_NAME
                    )?.content;

                    projectTraces[i].processedDescription = t(projectTraces[i].description);

                    if (resourceName) {
                        projectTraces[i].processedDescription = replaceMark(
                            projectTraces[i].processedDescription,
                            "{resource}",
                            resourceName
                        );
                    }

                    break;
                }
            }

            // Parse the author of the trace
            projectTraces[i].author = projectTraces[i].parameters.find(
                (p) => p.type === ProjectTraceParameterTypes.ACTION_AUTHOR_NAME
            )?.content;
        }

        return projectTraces;
    };

    return { processTracesDescription };
};

export default useProcessTraceDescription;
