import { useTranslation } from "react-i18next";
import styles from "./Paginator.module.scss";

const Paginator = ({ activePage, lastPage, setPage }) => {
    const { t } = useTranslation();
    const handlePrev = () => {
        if (activePage <= 1) return;
        setPage(activePage - 1);
    };

    const handleNext = () => {
        if (activePage >= lastPage) return;
        setPage(activePage + 1);
    };

    const handleFirstPage = () => {
        setPage(1);
    };

    const handleLastPage = () => {
        setPage(lastPage);
    };

    return (
        <div className={`${styles.paginationContainer} ${lastPage > 1 ? "d-flex" : "d-none"} justify-content-center`}>
            <div
                className={`${styles.paginationIndicator} ${styles.paginationIndicatorActive} ${
                    activePage === 1 ? "d-none" : "d-flex"
                } align-items-center`}
                onClick={handlePrev}
            >
                {t("prev")}
            </div>
            <div
                className={`${styles.paginationIndicator} ${activePage === 1 ? "d-none" : "d-flex"} align-items-center`}
                onClick={handleFirstPage}
            >
                1
            </div>
            <div
                className={`${styles.paginationIndicator} ${styles.paginationIndicatorActive} d-flex align-items-center`}
            >
                {activePage}
            </div>
            <div
                className={`${styles.paginationIndicator} ${
                    activePage === lastPage ? "d-none" : "d-flex"
                } align-items-center`}
                onClick={handleLastPage}
            >
                {lastPage}
            </div>
            <div
                className={`${styles.paginationIndicator} ${styles.paginationIndicatorActive} ${
                    activePage === lastPage ? "d-none" : "d-flex"
                } align-items-center`}
                onClick={handleNext}
            >
                {t("next")}
            </div>
        </div>
    );
};

export default Paginator;
