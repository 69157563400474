import { faBorderAll, faListUl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT, PROJECT_STATUS_IDS, ROLES, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import Routes from "../../../base/js/routes";
import {
    addProjectCreatorAsync,
    cancelProjectAsync,
    getProjectsListAsync,
    rejectProjectAsync,
    verifyProjectAsync,
} from "../../../services/ProjectService";
import { useForceLogout } from "../../../utils/useForceLogout";
import Paginator from "../../common/paginator/Paginator";
import ProjectCardPaginated from "../../common/project-card-paginated/ProjectCardPaginated";
import useHasRole from "../../hooks/UseHasRole";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import AddCreatorModal from "../add-creator-modal/AddCreatorModal";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminProjects.module.scss";
import ConfirmProjectCancelModal from "./confirm-project-cancel-modal/ConfirmProjectCancelModal";
import RejectProjectModal from "./reject-project-modal/RejectProjectModal";
import ValidateProjectModal from "./validate-project-modal/ValidateProjectModal";

const FILTER_TYPES = {
    ALL: 0,
    PENDING: 1,
    INTERVIEW: 2,
    CONFIRMED: 3,
    DEVELOPMENT: 4,
    ALLOCATED: 5,
    FINISHED: 6,
    ABANDONED: 7,
    CANCELED: 8,
};

const getFilterMapToProjectState = (filterType) => {
    switch (filterType) {
        case FILTER_TYPES.ALL:
            return [];
        case FILTER_TYPES.PENDING:
            return [3, 5, 6];
        case FILTER_TYPES.INTERVIEW:
            return [7];
        case FILTER_TYPES.CONFIRMED:
            return [8, 9];
        case FILTER_TYPES.DEVELOPMENT:
            return [10];
        case FILTER_TYPES.ALLOCATED:
            return [4];
        case FILTER_TYPES.FINISHED:
            return [11];
        case FILTER_TYPES.ABANDONED:
            return [1, 2];
        case FILTER_TYPES.CANCELED:
            return [12];
        default:
            return [];
    }
};

const AdminProjects = () => {
    const { t } = useTranslation();
    const forceLogout = useForceLogout();
    const isHiringManager = useHasRole([ROLES.HIRING_MANAGER]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = useSelector((state) => state.user);
    const [viewSelectorCard, setViewSelectorCard] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [filterActive, setFilterActive] = useState(FILTER_TYPES.ALL);
    const [searchInput, setSearchInput] = useState("");
    const [projectsData, setProjectsData] = useState([]);
    const [showAddCreatorModal, setShowAddCreatorModal] = useState(false);
    const [showProjectCancelModal, setShowProjectCancelModal] = useState(false);
    const [showValidateProjectModal, setShowValidateProjectModal] = useState(false);
    const [showRejectProjectModal, setShowRejectProjectModal] = useState(false);
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [refreshData, setRefreshData] = useState(false);

    const isAdminOrHiringManager = useHasRole([ROLES.ADMIN, ROLES.HIRING_MANAGER]);

    useEffect(() => {
        (async () => {
            const filter = getFilterMapToProjectState(filterActive);

            setIsLoading(true);

            const response = await getProjectsListAsync(activePage, totalPerPage, filter, searchInput);
            const responseData = await response.json();

            setIsLoading(false);

            responseData.projects = responseData.projects.map((project) => {
                const handleShowAddCreatorModal = (e) => {
                    e.preventDefault();
                    setActiveProjectId(project.id);
                    setShowAddCreatorModal(true);
                };

                const handleAllocateResourceClick = (e) => {
                    e.preventDefault();
                    const routeToNavigate = Routes.buildProjectAllocationResourcePath(project.id);
                    navigate(routeToNavigate, { replace: true });
                };

                const handleProjectTraceClick = (e) => {
                    e.preventDefault();
                    const routeToNavigate = Routes.buildProjectTracingPath(project.id);
                    navigate(routeToNavigate, { replace: true });
                };

                const handleRejectProjectClick = (e) => {
                    e.preventDefault();
                    setActiveProjectId(project.id);
                    setShowRejectProjectModal(true);
                };

                const handleCancelProjectClick = (e) => {
                    e.preventDefault();
                    setActiveProjectId(project.id);
                    setShowProjectCancelModal(true);
                };

                const handleValidateProjectClick = (e) => {
                    e.preventDefault();
                    setActiveProjectId(project.id);
                    setShowValidateProjectModal(true);
                };

                //Checks if the project status is Pending allocation of resource o greater
                //and the logged user is the project's creator for adding the "Add creator" action
                const actionsAllowed =
                    project.projectStatus[project.projectStatus.length - 1]?.status?.id >=
                        PROJECT_STATUS_IDS.PENDING_ALLOCATION &&
                    project.projectStatus[project.projectStatus.length - 1]?.status?.id !=
                        PROJECT_STATUS_IDS.CANCELLED &&
                    project.projectStatus[project.projectStatus.length - 1]?.status?.id != null &&
                    project.userProjectCreators?.filter((creator) => {
                        return creator.creator.email === userInfo.email;
                    }).length > 0
                        ? [
                              {
                                  label: "addCreator",
                                  action: handleShowAddCreatorModal,
                                  color: "",
                              },
                          ]
                        : [];

                //Checks if the project status is one of this:
                //Pending Allocation of Resource
                //Not Agree & Reselecting
                //Reselected & Pending allocation of resources
                //Add the action "Allocate Resource" if the project status is one of the previous and is admin or hiring manager
                const statusAllowedForAllocateResources = [
                    PROJECT_STATUS_IDS.PENDING_ALLOCATION,
                    PROJECT_STATUS_IDS.NOT_AGREE_AND_SELECTING,
                    PROJECT_STATUS_IDS.PARTIAL_RESELECTING,
                ];

                if (
                    statusAllowedForAllocateResources.includes(
                        project.projectStatus[project.projectStatus.length - 1]?.status?.id
                    )
                ) {
                    if (isHiringManager)
                        actionsAllowed.push({
                            label: "rejectProject",
                            action: handleRejectProjectClick,
                            color: "",
                        });

                    actionsAllowed.push({
                        label: "allocateResources",
                        action: handleAllocateResourceClick,
                        color: "",
                    });
                }

                //Add the possibility of confirm payment when the status project is Team Confirmed
                if (
                    project.projectStatus[project.projectStatus.length - 1]?.status.id ==
                    PROJECT_STATUS_IDS.TEAM_CONFIRMED
                ) {
                    actionsAllowed.push({
                        label: "validateProject",
                        action: handleValidateProjectClick,
                        color: "",
                    });
                }

                //Add the possibility of cancel a project when the status is difference of Canceled or Deleted
                if (
                    project.projectStatus[project.projectStatus.length - 1]?.status?.id !=
                        PROJECT_STATUS_IDS.CANCELLED &&
                    project.projectStatus[project.projectStatus.length - 1]?.status?.id != null
                )
                    actionsAllowed.push({
                        label: "cancel",
                        action: handleCancelProjectClick,
                        color: "",
                    });

                actionsAllowed.push({
                    label: "projectTracing",
                    action: handleProjectTraceClick,
                    color: "",
                });

                if (project.isDeleted) {
                    actionsAllowed.length = 0;
                }

                return {
                    ...project,
                    actions: isAdminOrHiringManager ? actionsAllowed : [],
                };
            });

            setProjectsData(responseData);
        })();
    }, [activePage, totalPerPage, filterActive, searchInput, refreshData]);

    const getAllowedActions = (project) => {};

    const setViewAsList = () => {
        setViewSelectorCard(false);
    };

    const setViewAsCard = () => {
        setViewSelectorCard(true);
    };

    const handleSearchInputChange = (event) => {
        event.preventDefault();
        setSearchInput(event.target.value);
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
        setActivePage(1);
    };

    const handleFilterChange = (event) => {
        setFilterActive(parseInt(event.target.id));
    };

    const handleAddCreator = async (user) => {
        const response = await addProjectCreatorAsync(activeProjectId, user.value);

        if (!response.ok) {
            if (response.status === 401) {
                await forceLogout();
                return;
            }

            toast.error(t("unexpectedError") + ": " + (await response.text()));
            handleHideAddCreatorModal();
            return;
        }

        toast.success(t("addCreatorSuccess"));
        handleHideAddCreatorModal();
    };

    const handleProjectCancel = async () => {
        const response = await cancelProjectAsync(activeProjectId);

        if (!response.ok) {
            if (response.status === 401) {
                await forceLogout();
                return;
            }

            toast.error(t("unexpectedError") + ": " + (await response.text()));
            handleHideProjectCancelModal();
            return;
        }

        toast.success(t("cancelProjectSuccess"));
        handleHideProjectCancelModal();
        setRefreshData(!refreshData);
    };

    const handleHideAddCreatorModal = () => {
        setActiveProjectId(null);
        setShowAddCreatorModal(false);
    };

    const handleHideProjectCancelModal = () => {
        setActiveProjectId(null);
        setShowProjectCancelModal(false);
    };

    const handleRejectProject = async (cause) => {
        setIsLoading(true);
        try {
            const response = await rejectProjectAsync(activeProjectId, cause);
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                    return;
                }

                if (response.status === 403) {
                    toast.error(t("insufficientPermissions2"));
                    return;
                }

                toast.error(t("unexpectedError") + ": " + (await response.text()));
                return;
            }

            toast.success(t("projectRejectionSuccess"));
            setRefreshData(!refreshData);
            handleCloseRejectProjectModal();
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleValidateProject = async () => {
        setIsLoading(true);
        try {
            const response = await verifyProjectAsync(activeProjectId);
            if (!response.ok) {
                if (response.status === 401) {
                    await forceLogout();
                    return;
                }

                toast.error(t("unexpectedError") + ": " + (await response.text()));
                return;
            }

            toast.success(t("projectValidationSuccess"));
            setRefreshData(!refreshData);
            handleCloseValidateProjectModal();
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseValidateProjectModal = () => {
        setActiveProjectId(null);
        setShowValidateProjectModal(false);
    };

    const handleCloseRejectProjectModal = () => {
        setActiveProjectId(null);
        setShowRejectProjectModal(false);
    };

    const getProjectData = () => {
        return projectsData.projects.map((project) => {
            let totalResource = 0;
            let totalAssigned = 0;
            project.positions?.forEach((position) => {
                totalResource += position.total;
                totalAssigned += position.positionStaffs.length;
            });

            return {
                name: project.name,
                user: project.userProjectCreators[project.userProjectCreators.length - 1].creator.name,
                status:
                    project.projectStatus.length > 0
                        ? project.projectStatus[project.projectStatus.length - 1]?.status?.value
                        : t("deleted"),
                created_at: format(parseISO(project.createdAt), DATE_FORMAT),
                tot_res: `${totalAssigned} / ${totalResource}`,
                actions: project.actions,
            };
        });
    };

    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.filtersContainer}>
                    <div className={styles.filters}>
                        <a
                            id={FILTER_TYPES.ALL.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.ALL ? styles.filterActive : ""}
                        >
                            All
                        </a>
                        <a
                            id={FILTER_TYPES.PENDING.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.PENDING ? styles.filterActive : ""}
                        >
                            Pending
                        </a>
                        <a
                            id={FILTER_TYPES.INTERVIEW.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.INTERVIEW ? styles.filterActive : ""}
                        >
                            Interview
                        </a>
                        <a
                            id={FILTER_TYPES.CONFIRMED.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.CONFIRMED ? styles.filterActive : ""}
                        >
                            Confirmed
                        </a>
                        <a
                            id={FILTER_TYPES.DEVELOPMENT.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.DEVELOPMENT ? styles.filterActive : ""}
                        >
                            Development
                        </a>
                        <a
                            id={FILTER_TYPES.ALLOCATED.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.ALLOCATED ? styles.filterActive : ""}
                        >
                            Allocated
                        </a>
                        <a
                            id={FILTER_TYPES.FINISHED.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.FINISHED ? styles.filterActive : ""}
                        >
                            Finished
                        </a>
                        <a
                            id={FILTER_TYPES.ABANDONED.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.ABANDONED ? styles.filterActive : ""}
                        >
                            Abandoned
                        </a>
                        <a
                            id={FILTER_TYPES.CANCELED.toString()}
                            onClick={handleFilterChange}
                            className={filterActive === FILTER_TYPES.CANCELED ? styles.filterActive : ""}
                        >
                            Canceled
                        </a>
                    </div>
                    <div className={styles.searchContainer}>
                        <div className={styles.search}>
                            <label>{t("search")}</label>
                            <input
                                className={`${sharedStyle.inputText} px-2`}
                                value={searchInput}
                                onChange={handleSearchInputChange}
                            />
                        </div>
                        <div className={styles.selectorsContainer}>
                            <div className={styles.viewSelectorContainer}>
                                <div
                                    className={`${styles.viewSelectorList} ${
                                        viewSelectorCard ? "" : styles.viewSelectorActive
                                    }`}
                                    onClick={setViewAsList}
                                >
                                    <FontAwesomeIcon
                                        icon={faListUl}
                                        className={`${styles.icon} ${viewSelectorCard ? "" : styles.iconActive}`}
                                    />
                                </div>
                                <div
                                    className={`${styles.viewSelectorCard} ${
                                        viewSelectorCard ? styles.viewSelectorActive : ""
                                    }`}
                                    onClick={setViewAsCard}
                                >
                                    <FontAwesomeIcon
                                        icon={faBorderAll}
                                        className={`${styles.icon} ${viewSelectorCard ? styles.iconActive : ""}`}
                                    />
                                </div>
                            </div>
                            <div className={styles.totalPerPage}>
                                <select
                                    id="select-limit"
                                    className="py-1 px-2"
                                    defaultValue={totalPerPage}
                                    onChange={handleChangePerPage}
                                >
                                    {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                        return (
                                            <option key={index} value={rowLimit}>
                                                {rowLimit}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.contentContainer} mx-auto`}>
                    {viewSelectorCard ? (
                        <ProjectCardPaginated projects={projectsData.projects} />
                    ) : (
                        <AdminTable
                            headers={["Name", "User", "Status", "Finish At", "Tot/Res", ""]}
                            data={getProjectData()}
                        />
                    )}
                    <AddCreatorModal
                        onCancel={handleHideAddCreatorModal}
                        show={showAddCreatorModal}
                        onAccept={handleAddCreator}
                    />
                    <ConfirmProjectCancelModal
                        onCancel={handleHideProjectCancelModal}
                        show={showProjectCancelModal}
                        onAccept={handleProjectCancel}
                    />
                    <ValidateProjectModal
                        show={showValidateProjectModal}
                        onValidate={handleValidateProject}
                        onClose={handleCloseValidateProjectModal}
                    />
                    <RejectProjectModal
                        show={showRejectProjectModal}
                        onReject={handleRejectProject}
                        onClose={handleCloseRejectProjectModal}
                    />
                    <div className={styles.paginatorContainer}>
                        <Paginator
                            setPage={setActivePage}
                            activePage={activePage}
                            lastPage={Math.ceil(projectsData.total ? projectsData.total / totalPerPage : 1)}
                        />
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
        </>
    );
};

export default AdminProjects;
