import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./OptionLabel.module.scss";
import colors from "../../../base/js/colors";

const OptionLabel = ({ icon, children, iconColor = null }) => (
    <div className={`${iconColor !== colors.COLOR_DANGER ? style.genericStyle : ""} d-flex align-items-center`}>
        <FontAwesomeIcon icon={icon} className={`${style.icon} me-2`} color={iconColor} />
        {children}
    </div>
);

export default OptionLabel;
